import { useEffect, useState } from "react";
import "./Learners.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { learnerList, learnerSendMail } from "../redux/learner";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Sendmail = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [medicineIdList, setMedicineIdList] = useState([]);
  const [options, setOptions] = useState([]);
  const [addData, setVal] = useState("");
  const [subject, setSubject] = useState("")
  const selectedLearnerIds = sessionStorage.getItem("selectedLearnerIds");
  const selectedLearnerIdArray = selectedLearnerIds
    ? selectedLearnerIds.split(",")
    : [];
  const handleChange = (e) => {
    setOptions(Array.isArray(e) ? e.map((x) => x) : []);
  };

  const editorchange = (e, editor) => {
    const data = editor.getData();
    setVal(data);
  };

  const handleCancel = () => {
    setVal("");
    navigate("/learners");
  };

  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      learnerList((resp) => {
        if (resp.status) {
          var temp = [];
          resp.data.map((element) => {
            temp.push({ label: element.email, value: element._id });
          });
          setMedicineIdList([...medicineIdList, ...temp]);
          const tempOptions = resp.data.map((element) => ({
            label: element.email,
            value: element._id,
          }));
          const filteredOptions = tempOptions.filter((item) =>
            selectedLearnerIdArray.includes(item.value)
          );
          setOptions(filteredOptions);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, []);

  const emailSubmit = (event) => {
    event.preventDefault();
    var speciality = options.map((x) => x.value);
    let mailIds = sessionStorage.getItem("selectedLearnerIds");
    let data = {};
    data["selectedLearnersIds"] = speciality;
    data["subject"] = subject;
    data["message"] = addData;

    if (speciality.length === 0) {
      toast.error("Mail Required")
    }
    else if (!subject) {
      toast.error("Subject Required")
    } else if (!addData) {
      toast.error('Message Required')
    } else {
      dispatch(loader(true));
      dispatch(
        learnerSendMail(data, (resp) => {
          if (resp.status) {
            toast.success(resp.message);
            navigate("/learners");
            sessionStorage.clear();
            dispatch(loader(false));
          } else {
            dispatch(loader(false));
            toast.error(resp.message);
          }
        })
      );
    }

  };

  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <h3>Mail</h3>
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/learners">Learners</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="">Mail</Link>
              </p>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <div className="ExpertSBacKG">
                <div className="row">
                  <div className="col-12">
                    <form onSubmit={emailSubmit}>
                      <div className="row">
                        <div className="col-3">
                          <label>Email id's</label>
                        </div>
                        <div className="col-9">
                          <Select
                            isMulti
                            value={options}
                            onChange={handleChange}
                            options={medicineIdList}
                          />
                        </div>
                      </div>
                      <div className="row subjectDiv">
                        <div className="col-lg-3 col-3">
                          <label>Subject</label>
                        </div>
                        <div className="col-lg-9 col-9">
                          <input
                            type="text"
                            className="subjectBox"
                            name="subject"
                            id="courseTitle"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                          />

                        </div>
                      </div>
                      <div className="row pt-5">
                        <div className="col-3">
                          <label>Message</label>
                        </div>
                        <div className="col-9">
                          <CKEditor
                            editor={ClassicEditor}
                            data={addData}
                            onChange={editorchange}
                          />
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-3">
                          <div className="SHortsForm1">
                            <button className="Grn-Btn" type="submit">
                              send
                            </button>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="SHortsForm1">
                            <button
                              className="WHt-Btn"
                              type="button"
                              onClick={handleCancel}
                            >
                              cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Sendmail;
