import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./Faq.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import { useForm } from "react-hook-form";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { toast } from "react-toastify";
import { viewFAQList, editFAQCb, deleteFAQCb, addFAQCb } from "../redux/shorts";

const Faq = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showview, setShowview] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => {
    setShowview(false);
  };
  const handleShow1 = (data) => {
    setShowview(true);
    setEditQuestion(data.question);
    setEditAnswer(data.answer);
    setEditFaqData(data);
  };
  const [refresher, setRefresher] = useState(false);
  const [listFAQ, setListFAQ] = useState([]);
  const [editFaqData, setEditFaqData] = useState([]);
  const [editQuestion, setEditQuestion] = useState("");
  const [editAnswer, setEditAnswer] = useState("");
  const [checked, setChecked] = useState([]);

  const {
    register: register,
    control,
    formState: { errors: errors },
    reset: reset,
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur" });
  const {
    register: register1,
    control1,
    formState: { errors: errors1 },
    reset: reset1,
    handleSubmit: handleSubmit1,
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    dispatch(
      viewFAQList((resp) => {
        dispatch(loader(false));
        if (resp.status) {
          setListFAQ(resp.data);
          var arr = [];
          for (var i = 0; i < resp.data.length; i++) {
            arr.push(resp.data[i].isActive);
          }
          setChecked(arr);
        }
      })
    );
  }, [refresher]);

  const changeStatus = (id, index) => {
    let data = {};
    let value = {
      isHidden: !checked[index],
    };
    data["_id"] = id;
    data["isActive"] = value.isHidden;
    dispatch(loader(true));
    dispatch(
      editFAQCb(data, (response) => {
        if (response.status) {
          toast.success(response.message);
          dispatch(loader(false));
          setRefresher(!refresher);
        } else {
          dispatch(loader(false));
          toast.error(response.message);
        }
      })
    );
  };

  const testAddFn = (data) => {
    dispatch(loader(true));
    dispatch(
      addFAQCb(data, (response) => {
        if (response.status) {
          toast.success(response.message);
          dispatch(loader(false));
          reset();
          handleClose();
          setRefresher(!refresher);
        } else {
          dispatch(loader(false));
          toast.error(response.message);
        }
      })
    );
  };

  const editFaQFn = (data) => {
    function removeEmptyValues(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
            delete obj[key];
          }
        }
      }
    }

    removeEmptyValues(data);

    // Check if data is empty after removing empty values
    if (Object.keys(data).length === 0) {
      //toast.error("No changes made");
    } else {
      data["_id"] = editFaqData._id;
      dispatch(loader(true));
      dispatch(
        editFAQCb(data, (response) => {
          if (response.status) {
            toast.success(response.message);
            dispatch(loader(false));
            setRefresher(!refresher);
            reset1();
            handleClose1();
          } else {
            dispatch(loader(false));
            toast.error(response.message);
          }
        })
      );
    }
  };
  const editFaQFn1 = () => {
    let datas = {};
    datas["_id"] = editFaqData._id;
    datas["question"] = editQuestion;
    datas["answer"] = editAnswer;
    dispatch(loader(true));
    dispatch(
      editFAQCb(datas, (response) => {
        if (response.status) {
          toast.success(response.message);
          dispatch(loader(false));
          setRefresher(!refresher);
          reset1();
          handleClose1();
        } else {
          dispatch(loader(false));
          toast.error(response.message);
        }
      })
    );
  };

  const deleteAddFn = (id) => {
    let data = {};
    data["_id"] = id;
    dispatch(loader(true));
    dispatch(
      deleteFAQCb(data, (resp) => {
        if (resp.status) {
          dispatch(loader(false));
          setRefresher(!refresher);
          toast.success(resp.message);
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <h3>FAQ List</h3>
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/faq">Faq</Link>
              </p>
            </div>
            <div className="col-6">
              <div className="Grn-Btnmanin">
                <button className="Grn-Btn" onClick={handleShow}>
                  add faq
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <Table striped hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Question</th>
                    <th>Answer</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {listFAQ?.map((item, i) => {
                    return (
                      <>
                        <tr>
                          <td className="text-center">{i + 1}</td>
                          <td className="DivWidth faq">{item?.question}</td>
                          <td className="DivWidth faq">{item?.answer}</td>
                          <td>
                            <div className="d-flex justify-content-center">
                              <div className="">
                                <div className="OnOfF">
                                  <Form>
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      onChange={() => changeStatus(item._id, i)}
                                      checked={checked[i]}
                                    />
                                  </Form>
                                </div>
                              </div>
                              <div className="col-5">
                                <div className="Optionsfaq">
                                  <i>
                                    <BsThreeDotsVertical />
                                  </i>
                                  <ul class="hidden-list tstLi">
                                    <li
                                      onClick={() => {
                                        handleShow1(item);
                                      }}
                                    >
                                      Edit
                                    </li>
                                    <li
                                      onClick={() => {
                                        deleteAddFn(item._id, i);
                                      }}
                                    >
                                      Delete
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(testAddFn)}>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Question</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("question", {
                      required: "Question is required",
                    })}
                  />
                  {errors.question && (
                    <small style={{ color: "red" }}>
                      {errors.question.message}
                    </small>
                  )}
                </Form.Group>
              </div>
            </div>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Answer</Form.Label>
              <Form.Control
                as="textarea"
                placeholder=""
                style={{ height: "150px" }}
                {...register("answer", {
                  required: "Answer is required",
                })}
              />
              {errors.answer && (
                <small style={{ color: "red" }}>{errors.answer.message}</small>
              )}
            </Form.Group>
            <div className="d-flex justify-content-center">
              <button className="Grn-Btn Half" type="submit">
                add
              </button>
              <button
                className="WHt-Btn Half"
                type="button"
                onClick={handleClose}
              >
                cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* view modal */}
      <Modal show={showview} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit1(editFaQFn1)}>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Question</Form.Label>
                  <Form.Control
                    type="text"
                    value={editQuestion}
                    onChange={(e) => setEditQuestion(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Answer</Form.Label>
              <Form.Control
                as="textarea"
                value={editAnswer}
                style={{ height: "150px" }}
                onChange={(e) => setEditAnswer(e.target.value)}
              />
            </Form.Group>
            <div className="d-flex justify-content-center">
              <button className="Grn-Btn Half" type="submit">
                Update
              </button>
              <button className="WHt-Btn Half" onClick={handleClose1}>
                cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Faq;
