import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./Purchase.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { useDispatch } from "react-redux";
import { loader, isLogin } from "../redux/common";
import { BsThreeDotsVertical } from "react-icons/bs";
import { toast } from "react-toastify";
import { trainingOrderDetail, trainingOrderList } from "../redux/jobs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import moment from "moment";

const TrainingPurchase = () => {
  let dispatch = useDispatch();
  let [refresher, setRefresher] = useState(true);
  const [showview, setShowview] = useState(false);
  let [viewData, setViewData] = useState([]);
  const [localSearchValue, setLocalSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [selectedItems, setSelectedItems] = useState([]);
  const handleClose1 = () => setShowview(false);

  const handleShow1 = (id) => {
    let data = {};
    data["traineeId"] = id;
    setShowview(true);
    dispatch(
      trainingOrderDetail(data, (resp) => {
        dispatch(loader(true));
        if (resp.status) {
          setViewData(resp.data);
          console.log("filter123", resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const sortDataByDate = (data) => {
    return data.sort((a, b) => {
      const dateA = new Date(
        a.trans_date.split(" ")[0].split("/").reverse().join("-")
      );
      const dateB = new Date(
        b.trans_date.split(" ")[0].split("/").reverse().join("-")
      );
      return dateB - dateA;
    });
  };

  const sortedData = sortDataByDate(filteredData);

  useEffect(() => {
    fetchData({ search: localSearchValue });
  }, [refresher, localSearchValue]);

  const fetchData = (data) => {
    dispatch(
      trainingOrderList(data, (resp) => {
        dispatch(loader(true));
        if (resp.status) {
          setFilteredData(resp.data);
          console.log("filter", resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setLocalSearchValue(value);
  };

  const handleCheckboxChange = (Id) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(Id)) {
        // If educatorId is already selected, remove it
        return prevSelected.filter((id) => id !== Id);
      } else {
        // If educatorId is not selected, add it
        return [...prevSelected, Id];
      }
    });
  };

  const paidForMonthDate = new Date(viewData?.map((item) => item.paidForMonth));

  const day = paidForMonthDate.getDate();
  const month = paidForMonthDate.getMonth() + 1; // Months are 0-based
  const year = paidForMonthDate.getFullYear();

  const formattedDueDate = `${day}/${month}/${year}`;

  function addSuffix(num) {
    const j = num % 10;
    const k = num % 100;
    if (j === 1 && k !== 11) {
      return num + "st";
    }
    if (j === 2 && k !== 12) {
      return num + "nd";
    }
    if (j === 3 && k !== 13) {
      return num + "rd";
    }
    return num + "th";
  }
  const generatePDF = () => {
    const doc = new jsPDF();
    let dataToExport =
      selectedItems.length > 0
        ? filteredData.filter((item) => selectedItems.includes(item._id))
        : filteredData;
    const tableData = dataToExport.map((item, index) => {
      return [
        index + 1,
        item?.paymentDetails?.billing_name
          ? item?.paymentDetails?.billing_name
          : item?.traineeInfo?.fullName,
        item?.paymentDetails?.billing_email
          ? item?.paymentDetails?.billing_email
          : item?.traineeInfo?.email,
        item?.paymentDetails?.amount
          ? `INR ${item?.paymentDetails?.amount}`
          : `INR ${item?.amount.toFixed(2)}`,
        item?.trans_date
          ? moment(item.trans_date, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY")
          : "-",
        item?.status,
      ];
    });

    doc.autoTable({
      head: [["S.No", "Name", "Email", "Paid Amount", "Paid on", "Status"]],
      body: tableData,
      columnStyles: {
        0: { cellWidth: 15 },
        1: { cellWidth: 35 },
        2: { cellWidth: 50 },
        3: { cellWidth: 35 },
        4: { cellWidth: 30 },
        5: { cellWidth: 20 },
      },
      styles: {
        overflow: "linebreak",
        columnStyles: { 5: { lineHeight: 1.5 } },
      },
    });

    doc.save("direct_training_purchase_list.pdf");
  };

  const exportToExcel = () => {
    let dataToExport =
      selectedItems.length > 0
        ? filteredData.filter((item) => selectedItems.includes(item._id))
        : filteredData;
    const tableData = dataToExport.map((item, index) => {
      return {
        "S.No": index + 1,
        Name: item?.paymentDetails?.billing_name
          ? item?.paymentDetails?.billing_name
          : item?.traineeInfo?.fullName,
        Email: item?.paymentDetails?.billing_email
          ? item?.paymentDetails?.billing_email
          : item?.traineeInfo?.email,
        "Paid Amount": item?.paymentDetails?.amount
          ? `INR ${item?.paymentDetails?.amount}`
          : `INR ${item?.amount.toFixed(2)}`,
        "Paid On": item?.trans_date
          ? moment(item.trans_date, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY")
          : "-",
        Status: item?.status,
      };
    });

    const ws = XLSX.utils.json_to_sheet(tableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Purchase History");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "direct_training_purchase_list.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="Categorypage">
        <div className="PageTit ">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h3 className="purcHist">Direct Training Purchase history</h3>
                <p>
                  <Link to="/dashboard">Dashboard</Link>{" "}
                  <i>
                    <AiOutlineRight />
                  </i>{" "}
                  <Link to="/training_purchase_list">
                    Direct Training Purchase history
                  </Link>
                </p>
              </div>
              <div className="col-6"></div>
            </div>
          </div>
        </div>
        <div className="CateCont">
          <div className="container">
            <div className="row">
              <div className="CateGorTab">
                <div className="col-12">
                  <div className="searchBtNs">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="BtNSSearcH">
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Search"
                              aria-describedby="basic-addon2"
                              value={localSearchValue}
                              onChange={handleSearchInputChange}
                            />
                            <Button
                              variant="outline-secondary"
                              id="button-addon2"
                            >
                              <BsSearch />
                            </Button>
                          </InputGroup>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6"></div>

                      <div className="col-lg-2 col-md-6">
                        <div className="BtNSSearcH pur">
                          <button className="Grn-Btn pdf" onClick={generatePDF}>
                            Export as PDF
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-6">
                        <div className="BtNSSearcH pur">
                          <button
                            className="Grn-Btn pdf"
                            onClick={exportToExcel}
                          >
                            Export as Excel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Table
                  striped
                  hover
                  id="purchaseTable"
                  style={{ display: "none" }}
                >
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" />
                      </th>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Email Address</th>
                      <th>Mobile Number</th>
                      <th>Paid Amount</th>
                      <th>Payment Details</th>
                      <th>Purchase Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <input type="checkbox" />
                          </td>
                          <td>{index + 1}</td>
                          <td>
                            {item?.paymentDetails?.billing_name
                              ? item?.paymentDetails?.billing_name
                              : item?.traineeInfo?.fullName}
                          </td>
                          <td>
                            {item?.paymentDetails?.billing_email
                              ? item?.paymentDetails?.billing_email
                              : item?.traineeInfo?.email}
                          </td>
                          <td>
                            {item?.paymentDetails?.amount
                              ? `₹ ${item?.paymentDetails?.amount.toFixed(2)}`
                              : `₹ ${item?.amount}`}{" "}
                          </td>
                          <td>
                            {item.trans_date
                              ? moment(
                                  item.trans_date,
                                  "DD/MM/YYYY HH:mm:ss"
                                ).format("DD/MM/YYYY")
                              : ""}
                          </td>
                          <td>{item?.status}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {/* <Table striped hover>
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" />
                      </th>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Email Address</th>
                      <th>Paid Amount</th>
                      <th>Purchase Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((item, index) => {
                      const formattedDate = item.trans_date
                        ? item.trans_date.split(" ")[0].split("/").join("/")
                        : "";

  return (
    {item.orders.map(training, trainingIndex)=>{
      <div key={index}>
    <tr >
      <td>
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(item._id)}
          checked={selectedItems.includes(item._id)}
        />
      </td>
      <td>{index + 1}</td>
      <td>{item.billing_name}</td>
      <td>{item.billing_email}</td>
      <td>{`₹${item.amount}`}</td>
      <td>{formattedDate}</td>
      <td>{item.status}</td>
      <td>
        <div>
          <div className="Options pt-1">
            <i>
              <BsThreeDotsVertical />
            </i>
            <ul className="hidden-list">
              <li onClick={() => handleShow1(item._id)}>
                View
              </li>
            </ul>
          </div>
        </div>
      </td>
    </tr>
    </div>
  );
}}
                     
                    })}
                  </tbody>
                </Table> */}
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" />
                      </th>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Email Address</th>
                      <th>Mobile Number</th>
                      <th>Purchase Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedData.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              onChange={() => handleCheckboxChange(item._id)}
                              checked={selectedItems.includes(item._id)}
                            />
                          </td>
                          <td>{index + 1}</td>
                          <td>
                            {item?.paymentDetails?.billing_name
                              ? item?.paymentDetails?.billing_name
                              : item?.traineeInfo?.fullName}
                          </td>
                          <td>
                            {item?.paymentDetails?.billing_email
                              ? item?.paymentDetails?.billing_email
                              : item?.traineeInfo?.email}
                          </td>
                          <td>
                            {item?.traineeInfo?.mobileNumber
                              ? item?.traineeInfo?.mobileNumber
                              : "-"}{" "}
                          </td>
                          <td>
                            {item.trans_date
                              ? moment(
                                  item.trans_date,
                                  "DD/MM/YYYY HH:mm:ss"
                                ).format("DD/MM/YYYY")
                              : ""}
                          </td>
                          <td style={{ textTransform: "capitalize" }}>
                            {item?.status}
                          </td>
                          <td>
                            <div>
                              <div className="Options pt-1">
                                <i>
                                  <BsThreeDotsVertical />
                                </i>
                                <ul className="hidden-list">
                                  <li
                                    onClick={() =>
                                      handleShow1(item.traineeInfo._id)
                                    }
                                  >
                                    View
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showview} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>View Purchase Detailssss</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon3">Name</InputGroup.Text>
                <Form.Control
                  id="basicborder"
                  aria-describedby="basic-addon3"
                  value={viewData[0]?.traineeId?.fullName}
                  readOnly
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon3">Email</InputGroup.Text>
                <Form.Control
                  id="basicborder"
                  aria-describedby="basic-addon3"
                  value={viewData[0]?.traineeId?.email}
                  readOnly
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon3">
                  Mobile Number
                </InputGroup.Text>
                <Form.Control
                  id="basicborder"
                  aria-describedby="basic-addon3"
                  value={viewData[0]?.traineeId?.mobileNumber}
                  readOnly
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon3">
                  Current Address
                </InputGroup.Text>
                <Form.Control
                  id="basicborder"
                  aria-describedby="basic-addon3"
                  value={
                    viewData[0]?.traineeId?.currentAddress +
                    " " +
                    viewData[0]?.traineeId?.currentAddress2 +
                    viewData[0]?.traineeId?.city +
                    " " +
                    viewData[0]?.traineeId?.state +
                    " " +
                    viewData[0]?.traineeId?.pincode
                  }
                  readOnly
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon3">
                  Permanent Address
                </InputGroup.Text>
                <Form.Control
                  id="basicborder"
                  aria-describedby="basic-addon3"
                  value={
                    viewData[0]?.traineeId?.pcurrentAddress +
                    " " +
                    viewData[0]?.traineeId?.pcurrentAddress2 +
                    viewData[0]?.traineeId?.pcity +
                    " " +
                    viewData[0]?.traineeId?.pstate +
                    " " +
                    viewData[0]?.traineeId?.ppincode
                  }
                  readOnly
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon3">Designation</InputGroup.Text>
                <Form.Control
                  id="basicborder"
                  aria-describedby="basic-addon3"
                  value={viewData[0]?.traineeId?.designation}
                  readOnly
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon3">Role</InputGroup.Text>
                <Form.Control
                  id="basicborder"
                  aria-describedby="basic-addon3"
                  value={viewData[0]?.traineeId?.role}
                  readOnly
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon3">
                  Company Name
                </InputGroup.Text>
                <Form.Control
                  id="basicborder"
                  aria-describedby="basic-addon3"
                  value={viewData[0]?.traineeId?.companyName}
                  readOnly
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon3">Experience</InputGroup.Text>
                <Form.Control
                  id="basicborder"
                  aria-describedby="basic-addon3"
                  value={viewData[0]?.traineeId?.experience}
                  readOnly
                />
              </InputGroup>
            </Form>

            <Table striped bordered hover>
              <thead>
                <tr className="text-center">
                  <th>Order ID</th>
                  <th>Installments</th>
                  <th>Due Date</th>
                  <th>Transaction Date</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {viewData?.map((item) => {
                  const paidForMonthDate = new Date(item?.paidForMonth);

                  const originalDate = moment(
                    item?.paymentDetails?.trans_date,
                    "DD/MM/YYYY HH:mm:ss"
                  );
                  const formattedDate = originalDate.format("DD/MM/YYYY");

                  const options = {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  };

                  // Customize the order of date components
                  const formattedDueDate = moment(item?.paidForMonth).format(
                    "DD/MM/YYYY"
                  );

                  const [month, day, year, time] = formattedDueDate.split("/"); // Split date and time
                  const rearrangedDate = `${day}/${month}/${year}`; // Rearrange components

                  let transDate = item?.paymentDetails?.trans_date;
                  if (!transDate || transDate === "undefined") {
                    // Check for falsy or 'undefined' string
                    transDate = rearrangedDate;
                  }
                  const gstAmount =
                    Number(item?.installmentInfo?.amount) * 0.18;
                  return (
                    <tr key={item._id}>
                      <td>
                        {item.paymentDetails?.order_id
                          ? `#${item?.paymentDetails?.order_id
                              .slice(-7)
                              .toUpperCase()}`
                          : "-"}
                      </td>
                      <td>{item?.installmentInfo?.title} Month</td>
                      <td>{formattedDueDate}</td>{" "}
                      {/* Include time with space */}
                      <td>
                        {formattedDate && formattedDate !== "Invalid date"
                          ? formattedDate
                          : "-"}
                      </td>
                      <td>
                        {(
                          Number(item?.installmentInfo.amount) + gstAmount
                        ).toFixed(2)}
                      </td>
                      <td style={{ textTransform: "capitalize" }}>
                        {item?.status}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
      ;
    </>
  );
};
export default TrainingPurchase;
