import { TbDeviceTabletPause, TbFileUpload } from "react-icons/tb";
import "./Experts.css";
import { Link, useNavigate } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Topbar from "../../components/topbar/Topbar";
import SideBar from "../../components/Sidebar/SideBar";
import { addShortsList } from "../redux/shorts";
import { useDispatch } from "react-redux";
import { loader, isLogin } from "../redux/common";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
const Addexperts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [previewImage, setPreviewImage] = useState();
  const [imageFile, setImageFile] = useState();

  const addShortList = (data) => {
    let formData = new FormData();
    formData.append("videoUrl", data.videoUrl);
    formData.append("thumbnail", imageFile);
    formData.append("title", data.Title);

    dispatch(loader(true));
    dispatch(
      addShortsList(formData, (resp) => {
        if (resp.status) {
          dispatch(loader(false));
          toast.success(resp.message);
          navigate("/list_to_experts");
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const handleProfilePic = (event) => {
    const selectedFile = event.target.files[0];
    setImageFile(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };

      reader.readAsDataURL(selectedFile);
    } else {
      //   setPreviewImage("");
    }
  };
  const {
    register: register,
    formState: { errors: errors },
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur" });
  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <h3>Add Shorts</h3>
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/list_to_experts">Listen to Experts</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="">Add Shorts</Link>
              </p>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <div className="TittlE">
                <h4>Add Shorts</h4>
              </div>
              <div className="ExpertSBacKG">
                <form onSubmit={handleSubmit(addShortList)}>
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="ThumBnail blogcnt">
                        <div className="thumbnail-container adBlog">
                          <div className="blogadd">
                            {previewImage ? null : (
                              <label
                                htmlFor="file-upload"
                                className="file-upload-label"
                                style={{ paddingTop: "77px" }}
                              >
                                Image Dimensions (1700 x 1133)
                              </label>
                            )}
                            <input
                              id="file-upload"
                              type="file"
                              className="fileUpload"
                              onChange={handleProfilePic}
                              accept="image/*"
                            />
                            {previewImage && (
                              <div className="thumbnail-preview">
                                <img
                                  src={previewImage}
                                  alt="Thumbnail Preview"
                                  className="imgthumbnails"
                                />
                              </div>
                            )}
                          </div>
                          <div className="iconUpload">
                            <label
                              for="file-upload"
                              className="custom-file-upload"
                            >
                              <span className="text-center">
                                <TbFileUpload className="iconuploads blogicon uplIcon" />
                              </span>
                              <p className="uploadthumbs blogpad clkBlog">
                                Click here to upload
                              </p>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-9 pt-3">
                      <div className="row">
                        <div className="col-12">
                          <div className="SHortsForm">
                            <label>Title</label>
                            <br></br>
                            <input
                              type="text"
                              placeholder="Enter Title"
                              className="FormShorts"
                              name="Title"
                              {...register("Title", {
                                required: "Title is required",
                              })}
                            />
                            {errors.Title && (
                              <small style={{ color: "red" }}>
                                {errors.Title.message}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="SHortsForm">
                            <label>Video URL</label>
                            <br />
                            <input
                              type="text"
                              placeholder="https://www.youtube.com/embed/nmHzvQr3kYE"
                              className="FormShorts"
                              {...register("videoUrl", {
                                required: "Video URL is required",
                                pattern: {
                                  value:
                                    /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
                                  message: "Invalid URL format",
                                },
                              })}
                            />
                            {errors.videoUrl && (
                              <small style={{ color: "red" }}>
                                {errors.videoUrl.message}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <div className="SHortsForm1">
                            <button className="Grn-Btn" type="submit">
                              add
                            </button>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="SHortsForm1">
                            <button
                              className="WHt-Btn"
                              type="button"
                              onClick={() => navigate("/list_to_experts")}
                            >
                              cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Addexperts;
