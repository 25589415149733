import Form from "react-bootstrap/Form";
import "./Banners.css";
import { Link, useNavigate } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  liveBannersList,
  deleteLiveBanner,
  liveChangeStatus,
} from "../redux/liveprogram";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

const Liveprogram = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [bannerList, setBannerList] = useState([]);
  let [refresher, setRefresher] = useState(true);
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      liveBannersList((resp) => {
        if (resp.status) {
          setBannerList(resp.data);

          var arr = [];
          for (var i = 0; i < resp.data.length; i++) {
            arr.push(resp.data[i].isActive);
          }
          setChecked(arr);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, [refresher]);

  const DeleteBanner = (id) => {
    let data = {};
    data["bannerId"] = id;
    dispatch(loader(true));
    dispatch(
      deleteLiveBanner(data, (resp) => {
        if (resp.status) {
          toast.success(resp.message);
          setRefresher(!refresher);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const changeStatusBanner = (id, index) => {
    let data = {};
    let value = {
      isActive: !checked[index],
    };

    data["bannerId"] = id;
    data["isActive"] = value.isActive;

    dispatch(loader(true));
    dispatch(
      liveChangeStatus(data, (response) => {
        if (response.status) {
          toast.success(response.message);
          dispatch(loader(false));
          setRefresher(!refresher);
        } else {
          dispatch(loader(false));
          toast.error(response.message);
        }
      })
    );
  };
  return (
      <div className="Categorypage">
        <div className="PageTit ">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h3>Live Program Banner</h3>
                <p>
                  <Link to="/dashboard">Dashboard</Link>{" "}
                  <i>
                    <AiOutlineRight />
                  </i>{" "}
                  <Link to="/">Content update</Link>{" "}
                  <i>
                    <AiOutlineRight />
                  </i>{" "}
                  <Link to="/">Live Program Banner</Link>
                </p>
              </div>
              <div className="col-6">
                <div className="Grn-Btnmanin">
                  <Link to="/add_liveprogram" className="Grn-Btn">
                    add banner
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="CateCont">
          <div className="container">
            <div className="row">
              <div className="CateGorTab">
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Thumbnail</th>
                      <th>Url</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bannerList.map((item, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td className="TabImg banner">
                          <img src={item.thumbnail} className="img-fluid" />
                        </td>
                        <td className="DivWidth banner">{item.videoUrl}</td>

                        <td >
                          <div  className="d-flex justify-content-center" >
                          <div className=" ">
                            <div className="OnOfF">
                              <Form>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  onChange={() =>
                                    changeStatusBanner(item._id, index)
                                  }
                                  checked={checked[index]}
                                />
                              </Form>
                            </div>
                          </div>
                          <div className="col-5">
                            <div className="Options pt-1">
                              <i>
                                <BsThreeDotsVertical />
                              </i>
                              <ul class="hidden-list">
                                <li
                                  onClick={() =>
                                    navigate(
                                      "/edit_liveprogrambanner/" + item._id
                                    )
                                  }
                                >
                                  Edit
                                </li>
                                <li onClick={() => DeleteBanner(item._id)}>
                                  Delete
                                </li>
                              </ul>
                            </div>
                          </div>
                          </div>
                        
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
 
  );
};
export default Liveprogram;
