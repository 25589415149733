import React, { useState, useEffect } from "react";
import "../Course/Course.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import {
  CoursesStatus,
  ratingsFetchCb,
  ratingsListCb,
  ratingsListDelete,
  ratingsStatus,
} from "../redux/courses";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiFillStar } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { loader } from "../redux/common";

const RatingsandReviews = () => {
  let dispatch = useDispatch();
  let [refresher, setRefresher] = useState(true);
  const [toggled, setToggled] = useState([]);
  const [showview, setShowview] = useState(false);
  let [viewData, setViewData] = useState({});
  const [filteredCourses, setFilteredCourses] = useState([]);
  const handleClose1 = () => setShowview(false);
  const handleShow1 = (id) => {
    let data = {};
    data["rrId"] = id;
    setShowview(true);
    dispatch(loader(true));
    dispatch(
      ratingsFetchCb(data, (resp) => {
        if (resp.status) {
          console.log(resp)
          setViewData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  useEffect(() => {
    dispatch(
      ratingsListCb((resp) => {
        dispatch(loader(true));
        if (resp.status) {
          setFilteredCourses(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, [refresher]);

  const deleteCourse = (id, i) => {
    let data = {};
    data["rrId"] = id;
    dispatch(loader(true));
    dispatch(
      ratingsListDelete(data, (resp) => {
        if (resp.status) {
          toast.success(resp.message);
          setRefresher(!refresher);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const changeStatus = (id, index) => {
    if (filteredCourses[index].rrInfo.isApproved) {
      return;
    }
    let data = {};
    let value = {
      isApproved: !toggled[index],
    };

    data["rrId"] = id;
    dispatch(loader(true));
    const updatedToggled = [...toggled];
    updatedToggled[index] = value.isApproved;
    setToggled(updatedToggled);
    dispatch(
      ratingsStatus(data, (response) => {
        if (response.status) {
          toast.success(response.message);
          dispatch(loader(false));
        } else {
          toast.error(response.message);
          dispatch(loader(false));
        }
      })
    );
  };

  return (
    <>
      <div className="Categorypage">
        <div className="PageTit ">
          <div className="container">
            <div className="row">
              <div className="col-6 col-md-5">
                <h3>Ratings and Reviews</h3>
                <p>
                  <Link to="/dashboard">Dashboard</Link>{" "}
                  <i>
                    <AiOutlineRight />
                  </i>{" "}
                  <Link to="/reviews">Ratings and Reviews</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="CateCont">
          <div className="container">
            <div className="row">
              <div className="CateGorTab ratingRe">
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Designation</th>
                      <th>Ratings</th>
                      <th>Reviews</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredCourses?.length === 0 ? (
                      <tr>
                        <td colSpan="6">No courses found.</td>
                      </tr>
                    ) : (
                      filteredCourses?.map((item, i) => (
                        <tr key={item._id}>
                          <td>{i + 1}</td>
                          <td>{item?.userInfo?.fullName}</td>
                          <td>
                            {item?.userInfo?.userType ===
                              "workingProfessional" &&
                            item?.userProfessionalInfo?.designation
                              ? item?.userProfessionalInfo?.designation
                              : item?.userInfo?.userType === "student" &&
                                item?.userAcademicInfo?.degreeOfStream
                              ? item?.userAcademicInfo?.degreeOfStream
                              : ""}
                          </td>
                          <td>
                            <p className="star">
                              {[...Array(item?.rrInfo?.rating)].map(
                                (star, index) => (
                                  <i key={index}>
                                    <AiFillStar />
                                  </i>
                                )
                              )}
                            </p>
                          </td>
                          <td>{item?.rrInfo?.reviews}</td>
                          <td>
                            <div
                              className="d-flex"
                              style={{ borderLeft: "none", gap: "10px" }}
                            >
                              <div className="">
                              <button
                                className={
                                  item?.rrInfo?.isApproved
                                    ? "GrnActn"
                                    : "RedDel"
                                }
                                onClick={() => changeStatus(item.rrInfo._id, i)}
                              >
                                {item?.rrInfo?.isApproved
                                  ? "Approved"
                                  : "Pending"}
                              </button>
                              </div>
                             
                              <div className="Options rATing pt-1">
                                <i>
                                  <BsThreeDotsVertical />
                                </i>
                                <ul className="hidden-list tstLi">
                                  <li
                                    onClick={() => handleShow1(item.rrInfo._id)}
                                  >
                                    View
                                  </li>
                                  <li
                                    onClick={() =>
                                      deleteCourse(item.rrInfo._id, i)
                                    }
                                  >
                                    Delete
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* view modal */}
      <Modal show={showview} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Review Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover responsive>
            <tbody>
              <tr>
                <td>Name</td>
                <td>{viewData.fullName}</td>
              </tr>
              <tr>
                <td>Designation</td>
                <td>{viewData.designation}</td>
              </tr>
              <tr>
                <td>Rating</td>
                <td>{viewData.rating}</td>
              </tr>
              <tr>
                <td>Review</td>
                <td>{viewData.reviews}</td>
              </tr>
              <tr>
                <td>Course Name</td>
                <td>{viewData.title}</td>
              </tr>
              
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RatingsandReviews;
