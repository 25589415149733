
import { createSlice } from "@reduxjs/toolkit";
import Helper from "../../helper/axiosHelper";
const baseUrl = Helper.baseUrl();

const initialState = {
  //   firstSignUp: {},
};

export const jobsSlice = createSlice({
  name: "jobList",
  initialState,
  reducers: {},
});

export const { firstSignUp } = jobsSlice.actions;

export default jobsSlice.reducer;




export const addBannerSubScription =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.formData(
      baseUrl + "subscription_banner/add_subscription_banner",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const viewJobDetail =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + `job/job_detail/${data}`
    ).then((response) => response.data);
    callback(result);
  };
  export const statesList =
  ( callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + "job/list_of_states"
    ).then((response) => response.data);
    callback(result);
  };

  export const viewApplicantDetail =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + `job/applicant_Information/${data}`
    ).then((response) => response.data);
    callback(result);
  };

  export const addJobs =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "job/add_new_job",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const editSubBanner =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.pathFormData(
      baseUrl + "subscription_banner/edit_subscription_banner",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const deleteSubBanner =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "subscription_banner/delete_subscription_banner",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const editJobList =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(
      baseUrl + "job/update_job_detail",
      data
    ).then((response) => response.data);
    callback(result);
  };
  export const addSubscriptionPlan =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "subscription_plan/add_subscription_plan",
      data
    ).then((response) => response.data);
    callback(result);
  };
  export const jobListData =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + "job/job_list"
    ).then((response) => response.data);
    callback(result);
  };
  export const activeJobList =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "job/active_job_list", data
    ).then((response) => response.data);
    callback(result);
  };
  export const orderList =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "order/list_of_orders", data
    ).then((response) => response.data);
    callback(result);
  };
  export const subOrderList =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "my_subscription_plan/list_of_SubscriptionPlans", data
    ).then((response) => response.data);
    callback(result);
  };
  export const trainingOrderList =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "direct_training/list_of_orders", data
    ).then((response) => response.data);
    callback(result);
  };
  export const subOrderDetail =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "my_subscription_plan/subscription_plan_info", data
    ).then((response) => response.data);
    callback(result);
  };
  export const orderDetail =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "order/order_detail", data
    ).then((response) => response.data);
    callback(result);
  };
  export const trainingOrderDetail =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "direct_training/order_info", data
    ).then((response) => response.data);
    callback(result);
  };
  export const JobApplicantsList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + "job/applicant_list"
    ).then((response) => response.data);
    callback(result);
  };
  export const editSubscriptionPlan =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(
      baseUrl + "subscription_plan/edit_subscription_plan",
      data
    ).then((response) => response.data);
    callback(result);
  };
  export const deleteJob =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper. deleteData(
      baseUrl + `job/delete_job/${data}`,
    ).then((response) => response.data);
    callback(result);
  };
  export const SubscriptionPlanInfo =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "subscription_plan/subscription_plan_info",
      data
    ).then((response) => response.data);
    callback(result);
  };
  export const activeSubscriptionPlanList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + "subscription_plan/active_subscription_plan_list"
    ).then((response) => response.data);
    callback(result);
  };