import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import Helper from "../../helper/axiosHelper"
const baseUrl = Helper.baseUrl();

const initialState = {
    firstSignUp:{},
    secSignUp:{},
    
    
};


export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        firstSignUp: (state,action) => {
            state.firstSignUp = action.payload;
        },
        secSignUp: (state,action) => {
            state.secSignUp = action.payload;
        },
    }
});

export const {firstSignUp,secSignUp,getReasonList} = userSlice.actions;

export default userSlice.reducer;



export const getReasonListCb = ( callback = () => { }) => async (dispatch) => {
    var result = await Helper.getData(baseUrl + "user/reasons")
        .then((response) => response.data)
    callback(result);
};

export const userRegisterCb = (data, callback = () => { }) => async (dispatch) => {
    var result = await Helper.postData(baseUrl + "user/register",data)
        .then((response) => response.data)
    callback(result);
};

export const userLoginCb = (data, callback = () => { }) => async (dispatch) => {
    var result = await Helper.postData(baseUrl + "user/login",data)
        .then((response) => response.data)
    callback(result);
};

export const userForgotPassCb = (data, callback = () => { }) => async (dispatch) => {
    var result = await Helper.postData(baseUrl + "user/forgotPassword",data)
        .then((response) => response.data)
    callback(result);
};


export const userForgotPassSecCb = (data, callback = () => { }) => async (dispatch) => {
    var result = await Helper.postData(baseUrl + "user/confirmPassword",data)
        .then((response) => response.data)
    callback(result);
};


export const educatorSignupCb = (data, callback = () => { }) => async (dispatch) => {
    var result = await Helper.postData(baseUrl + "educator/signUpFrom",data)
        .then((response) => response.data)
    callback(result);
};

export const educatorRegisterCb = (data, callback = () => { }) => async (dispatch) => {
    var result = await Helper.formData(baseUrl + "educator/signUpFrom",data)
        .then((response) => response.data)
    callback(result);
};