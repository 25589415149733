import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import "./Adminlist.css";
import { Link, useNavigate } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Topbar from "../../components/topbar/Topbar";
import SideBar from "../../components/Sidebar/SideBar";
import { BsThreeDotsVertical } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import {
  add_admin_users,
  change_admin_user_status,
  delete_admin_user,
  list_Of_admin_users,
  list_of_admin_roles,
} from "../redux/login";
import { toast } from "react-toastify";
import { loader } from "../redux/common";
import { useDispatch } from "react-redux";
import { educatorEmailIds } from "../redux/Educators";
import { useForm } from "react-hook-form";

const Adminlist = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showview, setShowview] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShowview(false);

  let [refresher, setRefresher] = useState(true);
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState(0);
  const [adminRoles, setAdminRoles] = useState([]);
  const [searchtypeOrRole, setSearchtypeOrRole] = useState("");
  const [searchName, setSearchName] = useState("");
  const [adminList, setAdminList] = useState([]);
  const [educatorData, setEducatorData] = useState([]);
  const [showEducatorDropdown, setShowEducatorDropdown] = useState(false);
  const [isEducatorSelected, setIsEducatorSelected] = useState(false);
  const [singleEduData, setSingleEduData] = useState([]);

  const {
    register: register,
    formState: { errors: errors },
    reset: reset,
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur" });

  const handleSubmitFn = (e) => {
    e.preventDefault();
    if (!type) {
      toast.error("Please select a role.");
      return;
    }

    const selectedRole = adminRoles.find((role) => role.role === type);

    if (!selectedRole) {
      toast.error("Invalid role selected.");
      return;
    }

    let data = {};

    data["name"] = name;
    data["roleId"] = selectedRole._id;
    data["email"] = email;
    data["status"] = Number(status);

    dispatch(loader(true));
    dispatch(
      add_admin_users(data, (result) => {
        if (result.status) {
          toast.success(result.message);
          setType("");
          setName("");
          setEmail("");
          setStatus(0);
          handleClose();
          reset();
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(result.message);
        }
      })
    );
  };

  const handleSearch = (event) => {
    let data = {
      username: searchName,
      type: searchtypeOrRole,
    };
    dispatch(loader(true));
    dispatch(
      list_Of_admin_users(data, (result) => {
        if (result.status) {
          setAdminList(result.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );
  };

  const handleChangeStatus = (adminId, event) => {
    let data = {
      adminId: adminId,
    };
    dispatch(loader(true));
    dispatch(
      change_admin_user_status(data, (result) => {
        if (result.status) {
          handleSearch(event);
          toast.success(result.message);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(result.message);
        }
        dispatch(loader(false));
      })
    );
  };

  const educatorSelect = (event) => {
    const selectedEducatorId = event.target.value;
    if (selectedEducatorId === "") {
      setIsEducatorSelected(false);
    } else {
      const selectedEducator = educatorData.find(
        (educator) => educator._id === selectedEducatorId
      );
      setSingleEduData(selectedEducator);
      setIsEducatorSelected(true);
      setName(selectedEducator.name);
      setEmail(selectedEducator.email);
    }
  };

  const deleteAdminUser = (adminId, event) => {
    let data = {
      adminId: adminId,
    };
    dispatch(loader(true));
    dispatch(
      delete_admin_user(data, (result) => {
        if (result.status) {
          handleSearch(event);
          toast.success(result.message);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(result.message);
        }
      })
    );
  };

  useEffect(() => {
    const obj = { username: searchName, type: searchtypeOrRole };
    dispatch(loader(true));
    dispatch(
      list_Of_admin_users(obj, (resp) => {
        if (resp.status) {
          setAdminList(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );
    dispatch(
      list_of_admin_roles((resp) => {
        if (resp.status) {
          setAdminRoles(
            resp.data.filter((role) => role.role !== "super_admin")
          );
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );

    if (type === "educator") {
      setShowEducatorDropdown(true);
    } else {
      setShowEducatorDropdown(false);
    }
    dispatch(
      educatorEmailIds((resp) => {
        if (resp.status) {
          setName(resp.data.name);
          setEmail(resp.data.email);
          setEducatorData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );
  }, [searchName, searchtypeOrRole, type, refresher]);

  useEffect(()=> {
    if(type !== "educator"){
      setIsEducatorSelected(false);
      setName("");
      setEmail("");
    }
  }, [type])

  const handleUserView = (id) => {
    const adminUserInfo = adminList.find((x) => x._id === id);
    navigate("/view_admin_user", { state: { data: adminUserInfo } });
  };
  const capitalizeAndRemoveUnderscores = (str) => {
    if (!str) {
      return "";
    }
    const words = str
      .split("_")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
    return words;
  };
  return (
   
   
      <div className="Categorypage">
        <div className="PageTit ">
          <div className="container">
            <div className="row">
              <div className="col-6 col-md-5">
                <h3>Admin Users</h3>
                <p>
                  <Link to="/dashboard">Dashboard</Link>{" "}
                  <i>
                    <AiOutlineRight />
                  </i>{" "}
                  <Link to="/admin_user">Admin Users</Link>
                </p>
              </div>
              <div className="col-6 col-md-7">
                <div className="Grn-Btnmanin">
                  <button className="Grn-Btn" onClick={handleShow}>
                    add admin users
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="CateCont">
          <div className="container">
            <div className="row">
              <div className="CateGorTab">
                <div className="col-12">
                  <div className="searchBtNs">
                    <div className="row">
                      <div className="col-lg-9 col-md-9">
                        <div className="BtNSSearcH">
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Search"
                              value={searchName}
                              onChange={(e) => setSearchName(e.target.value)}
                              aria-describedby="basic-addon2"
                            />
                            <Button
                              variant="outline-secondary"
                              id="button-addon2"
                              onClick={handleSearch}
                            >
                              <BsSearch />
                            </Button>
                          </InputGroup>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <div className="BtNSSearcH">
                          <select
                            name="searchtypeOrRole"
                            value={searchtypeOrRole}
                            onChange={(e) => {
                              setSearchtypeOrRole(e.target.value);
                            }}
                          >
                            <option value="">All</option>
                            {adminRoles.map((item) => (
                              <option key={item._id}>
                                {capitalizeAndRemoveUnderscores(item.role)}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Table striped hover>
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" />
                      </th>
                      <th>Name</th>
                      <th>Email Address</th>
                      <th>Role</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {adminList.map((el, index) => (
                      <tr key={index}>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>{el.name}</td>
                        <td>{el.email}</td>
                        <td>{capitalizeAndRemoveUnderscores(el.type)}</td>
                        <td className="ddFl">
                          <div className="OnOfF">
                            <Form>
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                onChange={() => handleChangeStatus(el._id)}
                                checked={el.status === 0 ? true : false}
                              />
                            </Form>
                          </div>
                        </td>
                        <td className="ddFl ">
                          <div className="Options">
                            <i>
                              <BsThreeDotsVertical />
                            </i>
                            <ul class="hidden-list">
                              <li onClick={() => handleUserView(el._id)}>
                                View
                              </li>
                              <li onClick={() => deleteAdminUser(el._id)}>
                                Delete
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add admin user</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmitFn}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Role</Form.Label>
                <br></br>
                <Form.Control
                  as="select"
                  value={type}
                  onChange={(e) => {setType(e.target.value); }}
                >
                  <option value="" disabled selected>
                    Select Role
                  </option>
                  {adminRoles.map((item) => (
                    <option key={item._id}>{item.role}</option>
                  ))}
                </Form.Control>
              </Form.Group>

              {showEducatorDropdown && (
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>Educator List</Form.Label>
                  <Form.Control
                    as="select"
                    value={isEducatorSelected ? singleEduData._id : ""}
                    onChange={educatorSelect}
                  >
                    <option value="">Select Educator</option>
                    {educatorData.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="User Name"
                  onChange={(e) => setName(e.target.value)}
                  value={isEducatorSelected ? singleEduData.name : name}
                  autoFocus
                  disabled={isEducatorSelected}
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Email"
                  name="email"
                  value={isEducatorSelected ? singleEduData.email : email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoFocus
                  disabled={isEducatorSelected}
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Status</Form.Label>
                <br></br>
                <select
                  name="status"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option value={1}>Inactive</option>
                  <option value={0}>Active</option>
                </select>
              </Form.Group>

              <div className="d-flex justify-content-center">
                <button type="submit" className="Grn-Btn Half">
                  add
                </button>
                <button
                  type="button"
                  className="WHt-Btn Half"
                  onClick={handleClose}
                >
                  cancel
                </button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        {/* view modal */}
        <Modal show={showview} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>View Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon3">
                  Category Name
                </InputGroup.Text>
                <Form.Control
                  id="basicborder"
                  aria-describedby="basic-addon3"
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon3">
                  Category Short description
                </InputGroup.Text>
                <Form.Control
                  id="basicborder"
                  aria-describedby="basic-addon3"
                />
              </InputGroup>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
  
  );
};
export default Adminlist;
