import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
// import Helper from "../helper/axiosHelper"

const initialState = {
    loader:false,
    isLogin:false,
    authToggler:true

    
};


export const counterSlice = createSlice({
    name: "counter",
    initialState,
    reducers: {
        loader: (state,action) => {
            state.loader = action.payload;
        },
        isLogin: (state,action) => {
            state.isLogin = action.payload;
        },
        searchValue: (state, action) => {
            state.searchValue = action.payload;      
          },setAuthToggler: (state, action) => {
            state.authToggler = action.payload;
          },
        
   
    }
});

export const {loader,isLogin, searchValue,setAuthToggler} = counterSlice.actions;

export default counterSlice.reducer;



