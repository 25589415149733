import { useEffect, useState, useRef } from "react";
import "./Course.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import { MdEdit, MdDelete } from "react-icons/md";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { TbFileUpload } from "react-icons/tb";
import Accordion from "react-bootstrap/Accordion";
import {
  categoryList,
  courseSessionAddNew,
  addAssesmentCb,
  courseListview,
  courseSessionEdit,
  courseSessionDelete,
  courseLessonEdit,
  courseLessonDelete,
  courseAssesmentEdit,
  courseAssesmentDelete,
  editCourse,
  fileUploading,
  collegeList,
} from "../redux/courses";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { sessionListCb } from "../redux/courses";
import { ActiveEducators, educatorEmailIds } from "../redux/Educators";
import { useParams, useNavigate } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import Select from "react-select";
import moment from "moment";
const Editcourse = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const [show1, setShow1] = useState(false);
  const [show, setShow] = useState(false);
  const [showview, setShowview] = useState(false);
  const [showview1, setShowview1] = useState(false);
  const [showview3, setShowview3] = useState(false);
  const [editsession, setEditSession] = useState(false);
  const [editlesson, setEditLesson] = useState(false);
  const [editAssessment, setEditAssessment] = useState(false);
  const [sessionIndexToShow, setSessionIndexToShow] = useState(null);
  const [editedSession, setEditedSession] = useState(null);
  const [editedLesson, setEditedLesson] = useState(null);
  const [editedAssessment, setEditedAssessment] = useState(null);
  const [assesmentUpdate, setAssesmentUpdate] = useState([]);
  const [updatedValue, setUpdatedValue] = useState([]);
  const [updatedValuedes, setUpdatedValuedes] = useState("");
  const [updatedValuewwyl, setUpdatedValuewwyl] = useState("");
  const [updatedValuecert, setUpdatedValuecert] = useState("");
  const [updatedValuewtcf, setUpdatedValuewtcf] = useState("");
  const [editedOption, setEditedOption] = useState(null);
  const [rows, setRows] = useState([1]);
  const [getcategoryList, setCategoryList] = useState([]);
  const [sessionData, setSessionData] = useState([]);
  const [basicsData, setBasicsData] = useState([]);
  const [activeTab, setActiveTab] = useState("first");
  const [educatorData, setEducatorData] = useState([]);
  const [refresher, setRefresher] = useState(true);
  const [options, setOptions] = useState([{}]);
  const [havingDiscount, sethavingDiscount] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailFile, setThumbnaiFile] = useState("");
  const [previewImage, setPreviewImage] = useState();
  const [imageFile, setImageFile] = useState("");
  const [getImg, setImg] = useState("");
  const [videoDuration, setVideoDuration] = useState("");
  const handleClose4 = () => setShow1(false);
  const [sessList, setSessList] = useState([]);
  const courseId = useParams();
  const fileInputRefvid = useRef(null);
  const fileInputRef = useRef(null);
  const progressBarRef = useRef(null);
  const [file, setFile] = useState(null);
  const [editFile, setEditFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [editFileName, setEditFileName] = useState(null);
  const [totalChunks, setTotalChunks] = useState(null);
  const [editTotalChunks, setEditTotalChunks] = useState(null);
  const [uploadId, setUploadId] = useState(null);
  const [selectedLessonId, setSelectedLessonId] = useState("");
  const [selectedSessionId, setSelectedSessionId] = useState("");
  const [selectedSessionDuration, setSelectedSessionDuration] = useState("");
  const [selectedSessionTitle, setSelectedSessionTitle] = useState("");
  const [optionscol, setOptionscol] = useState([]);
  const [optionscolleges, setOptionscolleges] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [collegeEdit, setCollegeEdit] = useState([]);

  const userType = localStorage.getItem("userType");
  const profileName = JSON.parse(localStorage.getItem("profileName"));

  useEffect(() => {
    let data = {};
    data["search"] = searchTerm;
    dispatch(loader(true));
    dispatch(
      collegeList(data, (resp) => {
        if (resp) {
          var temp2 = [];
          var optData = resp.data.map((ele) => {
            temp2.push({ value: ele._id, label: ele.name });
          });
          setOptionscolleges(temp2);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );
  }, [searchTerm]);

  const getDefaultCollegeOptions = (collegeIds) => {
    if (!collegeIds || collegeIds.length === 0) return null;
    const defaultOptions = collegeIds.map((collegeId) => {
      const college = optionscolleges.find(
        (option) => option.value === collegeId
      );
      return college ? college : null;
    });
    return defaultOptions.filter((option) => option !== null);
  };

  const handleLessonSelectId = (e) => {
    setSelectedLessonId(e.target.value);
  };
  const handleSessionSelectId = (e) => {
    setSelectedSessionId(e.target.value);
  };
  const handleSessionSelectTitle = (e) => {
    setSelectedSessionTitle(e.target.value);
  };

  const handleSessionSelectDuration = (input) => {
    const cleanedInput = input.replace(/[^0-9]/g, "");
    let hours = cleanedInput.slice(0, 2);
    let minutes = cleanedInput.slice(2, 4);
    let seconds = cleanedInput.slice(4, 6);

    if (hours.length > 2) {
      hours = hours.slice(0, 2);
    }

    if (minutes.length > 2) {
      minutes = minutes.slice(0, 2);
    } else if (minutes.length === 1 && seconds.length > 0) {
      seconds = minutes + seconds.slice(0, 1);
      minutes = "";
    }

    if (seconds.length > 2) {
      seconds = seconds.slice(0, 2);
    }

    // Construct formatted duration
    const formattedInput = `${hours}${minutes.length > 0 ? `:${minutes}` : ""}${
      seconds.length > 0 ? `:${seconds}` : ""
    }`;
    setSelectedSessionDuration(formattedInput);
    // Set the formatted input back to the duration input field
    setValue("duration", formattedInput);
  };

  const handleEditSessionSelectDuration = (input) => {
    const newDuration = input;
    setEditedLesson({
      ...editedLesson,
      duration: newDuration,
    });
    const cleanedInput = input.replace(/[^0-9]/g, "");

    // Split input into hours, minutes, and seconds
    let hours = cleanedInput.slice(0, 2);
    let minutes = cleanedInput.slice(2, 4);
    let seconds = cleanedInput.slice(4, 6);

    // Format hours
    if (hours.length > 2) {
      hours = hours.slice(0, 2);
    }

    // Format minutes
    if (minutes.length > 2) {
      minutes = minutes.slice(0, 2);
    } else if (minutes.length === 1 && seconds.length > 0) {
      // If a single digit is entered in minutes, move it to seconds
      seconds = minutes + seconds.slice(0, 1);
      minutes = "";
    }

    // Format seconds
    if (seconds.length > 2) {
      seconds = seconds.slice(0, 2);
    }

    // Construct formatted duration
    const formattedInput = `${hours}${minutes.length > 0 ? `:${minutes}` : ""}${
      seconds.length > 0 ? `:${seconds}` : ""
    }`;
    setSelectedSessionDuration(formattedInput);
    // Set the formatted input back to the duration input field
    setValue7("duration", formattedInput);
  };

  const baseUrl = "https://www.dev.backend.kareersity.com";
  const CHUNK_SIZE = 5 * 1024 * 1024;

  const handleFileChange1 = (e) => {
    const selectedFile = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const video = document.createElement("video");
      video.src = event.target.result;

      video.onloadedmetadata = () => {
        const duration = video.duration;

        // Use 'duration' in your logic as needed, e.g., update state or perform other actions
        // For instance, you might want to convert 'duration' to a specific format (HH:mm:ss)
        const formattedDuration = formatDuration(duration);
        setFile(selectedFile);
        setFileName(`${Date.now()}_${selectedFile.name}`);
        setTotalChunks(Math.ceil(selectedFile.size / CHUNK_SIZE));
        setVideoDuration(formattedDuration);
        setValue("duration", formattedDuration);
      };
    };

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }
  };
  const handleFileChangeEdit = (e) => {
    const selectedFile = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const video = document.createElement("video");
      video.src = event.target.result;

      video.onloadedmetadata = () => {
        dispatch(loader(false));
        const duration = video.duration;
        const formattedDuration = formatDuration(duration);
        setEditFile(selectedFile);
        setEditFileName(`${Date.now()}_${selectedFile.name}`);
        setEditTotalChunks(Math.ceil(selectedFile.size / CHUNK_SIZE));
        setEditedLesson({
          ...editedLesson,
          duration: formattedDuration,
        });
        setValue7("duration", formattedDuration);
      };
    };

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }
  };
  const formatDuration = (duration) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor(duration % 60);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const updateProgressBar = (progress) => {
    progressBarRef.current.style.width = progress + "%";
    progressBarRef.current.textContent = progress + "%";
  };

  const resetProgressBar = () => {
    progressBarRef.current.style.width = "0%";
    progressBarRef.current.textContent = "";
    fileInputRef.current.value = "";
  };
  const firstTabFn = () => {
    let data = {};
    data["courseId"] = courseId.id;
    dispatch(loader(true));
    dispatch(
      courseListview(data.courseId, (resp) => {
        dispatch(loader(false));
        if (resp.status) {
          setBasicsData([resp.data[0]]);
          var temp = [];
          optionscol.map((element) => {
            temp.push({ label: element.label, value: element.value });
          });
          setCollegeEdit(temp, ...basicsData);
          setThumbnaiFile(resp.data[0].thumbnail);
          {
            resp.data[0].discountedPrice !== 0
              ? sethavingDiscount(true)
              : sethavingDiscount(false);
          }
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const fourthTabFn = () => {
    let data = {};
    data["courseId"] = courseId.id;
    dispatch(loader(true));
    dispatch(
      courseListview(data.courseId, (resp) => {
        dispatch(loader(false));
        if (resp.status) {
          setSessionData(resp.data[0].session);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const SessionSubmit = (data) => {
    data["courseId"] = courseId.id;
    dispatch(loader(true));
    dispatch(
      courseSessionAddNew(data, (resp) => {
        dispatch(loader(false));
        if (resp.status) {
          reset3();
          toast.success("Session added successfully");
          handleClose();
          fourthTabFn();
          setEditedSession(null);
          setEditSession(false);
        } else {
          toast.error(resp.message);
        }
      })
    );
  };

  const sessionSubmitupdate = () => {
    let data = {};
    data["courseId"] = courseId.id;
    data["sessionId"] = editedSession._id;
    data["title"] = editedSession.title;
    dispatch(loader(true));
    dispatch(
      courseSessionEdit(data, (resp) => {
        dispatch(loader(false));
        if (resp.status) {
          setEditSession(false);
          setEditedSession(null);
          reset6();
          setRefresher(!refresher);
          toast.success("Session title updated successfully");
        } else {
          toast.error(resp.message);
        }
      })
    );
  };
  const lessonSubmitupdate = () => {
    let data = {};
    data["lessonId"] = editedLesson._id;
    data["title"] = editedLesson.title;
    data["duration"] = editedLesson.duration;
    data["videoUrl"] = editedLesson.videoUrl;
    data["isFreeVideo"] = editedLesson.isFreeVideo;
    dispatch(loader(true));
    dispatch(
      courseLessonEdit(data, (resp) => {
        dispatch(loader(false));
        if (resp.status) {
          setEditLesson(false);
          setRefresher(!refresher);
          toast.success("Lesson added successFully");
        } else {
          toast.error(resp.message);
        }
      })
    );
  };

  const assesmentSubmitupdate = () => {
    if (!editedAssessment) {
      return;
    }
    const data = {
      assId: editedAssessment._id,
    };

    if (assesmentUpdate.question !== undefined) {
      data.question = editedAssessment.question; // Update the question field.
    }

    if (assesmentUpdate.options !== undefined) {
      data.options = editedAssessment.options;
    }

    if (assesmentUpdate.correctAnswer !== undefined) {
      data.correctAnswer = editedAssessment.correctAnswer;

      // If you are updating correctAnswer, include options as well
      if (editedAssessment.options !== undefined) {
        data.options = editedAssessment.options;
      }
    }

    // Validate and send the appropriate data based on what is being updated
    if (assesmentUpdate.question !== undefined && !data.question) {
      toast.error("Please add a question");
      return;
    }

    if (
      assesmentUpdate.options !== undefined &&
      (!data.options || data.options.length === 0)
    ) {
      toast.error("Please add options");
      return;
    }

    if (
      assesmentUpdate.correctAnswer !== undefined &&
      (!data.correctAnswer || data.correctAnswer.length === 0)
    ) {
      toast.error("Please select correct answer");
      return;
    }

    dispatch(loader(true));

    dispatch(
      courseAssesmentEdit(data, (resp) => {
        dispatch(loader(false));

        if (resp.status) {
          setEditAssessment(false);
          setRefresher(!refresher);
          toast.success("Assessment updated successfully");
          reset5();
        } else {
          toast.error(resp.message);
        }
      })
    );
  };
  const fileUploadFn = (thumbnail) => {
    dispatch(loader(true));
    var data = {};
    data["file "] = thumbnail;

    dispatch(
      fileUploading(data, (resp) => {
        dispatch(loader(false));

        if (resp.status) {
          toast.success("file uploaded successFully");
          setThumbnaiFile(resp.url);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };
  const handleCollegeChange = (e) => {
    setOptionscol(e);
    setOptionscol(Array.isArray(e) ? e.map((x) => x) : []);
  };
  const showPdf = (data) => {
    setShow1(!show1);
    setImg(data);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setThumbnail(reader.result);
    };
    fileUploadFn(file);
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const deleteLesson = (lessons) => {
    let data = {};
    data["lessonId"] = lessons._id;
    dispatch(loader(true));
    dispatch(
      courseLessonDelete(data, (resp) => {
        if (resp.status) {
          dispatch(loader(false));
          setRefresher(!refresher);
          toast.success(resp.message);
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };
  const deleteSession = (val) => {
    let data = {};
    data["sessionId"] = val._id;
    dispatch(loader(true));
    dispatch(
      courseSessionDelete(data, (resp) => {
        if (resp.status) {
          dispatch(loader(false));
          setRefresher(!refresher);
          toast.success(resp.message);
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const deleteAssessment = (val) => {
    let data = {};
    data["assId"] = val._id;
    dispatch(loader(true));
    dispatch(
      courseAssesmentDelete(data, (resp) => {
        if (resp.status) {
          dispatch(loader(false));
          setRefresher(!refresher);
          toast.success(resp.message);
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const handleEditSession = (session) => {
    setEditedSession(session);
    setEditSession(true);
  };

  const handleEditLesson = (lessons) => {
    setEditedLesson(lessons);
    setEditLesson(true);
    setSelectedLessonId(lessons._id);
  };

  const handleEditAssessment = (assessments) => {
    setEditedAssessment(assessments);
    setEditAssessment(true);
  };

  const handleViewAssessment = (sessionIndex) => {
    setSessionIndexToShow(sessionIndex);
    setShowview1(true);
  };

  const isEditing = (assessment, optionIndex) => {
    return (
      (editedAssessment && editedAssessment._id === assessment._id) ||
      (editedOption &&
        editedOption.assessmentId === assessment._id &&
        editedOption.optionIndex === optionIndex)
    );
  };

  const handleToggle = () => {
    setEditedLesson((prevLesson) => ({
      ...prevLesson,
      isFreeVideo: !prevLesson.isFreeVideo,
    }));
  };
  const handleToggle1 = () => {
    setIsHidden(!isHidden);
  };

  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
    if (tabKey == "four") {
      fourthTabFn();
    }
    if (tabKey == "first") {
      firstTabFn();
    }
  };

  const handleNextButton = (page) => {
    setActiveTab(page);
  };

  const handlePreviousButton = (page) => {
    setActiveTab(page);
  };

  const handleCheckBoxChange = (e, index, fieldName) => {
    if (fieldName == "freeForEveryone") {
      const updatedBasicsData = [...basicsData];
      updatedBasicsData[index][fieldName] = e.target.checked;
      updatedBasicsData[0].discountedPriceExpiry = 0;
      updatedBasicsData[0].discountedPrice = 0;
      updatedBasicsData[index].price = 0;
      updatedBasicsData[index].regularPrice = 0;
      setBasicsData(updatedBasicsData);
    } else {
      const updatedBasicsData = [...basicsData];
      updatedBasicsData[index][fieldName] = e.target.checked;
      setBasicsData(updatedBasicsData);
    }
  };

  const handleDiscountCheckbox = (e) => {
    sethavingDiscount(e.target.checked);
    const updatedBasicsData = [...basicsData];
    updatedBasicsData[0].discountedPriceExpiry = 0;
    updatedBasicsData[0].discountedPrice = 0;
    setBasicsData(updatedBasicsData);
  };

  const {
    register: register,
    control,
    formState: { errors: errors },
    reset: reset,
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur" });
  const {
    register: register3,
    formState: { errors: errors3 },
    reset: reset3,
    handleSubmit: handleSubmit3,
  } = useForm({ mode: "onBlur" });
  const {
    register: register4,
    formState: { errors: errors4 },
    reset: reset4,
    setValue: setValue,
    handleSubmit: handleSubmit4,
  } = useForm({ mode: "onBlur" });
  const {
    register: register5,
    formState: { errors: errors5 },
    reset: reset5,
    handleSubmit: handleSubmit5,
  } = useForm({ mode: "onBlur" });
  const {
    register: register6,
    formState: { errors: errors6 },
    reset: reset6,
    handleSubmit: handleSubmit6,
  } = useForm({ mode: "onBlur" });
  const {
    register: register7,
    formState: { errors: errors7 },
    setValue: setValue7,
    handleSubmit: handleSubmit7,
  } = useForm({ mode: "onBlur" });
  const { handleSubmit: handleSubmit8 } = useForm({ mode: "onBlur" });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShowview(false);
  const handleShow1 = () => {
    setShowview(true);
    setRefresher(!refresher);
  };
  const handleClose2 = () => setShowview1(false);
  const handleClose3 = () => setShowview3(false);
  const handleShow3 = () => {
    reset5();
    setShowview3(true);
  };

  const handleClose5 = () => setEditSession(false);
  const handleClose6 = () => setEditLesson(false);
  const addQandARow = () => {
    const newRow = rows.length + 1;
    setRows([...rows, newRow]);
  };

  const removeQandARow = () => {
    if (rows.length > 1) {
      const updatedRows = rows.slice(0, -1);
      setRows(updatedRows);
      setOptions((prevcrcIncludes) => {
        const updatedValues = [...prevcrcIncludes];
        updatedValues.splice(rows.length - 1, 1);
        return updatedValues;
      });
    }
  };

  const cancelAddSession = () => {
    handleClose();
  };

  const cancelEditLesson = () => {
    handleClose6();
  };

  const ansFn = (event, index, opt) => {
    const { value } = event.target;
    setOptions((prevcrcIncludes) => {
      if (!prevcrcIncludes[index]) {
        prevcrcIncludes[index] = {};
      }
      const updatedValues = [...prevcrcIncludes];
      if (opt == "correctAnswer") {
        updatedValues[index][opt] = updatedValues[index][value];
      } else {
        updatedValues[index][opt] = value;
      }
      return updatedValues;
    });
  };

  const sessionSelFn = (val) => {
    dispatch(loader(true));
    dispatch(
      sessionListCb({ courseId: val }, (resp) => {
        dispatch(loader(false));
        if (resp.status) {
          setSessList(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      categoryList((resp) => {
        if (resp.status) {
          const list = resp.data.filter((item) => item.isHidden);
          setCategoryList(list);
          setActiveTab("four");
          fourthTabFn();
          sessionSelFn(courseId.id);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );

    dispatch(
      ActiveEducators((resp) => {
        if (resp.status) {
          dispatch(loader(false));
          setEducatorData(resp.data);
        } else {
          dispatch(loader(false));
        }
      })
    );
  }, [refresher]);

  const handleUploadClick = async () => {
    dispatch(loader(true));
    if (!file) {
      return alert("Please select a file");
    }

    try {
      const startTime = new Date();
      const requestBody = {
        fileName: fileName,
        courseId: courseId.id,
        sessionId: selectedSessionId,
        title: selectedSessionTitle,
      };
      const res = await fetch(`${baseUrl}/course/initiateUpload`, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "true",
          Authorization: localStorage.getItem("adminOAuth"),
        },
      });

      const { uploadId } = await res.json();
      setUploadId(uploadId);
      const uploadPromises = [];
      let uploadedChunks = 0;
      let start = 0,
        end;

      for (let i = 0; i < totalChunks; i++) {
        end = start + CHUNK_SIZE;
        const chunk = file.slice(start, end);
        const formData = new FormData();
        formData.append("index", i);
        formData.append("totalChunks", totalChunks);
        formData.append("fileName", fileName);
        formData.append("file", chunk);
        const uploadPromise = fetch(
          `${baseUrl}/course/upload?uploadId=${uploadId}`,
          {
            method: "POST",

            body: formData,
          }
        ).then(() => {
          uploadedChunks++;
          const progress = Math.floor((uploadedChunks / totalChunks) * 100);
          updateProgressBar(progress);
        });
        uploadPromises.push(uploadPromise);
        start = end;
      }

      await Promise.all(uploadPromises);
      const requestBody1 = {
        courseId: courseId.id,
        sessionId: selectedSessionId,
        title: selectedSessionTitle,
        duration: videoDuration,
        isFreeVideo: isHidden,
      };

      const completeRes = await fetch(
        `${baseUrl}/course/completeUpload?fileName=${fileName}&uploadId=${uploadId}`,
        {
          method: "POST",
          body: JSON.stringify(requestBody1),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "true",
            Authorization: localStorage.getItem("adminOAuth"),
          },
        }
      );
      const { success, data } = await completeRes.json();
      if (success === false) {
        throw new Error("Error completing upload");
      }
      const endTime = new Date();
      const timeElapsed = (endTime - startTime) / 1000;
      handleClose1();
      fourthTabFn();
      reset4();
      setRefresher(!refresher);
      toast.success("Lesson added successFully");
      dispatch(loader(false));
    } catch (err) {
      console.log(err);
      dispatch(loader(false));
    }
  };

  const handleUploadEdit = async () => {
    dispatch(loader(true));
    // if (!editFile) {
    //   return alert("Please select a file");
    // }
    try {
      const startTime = new Date();
      const requestBody = {
        fileName: editFileName,
        lessonId: selectedLessonId,
      };
      const res = await fetch(`${baseUrl}/course/lesson_edit/initiate_upload`, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "true",
          Authorization: localStorage.getItem("adminOAuth"),
        },
      });

      const { uploadId } = await res.json();
      setUploadId(uploadId);
      const uploadPromises = [];
      let uploadedChunks = 0;
      let start = 0,
        end;

      for (let i = 0; i < editTotalChunks; i++) {
        end = start + CHUNK_SIZE;
        const chunk = editFile.slice(start, end);
        const formData = new FormData();
        formData.append("index", i);
        formData.append("totalChunks", editTotalChunks);
        formData.append("fileName", editFileName);
        formData.append("file", chunk);

        const uploadPromise = fetch(
          `${baseUrl}/course/upload?uploadId=${uploadId}`,
          {
            method: "POST",
            body: formData,
          }
        ).then(() => {
          uploadedChunks++;
          const progress = Math.floor((uploadedChunks / editTotalChunks) * 100);
          updateProgressBar(progress);
        });
        uploadPromises.push(uploadPromise);
        start = end;
      }
      await Promise.all(uploadPromises);
      const requestBody1 = {
        lessonId: selectedLessonId,
        title: editedLesson.title,
        duration: editedLesson.duration,
        isFreeVideo: editedLesson.isFreeVideo,
      };

      let url = `${baseUrl}/course/lesson_edit/complete_upload`;

      if (editFileName) {
        url += `?uploadId=${uploadId}&fileName=${editFileName}`;
      }
      const completeRes = await fetch(url, {
        method: "POST",
        body: JSON.stringify(requestBody1),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "true",
          Authorization: localStorage.getItem("adminOAuth"),
        },
      });
      const { success, data } = await completeRes.json();

      if (success === false) {
        throw new Error("Error completing upload");
      }

      const endTime = new Date();
      const timeElapsed = (endTime - startTime) / 1000;
      handleClose6();
      fourthTabFn();
      reset6();
      setEditFileName(null);
      setRefresher(!refresher);
      toast.success("Lesson added successFully");
      resetProgressBar();
      dispatch(loader(false));
    } catch (err) {
      dispatch(loader(false));
      console.log(err);
    }
  };

  const priceFn = () => {
    if (havingDiscount) {
      if (basicsData[0].discountedPrice == 0) {
        toast.error("Enter valid discount price");
        return;
      } else if (basicsData[0].discountedPriceExpiry == 0) {
        toast.error("Enter discount expiry date");
        return;
      }
    }

    if (!basicsData[0].freeForEveryone) {
      if (basicsData[0].price == 0) {
        toast.error("Enter valid course price");
        return;
      }
    }
    handleNextButton("third");
  };

  const resetManual = () => {
    setEducatorData([]);
  };
  const updateCourseFn = () => {
    dispatch(loader(true));
    let formData = new FormData();
    if (imageFile != "") {
      formData.append("thumbnail", imageFile);
    }
    let data = {};
    if (basicsData[0].catId == undefined || basicsData[0].catId == null) {
      var catId = getcategoryList.filter(
        (category) => category.name === basicsData[0].category
      );

      data["catId"] = catId[0]?._id;
    } else {
      data["catId"] = basicsData[0].catId;
    }
    const collegeEditIds =
      optionscol.length !== 0
        ? optionscol.map((option) => option.value)
        : basicsData[0].freeColleges;

    data["courseId"] = courseId.id;
    data["title"] = basicsData[0].title;
    data["shortDescription"] = basicsData[0].shortDescription;
    data["duration"] = basicsData[0].duration;
    data["level"] = basicsData[0].level;
    data["freeForEveryone"] = basicsData[0].freeForEveryone;
    data["freeForEnInLast30Days"] = basicsData[0].freeForEnInLast30Days;
    data["freeForbasedOnColleges"] = basicsData[0].freeForbasedOnColleges;
    data["freeColleges"] = collegeEditIds;
    data["price"] = basicsData[0].price;
    data["regularPrice"] = basicsData[0].regularPrice;
    data["discountedPrice"] = basicsData[0].discountedPrice;
    data["discountedPriceExpiry"] = basicsData[0].discountedPriceExpiry;
    data["description"] = updatedValuedes
      ? updatedValuedes
      : basicsData[0].description;
    data["whatWillYouLearn"] = updatedValuewwyl
      ? updatedValuewwyl
      : basicsData[0].whatWillYouLearn;
    data["certifications"] = updatedValuecert
      ? updatedValuecert
      : basicsData[0].certifications;
    data["whoThisCourseIsFor"] = updatedValuewtcf
      ? updatedValuewtcf
      : basicsData[0].whoThisCourseIsFor;
    data["courseIncludes"] = basicsData[0].courseIncludes;
    data["educators"] = basicsData[0].educators._id;
    data["thumbnail"] = thumbnailFile;

    dispatch(
      editCourse(data, (resp) => {
        dispatch(loader(false));
        if (resp.status) {
          toast.success(resp.message);
          resetManual();
          navigate("/courses");
          handlePreviousButton("four");
          setRefresher(!refresher);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const assesmentFn = (data) => {
    if (options.length > 0 && Object.keys(options[0]).length > 0) {
      dispatch(loader(true));

      var reqData = {};
      reqData["courseId"] = courseId.id;
      reqData["sessionId"] = data.sessionId;
      reqData["qList"] = options;

      dispatch(
        addAssesmentCb(reqData, (resp) => {
          if (resp.status) {
            handleClose3();
            setRefresher(!refresher);
            setOptions([{}]);
            reset5();
            toast.success("Assesment Added Successfully");
            dispatch(loader(false));
          } else {
            dispatch(loader(false));
            toast.error(
              resp.message === "Duplicate questions found in the assessment."
                ? "Duplicate questions found in the assessment."
                : "Please provide question and answer"
            );
          }
        })
      );
    } else {
      toast.error("Please provide question and answer");
    }
  };

  // Define these functions at an appropriate location in your code
  const handleCourseIncludeChange = (event, index, courseIndex) => {
    const updatedBasicsData = [...basicsData];
    updatedBasicsData[index].courseIncludes[courseIndex] = event.target.value;
    setBasicsData(updatedBasicsData);
  };

  const addCourseIncludeRow = (index) => {
    const updatedBasicsData = [...basicsData];
    updatedBasicsData[index].courseIncludes.push("");
    setBasicsData(updatedBasicsData);
  };

  const removeCourseIncludeRow = (index, courseIndex) => {
    const updatedBasicsData = [...basicsData];
    updatedBasicsData[index].courseIncludes.splice(courseIndex, 1);
    setBasicsData(updatedBasicsData);
  };

  const handleCategoryChange = (event, index) => {
    const selectedCategoryId = event.target.value;
    const updatedBasicsData = [...basicsData];
    updatedBasicsData[index].category = selectedCategoryId;
    updatedBasicsData[index].catId = selectedCategoryId;
    setBasicsData(updatedBasicsData);
  };

  const handleEducatorsChange = (event, index) => {
    const selectedEducatorId = event.target.value;
    const updatedEducatorData = [...basicsData];
    updatedEducatorData[index].educators._id = selectedEducatorId; // Update educator ID

    // Fetch and update the educator's details using the selectedEducatorId
    const selectedEducator = educatorData.find(
      (edu) => edu._id === selectedEducatorId
    );
    updatedEducatorData[index].educators.name = selectedEducator.name;
    updatedEducatorData[index].educators.email = selectedEducator.email;
    updatedEducatorData[index].educators.photoUrl = selectedEducator.photoUrl;
    updatedEducatorData[index].isEducatorEditable = false;
    setBasicsData(updatedEducatorData);
  };

  const handleCoursePriceChange = (event, index) => {
    const updatedBasicsData = [...basicsData];
    updatedBasicsData[index].price = parseInt(event.target.value) || "";
    setBasicsData(updatedBasicsData);
  };
  const handleCourseRegularPriceChange = (event, index) => {
    const updatedBasicsData = [...basicsData];
    updatedBasicsData[index].regularPrice = parseInt(event.target.value) || "";
    setBasicsData(updatedBasicsData);
  };
  const handleDiscountPriceChange = (event, index) => {
    const updatedBasicsData = [...basicsData];
    const newDiscountPrice = parseInt(event.target.value) || "";
    if (
      newDiscountPrice >= 0 &&
      newDiscountPrice <= updatedBasicsData[index].price
    ) {
      updatedBasicsData[index].discountedPrice = newDiscountPrice;
    }
    setBasicsData(updatedBasicsData);
  };

  // const handleDiscountExpiryChange = (event, index) => {
  //   const updatedBasicsData = [...basicsData];
  //   const discountExpiry = new Date(event.target.value).getTime() / 1000;
  //   updatedBasicsData[index].discountedPriceExpiry = discountExpiry;
  //   setBasicsData(updatedBasicsData);
  // };
  const handleDiscountExpiryChange = (event, index) => {
    const updatedBasicsData = [...basicsData];
    const inputDate = new Date(event.target.value); // Parse the input date string
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth();
    const day = inputDate.getDate();

    // Set the time to 23:59:59:000
    const discountExpiry =
      new Date(year, month, day, 23, 59, 59, 0).getTime() / 1000;

    updatedBasicsData[index].discountedPriceExpiry = discountExpiry;
    setBasicsData(updatedBasicsData);
  };

  const handleEducatorSelect = (event, index) => {
    const selectedEducatorId = event.target.value;
    // Update the selected educator for the specific index in basicsData
    const updatedBasicsData = [...basicsData];
    updatedBasicsData[index].selectedEducatorId = selectedEducatorId;
    setBasicsData(updatedBasicsData);
  };

  const handleCategoryClick = (index) => {
    const updatedBasicsData = [...basicsData]; // Create a copy of the state array
    const clickedBasicData = updatedBasicsData[index];
    clickedBasicData.isCategoryEditable = true; // Set the category to be editable
    setBasicsData(updatedBasicsData); // Update the state with the modified array
  };

  const handleEducatorClick = (index) => {
    const updatedEducatorData = [...basicsData];
    const clickedEducatorData = updatedEducatorData[index];
    clickedEducatorData.isEducatorEditable = true;
    setBasicsData(updatedEducatorData);
  };
  const handleLevelChange = (e, index) => {
    const updatedBasicData = [...basicsData];
    updatedBasicData[index].level = e.target.value;
    setBasicsData(updatedBasicData);
  };

  const handleLevelClick = (index) => {
    const updatedBasicData = [...basicsData];
    updatedBasicData[index].isLevelEditable = true;
    setBasicsData(updatedBasicData);
  };
  const handleCourseDescClick = (index) => {
    const updatedBasicData = [...basicsData];
    updatedBasicData[index].isCourseDescEditable = true;
    setBasicsData(updatedBasicData);
  };
  const handleCourseDescChange = (data, index) => {
    const updatedBasicData = [...basicsData];
    updatedBasicData[index].description = data;
    setBasicsData(updatedBasicData);
  };

  const handleTitleChange = (e, index) => {
    const updatedBasicsData = [...basicsData];
    updatedBasicsData[index].title = e.target.value;
    setBasicsData(updatedBasicsData);
  };

  const handleTitleClick = (index) => {
    const updatedBasicsData = [...basicsData];
    updatedBasicsData[index].isTitleEditable = true;
    setBasicsData(updatedBasicsData);
  };

  const handleShortDescriptionChange = (e, index) => {
    const updatedBasicsData = [...basicsData];
    updatedBasicsData[index].shortDescription = e.target.value;
    setBasicsData(updatedBasicsData);
  };

  const handleShortDescriptionClick = (index) => {
    const updatedBasicsData = [...basicsData];
    updatedBasicsData[index].isShortDescriptionEditable = true;
    setBasicsData(updatedBasicsData);
  };

  const handleThumbnailClick = (index) => {
    const updatedBasicsData = [...basicsData];
    updatedBasicsData[index].isThumbnailEditable = true;
    setBasicsData(updatedBasicsData);
  };

  const handleDurationChanges = (input, index) => {
    const cleanedInput = input.replace(/[^0-9]/g, "");

    // Split input into hours, minutes, and seconds
    let hours = cleanedInput.slice(0, 2);
    let minutes = cleanedInput.slice(2, 4);
    let seconds = cleanedInput.slice(4, 6);

    // Format hours
    if (hours.length > 2) {
      hours = hours.slice(0, 2);
    }

    // Format minutes
    if (minutes.length > 2) {
      minutes = minutes.slice(0, 2);
    } else if (minutes.length === 1 && seconds.length > 0) {
      // If a single digit is entered in minutes, move it to seconds
      seconds = minutes + seconds.slice(0, 1);
      minutes = "";
    }

    // Format seconds
    if (seconds.length > 2) {
      seconds = seconds.slice(0, 2);
    }

    // Construct formatted duration
    const formattedInput = `${hours}${minutes.length > 0 ? `:${minutes}` : ""}${
      seconds.length > 0 ? `:${seconds}` : ""
    }`;
    const updatedBasicsData = [...basicsData];
    updatedBasicsData[index].duration = formattedInput;
    setBasicsData(updatedBasicsData);
  };

  const handleDurationClick = (index) => {
    const updatedBasicsData = [...basicsData];
    updatedBasicsData[index].isDurationEditable = true;
    setBasicsData(updatedBasicsData);
  };

  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <h3>Edit Course</h3>
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/add_course">EditCourse</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <div className="row">
                <div className="col-12">
                  <Tabs activeKey={activeTab} onSelect={handleTabSelect}>
                    <Tab eventKey="first" title="1. Basic">
                      {basicsData.map((basicData, index) => (
                        <div className="CorsELiSTab pt-4 pb-4">
                          <form key={index}>
                            <div className="row">
                              <div className="col-lg-3 col-3">
                                <label>Category</label>
                              </div>
                              <div className="col-lg-9 col-9">
                                {basicData.isCategoryEditable ? (
                                  <select
                                    name="category"
                                    value={basicData.category}
                                    onChange={(e) => {
                                      handleCategoryChange(e, index);
                                      setUpdatedValue({
                                        ...updatedValue,
                                        category: e.target.value,
                                      });
                                    }}
                                  >
                                    <option value="test">
                                      Select Category
                                    </option>
                                    {getcategoryList.map((item) => (
                                      <option key={item._id} value={item._id}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                ) : (
                                  <input
                                    type="text"
                                    className="COrAdForm"
                                    readOnly={true}
                                    value={basicData.category}
                                    onClick={() => handleCategoryClick(index)}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-3 col-3">
                                <label>Course Title</label>
                              </div>
                              <div className="col-lg-9 col-9">
                                {basicData.isTitleEditable ? (
                                  <input
                                    type="text"
                                    className="COrAdForm"
                                    name="courseTitle"
                                    id="courseTitle"
                                    value={basicData.title}
                                    onChange={(e) => {
                                      handleTitleChange(e, index);
                                      setUpdatedValue({
                                        ...updatedValue,
                                        title: e.target.value,
                                      });
                                    }}
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    className="COrAdForm"
                                    readOnly={true}
                                    value={basicData.title}
                                    onClick={() => handleTitleClick(index)}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-3">
                                <label>Short Description</label>
                              </div>
                              <div className="col-lg-9 col-9">
                                {basicData.isShortDescriptionEditable ? (
                                  <textarea
                                    rows="4"
                                    name="shortDescription"
                                    id="shortDescription"
                                    value={basicData.shortDescription}
                                    onChange={(e) => {
                                      handleShortDescriptionChange(e, index);
                                      setUpdatedValue({
                                        ...updatedValue,
                                        shortDescription: e.target.value,
                                      });
                                    }}
                                  />
                                ) : (
                                  <textarea
                                    rows="4"
                                    readOnly={true}
                                    value={basicData.shortDescription}
                                    onClick={() =>
                                      handleShortDescriptionClick(index)
                                    }
                                  />
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-3">
                                <label>Duration</label>
                              </div>
                              <div className="col-lg-9 col-9">
                                {basicData.isDurationEditable ? (
                                  <input
                                    type="text"
                                    placeholder="02h:20m:52s"
                                    className="COrAdForm"
                                    name="duration"
                                    id="duration"
                                    value={basicData.duration}
                                    onChange={(e) => {
                                      handleDurationChanges(
                                        e.target.value,
                                        index
                                      );
                                      setUpdatedValue({
                                        ...updatedValue,
                                        duration: e.target.value,
                                      });
                                    }}
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    className="COrAdForm"
                                    readOnly={true}
                                    value={basicData.duration}
                                    onClick={() => handleDurationClick(index)}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-3 col-3">
                                <label>Level</label>
                              </div>
                              <div className="col-lg-9 col-9">
                                {basicData.isLevelEditable ? (
                                  <select
                                    name="level"
                                    value={basicData.level}
                                    onChange={(e) => {
                                      handleLevelChange(e, index);
                                      setUpdatedValue({
                                        ...updatedValue,
                                        level: e.target.value,
                                      });
                                    }}
                                  >
                                    <option value="test">Select Level</option>
                                    <option value="Basic">Basic</option>
                                    <option value="Intermediate">
                                      Intermediate
                                    </option>
                                    <option value="Advance">Advance</option>
                                  </select>
                                ) : (
                                  <input
                                    type="text"
                                    className="COrAdForm"
                                    readOnly={true}
                                    value={basicData.level}
                                    onClick={() => handleLevelClick(index)}
                                  />
                                )}
                              </div>
                            </div>

                            <hr></hr>
                            <div className="row">
                              <div className="col-lg-3 col-3">
                                <label>Course Description</label>
                              </div>
                              <div className="col-lg-9 col-9">
                                <Controller
                                  name="description"
                                  control={control}
                                  rules={{
                                    validate: (value) =>
                                      (value && value.length > 0) ||
                                      "Course Description is required",
                                  }}
                                  render={({ field }) => (
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={basicData.description}
                                      onChange={(e, editor) => {
                                        const data = editor.getData();
                                        field.onChange(data);
                                        setUpdatedValuedes(data);
                                      }}
                                      onBlur={() => {
                                        if (!field.value) {
                                          field.onChange("");
                                        }
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-3">
                                <label>What you'll learn</label>
                              </div>
                              <div className="col-lg-9 col-9">
                                <Controller
                                  name="whatWillYouLearn"
                                  control={control}
                                  rules={{
                                    validate: (value) =>
                                      (value && value.length > 0) ||
                                      "What you'll learn is required",
                                  }}
                                  render={({ field }) => (
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={basicData.whatWillYouLearn}
                                      onChange={(e, editor) => {
                                        const data = editor.getData();
                                        field.onChange(data);
                                        setUpdatedValuewwyl(data);
                                      }}
                                      onBlur={() => {
                                        if (!field.value) {
                                          field.onChange("");
                                        }
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-3 col-md-3">
                                <label>Certifications</label>
                              </div>
                              <div className="col-lg-9 col-9">
                                <Controller
                                  name="certifications"
                                  control={control}
                                  rules={{
                                    validate: (value) =>
                                      (value && value.length > 0) ||
                                      "certifications is required",
                                  }}
                                  render={({ field }) => (
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={basicData.certifications}
                                      onChange={(e, editor) => {
                                        const data = editor.getData();
                                        field.onChange(data);
                                        setUpdatedValuecert(data);
                                      }}
                                      onBlur={() => {
                                        if (!field.value) {
                                          field.onChange("");
                                        }
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-3 col-md-3">
                                <label>Who this course is for</label>
                              </div>
                              <div className="col-lg-9 col-9">
                                <Controller
                                  name="whoThisCourseIsFor"
                                  control={control}
                                  rules={{
                                    validate: (value) =>
                                      (value && value.length > 0) ||
                                      "Who this course is for is required",
                                  }}
                                  render={({ field }) => (
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={basicData.whoThisCourseIsFor}
                                      onChange={(e, editor) => {
                                        const data = editor.getData();
                                        field.onChange(data);
                                        setUpdatedValuewtcf(data);
                                      }}
                                      onBlur={() => {
                                        if (!field.value) {
                                          field.onChange("");
                                        }
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-3 col-md-3">
                                <label className="labelthumb">Thumbnail</label>
                              </div>
                              <div className="col-lg-9 col-9">
                                {basicData.isThumbnailEditable ? (
                                  <div className="nail blog">
                                    <div className="upLoadedImgCourse">
                                      <label htmlFor="imgupload">
                                        <input
                                          type="file"
                                          id="imgupload"
                                          onChange={handleFileChange}
                                          style={{ display: "none" }}
                                          accept="image/*"
                                        />

                                        {thumbnail && (
                                          <div className="thumbnail-preview">
                                            <img
                                              src={thumbnail}
                                              alt="Thumbnail Preview"
                                              className="imgthumbnailedit"
                                            />
                                          </div>
                                        )}
                                        <i style={{ display: "none" }}>
                                          <TbFileUpload className="uploadIcon" />
                                        </i>
                                        {previewImage ? (
                                          <img
                                            src={previewImage}
                                            className="img-fluid preImageedit"
                                            alt="Thumbnail"
                                          />
                                        ) : (
                                          <p
                                            style={{
                                              color: "black",
                                              margin: "54px",
                                            }}
                                          >
                                            Upload Image
                                          </p>
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="course"
                                    onClick={() => handleThumbnailClick(index)}
                                  >
                                    <div className="upLoadedImgCourse">
                                      <img
                                        src={basicData.thumbnail}
                                        className="img-fluid preImageedit"
                                        alt="Thumbnail"
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-3 col-md-3">
                                <label>Course Includes</label>
                              </div>
                              <div className="col-lg-9 col-md-9">
                                {basicsData.map((basicData, index) => (
                                  <div key={index}>
                                    {basicData.courseIncludes.map(
                                      (courseInclude, courseIndex) => (
                                        <div
                                          className="assment-course d-flex addcourse"
                                          key={courseIndex}
                                        >
                                          <input
                                            type="text"
                                            className="add-course"
                                            onChange={(e) => {
                                              handleCourseIncludeChange(
                                                e,
                                                index,
                                                courseIndex
                                              );
                                            }}
                                            value={courseInclude}
                                          />
                                          <p
                                            className="Add-row"
                                            onClick={() =>
                                              addCourseIncludeRow(index)
                                            }
                                          >
                                            +
                                          </p>
                                          <p
                                            className="del-row"
                                            onClick={() =>
                                              removeCourseIncludeRow(
                                                index,
                                                courseIndex
                                              )
                                            }
                                          >
                                            -
                                          </p>
                                        </div>
                                      )
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className="row justify-content-center">
                              <div className="col-4">
                                <div class="Grn-Btnmanin">
                                  <span
                                    class="Grn-Btn"
                                    onClick={() => handleNextButton("second")}
                                  >
                                    Next
                                  </span>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      ))}
                    </Tab>

                    <Tab eventKey="second" title="2. Pricing" disabled>
                      <div className="CorsELiSTab">
                        {basicsData.map((basicData, index) => {
                          return (
                            <form key={index}>
                              <div className="row">
                                <div className="col-lg-3 col-md-12 col-3"></div>
                                <div className="col-lg-3 col-md-12 pt-4 d-flex">
                                  <input
                                    type="checkbox"
                                    name={`freeForEveryone${index}`}
                                    value="everyone"
                                    checked={basicData.freeForEveryone}
                                    onChange={(e) =>
                                      handleCheckBoxChange(
                                        e,
                                        index,
                                        "freeForEveryone"
                                      )
                                    }
                                    disabled={basicData.freeForEnInLast30Days}
                                  />
                                  <label className="TApadig">
                                    Free for everyone
                                  </label>
                                  <br />
                                </div>
                                <div className="col-lg-5 col-md-12 pt-4 d-flex">
                                  <input
                                    className="checkbox"
                                    type="checkbox"
                                    name={`freeForEnInLast30Days${index}`}
                                    value="enrolled"
                                    checked={basicData.freeForEnInLast30Days}
                                    onChange={(e) =>
                                      handleCheckBoxChange(
                                        e,
                                        index,
                                        "freeForEnInLast30Days"
                                      )
                                    }
                                    disabled={
                                      basicData.freeForEveryone ||
                                      basicData.freeForbasedOnColleges ||
                                      havingDiscount
                                    }
                                  />
                                  <label className="TApadig">
                                    Free for those who enrolled in 30 days
                                  </label>
                                  <br />
                                </div>
                              </div>
                              {basicData.freeForEveryone ? null : (
                                <>
                                  <div className="row">
                                    <div className="col-lg-3 col-md-12 col-3"></div>
                                    <div className="col-lg-3 col-md-12 pt-4 d-flex colChK">
                                      <input
                                        className="checkbox"
                                        type="checkbox"
                                        name="colleges"
                                        value="colleges"
                                        checked={
                                          basicData.freeForbasedOnColleges
                                        }
                                        onChange={(e) =>
                                          handleCheckBoxChange(
                                            e,
                                            index,
                                            "freeForbasedOnColleges"
                                          )
                                        }
                                        disabled={
                                          basicData.freeForEveryone ||
                                          basicData.freeForEnInLast30Days ||
                                          havingDiscount
                                        }
                                      />
                                      <label className="TApadig">
                                        Free for based on Colleges
                                      </label>
                                      <br />
                                    </div>
                                    {basicData.freeForbasedOnColleges ? (
                                      <div className="col-lg-6 col-md-12 pt-4 d-flex">
                                        <Select
                                          isMulti
                                          defaultValue={getDefaultCollegeOptions(
                                            basicData.freeColleges
                                          )}
                                          onChange={handleCollegeChange}
                                          options={optionscolleges}
                                          isSearchable={true}
                                          placeholder="Choose colleges..."
                                          classNamePrefix="react-select"
                                          className="collegeDrpDwn"
                                        />
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-3 col-md-12 col-3">
                                      <label>MRP</label>
                                    </div>
                                    <div className="col-lg-9 col-9">
                                      <input
                                        type="number"
                                        className="COrAdForm"
                                        name={`price${index}`}
                                        id={`price${index}`}
                                        onChange={(e) =>
                                          handleCoursePriceChange(e, index)
                                        }
                                        onWheel={(e) => e.target.blur()}
                                        value={basicData.price}
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-3 col-md-12 col-3">
                                      <label>Course Price</label>
                                    </div>
                                    <div className="col-lg-9 col-9">
                                      <input
                                        type="number"
                                        className="COrAdForm"
                                        name={`price${index}`}
                                        id={`price${index}`}
                                        onChange={(e) =>
                                          handleCourseRegularPriceChange(
                                            e,
                                            index
                                          )
                                        }
                                        onWheel={(e) => e.target.blur()}
                                        value={basicData.regularPrice}
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-3 col-md-12"></div>
                                    <div className="col-lg-6 col-md-12 col-9 d-flex">
                                      <input
                                        type="checkbox"
                                        name={`discount${index}`}
                                        checked={havingDiscount}
                                        onChange={(e) => {
                                          handleDiscountCheckbox(e);
                                        }}
                                        disabled={
                                          basicData.freeForEveryone ||
                                          basicData.freeForEnInLast30Days ||
                                          basicData.freeForbasedOnColleges
                                        }
                                      />
                                      <label className="TApadig">
                                        Check if this course has a discount
                                      </label>
                                      <br />
                                    </div>
                                  </div>

                                  {havingDiscount ? (
                                    <>
                                      <div className="row">
                                        <div className="col-lg-3 col-md-12 col-3">
                                          <label>Discount Price</label>
                                        </div>
                                        <div className="col-lg-9 col-md-12 col-9">
                                          <input
                                            type="number"
                                            className="COrAdForm"
                                            name={`discountPrice${index}`}
                                            id={`discountPrice${index}`}
                                            value={basicData.discountedPrice}
                                            onWheel={(e) => e.target.blur()}
                                            onChange={(e) =>
                                              handleDiscountPriceChange(
                                                e,
                                                index
                                              )
                                            }
                                          />
                                          <p className="NotCa">
                                            This course has{" "}
                                            <span>
                                              {basicData.discountedPrice !==
                                                0 && basicData.price !== 0
                                                ? (
                                                    (basicData.discountedPrice /
                                                      basicData.price) *
                                                    100
                                                  ).toFixed(1)
                                                : null}
                                              %
                                            </span>{" "}
                                            Discount
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-lg-3 col-3">
                                          <label>Discount Expiry Date</label>
                                        </div>
                                        <div className="col-lg-9 col-9">
                                          <input
                                            type="date"
                                            min={moment().format("YYYY-MM-DD")}
                                            className="COrAdForm"
                                            value={
                                              basicData.discountedPriceExpiry
                                                ? new Date(
                                                    basicData.discountedPriceExpiry *
                                                      1000
                                                  )
                                                    .toISOString()
                                                    .substr(0, 10)
                                                : ""
                                            }
                                            onChange={(e) =>
                                              handleDiscountExpiryChange(
                                                e,
                                                index
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                </>
                              )}
                              <div className="d-flex justify-content-center">
                                <div className="">
                                  <div className="SHortsForm1">
                                    <span
                                      className="Grn-Btn adCrs"
                                      onClick={() => {
                                        handlePreviousButton("first");
                                      }}
                                    >
                                      Previous
                                    </span>
                                  </div>
                                </div>
                                <div className="">
                                  <div className="SHortsForm1">
                                    <span
                                      className="Grn-Btn adCrs"
                                      onClick={() => priceFn(index)}
                                    >
                                      Next
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </form>
                          );
                        })}
                      </div>
                    </Tab>
                    <Tab eventKey="third" title="3. Educators" disabled>
                      {basicsData.map((basicData, index) => (
                        <div className="CorsELiSTab">
                          <form>
                            <div className="row">
                              <div className="col-lg-3 col-3">
                                <label>Select Educator</label>
                              </div>
                              <div className="col-lg-9 col-9">
                                {basicData.isEducatorEditable ? (
                                  <select
                                    disabled={userType === "educator"}
                                    name="category"
                                    value={basicData.educators.name}
                                    onChange={(e) => {
                                      handleEducatorsChange(e, index);
                                    }}
                                  >
                                    <option value="test">
                                      Select Educator
                                    </option>
                                    {userType !== "educator" ? (
                                      educatorData.map((item) => (
                                        <option
                                          key={item?._id}
                                          value={item?._id}
                                        >
                                          {item?.name}
                                        </option>
                                      ))
                                    ) : (
                                      <option>{profileName}</option>
                                    )}
                                  </select>
                                ) : (
                                  <input
                                    type="text"
                                    className="COrAdForm"
                                    readOnly={true}
                                    value={basicData.educators.name}
                                    onClick={() => handleEducatorClick(index)}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-3 pt-4">
                                <label>Educator Photo</label>
                              </div>
                              <div className="col-lg-3">
                                <div className="ThumBnail">
                                  <div className="upLoad course">
                                    <label
                                      for="file-upload"
                                      className="custom-file-upload "
                                    >
                                      <img
                                        src={basicData.educators.photoUrl}
                                        className=""
                                        style={{
                                          padding: "10px",
                                          height: "196px",
                                          width: " 226px",
                                        }}
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-3">
                                <label>Educator Name</label>
                              </div>
                              <div className="col-lg-9 col-9">
                                <input
                                  type="text"
                                  className="COrAdForm"
                                  readOnly
                                  value={basicData.educators.name}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-3">
                                <label>Email</label>
                              </div>
                              <div className="col-lg-9 col-9">
                                <input
                                  type="text"
                                  className="COrAdForm"
                                  readOnly
                                  value={basicData.educators.email}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-3">
                                <label>CV</label>
                              </div>
                              <div className="col-lg-9 col-9">
                                <span
                                  onClick={() =>
                                    showPdf(basicData.educators.cvUrl)
                                  }
                                  className="view_button "
                                >
                                  View CV Details
                                </span>
                              </div>
                            </div>

                            <div className="d-flex justify-content-center mt-5">
                              <div className="">
                                <div className="SHortsForm1">
                                  <span
                                    className="Grn-Btn adCrs"
                                    onClick={() => {
                                      handlePreviousButton("second");
                                    }}
                                  >
                                    Previous
                                  </span>
                                </div>
                              </div>
                              <div className="">
                                <div className="SHortsForm1">
                                  <span
                                    className="Grn-Btn adCrs"
                                    onClick={updateCourseFn}
                                  >
                                    Submit
                                  </span>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      ))}
                    </Tab>

                    <Tab eventKey="four" title="4. Course Content">
                      <div className="CorCountenT">
                        <div className="row justify-content-center"></div>
                        <div className="row justify-content-center">
                          <div className="col-lg-3 col-md-3">
                            <Link
                              to=""
                              className="BluEBUTn"
                              onClick={handleShow}
                            >
                              + Add Session
                            </Link>
                          </div>

                          <div className="col-lg-3 col-md-3">
                            <Link
                              to=""
                              className="BluEBUTn"
                              onClick={handleShow1}
                            >
                              + Add Lesson
                            </Link>
                          </div>
                          <div className="col-lg-3 col-md-3">
                            <Link
                              to=""
                              className="BluEBUTn"
                              onClick={handleShow3}
                            >
                              + Add Assessment
                            </Link>
                          </div>
                        </div>
                        <div className="row justify-content-center mt-5">
                          <div className="col-12">
                            {sessionData
                              ? sessionData.map((session, sessionIndex) => (
                                  <div className="DulBG">
                                    <div className="d-flex">
                                      <div className="DuLLBg">
                                        <p>
                                          <span>
                                            Session {sessionIndex + 1} :
                                          </span>{" "}
                                          {session.title}
                                        </p>
                                      </div>
                                      <div
                                        className={` ${
                                          session.assessments.length > 0
                                            ? "AcBtN Wh"
                                            : "AcBtN"
                                        }`}
                                      >
                                        <button
                                          onClick={() =>
                                            handleViewAssessment(sessionIndex)
                                          }
                                        >
                                          View Assessment
                                        </button>
                                      </div>
                                      <div className="AcBtN Wh">
                                        <button
                                          type="submit"
                                          onClick={() =>
                                            handleEditSession(session)
                                          }
                                        >
                                          Edit Session
                                        </button>
                                      </div>
                                      <div className="AcBtN Wh">
                                        <button
                                          type="submit"
                                          onClick={() => deleteSession(session)}
                                        >
                                          Delete Session
                                        </button>
                                      </div>
                                    </div>
                                    <div className="row mt-5">
                                      <div className="col-12">
                                        {session.lessons.map(
                                          (lessons, lessonIndex) => (
                                            <div className="LeSSonSesion">
                                              <div className="d-flex">
                                                <div className="LesSNp">
                                                  <p>
                                                    <span>
                                                      Lesson {lessonIndex + 1} :
                                                    </span>
                                                    {lessons.title} (
                                                    {lessons.duration})
                                                  </p>
                                                </div>
                                                <div className="AcBtN">
                                                  <button
                                                    onClick={() =>
                                                      handleEditLesson(lessons)
                                                    }
                                                  >
                                                    <i>
                                                      <MdEdit />
                                                    </i>
                                                  </button>
                                                  <button
                                                    onClick={() =>
                                                      deleteLesson(lessons)
                                                    }
                                                  >
                                                    <i>
                                                      <MdDelete />
                                                    </i>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))
                              : null}
                          </div>
                        </div>
                      </div>
                    </Tab>

                    <Tab eventKey="five" title="5. Finish" disabled>
                      <div className="CorsELiSTab">
                        <div className="row">
                          <div className="col-12">
                            <div className="CoConTenT">
                              <h4>Thank You!</h4>
                              <h4>Your Course has been Created </h4>
                              <div className="row justify-content-center">
                                <div className="col-xl-6 col-lg-6">
                                  <div class="Grn-Btnmanin">
                                    <Link class="Grn-Btn">Finish</Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Session</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit3(SessionSubmit)}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Session Title</Form.Label>

              <Form.Control
                type="text"
                placeholder=""
                autoFocus
                name="title"
                id="title"
                {...register3("title", {
                  required: "Session Title is required",
                })}
              />
            </Form.Group>
            {errors3.title && (
              <small style={{ color: "red", float: "left" }}>
                {errors3.title.message}
              </small>
            )}
            <div className="d-flex justify-content-center">
              <button className="Grn-Btn Half" type="submit">
                add
              </button>
              <button
                className="WHt-Btn Half"
                type="button"
                onClick={cancelAddSession}
              >
                cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* view modal */}
      <Modal show={showview} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Add Lesson</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit4(handleUploadClick)}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Select Session</Form.Label>
              <div className="assment-course">
                <select
                  name="sessionId"
                  {...register4("sessionId", {
                    required: "select session is required",
                    pattern: {
                      value: /^(?!.*test).*$/,
                      message: "select session",
                    },
                  })}
                  onChange={handleSessionSelectId}
                >
                  <option key="test" value="test">
                    Select session
                  </option>
                  {sessList.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {errors4.sessionId && (
                  <p style={{ color: "red" }}> {errors4.sessionId.message}</p>
                )}
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Lesson Title</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="title"
                id="title"
                {...register4("title", {
                  required: "title is required",
                })}
                onChange={handleSessionSelectTitle}
              />
              {errors4.title && (
                <p style={{ color: "red" }}>{errors4.title.message}</p>
              )}
            </Form.Group>

            <div className="row">
              <div className="col-6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Duration</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="hh:mm:ss"
                    name="duration"
                    id="duration"
                    {...register4("duration", {
                      required: "duration is required",
                      pattern: {
                        value: /^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/,
                        message: "Invalid duration format (HH:mm:ss)",
                      },
                    })}
                    onChange={(e) =>
                      handleSessionSelectDuration(e.target.value)
                    }
                  />

                  {errors4.duration && (
                    <small style={{ color: "red", float: "left" }}>
                      {errors4.duration.message}
                    </small>
                  )}
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Video Upload</Form.Label>
                  <Form.Control
                    type="file"
                    accept="video/*"
                    ref={fileInputRefvid}
                    {...register4("videoUrl", {
                      required: "video  is required",
                    })}
                    onChange={handleFileChange1}
                  />

                  {errors4.videoUrl && (
                    <small style={{ color: "red", float: "left" }}>
                      {errors4.videoUrl.message}
                    </small>
                  )}
                  <Form.Label className="progress-bar" ref={progressBarRef}>
                    <ProgressBar />
                  </Form.Label>
                </Form.Group>
              </div>
              {/* <div className="OnOfFbtn">
                <Form.Group className="mb-3">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="Free Video"
                    onChange={handleToggle1}
                    checked={isHidden}
                  />
                </Form.Group>
              </div> */}
            </div>
            <div className="d-flex justify-content-center">
              <button className="Grn-Btn Half" type="submit">
                add
              </button>
              <button
                className="WHt-Btn Half"
                type="button"
                onClick={handleClose1}
              >
                cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* view assesment */}

      <Modal show={showview1} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>View Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit8(assesmentSubmitupdate)}>
            <Form.Group>
              <div className="DuLLBg">
                {sessionData[sessionIndexToShow] && (
                  <p>
                    <span>Session {sessionIndexToShow + 1}:</span>{" "}
                    {sessionData[sessionIndexToShow].title}
                  </p>
                )}
              </div>
            </Form.Group>

            <Accordion defaultActiveKey="0">
              {sessionData[sessionIndexToShow]?.assessments.map(
                (assessment, assessmentIndex) => (
                  <Accordion.Item
                    eventKey={assessmentIndex.toString()}
                    key={assessmentIndex}
                  >
                    <Accordion.Header className="AccWidth">
                      <Form.Control
                        type="text"
                        value={
                          editedAssessment && isEditing(assessment)
                            ? editedAssessment.question
                            : assessment.question
                        }
                        onChange={(e) => {
                          setEditedAssessment({
                            ...editedAssessment,
                            question: e.target.value,
                          });
                          setAssesmentUpdate({
                            ...assesmentUpdate,
                            question: e.target.value,
                          });
                        }}
                      />
                      <div className="AcBtN asses">
                        <button
                          onClick={() => handleEditAssessment(assessment)}
                          type="button"
                          style={{ width: "38px" }}
                        >
                          <i>
                            <MdEdit />
                          </i>
                        </button>
                        <button
                          onClick={() => deleteAssessment(assessment)}
                          type="button"
                          style={{ width: "38px" }}
                        >
                          <i>
                            <MdDelete />
                          </i>
                        </button>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      {assessment.options.map((option, optionIndex) => (
                        <div key={optionIndex}>
                          <label className="CustomRadioButton">
                            <input
                              type="radio"
                              className="CustomTextInput"
                              name={`correctAnswer-${assessmentIndex}`}
                              value={editedAssessment?.options?.[optionIndex]}
                              onChange={(e) => {
                                setEditedAssessment({
                                  ...editedAssessment,
                                  correctAnswer: e.target.value,
                                });
                                setAssesmentUpdate({
                                  ...assesmentUpdate,
                                  correctAnswer: e.target.value,
                                });
                              }}
                              checked={
                                (!isEditing(assessment) &&
                                  option === assessment.correctAnswer) ||
                                (isEditing(assessment) &&
                                  editedAssessment.correctAnswer ===
                                    editedAssessment.options[optionIndex])
                              }
                              disabled={!isEditing(assessment)}
                            />

                            <span className="AccCheck">
                              <input
                                type="text"
                                value={
                                  isEditing(assessment)
                                    ? editedAssessment?.options?.[optionIndex]
                                    : option
                                }
                                onChange={(e) => {
                                  const newOptions = [
                                    ...editedAssessment.options,
                                  ];
                                  newOptions[optionIndex] = e.target.value;
                                  setEditedAssessment({
                                    ...editedAssessment,
                                    options: newOptions,
                                  });

                                  setAssesmentUpdate({
                                    ...assesmentUpdate,
                                    options: newOptions,
                                  });
                                }}
                              />
                            </span>
                          </label>
                          <br />
                        </div>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                )
              )}
            </Accordion>

            <div className="d-flex justify-content-center">
              {editAssessment && (
                <>
                  <button className="Grn-Btn Half" type="submit">
                    Update
                  </button>
                  <button
                    className="WHt-Btn Half"
                    type="button"
                    onClick={() => {
                      handleClose2();
                    }}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Edit Session Modal */}
      <Modal show={editsession} onHide={handleClose5}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Session</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit6(sessionSubmitupdate)}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Session Title</Form.Label>

              <Form.Control
                type="text"
                //placeholder=""
                autoFocus
                name="title"
                id="title"
                value={editedSession ? editedSession.title : ""}
                onChange={(e) =>
                  setEditedSession({
                    ...editedSession,
                    title: e.target.value,
                  })
                }
              />
            </Form.Group>
            {errors6.title && (
              <small style={{ color: "red", float: "left" }}>
                {errors6.title.message}
              </small>
            )}
            <div className="d-flex justify-content-center">
              <button className="Grn-Btn Half" type="submit">
                Update
              </button>

              <button
                className="WHt-Btn Half"
                type="button"
                onClick={handleClose5}
              >
                Cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Edit Lesson Modal */}
      <Modal show={editlesson} onHide={handleClose6}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Lesson</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit7(handleUploadEdit)}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Lesson Title</Form.Label>

              <Form.Control
                type="text"
                autoFocus
                name="title"
                id="title"
                value={editedLesson ? editedLesson.title : ""}
                onChange={(e) =>
                  setEditedLesson({
                    ...editedLesson,
                    title: e.target.value,
                  })
                }
              />
            </Form.Group>
            {errors7.title && (
              <small style={{ color: "red", float: "left" }}>
                {errors7.title.message}
              </small>
            )}

            <div className="row">
              <div className="col-6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Duration</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="hh:mm:ss"
                    name="duration"
                    id="duration"
                    value={editedLesson ? editedLesson.duration : ""}
                    onChange={(e) =>
                      handleEditSessionSelectDuration(e.target.value)
                    }
                  />
                  {errors7.duration && (
                    <small style={{ color: "red", float: "left" }}>
                      {errors7.duration.message}
                    </small>
                  )}
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Video Upload</Form.Label>
                  <Form.Control
                    type="file"
                    accept="video/*"
                    ref={fileInputRefvid}
                    onChange={handleFileChangeEdit}
                  />
                  {!editFileName && <small>{editedLesson?.fileName}</small>}
                  {errors7.videoUrl && (
                    <small style={{ color: "red", float: "left" }}>
                      {errors7.videoUrl.message}
                    </small>
                  )}
                  <Form.Label className="progress-bar" ref={progressBarRef}>
                    <ProgressBar />
                  </Form.Label>
                </Form.Group>
              </div>
              {/* <div className="OnOfFbtn">
                <Form.Group className="mb-3">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="Free Video"
                    onChange={handleToggle}
                    checked={editedLesson ? editedLesson.isFreeVideo : false}
                  />
                </Form.Group>
              </div> */}
            </div>
            <div className="d-flex justify-content-center">
              <button className="Grn-Btn Half" type="submit">
                Update
              </button>
              <button
                className="WHt-Btn Half"
                type="button"
                onClick={cancelEditLesson}
              >
                cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal */}
      <Modal show={show1} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>View CV Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <iframe
              src={`https://docs.google.com/gview?url=${getImg}&embedded=true`}
              width="100%"
              height="500px"
              title="CV Details"
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>

      {/* add assesment */}
      <Modal show={showview3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>Add Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit5(assesmentFn)}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Select Session</Form.Label>
              <div className="assment-course">
                <select
                  name="sessionId"
                  {...register5("sessionId", {
                    required: "select session is required",
                    pattern: {
                      value: /^(?!.*test).*$/,
                      message: "select session",
                    },
                  })}
                >
                  <option key="test" value="test">
                    Select session
                  </option>
                  {sessList.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {errors5.sessionId && (
                  <p style={{ color: "red" }}> {errors5.sessionId.message}</p>
                )}
              </div>
            </Form.Group>

            {rows.map((row, i) => (
              <div className="assment-course d-flex mt-4">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <input
                        type="text"
                        className="add-course"
                        placeholder="Question"
                        onChange={(event) => ansFn(event, i, "question")}
                      />
                      {/* {errors5.sessionId && (
                        <p style={{ color: "red" }}>
                          {" "}
                          {errors5.sessionId.message}
                        </p>
                      )} */}
                    </Accordion.Header>
                    <Accordion.Body>
                      <form className="Addasses">
                        <input
                          type="radio"
                          name={`courseanswer${i}`}
                          value="option1"
                          onChange={(event) => {
                            ansFn(event, i, "correctAnswer");
                          }}
                        />
                        <input
                          type="text"
                          className="Checkboxans"
                          placeholder="Option1"
                          onChange={(event) => ansFn(event, i, "option1")}
                        />
                        <br />
                        <input
                          type="radio"
                          name={`courseanswer${i}`}
                          value="option2"
                          onChange={(event) => {
                            ansFn(event, i, "correctAnswer");
                          }}
                        />
                        <input
                          type="text"
                          className="Checkboxans"
                          placeholder="Option2"
                          onChange={(event) => ansFn(event, i, "option2")}
                        />
                        <br />
                        <input
                          type="radio"
                          name={`courseanswer${i}`}
                          value="option3"
                          onChange={(event) => {
                            ansFn(event, i, "correctAnswer");
                          }}
                        />
                        <input
                          type="text"
                          className="Checkboxans"
                          placeholder="Option3"
                          onChange={(event) => ansFn(event, i, "option3")}
                        />
                        <br />
                        <input
                          type="radio"
                          name={`courseanswer${i}`}
                          value="option4"
                          onChange={(event) => {
                            ansFn(event, i, "correctAnswer");
                          }}
                        />
                        <input
                          type="text"
                          className="Checkboxans"
                          placeholder="Option4"
                          onChange={(event) => ansFn(event, i, "option4")}
                        />
                        <br />
                      </form>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <p className="Add-row" onClick={addQandARow}>
                  +
                </p>
                <p className="del-row" onClick={removeQandARow}>
                  -
                </p>
              </div>
            ))}
            <button className="Grn-Btn assesm" type="submit">
              Save
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Editcourse;
