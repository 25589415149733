import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./Purchase.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { useDispatch } from "react-redux";
import { loader, isLogin } from "../redux/common";
import { BsThreeDotsVertical } from "react-icons/bs";
import { toast } from "react-toastify";
import { orderDetail, orderList } from "../redux/jobs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

const Purchase = () => {
  let dispatch = useDispatch();
  let [refresher, setRefresher] = useState(true);
  const [showview, setShowview] = useState(false);
  let [viewData, setViewData] = useState([]);
  const [localSearchValue, setLocalSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleClose1 = () => setShowview(false);
  const handleShow1 = (id) => {
    let data = {};
    data["orderId"] = id;
    setShowview(true);
    dispatch(loader(true));
    dispatch(
      orderDetail(data, (resp) => {
        if (resp.status) {
          setViewData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  useEffect(() => {
    fetchData({ search: localSearchValue });
  }, [refresher, localSearchValue]);

  const fetchData = (data) => {
    dispatch(
      orderList(data, (resp) => {
        dispatch(loader(true));
        if (resp.status) {
          setFilteredData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
        dispatch(loader(false));
      })
    );
  };

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setLocalSearchValue(value);
  };

  const handleCheckboxChange = (Id) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(Id)) {
        return prevSelected.filter((id) => id !== Id);
      } else {
        return [...prevSelected, Id];
      }
    });
  };

  const generatePDF = () => {
    const doc = new jsPDF({
      orientation: "l",
      unit: "pt",
      format: [842, 595],
    });
    let dataToExport =
      selectedItems.length > 0
        ? filteredData.filter((item) => selectedItems.includes(item._id))
        : filteredData;

    const tableData = dataToExport.map((item, index) => {
      const paidAmount = item.paymentDetails
        ? `INR ${item.paymentDetails.paidAmount}`
        : "";
      const paymentDetails = item.paymentDetails
        ? `Total MRP: INR ${item.paymentDetails.totalMrp}\nCoupon Discount: INR ${item.paymentDetails.couponDiscount}\nTotal Discount: INR ${item.paymentDetails.totalDiscount}\nPaid Amount: INR ${item.paymentDetails.paidAmount}`
        : "";
      return [
        index + 1,
        item.billingDetails.name,
        item.billingDetails.email,
        item.items.courses.map((v) => `• ${v.title}`).join("\n"),
        paidAmount,
        paymentDetails,
        new Date(item.createdAt).toLocaleDateString(),
        item.status,
      ];
    });

    doc.autoTable({
      head: [
        [
          "S.No",
          "Name",
          "Email",
          "Title",
          "Paid Amount",
          "Payment Details",
          "Last Date",
          "Status",
        ],
      ],
      body: tableData,
      columnStyles: {
        0: { cellWidth: 35 },
        1: { cellWidth: 100 },
        2: { cellWidth: 125 },
        3: { cellWidth: 140 },
        4: { cellWidth: 80 },
        5: { cellWidth: 150 },
        6: { cellWidth: 80 },
        7: { cellWidth: 60 },
      },
      styles: {
        overflow: "linebreak",
        columnStyles: { 5: { lineHeight: 1.5 } }, // Handle line breaks
      },
    });

    doc.save("course_purchase_list.pdf");
  };

  const exportToExcel = () => {
    let dataToExport =
      selectedItems.length > 0
        ? filteredData.filter((item) => selectedItems.includes(item._id))
        : filteredData;

    const tableData = dataToExport.map((item, index) => {
      const paymentDetails = item.paymentDetails
        ? `Total MRP: INR ${item.paymentDetails.totalMrp}\nCoupon Discount: INR ${item.paymentDetails.couponDiscount}\nTotal Discount: INR ${item.paymentDetails.totalDiscount}\nPaid Amount: INR ${item.paymentDetails.paidAmount}`
        : "";
      return {
        "S.No": index + 1,
        Name: item.billingDetails.name,
        Email: item.billingDetails.email,
        Title: item.items.courses.map((v) => v.title).join(", "),
        "Paid Amount": item.paymentDetails
          ? `INR ${item.paymentDetails.paidAmount}`
          : "",
        "Payment Details": paymentDetails,
        "Last Date": new Date(item.createdAt).toLocaleDateString(),
        Status: item.status,
      };
    });

    const ws = XLSX.utils.json_to_sheet(tableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Purchase History");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "course_purchase_history.xlsx"); // Changed the filename extension to .xlsx
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <div className="Categorypage">
        <div className="PageTit ">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h3>Course Purchase history</h3>
                <p>
                  <Link to="/dashboard">Dashboard</Link>{" "}
                  <i>
                    <AiOutlineRight />
                  </i>{" "}
                  <Link to="/purchase_list">Course Purchase history</Link>
                </p>
              </div>
              <div className="col-6"></div>
            </div>
          </div>
        </div>
        <div className="CateCont">
          <div className="container">
            <div className="row">
              <div className="CateGorTab">
                <div className="col-12">
                  <div className="searchBtNs">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="BtNSSearcH">
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Search"
                              aria-describedby="basic-addon2"
                              value={localSearchValue}
                              onChange={handleSearchInputChange}
                            />
                            <Button
                              variant="outline-secondary"
                              id="button-addon2"
                            >
                              <BsSearch />
                            </Button>
                          </InputGroup>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6"></div>

                      <div className="col-lg-2 col-md-6">
                        <div className="BtNSSearcH pur">
                          <button className="Grn-Btn pdf" onClick={generatePDF}>
                            Export as PDF
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-6">
                        <div className="BtNSSearcH pur">
                          <button
                            className="Grn-Btn pdf"
                            onClick={exportToExcel}
                          >
                            Export as Excel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Table
                  striped
                  hover
                  id="purchaseTable"
                  style={{ display: "none" }}
                >
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" />
                      </th>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Email Address</th>
                      <th>Course Name</th>
                      <th>Paid Amount</th>
                      <th>Payment Details</th>
                      <th>Purchase Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((item, index) => {
                      const paymentDetails = item.paymentDetails
                        ? `Total MRP: INR ${item.paymentDetails.totalMrp}\nCoupon Discount: INR ${item.paymentDetails.couponDiscount}\nTotal Discount: INR ${item.paymentDetails.totalDiscount}\nPaid Amount:INR ${item.paymentDetails.paidAmount}`
                        : "";

                      const paymentDetailsArray = paymentDetails
                        .split("\n")
                        .map((detail) => {
                          const [key, value] = detail.split(":");
                          return { key: key.trim(), value: value.trim() };
                        });
                      return (
                        <tr key={index}>
                          <td>
                            <input type="checkbox" />
                          </td>
                          <td>{index + 1}</td>
                          <td>{item.billingDetails.name}</td>
                          <td>{item.billingDetails.email}</td>
                          <td>
                            {item.items.courses.map((course, index) => (
                              <span key={index}>
                                {course.title}
                                <br />
                              </span>
                            ))}
                          </td>
                          <td>{`INR ${item.paymentDetails.paidAmount}`}</td>

                          <td>
                            <ul>
                              {paymentDetailsArray.map((detail, idx) => (
                                <li key={idx}>
                                  {detail.key}: {detail.value}
                                </li>
                              ))}
                            </ul>
                          </td>
                          <td>
                            {new Date(item.createdAt).toLocaleDateString()}
                          </td>
                          <td>{item.status}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" />
                      </th>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Email Address</th>
                      <th>Course Name</th>
                      <th>Paid Amount</th>
                      <th>Purchase Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange(item._id)}
                            checked={selectedItems.includes(item._id)}
                          />
                        </td>
                        <td>{index + 1}</td>
                        <td>{item.billingDetails.name}</td>
                        <td>{item.billingDetails.email}</td>
                        <td>
                          {item.items.courses.map((course, index) => (
                            <span key={index}>
                              {course.title}
                              <br />
                            </span>
                          ))}
                        </td>
                        <td>{`₹${item.paymentDetails.paidAmount}`}</td>
                        <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                        <td>{item.status}</td>
                        <td>
                          <div>
                            <div className="Options pt-1">
                              <i>
                                <BsThreeDotsVertical />
                              </i>
                              <ul className="hidden-list">
                                <li onClick={() => handleShow1(item._id)}>
                                  View
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showview} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>View Purchase Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Order ID</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData._id}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Name</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.billingDetails?.name}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Email</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.billingDetails?.email}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Mobile Number</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.billingDetails?.mobileNumber}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">State</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.billingDetails?.state}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Country</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.billingDetails?.country}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Pincode</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.paymentDetails?.billing_zip}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Total MRP</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={`INR ${viewData?.paymentDetails?.totalMrp}`}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">
                Coupon Discount
              </InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={`INR ${viewData?.paymentDetails?.couponDiscount}`}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">
                Total Discount
              </InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={`INR ${viewData?.paymentDetails?.totalDiscount}`}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Paid Amount</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={`INR ${viewData?.paymentDetails?.paidAmount}`}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Tracking ID</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.paymentDetails?.tracking_id}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Bank Ref No</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.paymentDetails?.bank_ref_no}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Payment Mode</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.paymentDetails?.payment_mode}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Card Name</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.paymentDetails?.card_name}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">
                Transaction Date
              </InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.paymentDetails?.trans_date}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Order Status</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.paymentDetails?.order_status}
                readOnly
              />
            </InputGroup>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Purchase;
