import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { TbFileUpload } from "react-icons/tb";
import { educatordetailView, educatorStatusChange } from "../redux/Educators";
import { useDispatch } from "react-redux";
import { loader, isLogin } from "../redux/common";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Topbar from "../../components/topbar/Topbar";
import SideBar from "../../components/Sidebar/SideBar";
import "./Educator.css";
const Vieweducator = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [showview, setShowview] = useState(false);
  const [viewData, setViewData] = useState({});
  const [pdfUrl, setPdfUrl] = useState({});
  const [dataUrl, setDataUrl] = useState({});
  const [viewDataDummy, setViewDataDummy] = useState({});
  const [statusData, setStatusData] = useState();
  const [getImg, setImg] = useState();
  const [pdfLoaded, setPdfLoaded] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShow2 = () => setShow1(true);
  const handleModalShow = () => {
    setPdfLoaded(true);
  };

  const handleClose2 = () => {
    setShow1(false);
  };
  const handleClose3 = () => setShow2(false);
  const handleClose4 = () => setShow3(false);
  const handleClose1 = () => setShowview(false);
  const handleShow1 = () => setShowview(true);

  useEffect(() => {
    let educatorID = sessionStorage.getItem("EducatorId");
    let data = educatorID;
    let navigate = useNavigate;
    dispatch(loader(true));
    dispatch(
      educatordetailView(data, (resp) => {
        if (resp.status) {
          setViewData(resp.data);
          setPdfUrl(resp.data.cvUrl);
          setDataUrl(resp.data.course1Url);
          setViewDataDummy(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, []);

  const statusChange = (e) => {
    setViewDataDummy(e.target.value);
    setStatusData(e.target.value);
  };

  const updateStatus = () => {
    let educatorID = sessionStorage.getItem("EducatorId");
    let data = {};
    data["eduId"] = educatorID;
    data["status"] = statusData;
    dispatch(loader(true));
    dispatch(
      educatorStatusChange(data, (resp) => {
        if (resp.status) {
          setViewData(resp.data);
          toast.success(resp.message);
          navigate("/educators");
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const showPdf = (data) => {
    setShow1(!show1);
    setImg(data);
  };

  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/educators">Educators</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="">{viewData.name}</Link>
              </p>
              <h3>{viewData.name}</h3>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <div className="CorsELiSTabedu">
                <div className="row justify-content-center">
                  <div className="col-9">
                    <div className="CorsELiSTabedu pt-4 pb-4">
                      <Table striped bordered hover>
                        <tbody>
                          <tr>
                            <td className="CatCOlr">Name</td>
                            <td>{viewData.name}</td>
                          </tr>

                          <tr>
                            <td className="CatCOlr">Email Address</td>
                            <td>{viewData.email}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Mobile Number</td>
                            <td>{viewData.phoneNumber}</td>
                          </tr>

                          <tr>
                            <td className="CatCOlr">City</td>
                            <td>{viewData.city}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Expertise</td>
                            <td>{viewData.expertise}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">CV</td>

                            <td>
                              <button
                                onClick={() => showPdf(viewData.cvUrl)}
                                className="view_button "
                              >
                                {" "}
                                Click Here To View
                              </button>
                            </td>
                          </tr>

                          <tr>
                            <td className="CatCOlr">Sample Course 1</td>
                            <td>
                              <button
                                onClick={setShow2}
                                className="view_button "
                              >
                                View Sample Course 1
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Sample Course 2</td>
                            <td>
                              <button
                                onClick={setShow3}
                                className="view_button "
                              >
                                View Sample Course 2
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                <div className="CorsELiSTabedu pt-0 pb-4">
                  <div className="row justify-content-center">
                    <div className="col-xl-1 col-lg-1 col-md-2 col-sm-2">
                      <div className="ViewCon educator">
                        <label>Status</label>
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4">
                      <div className="ViewCon educator">
                        <select
                          value={viewDataDummy.status}
                          onChange={statusChange}
                        >
                          <option value="">Select</option>
                          <option value="active">Active</option>
                          <option value="inactive">InActive</option>
                          <option value="rejected">Rejected</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-2">
                      <div className="ViewCon educator">
                        <div class="Grn-Btnmanin flLef lrnedit eduEdit">
                          <button class="Grn-Btn" onClick={updateStatus}>
                            UPDATE STATUS
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={show1} onHide={handleClose2} onShow={handleModalShow}>
        <Modal.Header closeButton>
          <Modal.Title>View CV Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            {pdfLoaded && getImg && (
              <iframe
                src={`https://docs.google.com/gview?url=${getImg}&embedded=true`}
                width="100%"
                height="500px"
              ></iframe>
            )}
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal */}
      <Modal show={show2} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>View Sample Course 1</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <video controls width="640" height="360">
              <source src={viewData.course1Url} type="video/mp4" />
            </video>
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal */}
      <Modal show={show3} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>View Sample Course 2</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <video controls width="640" height="360">
              <source src={viewData.course2Url} type="video/mp4" />
            </video>
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Question</Form.Label>
                  <Form.Control type="text" placeholder="" autoFocus />
                </Form.Group>
              </div>
            </div>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Answer</Form.Label>
              <Form.Control
                as="textarea"
                placeholder=""
                autoFocus
                style={{ height: "150px" }}
              />
            </Form.Group>
            <div className="d-flex">
              <button className="Grn-Btn Half">add</button>
              <button className="WHt-Btn Half">cancel</button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* view modal */}
      <Modal show={showview} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Question</Form.Label>
                  <Form.Control type="text" placeholder="" autoFocus />
                </Form.Group>
              </div>
            </div>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Answer</Form.Label>
              <Form.Control
                as="textarea"
                placeholder=""
                autoFocus
                style={{ height: "150px" }}
              />
            </Form.Group>
            <div className="d-flex">
              <button className="Grn-Btn Half">add</button>
              <button className="WHt-Btn Half">cancel</button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Vieweducator;
