import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import "./Blogs.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useState } from "react";
import { fetchBlogList, deleteBlog, changeStatusCb } from "../redux/blogs";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { loader } from "../redux/common";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setChecked] = useState([]);
  let [refresher, setRefresher] = useState(true);
  const [blogData, setBlogData] = useState([]);

  const handleAddBlogClick = () => {
    navigate("/add_blog");
  };

  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      fetchBlogList((resp) => {
        if (resp.status) {
          setBlogData(resp.data);
          var arr = [];
          for (var i = 0; i < resp.data.length; i++) {
            arr.push(resp.data[i].isActive);
          }
          setChecked(arr);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, [refresher]);

  const changeStatus = (id, index) => {
    let value = {
      isActive: !checked[index],
    };
    let data = {};
    data["blogId"] = id;
    data["isActive"] = value.isActive;
    dispatch(loader(true));
    dispatch(
      changeStatusCb(data, (resp) => {
        if (resp.status) {
          dispatch(loader(false));
          toast.success(resp.message);
          setRefresher(!refresher);
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const deleteblog = (id, index) => {
    let data = {};
    data["blogId"] = id;
    dispatch(loader(true));
    dispatch(
      deleteBlog(data, (resp) => {
        if (resp.status) {
          dispatch(loader(false));
          setRefresher(!refresher);
          toast.success(resp.message);
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <h3>Blogs List</h3>
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/blogs">Blogs</Link>
              </p>
            </div>
            <div className="col-6">
              <div className="Grn-Btnmanin">
                <button className="Grn-Btn" onClick={handleAddBlogClick}>
                  add blog
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <Table striped hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Image</th>
                    <th>Title</th>
                    <th>Short Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {blogData.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td className="TabImg">
                        <img
                          src={item.thumbnail}
                          className="img-fluid"
                          alt="Thumbnail"
                        />
                      </td>
                      <td className="DivWidth blog">{item.title}</td>
                      <td className="DivWidth blog">{item.sDesc}</td>
                      <td>
                        <div className="d-flex">
                          <div className="">
                            <div className="OnOfF">
                              <Form>
                                <Form.Check
                                  type="switch"
                                  id={`custom-switch-${index}`}
                                  onChange={() => changeStatus(item._id, index)}
                                  checked={checked[index]}
                                />
                              </Form>
                            </div>
                          </div>
                          <div className="col-5">
                            <div className="Options pt-1">
                              <i>
                                <BsThreeDotsVertical />
                              </i>
                              <ul class="hidden-list blgLi">
                                <li
                                  onClick={() =>
                                    navigate("/edit_blog/" + item._id)
                                  }
                                >
                                  Edit
                                </li>
                                <li onClick={() => deleteblog(item._id, index)}>
                                  Delete
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Blogs;
