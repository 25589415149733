import { useState, useEffect } from "react";
import "./Learners.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import { learnerInfo, learnerStatusChange } from "../redux/learner";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { toast } from "react-toastify";
import { TbFileUpload } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

const Editlearners = () => {
  const [learnerInfoData, setLearnerInfoData] = useState({});
  const [learnerInfoDataCopy, setLearnerInfoDataCopy] = useState({});
  const [statusData, setStatusData] = useState();
  const [previewImage, setPreviewImage] = useState();

  let dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    let learnerID = sessionStorage.getItem("LearnerId");
    let data = {};
    data["id"] = learnerID;
    dispatch(loader(true));
    dispatch(
      learnerInfo(data, (resp) => {
        if (resp.status) {
          setLearnerInfoData(resp.data[0]);
          setLearnerInfoDataCopy(resp.data[0]);
          setPreviewImage(resp.data[0].profilePic);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, []);

  const statusChange = (e) => {
    const selectedValue = e.target.value;
    const isActive = selectedValue === "true";
    setStatusData(isActive);
    setLearnerInfoDataCopy(e.target.value);
  };

  const updateStatus = () => {
    let learnerID = sessionStorage.getItem("LearnerId");
    dispatch(loader(true));

    let data = {};
    data["id"] = learnerID;
    data["isActive"] = statusData;

    dispatch(
      learnerStatusChange(data, (resp) => {
        if (resp.status) {
          toast.success(resp.message);
          navigate("/learners");
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const handleProfilePic = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };

      reader.readAsDataURL(selectedFile);
    } else {
      //   setPreviewImage(
      //     "https://cdn.landesa.org/wp-content/uploads/default-user-image.png"
      //   );
    }
  };

  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/learners">Learners</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="">{learnerInfoData.fullName}</Link>
              </p>
              <h3>{learnerInfoData.fullName}</h3>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <div className="CorsELiSTab">
                <div className="row">
                  <div className="col-md-12 col-lg-6 col-xl-6 lrnImg">
                    <div className="ThumBnail lrnrs">
                      <div className="upLoad-learner">
                        <label
                          for="file-upload"
                          className="custom-file-upload "
                        ></label>
                        <label htmlFor="imgupload">
                          <input
                            type="file"
                            id="imgupload"
                            onChange={handleProfilePic}
                            style={{ display: "none" }}
                            disabled
                          />
                          <i style={{ display: "none" }}>
                            <TbFileUpload className="uploadIcon" />
                          </i>
                          <img
                            src={previewImage}
                            className="img-fluid"
                            alt="User Profile"
                            style={{
                              width: "200px",
                              marginTop: "-54px",
                              height: "150px",
                            }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="ViewCon">
                      <label>Status</label>
                      <br />
                      <select
                        value={learnerInfoDataCopy.isActive}
                        onChange={statusChange}
                        
                      >
                        <option value="true">Active</option>
                        <option value="false">InActive</option>
                      </select>
                      <div class="Grn-Btnmanin flLef lrnedit">
                        <button class="Grn-Btn" onClick={updateStatus}>
                          Update Status
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6 col-xl-6">
                    <div className="CorsELiSTab pt-4 pb-4">
                      <Table striped bordered hover>
                        <tbody>
                          <tr>
                            <td className="CatCOlr">Name</td>
                            <td>{learnerInfoData?.fullName}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Learner Type</td>
                            <td>{learnerInfoData?.userType}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Email Address</td>
                            <td>{learnerInfoData?.email}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Mobile Number</td>
                            <td>{learnerInfoData?.phoneNumber}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Pincode</td>
                            <td>{learnerInfoData?.pinCode}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">City</td>
                            <td>{learnerInfoData?.city}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">State</td>
                            <td>{learnerInfoData?.state}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Country</td>
                            <td>{learnerInfoData?.country}</td>
                          </tr>

                          <tr>
                            <td className="CatCOlr">Name of the College</td>
                            <td>{learnerInfoData?.collegeName} </td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Year of the College</td>
                            <td>{learnerInfoData?.yearOfCollege}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Degree of Stream</td>
                            <td>{learnerInfoData?.degreeOfStream}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">City</td>
                            <td>{learnerInfoData?.City}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Pincode</td>
                            <td>{learnerInfoData?.PinCode}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Name of the Company</td>
                            <td>{learnerInfoData?.companyName}</td>
                          </tr>

                          <tr>
                            <td className="CatCOlr">Designation</td>
                            <td>{learnerInfoData?.designation}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">City</td>
                            <td>{learnerInfoData?.CiTy}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Pincode</td>
                            <td>{learnerInfoData?.Pincode}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Editlearners;
