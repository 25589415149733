import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom/dist";
import Table from "react-bootstrap/Table";
import {
  educatorInfo,
  passwordChange,
  profileInfo,
} from "../../pages/redux/Educators";
import { useDispatch } from "react-redux";
import { loader } from "../../pages/redux/common";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Topbar from "./Topbar";
import SideBar from "../Sidebar/SideBar";

const Profile = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const userType = localStorage.getItem("userType");
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [viewData, setViewData] = useState({});
  const [refresher, setRefresher] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [getImg, setImg] = useState();

  const handleClose2 = () => {
    setShow1(false);
  };
  const handleClose3 = () => setShow2(false);
  const handleClose4 = () => setShow3(false);

  useEffect(() => {
    const storedEducatorID = localStorage.getItem("profileDet");
    if (userType === "educator" && storedEducatorID) {
      const educatorID = JSON.parse(storedEducatorID);

      const data = {
        email: educatorID.email,
      };
      dispatch(loader(true));
      dispatch(
        educatorInfo(data, (resp) => {
          if (resp.status) {
            setViewData(resp.data);
            dispatch(loader(false));
          } else {
            dispatch(loader(false));
            toast.error(resp.message);
          }
        })
      );
    } else {
      // Handle the case when educatorID is not available in localStorage
      dispatch(loader(true));
      dispatch(
        profileInfo((resp) => {
          if (resp.status) {
            setViewData(resp.data);
            dispatch(loader(false));
          } else {
            dispatch(loader(false));
            toast.error(resp.message);
          }
        })
      );
    }
  }, []);

  const handlePasswordChange = () => {
    if (newPassword === confirmPassword) {
      let data = {
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      };
      dispatch(loader(true));
      dispatch(
        passwordChange(data, (resp) => {
          if (resp.status) {
            toast.success(resp.message);
            navigate("/Courses");
            setRefresher(!refresher);
          } else {
            dispatch(loader(false));
            toast.error(resp.message);
          }
        })
      );
    } else {
      toast.error("Passwords do not match.");
    }
  };

  const showPdf = (data) => {
    setShow1(!show1);
    setImg(data);
  };

  return (
 
      <div className="Categorypage">
        <div className="PageTit ">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <p>
                  <Link to="/profile">Educators</Link>{" "}
                </p>

                <h3>{viewData.name}</h3>
              </div>
              <div className="col-6"></div>
            </div>
          </div>
        </div>
        <div className="CateCont">
          <div className="container">
            <div className="row">
              <div className="CateGorTab">
                <div className="CorsELiSTab">
                  <div className="row justify-content-center">
                    <div className="col-9">
                      <div className="CorsELiSTab pt-4 pb-4">
                        <Table striped bordered hover>
                          <tbody>
                            <tr>
                              <td className="CatCOlr">Name</td>
                              <td>{viewData.name}</td>
                            </tr>

                            <tr>
                              <td className="CatCOlr">Email Address</td>
                              <td>{viewData.email}</td>
                            </tr>
                            {userType === "educator" && (
                              <>
                                <tr>
                                  <td className="CatCOlr">Mobile Number</td>
                                  <td>{viewData.phoneNumber}</td>
                                </tr>

                                <tr>
                                  <td className="CatCOlr">City</td>
                                  <td>{viewData.city}</td>
                                </tr>
                                <tr>
                                  <td className="CatCOlr">Expertise</td>
                                  <td>{viewData.expertise}</td>
                                </tr>
                                <tr>
                                  <td className="CatCOlr">CV</td>

                                  <td>
                                    <button
                                      onClick={() => showPdf(viewData.cvUrl)}
                                      className="view_button "
                                    >
                                      {" "}
                                      Click Here To View
                                    </button>
                                  </td>
                                </tr>

                                <tr>
                                  <td className="CatCOlr">Sample Course 1</td>
                                  <td>
                                    <button
                                      onClick={setShow2}
                                      className="view_button "
                                    >
                                      View Sample Course 1
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="CatCOlr">Sample Course 2</td>
                                  <td>
                                    <button
                                      onClick={setShow3}
                                      className="view_button "
                                    >
                                      View Sample Course 2
                                    </button>
                                  </td>
                                </tr>
                              </>
                            )}
                            <tr>
                              <td className="CatCOlr">New Password</td>
                              <td>
                                <input
                                  type="password"
                                  value={newPassword}
                                  onChange={(e) =>
                                    setNewPassword(e.target.value)
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="CatCOlr">Confirm Password</td>
                              <td>
                                <input
                                  type="password"
                                  value={confirmPassword}
                                  onChange={(e) =>
                                    setConfirmPassword(e.target.value)
                                  }
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                  <div class="Grn-Btnmanin flLef pass">
                    <button class="Grn-Btn" onClick={handlePasswordChange}>
                      UPDATE PASSWORD
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*View CV Modal */}
        <Modal show={show1} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>View CV Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center">
              <iframe
                src={`https://docs.google.com/gview?url=${getImg}&embedded=true`}
                width="100%"
                height="500px"
              ></iframe>
            </div>
          </Modal.Body>
        </Modal>

        {/* View Sample course Modal 1 */}
        <Modal show={show2} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>View Sample Course 1</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center">
              <video controls width="640" height="360">
                <source src={viewData.course1Url} type="video/mp4" />
              </video>
            </div>
          </Modal.Body>
        </Modal>

        {/* View Sample course Modal 2 */}
        <Modal show={show3} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>View Sample Course 2</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center">
              <video controls width="640" height="360">
                <source src={viewData.course2Url} type="video/mp4" />
              </video>
            </div>
          </Modal.Body>
        </Modal>
      </div>
   
  );
};
export default Profile;
