import { createSlice } from "@reduxjs/toolkit";
import Helper from "../../helper/axiosHelper";
const baseUrl = Helper.baseUrl();

const initialState = {
  //   firstSignUp: {},
};

export const bannerSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {},
});
export const { addBlog, blogList, updateBlog } = bannerSlice.actions;

export default bannerSlice.reducer;

export const bannersList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "banner/banner_list").then(
      (response) => response.data
    );
    callback(result);
  };

export const addBanner =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.formData(
      baseUrl + "banner/add_banner",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const viewBanner =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "banner/banner_info",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const editBanner =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.pathFormData(baseUrl + "banner/edit_banner", data).then(
      (response) => response.data
    );
    callback(result);
  };

export const deleteBanner =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "banner/delete_banner",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const changeStatus =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(
      baseUrl + "banner/change_status_of_banner",
      data
    ).then((response) => response.data);
    callback(result);
  };
