import { TbFileUpload } from "react-icons/tb";
import "./Experts.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import { viewShortsList } from "../redux/shorts";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";

const Viewexperts = () => {
  const dispatch = useDispatch();
  const [shortSViewData, setShortSViewData] = useState();
  const [previewImage, setPreviewImage] = useState();

  useEffect(() => {
    let viewListID = sessionStorage.getItem("ShortListId");
    let data = {};
    data["shortsId"] = viewListID;
    dispatch(loader(true));
    dispatch(
      viewShortsList(data, (resp) => {
        if (resp.status) {
          setShortSViewData(resp.data);
          setPreviewImage(resp.data.thumbnail);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, []);

  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <h3>View Shorts</h3>
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/list_to_experts">Listen to Experts</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="">View Shorts</Link>
              </p>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <div className="TittlE">
                <h4>View Shorts</h4>
              </div>
              <div className="ExpertSBacKG">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="">
                      <div className="upLoadexperts">
                        <label htmlFor="imgupload">
                          <input
                            type="file"
                            id="imgupload"
                            style={{ display: "none" }}
                            disabled
                          />
                          <i style={{ display: "none" }}>
                            <TbFileUpload className="uploadIcon" />
                          </i>
                          <img
                            src={previewImage}
                            className="img-fluid"
                            alt="User Profile"
                            style={{
                              padding: "18px",
                              height: "180px",
                              width: "230px",
                            }}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 pt-3">
                    <div className="row">
                      <div className="col-12">
                        <div className="SHortsForm">
                          <form>
                            <label>Title</label>
                            <br></br>
                            <input
                              type="text"
                              className="FormShorts"
                              readOnly
                              value={shortSViewData?.title}
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="SHortsForm">
                          <form>
                            <label>Video URL</label>
                            <br></br>
                            <input
                              type="text"
                              className="FormShorts"
                              readOnly
                              value={shortSViewData?.videoUrl}
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Viewexperts;
