import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./Purchase.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { BsThreeDotsVertical } from "react-icons/bs";
import { toast } from "react-toastify";
import { subOrderDetail, subOrderList } from "../redux/jobs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import moment from 'moment';

const SubPurchase = () => {
  let dispatch = useDispatch();
  let [refresher, setRefresher] = useState(true);
  const [orderListData, setOrderListData] = useState([]);
  const [showview, setShowview] = useState(false);
  let [viewData, setViewData] = useState([]);
  const [localSearchValue, setLocalSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const handleClose1 = () => setShowview(false);

  const handleShow1 = (id) => {
    let data = {};
    data["orderId"] = id;
    setShowview(true);
    dispatch(loader(true));
    dispatch(
      subOrderDetail(data, (resp) => {
        if (resp.status) {
          setViewData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  useEffect(() => {
    fetchData({ search: localSearchValue });
  }, [refresher, localSearchValue]);

  const fetchData = (data) => {
    dispatch(
      subOrderList(data, (resp) => {
        dispatch(loader(true));
        if (resp.status) {
          setOrderListData(resp.data);
          setFilteredData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const handleSearchInputChange = (e) => {
    const value = e.target.value.toLowerCase();
    setLocalSearchValue(value);
    const filteredOrders = orderListData.filter((item) => {
      const name = (item.billing_name || "").toLowerCase();
      const email = (item.billing_email || "").toLowerCase();
      return name.includes(value) || email.includes(value);
    });
    setFilteredData(filteredOrders);
  };

  const handleCheckboxChange = (Id) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(Id)) {
        // If educatorId is already selected, remove it
        return prevSelected.filter((id) => id !== Id);
      } else {
        // If educatorId is not selected, add it
        return [...prevSelected, Id];
      }
    });
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    let dataToExport =
      selectedItems.length > 0
        ? orderListData.filter((item) => selectedItems.includes(item._id))
        : orderListData;
    const tableData = dataToExport.map((item, index) => {
      const paidAmount = `INR ${item.price}`;
      return [
        index + 1,
        item.billing_name,
        item.billing_email,
        item.planName,
        paidAmount,
        new Date(item.trans_date).toLocaleDateString(),
        item.status,
      ];
    });

    doc.autoTable({
      head: [
        [
          "S.No",
          "Name",
          "Email",
          "Title",
          "Paid Amount",
          "Last Date",
          "Status",
        ],
      ],
      body: tableData,
      styles: {
        overflow: "linebreak",
        columnStyles: { 5: { lineHeight: 1.5 } }, // Handle line breaks
      },
    });
    doc.save("subscription_purchase_list.pdf");
  };

  const exportToExcel = () => {
    let dataToExport =
      selectedItems.length > 0
        ? orderListData.filter((item) => selectedItems.includes(item._id))
        : orderListData;

    const excelData = dataToExport.map((item, index) => ({
      "S.No": index + 1,
      Name: item.billing_name,
      Email: item.billing_email,
      Title: item.planName,
      "Paid Amount": `INR ${item.price}`,
      "Last Date": new Date(item.trans_date).toLocaleDateString(),
      Status: item.status,
    }));

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Purchase History");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "subscription_purchase_history.xlsx"); // Changed the filename extension to .xlsx
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="Categorypage">
        <div className="PageTit ">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h3 className="purcHist">Subscription Purchase history</h3>
                <p>
                  <Link to="/dashboard">Dashboard</Link>{" "}
                  <i>
                    <AiOutlineRight />
                  </i>{" "}
                  <Link to="/sub_purchase_list">Subscription Purchase history</Link>
                </p>
              </div>
              <div className="col-6"></div>
            </div>
          </div>
        </div>
        <div className="CateCont">
          <div className="container">
            <div className="row">
              <div className="CateGorTab">
                <div className="col-12">
                  <div className="searchBtNs">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="BtNSSearcH">
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Search"
                              aria-describedby="basic-addon2"
                              value={localSearchValue}
                              onChange={handleSearchInputChange}
                            />
                            <Button
                              variant="outline-secondary"
                              id="button-addon2"
                            >
                              <BsSearch />
                            </Button>
                          </InputGroup>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6"></div>

                      <div className="col-lg-2 col-md-6">
                        <div className="BtNSSearcH pur">
                          <button className="Grn-Btn pdf" onClick={generatePDF}>
                            Export as PDF
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-6">
                        <div className="BtNSSearcH pur">
                          <button className="Grn-Btn pdf" onClick={exportToExcel}>
                            Export as Excel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Table
                  striped
                  hover
                  id="purchaseTable"
                  style={{ display: "none" }}
                >
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" />
                      </th>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Email Address</th>
                      <th>Plan Name</th>
                      <th>Paid Amount</th>
                      <th>Purchase Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <input type="checkbox" />
                          </td>
                          <td>{index + 1}</td>
                          <td>{item?.paymentInfo && item?.paymentInfo?.billing_name ? item?.paymentInfo?.billing_name : item.userId ? item?.userId?.fullName : "-"}</td>
                          <td>{item?.paymentInfo && item?.paymentInfo?.billing_email ? item?.paymentInfo?.billing_email : item.userId ? item?.userId?.email : "-"}</td>
                          <td>{item?.planName}</td>
                          <td>{item?.paymentInfo && item?.paymentInfo?.amount ? `INR ${item?.paymentInfo?.amount}` : item.price ? `INR ${item?.price?.toFixed(2)}` : "-"}</td>
                          <td>
                            {item?.paymentInfo && item?.paymentInfo?.trans_date ? moment(item?.paymentInfo?.trans_date, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY') : "-"}
                          </td>
                          <td>{item?.paymentInfo && item?.paymentInfo?.order_status ? item?.paymentInfo?.order_status : "-"}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" />
                      </th>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Email Address</th>
                      <th>Plan Name</th>
                      <th>Paid Amount</th>
                      <th>Purchase Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange(item._id)}
                            checked={selectedItems.includes(item._id)}
                          />
                        </td>
                        <td>{index + 1}</td>
                        <td>{item?.paymentInfo && item?.paymentInfo?.billing_name ? item?.paymentInfo?.billing_name : item.userId ? item?.userId?.fullName : "-"}</td>
                        <td>{item?.paymentInfo && item?.paymentInfo?.billing_email ? item?.paymentInfo?.billing_email : item.userId ? item?.userId?.email : "-"}</td>
                        <td>{item?.planName}</td>
                        <td>{item?.paymentInfo && item?.paymentInfo?.amount ? `INR ${item?.paymentInfo?.amount}` : item.price ? `INR ${item?.price?.toFixed(2)}` : "-"}</td>
                        <td>
                          {item?.paymentInfo && item?.paymentInfo?.trans_date ? moment(item?.paymentInfo?.trans_date, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY') : "-"}
                        </td>
                        <td>{item?.paymentInfo && item?.paymentInfo?.order_status ? item?.paymentInfo?.order_status : "-"}</td>
                        <td>
                          <div>
                            <div className="Options pt-1">
                              <i>
                                <BsThreeDotsVertical />
                              </i>
                              <ul className="hidden-list">
                                <li onClick={() => handleShow1(item._id)}>
                                  View
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showview} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>View Purchase Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Order ID</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.paymentInfo?.order_id}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Plan Name</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.planName}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Name</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.paymentInfo?.billing_name}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Email</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.paymentInfo?.billing_email}
                readOnly
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">State</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.paymentInfo?.billing_state}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Country</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.paymentInfo?.billing_country}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Pincode</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.paymentInfo?.billing_zip}
                readOnly
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">
                Coupon Discount
              </InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={`INR ${viewData?.couponDiscount ? viewData?.couponDiscount.toFixed(2) : "-"}`}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Paid Amount</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={`INR ${viewData?.paymentInfo ? viewData?.paymentInfo?.mer_amount : "-"}`}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Tracking ID</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.paymentInfo?.tracking_id}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Bank Ref No</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.paymentInfo?.bank_ref_no}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Payment Mode</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.paymentInfo?.payment_mode}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Card Name</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.paymentInfo?.card_name}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">
                Transaction Date
              </InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.paymentInfo?.trans_date}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Order Status</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData?.paymentInfo?.order_status}
                readOnly
              />
            </InputGroup>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default SubPurchase;
