import { createSlice } from "@reduxjs/toolkit";
import Helper from "../../helper/axiosHelper";
const baseUrl = Helper.baseUrl();

const initialState = {
  //   firstSignUp: {},
};

export const learnerSlice = createSlice({
  name: "learner",
  initialState,
  reducers: {},
});

export const { firstSignUp } = learnerSlice.actions;

export default learnerSlice.reducer;

export const learnerList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "learners/list").then(
      (response) => response.data
    );
    callback(result);
  };

export const learnerInfo =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "learners/lInfo", data).then(
      (response) => response.data
    );
    callback(result);
  };

export const learnerStatusChange =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(
      baseUrl + "learners/changeStatus",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const learnerSendMail =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "learners/sendMail",
      data
    ).then((response) => response.data);
    callback(result);
  };
