import React, { useState, useEffect } from "react";
import { TbFileUpload } from "react-icons/tb";
import "./Blogs.css";
import { Link, useParams, useNavigate } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDispatch } from "react-redux";
import { editBlog, viewBlog } from "../redux/blogs";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { loader } from "../redux/common";

const Editblog = () => {
  let [refresher, setRefresher] = useState(true);
  const [blogInfoData, setBlogInfoData] = useState();
  const [previewImage, setPreviewImage] = useState();
  const [imageFile, setImageFile] = useState("");

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const blogId = useParams();

  useEffect(() => {
    let data = {};
    data["blogId"] = blogId.id;
    dispatch(loader(true));
    dispatch(
      viewBlog(data, (resp) => {
        if (resp.status) {
          setBlogInfoData(resp.data);
          setPreviewImage(resp.data.thumbnail);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, [refresher]);

  const handleProfilePic = (event) => {
    const selectedFile = event.target.files[0];
    setImageFile(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };

      reader.readAsDataURL(selectedFile);
    } else {
    }
  };

  function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    var arr = {};
    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        arr[key] = object1[key];
      }
    }
    return arr;
  }

  const submitEdit = (data) => {
    var compareVal = {
      title: data.title,
      sDesc: data.shortDescription,
      dDesc: data.description,
    };
    var result = shallowEqual(compareVal, blogInfoData);

    let formData = new FormData();
    formData.append("blogId", blogId.id);
    if (result.title != "") {
      formData.append("title", data.title);
    }
    if (result.sDesc != "") {
      formData.append("sDesc", data.shortDescription);
    }
    if (result != "") {
      formData.append("dDesc", data.description);
    }
    if (imageFile != "") {
      formData.append("thumbnail", imageFile);
    }

    dispatch(loader(true));
    dispatch(
      editBlog(formData, (resp) => {
        if (resp.status) {
          setRefresher(!refresher);
          toast.success(resp.message);
          navigate("/blogs");
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };
  const validateWordLimit = (value) => {
    if (value && value.split(" ").length > 30) {
      return "Short description should not exceed 30 words";
    }
    return true;
  };
  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <h3>Edit Blog</h3>
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/blogs">Blogs</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="#">Edit Blog</Link>
              </p>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <div className="TittlE">
                <h4>Edit Blog</h4>
              </div>
              <form onSubmit={handleSubmit(submitEdit)}>
                <div className="ExpertSBacKG">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="nail blog thumbs">
                        <div className="upLoadedimg">
                          <label htmlFor="imgupload">
                            <input
                              type="file"
                              id="imgupload"
                              onChange={handleProfilePic}
                              style={{ display: "none" }}
                              accept="image/*"
                            />
                            {previewImage ? (
                              <img
                                src={previewImage}
                                className="img-fluid preImage"
                                alt="User Profile"
                                style={{ padding: "20px" }}
                              />
                            ) : (
                              <p style={{ color: "black" }}>
                                Image Not Uploaded
                              </p>
                            )}
                            Image Dimensions (1700 x 1133)
                            <br />
                            Click here to Upload{" "}
                            <TbFileUpload className="uploadIcon" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 pt-3">
                      <div className="row ">
                        <div className="col-12">
                          <div className="SHortsForm">
                            <label>Title</label>
                            <br></br>
                            <input
                              type="text"
                              className="FormShorts"
                              name="title"
                              defaultValue={blogInfoData?.title}
                              {...register("title", {
                                //  required: "Title is required",
                              })}
                            />
                            {errors.title && (
                              <small style={{ color: "red", float: "left" }}>
                                {errors.title.message}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="SHortsForm">
                            <label>short description</label>
                            <br></br>
                            <textarea
                              rows="5"
                              cols="72"
                              class="FormShorts"
                              defaultValue={blogInfoData?.sDesc}
                              {...register("shortDescription", {
                                //  required: "Short description is required",
                                validate: validateWordLimit,
                              })}
                            ></textarea>
                            {errors.shortDescription && (
                              <small style={{ color: "red", float: "left" }}>
                                {errors.shortDescription.message}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-12">
                      <div className="CKEditTors">
                        <label>Detailed Description</label>
                        <br></br>
                        <Controller
                          name="description"
                          control={control}
                          rules={{
                            validate: (value) =>
                              (value && value.length > 0) ||
                              "Description is required",
                          }}
                          render={({ field }) => (
                            <CKEditor
                              editor={ClassicEditor}
                              data={blogInfoData?.dDesc}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                field.onChange(data);
                              }}
                              onBlur={() => {
                                if (!field.value) {
                                  field.onChange("");
                                }
                              }}
                            />
                          )}
                        />
                        {errors.description && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.description.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-3">
                      <div className="SHortsForm1">
                        <button className="Grn-Btn " type="submit">
                          Update
                        </button>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="SHortsForm1">
                        <button
                          className="WHt-Btn"
                          type="buttton"
                          onClick={() => navigate("/blogs")}
                        >
                          cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Editblog;
