import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./Testimonial.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import InputGroup from "react-bootstrap/InputGroup";
import { useForm, Controller } from "react-hook-form";

import { BsThreeDotsVertical } from "react-icons/bs";
import Topbar from "../../components/topbar/Topbar";
import SideBar from "../../components/Sidebar/SideBar";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loader, isLogin } from "../redux/common";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  viewTestimonialsList,
  addTestimonialCb,
  deleteTestCb,
  editTestCb,
} from "../redux/shorts";
const Testimonial = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showview, setShowview] = useState(false);

  const [refresher, setRefresher] = useState(false);

  const [editTestData, setEditTestData] = useState([]);
  const [editTestViewData, setEditTestViewData] = useState();
  const [listTestimonials, setListTestimonials] = useState([]);
  const [checked, setChecked] = useState([]);

  const handleClose = () => {
    setShow(false);
    reset();
  };
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShowview(false);
  const handleShow1 = (data, index) => {
    
    setEditTestData(data);
    setValue('name', data.name);
    setValue('qualification', data.qualification);
    setValue('feedback', data.feedback);
    sessionStorage.setItem("EditTestId", data._id);
    setShowview(true);
  };

  const {
    register: register,
    control,
    formState: { errors: errors },
    reset: reset,
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur" });

  const {
    register: register1,
    control1,
    formState: { errors: errors1 },
    reset: reset1,
    handleSubmit: handleSubmit1,
    setValue
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    let editTestId = sessionStorage.getItem("EditTestId");
    console.log("editTestIDsessionStorage", sessionStorage);
    dispatch(loader(true));
    dispatch(
      viewTestimonialsList((resp) => {
        dispatch(loader(false));
        if (resp.status) {
          console.log(resp.data, "resp.dataaa");
          setListTestimonials(resp.data);
          var arr = [];
          for (var i = 0; i < resp.data.length; i++) {
            arr.push(resp.data[i].isActive);
          }
          setChecked(arr);
        }
      })
    );
  }, [refresher]);

  const changeStatus = (id, index) => {
    let data = {};
    let value = {
      isHidden: !checked[index],
    };
    data["_id"] = id;
    data["isActive"] = value.isHidden;
    dispatch(loader(true));
    dispatch(
      editTestCb(data, (response) => {
        if (response.status) {
          toast.success(response.message);
          dispatch(loader(false));
          setRefresher(!refresher);
        } else {
          dispatch(loader(false));
          toast.error(response.message);
        }
      })
    );
  };

  const testAddFn = (data) => {
    dispatch(loader(true));
    dispatch(
      addTestimonialCb(data, (response) => {
        if (response.status) {
          toast.success(response.message);
          dispatch(loader(false));
          reset();
          handleClose();
          setRefresher(!refresher);
        } else {
          dispatch(loader(false));
          toast.error(response.message);
        }
      })
    );
  };

  function shallowEqual(object1, object2) {
    if (
      object1 === null ||
      object2 === null ||
      typeof object1 !== "object" ||
      typeof object2 !== "object"
    ) {
      return {}; // Return an empty object to indicate no differences
    }

    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    var arr = {};

    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        arr[key] = object1[key];
      }
    }

    return arr;
  }

  // const editTestFn = () => {
  //     // Get the testimonial ID from sessionStorage
  //     let editTestId = sessionStorage.getItem("EditTestId");
  //   console.log("editTestData.name", editTestData.name)
  //     var compareVal = {
  //       name: editTestData.name, // Use editTestData instead of e.target
  //       qualification: editTestData.qualification,
  //       videoUrl: editTestData.videoUrl,
  //       feedback: editTestData.feedback,
  //       isActive: editTestData.isActive,
  //     };

  //     var result = shallowEqual(compareVal, editTestViewData);
  //     console.log("resultresult", result)
  //     let formData = new FormData();
  //     formData.append("_id", editTestId);

  //     // Append updated values to formData if they have changed

  //       formData.append("name", result.name);

  //       formData.append("qualification", result.qualification);

  //       formData.append("videoUrl", result.videoUrl);

  //       formData.append("feedback", result.feedback);

  //     formData.append("isActive", result.isActive);

  //     dispatch(loader(true));
  //     dispatch(
  //       editTestCb(formData, (resp) => {
  //         if (resp.status) {
  //           console.log("responsible", resp);
  //           setListTestimonials(resp.data)
  //           setEditTestViewData(resp.data);
  //           dispatch(loader(false));
  //           toast.success(resp.message);
  //           navigate("/testimonials");
  //         } else {
  //           dispatch(loader(false));
  //           toast.error(resp.message);
  //         }
  //       })
  //     );
  //   };

  const editTestFn = (e) => {
    console.log(e.name);
    console.log(e.feedback);
    console.log(e.qualification);
    console.log(editTestData.videoUrl);
    console.log(editTestData.isActive);
 
    let editTestId = sessionStorage.getItem("EditTestId");

    let formData = new FormData();
    formData.append("_id", editTestId);
    console.log("editTestId", editTestId);
    formData.append("name", e.name);
    formData.append("qualification", e.qualification);
    formData.append("videoUrl", "www.Kareesity.com");
    formData.append("feedback", e.feedback);
    formData.append("isActive", true);

    // Append other values only if they have changed

    // if (compareVal.name !== editTestViewData.name) {
    //   formData.append("name", compareVal.name);
    // }

    // if (compareVal.qualification !== editTestViewData.qualification) {
    //   formData.append("qualification", compareVal.qualification);
    // }

    // if (compareVal.videoUrl !== editTestViewData.videoUrl) {
    //   formData.append("videoUrl", compareVal.videoUrl);
    // }

    // if (compareVal.feedback !== editTestViewData.feedback) {
    //   formData.append("feedback", compareVal.feedback);
    // }

    // if (compareVal.isActive !== editTestViewData.isActive) {
    //   formData.append("isActive", compareVal.isActive);
    // }

    dispatch(loader(true));
    dispatch(
      editTestCb(formData, (resp) => {
        if (resp.status) {
          console.log("responsible", resp);
          setEditTestViewData(resp.data);
          dispatch(loader(false));
          setShowview(!showview);
          toast.success(resp.message);
          setRefresher(!refresher);
          navigate("/testimonials");
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  //   const editTestFn = (e) => {
  //     // Get the testimonial ID from sessionStorage
  //     let editTestId = sessionStorage.getItem("EditTestId");
  //     console.log("editTestId", editTestId)
  //     // Read updated values directly from form fields or state variables
  //     let updatedName = e.target.name.value; // Replace with the actual source of your updated name value
  //     let updatedQualification = e.target.qualification.value; // Replace with the actual source of your updated qualification value
  //     let updatedVideoUrl = e.target.videoUrl.value; // Replace with the actual source of your updated videoUrl value
  //     let updatedFeedback = e.target.feedback.value; // Replace with the actual source of your updated feedback value
  //     let updatedIsActive = e.target.isActive.checked; // Replace with the actual source of your updated isActive value

  //     // Create a FormData object and append the ID and updated values
  //     let formData = new FormData();
  //     formData.append("_id", editTestId);
  //     formData.append("name", updatedName);
  //     formData.append("qualification", updatedQualification);
  //     formData.append("videoUrl", updatedVideoUrl);
  //     formData.append("feedback", updatedFeedback);
  //     formData.append("isActive", updatedIsActive);

  //     dispatch(loader(true));

  //     dispatch(
  //       editTestCb(formData, (resp) => {
  //         if (resp.status) {
  //           console.log("responsible", resp);
  //           setListTestimonials(resp.data);
  //           setEditTestViewData(resp.data);
  //           dispatch(loader(false));
  //           toast.success(resp.message);
  //           navigate("/testimonials");
  //         } else {
  //           dispatch(loader(false));
  //           toast.error(resp.message);
  //         }
  //       })
  //     );
  //   };

  const deleteTestFn = (id) => {
    let data = {};
    data["_id"] = id;
    dispatch(loader(true));
    dispatch(
      deleteTestCb(data, (resp) => {
        if (resp.status) {
          dispatch(loader(false));
          setRefresher(!refresher);
          toast.success(resp.message);
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  return (
   
      <div className="Categorypage">
        <div className="PageTit ">
          <div className="container">
            <div className="row">
              <div className="col-6 col-md-5">
                <h3>Testimonial List</h3>
                <p>
                  <Link to="/dashboard">Dashboard</Link>{" "}
                  <i>
                    <AiOutlineRight />
                  </i>{" "}
                  <Link to="/testimonials">Testimonial</Link>
                </p>
              </div>
              <div className="col-6 col-md-7">
                <div className="Grn-Btnmanin">
                  <button className="Grn-Btn" onClick={handleShow}>
                    add testimonial
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="CateCont">
          <div className="container">
            <div className="row">
              <div className="CateGorTab">
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Qualification</th>
                      <th>Feedback</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listTestimonials.map((item, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{item?.name}</td>
                        <td>{item?.qualification}</td>
                        <td className="DivWidth">{item?.feedback}</td>
                        <td >
                          <div className="d-flex">
                          <div className="">
                            <div className="OnOfF">
                              <Form>
                                <Form.Check // prettier-ignore
                                  type="switch"
                                  id="custom-switch"
                                  onChange={() => changeStatus(item._id, i)}
                                  checked={checked[i]}
                                />
                              </Form>
                            </div>
                          </div>
                          <div className="col-5">
                            <div className="Options pt-1">
                              <i>
                                <BsThreeDotsVertical />
                              </i>
                              <ul class="hidden-list tstLi">
                                <li
                                  onClick={() => {
                                    handleShow1(item, i);
                                  }}
                                >
                                  Edit
                                </li>
                                <li onClick={() => deleteTestFn(item._id, i)}>
                                  Delete
                                </li>
                              </ul>
                            </div>
                          </div>
                          </div>
                          
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>

        {/* Modal */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Testimonial</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit(testAddFn)}>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      {...register("name", {
                        required: "Name is required",
                      })}
                    />
                    {errors.name && (
                      <small style={{ color: "red" }}>
                        {errors.name.message}
                      </small>
                    )}
                  </Form.Group>
                </div>
                <div className="col-lg-6 col-md-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Qualification</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      {...register("qualification", {
                        required: "qualification is required",
                      })}
                    />
                    {errors.qualification && (
                      <small style={{ color: "red" }}>
                        {errors.qualification.message}
                      </small>
                    )}
                  </Form.Group>
                </div>
              </div>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Feedback</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder=""
                  style={{ height: "150px" }}
                  {...register("feedback", {
                    required: "feedback is required",
                  })}
                />
                {errors.feedback && (
                  <small style={{ color: "red" }}>
                    {errors.feedback.message}
                  </small>
                )}
              </Form.Group>
              <div className="d-flex justify-content-center">
                <button className="Grn-Btn Half" type="submit">
                  add
                </button>
                {/* <span className="WHt-Btn Half" onClick={handleClose}>cancel</span> */}
                <button
                  className="WHt-Btn Half"
                  type="button"
                  onClick={handleClose}
                >
                  cancel
                </button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        {/* view modal */}
        <Modal show={showview} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Testimonial</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit1(editTestFn)}>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      name="TestimonialName"
                      type="text"
                      //   placeholder={editTestData.name}
                      defaultValue={editTestData?.name}
                      {...register1("name")}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6 col-md-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Qualification</Form.Label>
                    <Form.Control
                      type="text"
                      name="TestimonialQualification"
                      //   placeholder={editTestData.qualification}
                      defaultValue={editTestData?.qualification}
                      {...register1("qualification")}
                    />
                  </Form.Group>
                </div>
              </div>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Feedback</Form.Label>
                <Form.Control
                  as="textarea"
                  //   placeholder={editTestData.feedback}
                  name="TestimonialFeedback"
                  defaultValue={editTestData?.feedback}
                  {...register1("feedback")}
                  style={{ height: "150px" }}
                />
              </Form.Group>
              <div className="d-flex justify-content-center">
                <button className="Grn-Btn Half" type="submit">
                  Update
                </button>
                <button
                  className="WHt-Btn Half"
                  type="button"
                  onClick={handleClose1}
                >
                  cancel
                </button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
   
  );
};
export default Testimonial;
