import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import "./Educator.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { BsThreeDotsVertical } from "react-icons/bs";
import { educatorEmailIds, educatorApproveChange } from "../redux/Educators";
import { useDispatch } from "react-redux";
import { loader, isLogin } from "../redux/common";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
const Educators = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const [educatorData, setEducatorData] = useState([]);
  const [dummydataComplete, setdummydataComplete] = useState([]);
  const [refresher, setRefresher] = useState(true);
  const [selectedEducators, setSelectedEducators] = useState([]);
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = educatorData.slice(indexOfFirstItem, indexOfLastItem);

  const handleSendMail = () => {
    const selectedEducatorIds = selectedEducators;
    sessionStorage.setItem(
      "selectedEducatorIds",
      selectedEducatorIds.join(",")
    );
    navigate("/send_mail_edu");
  };

  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      educatorEmailIds((resp) => {
        if (resp.status) {
          setEducatorData(resp.data);
          setdummydataComplete(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );
  }, [refresher]);
  const educatorView = (id) => {
    sessionStorage.setItem("EducatorId", id);
    navigate("/view_educator");
  };

  const educatorApprove = (id) => {
    dispatch(loader(true));
    let data = {};
    data["eduId"] = id;
    dispatch(
      educatorApproveChange(data, (resp) => {
        if (resp.status) {
          setRefresher(!refresher);
          toast.success(resp.message);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };
  const handleCheckboxChange = (educatorId) => {
    setSelectedEducators((prevSelected) => {
      if (prevSelected.includes(educatorId)) {
        // If educatorId is already selected, remove it
        return prevSelected.filter((id) => id !== educatorId);
      } else {
        // If educatorId is not selected, add it
        return [...prevSelected, educatorId];
      }
    });
  };

  const filterBySearch = (event) => {
    const query = event.target.value;
    var updatedlist = [...dummydataComplete];

    var updatedList = updatedlist.filter((item) => {
      return item.email.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });

    setEducatorData(updatedList);
  };
  return (
  <>
      <div className="Categorypage">
        <div className="PageTit ">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h3>Educators List</h3>
                <p>
                  <Link to="/dashboard">Dashboard</Link>{" "}
                  <i>
                    <AiOutlineRight />
                  </i>{" "}
                  <Link to="">Educators</Link>
                </p>
              </div>
              <div className="col-6"></div>
            </div>
          </div>
        </div>
        <div className="CateCont">
          <div className="container">
            <div className="row">
              <div className="CateGorTab">
                <div className="col-12">
                  <div className="searchBtNs">
                    <div className="row">
                      <div className="col-xl-10 col-lg-9 col-md-8 pt-2">
                        <div className="BtNSSearcH">
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Search By Email Address"
                              aria-describedby="basic-addon2"
                              onChange={filterBySearch}
                            />
                            <Button
                              variant="outline-secondary"
                              id="button-addon2"
                              className="btnSrch"
                            >
                              <BsSearch />
                            </Button>
                          </InputGroup>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 mt-2 pt-2">
                        <div className="BtNSSearcH">
                          <Link
                            to="/send_mail_edu"
                            className="Grn-Btn"
                            onClick={handleSendMail}
                          >
                            Send Mail
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Table striped hover>
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" />
                      </th>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Email Address</th>
                      <th>Mobile Number</th>
                      <th>Verification</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item, index) => (
                      <tr>
                        <td>
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange(item._id)}
                            checked={selectedEducators.includes(item._id)}
                          />
                        </td>
                        <td>{indexOfFirstItem + index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.phoneNumber}</td>
                        <td>
                          <button
                            className="GrnActn"
                            onClick={() => educatorApprove(item._id)}
                            style={{
                              background:
                                item.isVerified === true
                                  ? "#C7F7DA"
                                  : "#F8D9D9",
                              border:
                                item.isVerified === true
                                  ? "1px solid #107B38"
                                  : "1px solid #AB0A0A",
                            }}
                          >
                            {item.isVerified === true ? "Approved" : "Pending"}
                          </button>
                        </td>
                        <td>
                          <button
                            className="GrnActn"
                            style={{
                              background:
                                item.status?.toLowerCase() === "active"
                                  ? "#C7F7DA"
                                  : item.status?.toLowerCase() === "inactive"
                                  ? "#F8D9D9"
                                  : item.status?.toLowerCase() === "rejected"
                                  ? "#DCDAE3"
                                  : "",
                              border:
                                item.status?.toLowerCase() === "active"
                                  ? "1px solid #107B38"
                                  : item.status?.toLowerCase() === "inactive"
                                  ? "1px solid #AB0A0A"
                                  : item.status?.toLowerCase() === "rejected"
                                  ? "1px solid #6F6B80"
                                  : "",
                            }}
                          >
                            {item.status?.toLowerCase() === "active"
                              ? "Active"
                              : item.status?.toLowerCase() === "inactive"
                              ? "InActive"
                              : "Rejected"}
                          </button>
                        </td>
                        <td>
                          <div className="d-flex">
                            <div className="Options pt-1">
                              <i>
                                <BsThreeDotsVertical />
                              </i>
                              <ul class="hidden-list">
                                <li onClick={() => educatorView(item._id)}>
                                  View
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(educatorData.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        subContainerClassName={"pages pagination"}
        containerClassName={"pagination-containers  green-pagination"}
        activeClassName={"active-page"}
        forcePage={currentPage}
      />
  </>
  );
};
export default Educators;
