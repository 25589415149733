import { useEffect, useState } from "react";
import "./Course.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight, AiTwotoneDelete } from "react-icons/ai";
import { MdEdit, MdDelete } from "react-icons/md";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { TbFileUpload } from "react-icons/tb";
import Accordion from "react-bootstrap/Accordion";
import { BiSolidPencil } from "react-icons/bi";
import {
  categoryList,
  courseAddNew,
  courseSessionAddNew,
  addLessonCb,
  addAssesmentCb,
  fileUploading,
  collegeList,
} from "../redux/courses";
import { useDispatch } from "react-redux";
import { loader, isLogin } from "../redux/common";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { courseListCb, sessionListCb } from "../redux/courses";
import {
  ActiveEducators,
  educatorEmailIds,
  educatordetailView,
} from "../redux/Educators";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import helper from "../../helper/axiosHelper";

const Addcourse = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const [corserows, setCourserows] = useState([1]);
  const [show1, setShow1] = useState(false);
  const [show, setShow] = useState(false);
  const [showview, setShowview] = useState(false);
  const [showview1, setShowview1] = useState(false);
  const [showview3, setShowview3] = useState(false);
  const [rows, setRows] = useState([1]);
  const [getcategoryList, setCategoryList] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);
  const [dataThumbnail, setDataThumbnail] = useState("");
  const [crcIncludes, setCrcIncludes] = useState([]);
  const [activeTab, setActiveTab] = useState("first");
  const [isHidden, setIsHidden] = useState(false);
  const [getImg, setImg] = useState();
  const [freeFor, setFreeFor] = useState("");
  const [basicData, setBasicData] = useState([]);
  const [disPrice, setDisPrice] = useState(0);
  const [coursePrice, setCoursePrice] = useState(0);
  const [regularPrice, setRegularPrice] = useState(0);
  const [discountExpiry, setDiscountExpiry] = useState(0);
  const [educatorData, setEducatorData] = useState([]);
  const [educatorId, setEducatorId] = useState("");
  const [singleEduData, setSingleEduData] = useState([]);
  const [videoData, setVideoData] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([{}]);
  const [optionscol, setOptionscol] = useState([]);
  const [optionscolleges, setOptionscolleges] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [sessList, setSessList] = useState([]);

  const handleClose4 = () => setShow1(false);

  useEffect(() => {
    let data = {};
    data["search"] = searchTerm;
    dispatch(loader(true));
    dispatch(
      collegeList(data, (resp) => {
        dispatch(loader(false));
        if (resp) {
          var temp2 = [];
          var optData = resp.data.map((ele) => {
            temp2.push({ value: ele._id, label: ele.name });
          });
          setOptionscolleges(temp2);
        } else {
        }
      })
    );
  }, [searchTerm]);

  const handleCollegeChange = (e) => {
    setOptionscol(e);
    setOptionscol(Array.isArray(e) ? e.map((x) => x) : []);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setDataThumbnail(file);
    const reader = new FileReader();

    reader.onloadend = () => {
      setThumbnail(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const showPdf = (data) => {
    setShow1(!show1);
    setImg(data);
  };
  const fourthTabFn = () => {
    dispatch(loader(true));
    dispatch(
      courseListCb((resp) => {
        if (resp.status) {
          setCourseList(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );
  };

  const handleToggle1 = () => {
    setIsHidden(!isHidden);
  };
  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
    if (tabKey == "four") {
      fourthTabFn();
    }
  };
  const handleNextButton = (page) => {
    setActiveTab(page);
  };

  const handlePreviousButton = (page) => {
    setActiveTab(page);
  };
  const calculateDiscountPercentage = () => {
    if (disPrice && coursePrice) {
      return (((coursePrice - disPrice) / coursePrice) * 100).toFixed(1);
    } else {
      return "";
    }
  };

  const [checkboxValues, setCheckboxValues] = useState({ discount: false });
  const [checkboxes, setCheckboxes] = useState({ freeFor: false });
  const [checkboxenr, setCheckboxenr] = useState({ enrolled: false });
  const [checkboxcol, setCheckboxcol] = useState({ colleges: false });

  const handleCheckBoxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxValues((prevValues) => ({ ...prevValues, [name]: checked }));
  };
  const handleCheckBoxesChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxes((prevValues) => ({ ...prevValues, [name]: checked }));
  };
  const handleCheckBoxesenrolled = (event) => {
    const { name, checked } = event.target;
    setCheckboxenr((prevValues) => ({ ...prevValues, [name]: checked }));
  };
  const handleCheckBoxesColleges = (event) => {
    const { name, checked } = event.target;
    setCheckboxcol((prevValues) => ({ ...prevValues, [name]: checked }));
  };

  const {
    register: register,
    control,
    formState: { errors: errors },
    reset: reset,
    setValue,
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur" });
  const {
    register: register3,
    formState: { errors: errors3 },
    reset: reset3,
    handleSubmit: handleSubmit3,
  } = useForm({ mode: "onBlur" });
  const {
    register: register4,
    formState: { errors: errors4 },
    reset: reset4,
    handleSubmit: handleSubmit4,
  } = useForm({ mode: "onBlur" });
  const {
    register: register5,
    formState: { errors: errors5 },
    reset: reset5,
    handleSubmit: handleSubmit5,
  } = useForm({ mode: "onBlur" });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShowview(false);
  const handleShow1 = () => setShowview(true);

  const handleClose2 = () => setShowview1(false);
  const handleShow2 = () => setShowview1(true);

  const handleClose3 = () => setShowview3(false);
  const handleShow3 = () => setShowview3(true);

  const addQandARow = () => {
    const newRow = rows.length + 1;
    setRows([...rows, newRow]);
  };

  const removeQandARow = () => {
    if (rows.length > 1) {
      const updatedRows = rows.slice(0, -1);
      setRows(updatedRows);
      setOptions((prevcrcIncludes) => {
        const updatedValues = [...prevcrcIncludes];
        updatedValues.splice(rows.length - 1, 1);
        return updatedValues;
      });
    }
  };

  const cancelAddSession = () => {
    handleClose();
  };
  const cancelAddLesson = () => {
    handleClose1();
  };
  const cousrseInclude = (event, index) => {
    const { value } = event.target;
    setCrcIncludes((prevcrcIncludes) => {
      const updatedValues = [...prevcrcIncludes];
      updatedValues[index] = value;
      return updatedValues;
    });
  };

  const ansFn = (event, index, opt) => {
    const { value } = event.target;
    setOptions((prevcrcIncludes) => {
      if (!prevcrcIncludes[index]) {
        prevcrcIncludes[index] = {};
      }
      const updatedValues = [...prevcrcIncludes];
      if (opt == "correctAnswer") {
        updatedValues[index][opt] = updatedValues[index][value];
      } else {
        updatedValues[index][opt] = value;
      }
      return updatedValues;
    });
  };

  const profileDetails = JSON.parse(localStorage.getItem("profileDetails"));
  const profileName = JSON.parse(localStorage.getItem("profileName"));

  const educatorSelect = (event) => {
    const selectedEducatorId = event.target.value;
    const selectedEducator = educatorData.find(
      (educator) => educator._id === selectedEducatorId
    );
    const userEducator = educatorData.find(
      (educator) => educator._id === profileDetails
    );
    setSingleEduData(userType === "educator" ? userEducator : selectedEducator);
    setEducatorId(userType === "educator" ? userEducator : selectedEducatorId);
  };

  const userType = localStorage.getItem("userType");
  const addRow1 = () => {
    const newRow = corserows.length + 1;
    setCourserows([...corserows, newRow]);
    setCrcIncludes([...crcIncludes, ""]);
  };

  const removeRow1 = () => {
    if (corserows.length > 1) {
      const updatedRows = corserows.slice(0, -1);
      setCourserows(updatedRows);
      setCrcIncludes(crcIncludes.slice(0, -1));
    }
  };

  const validateFile = (file) => {
    setVideoData(file);
    if (!file.type.startsWith("video/")) {
      toast.error("Only video files are allowed!");
      return;
    }
  };

  const sessionSelFn = (val) => {
    dispatch(loader(true));
    dispatch(
      sessionListCb({ courseId: val }, (resp) => {
        if (resp.status) {
          setSessList(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );
  };

  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      categoryList((resp) => {
        if (resp.status) {
          const list = resp.data.filter((item) => item.isHidden);
          setCategoryList(list);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
    dispatch(
      ActiveEducators((resp) => {
        if (resp.status) {
          dispatch(loader(false));
          setEducatorData(resp.data);
        } else {
          dispatch(loader(false));
        }
      })
    );
  }, []);

  const SessionSubmit = (data) => {
    dispatch(loader(true));
    dispatch(
      courseSessionAddNew(data, (resp) => {
        if (resp.status) {
          toast.success("Session added successfully");
          handleClose();
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const lessonSubmit = (data) => {
    dispatch(loader(true));
    data["videoUrl"] = data["videoUrl"][0];

    if (!videoData.type.startsWith("video/")) {
      toast.error("Only video files are allowed !");
      return;
    } else {
      dispatch(
        addLessonCb(data, (resp) => {
          if (resp.status) {
            toast.success("Lesson added successFully");
            handleClose1();
            reset4();
            dispatch(loader(false));
          } else {
            dispatch(loader(false));
            toast.error(resp.message);
          }
        })
      );
    }
  };

  const basicSubmit = (data) => {
    if (crcIncludes.length != 0) {
      setBasicData(data);
      handleNextButton("second");
    } else {
      toast.error("Course include is mandatory.Please fill the fields");
    }
  };

  const priceFn = () => {
    handleNextButton("third");
  };

  const priceFn1 = () => {
    if (!checkboxes.freeFor) {
      if (coursePrice === 0) {
        toast.error("Enter valid course price");
        return;
      }
    }

    if (checkboxValues.discount) {
      if (disPrice === 0) {
        toast.error("Enter valid discount price");
        return;
      } else if (discountExpiry === 0) {
        toast.error("Enter discount expiry date");
        return;
      }
    }
    handleNextButton("four");
  };

  const resetManual = () => {
    setFreeFor("");
    setBasicData([]);
    setDisPrice(0);
    setCoursePrice(0);
    setRegularPrice(0);
    setDiscountExpiry(0);
    setEducatorData([]);
    setEducatorId("");
    setCrcIncludes([]);
  };
  let globalUrl;
  const fileUploadFn = (thumbnail, callback) => {
    dispatch(loader(true));
    var data = {};
    data["file"] = thumbnail;

    dispatch(
      fileUploading(data, (resp) => {
        if (resp.status) {
          if (resp.url) {
            globalUrl = resp.url;
            if (callback) {
              callback(resp.url);
            }
            dispatch(loader(false));
          } else {
            dispatch(loader(false));
            console.error("Response does not contain URL.");
          }
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const [isStudent, setIsStudent] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [isDoctor, setIsDoctor] = useState(false);

  const addCourseFn = () => {
    let data = {};
    const collegeIds = optionscol.map((option) => option.value);

    if (educatorId !== "") {
      dispatch(loader(true));

      const freeVal = freeFor === "everyone";

      if (dataThumbnail) {
        fileUploadFn(dataThumbnail, (url) => {
          data = {
            catId: basicData.category,
            title: basicData.courseTitle,
            shortDescription: basicData.shortDescription,
            duration: basicData.duration,
            level: basicData.level,
            freeForEveryone: freeVal,
            freeForEnInLast30Days: checkboxenr.enrolled,
            freeForbasedOnColleges: checkboxcol.colleges,
            price: coursePrice,
            discountedPrice: disPrice,
            discountedPriceExpiry: discountExpiry,
            description: basicData.description,
            whatWillYouLearn: basicData.whatWillYouLearn,
            certifications: basicData.certifications,
            whoThisCourseIsFor: basicData.whoThisCourseIsFor,
            thumbnail: url,
            regularPrice: regularPrice,
            courseIncludes: crcIncludes,
            freeColleges: collegeIds.length > 0 ? collegeIds : undefined,
            educators:
              userType === "educator"
                ? profileDetails
                : educatorId || undefined,
          };

          const selectedUserTypes = [];
          if (isStudent) selectedUserTypes.push("student");
          if (isWorking) selectedUserTypes.push("working");
          if (isDoctor) selectedUserTypes.push("doctor");

          if (selectedOptions.length > 0) {
            data["forUsersOfType"] = selectedOptions;
          }
          if (isCorporate) {
            data["isForCorporate"] = true;
            data["corporate"] = selectedCorporate;
          } else {
            data["isForCorporate"] = false;
            data["corporate"] = undefined;
          }

          // Make the API call
          dispatch(
            courseAddNew(data, (resp) => {
              if (resp?.status) {
                toast.success(resp?.message);
                resetManual();
                navigate("/courses");
                handlePreviousButton("four");
              } else {
                toast.error(resp?.message || "error");
              }
              dispatch(loader(false));
            })
          );
        });
      } else {
        dispatch(loader(false));
        toast.error("Please upload a thumbnail");
      }
    } else {
      dispatch(loader(false));
      toast.error("Please select Educator");
    }
  };

  const assesmentFn = (data) => {
    if (options.length > 0 && Object.keys(options[0]).length > 0) {
      var reqData = {};
      reqData["courseId"] = data.courseId;
      reqData["sessionId"] = data.sessionId;
      reqData["qList"] = options;
      dispatch(loader(true));
      dispatch(
        addAssesmentCb(reqData, (resp) => {
          if (resp.status) {
            handleClose3();
            toast.success("Assesment Added Successfully");
            dispatch(loader(false));
          } else {
            dispatch(loader(false));
            toast.error(resp.message);
          }
        })
      );
    } else {
      toast.error("Please provide question and answer");
    }
  };

  function hasAllValues(obj) {
    const keysToCheck = [
      "question",
      "option1",
      "option2",
      "option3",
      "option4",
      "correctAnswer",
    ];
    return keysToCheck.every(
      (key) => obj.hasOwnProperty(key) && obj[key] !== ""
    );
  }

  const handleDurationChange = (input) => {
    // Remove colons and non-numeric characters
    const cleanedInput = input.replace(/[^0-9]/g, "");

    // Split input into hours, minutes, and seconds
    let hours = cleanedInput.slice(0, 2);
    let minutes = cleanedInput.slice(2, 4);
    let seconds = cleanedInput.slice(4, 6);

    // Format hours
    if (hours.length > 2) {
      hours = hours.slice(0, 2);
    }
    // Format minutes
    if (minutes.length > 2) {
      minutes = minutes.slice(0, 2);
    } else if (minutes.length === 1 && seconds.length > 0) {
      // If a single digit is entered in minutes, move it to seconds
      seconds = minutes + seconds.slice(0, 1);
      minutes = "";
    }
    // Format seconds
    if (seconds.length > 2) {
      seconds = seconds.slice(0, 2);
    }
    // Construct formatted duration
    const formattedInput = `${hours}${minutes.length > 0 ? `:${minutes}` : ""}${
      seconds.length > 0 ? `:${seconds}` : ""
    }`;

    // Set the formatted input back to the duration input field
    setValue("duration", formattedInput);
  };

  const [isCorporate, setIsCorporate] = useState(false);
  const [corporates, setCorporates] = useState([]);
  const [selectedCorporate, setSelectedCorporate] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);

  const list_corporates = async () => {
    try {
      const response = await helper.postData(
        helper.baseUrl() + "getCorporates"
      );
      console.log("Corporates Data:", response.data.corporates);
      setCorporates(response?.data.corporates);
    } catch (error) {
      console.error("Error fetching corporates:", error);
    }
  };

  useEffect(() => {
    if (activeTab === "second") {
      list_corporates();
    }
  }, [activeTab]);

  const handleCorporateCheckboxChange = (e) => {
    setIsCorporate(e.target.checked);
    if (e.target.checked) {
      setSelectedOptions([]);
    }
  };

  const handleCorporateChange = (event) => {
    setSelectedCorporate(event.target.value);
  };

  const handleOptionChange = (value) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(value)) {
        return prevSelectedOptions.filter((option) => option !== value);
      } else {
        return [...prevSelectedOptions, value];
      }
    });
    setIsCorporate(false);
  };

  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <h3>Add New Course</h3>
              {userType !== "educator" ? (
                <p>
                  <Link to="/dashboard">Dashboard</Link>{" "}
                  <i>
                    <AiOutlineRight />
                  </i>{" "}
                  <Link to="/add_course">Add New Course</Link>
                </p>
              ) : (
                <p>
                  <Link to="/add_course">Add New Course</Link>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <div className="row">
                <div className="col-12">
                  <Tabs activeKey={activeTab} onSelect={handleTabSelect}>
                    <Tab eventKey="first" title="1. Basic" className="">
                      <div className="CorsELiSTab pt-4 pb-4">
                        <form onSubmit={handleSubmit(basicSubmit)}>
                          <div className="row">
                            <div className="col-lg-3 col-3">
                              <label>Category</label>
                            </div>
                            <div className="col-lg-9 col-9">
                              <select
                                name="category"
                                {...register("category", {
                                  required: "category is required",
                                  pattern: {
                                    value: /^(?!.*test).*$/,
                                    message: "select category",
                                  },
                                })}
                              >
                                <option value="test">Select Category</option>
                                {getcategoryList.map((item) => (
                                  <option key={item._id} value={item._id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                              {errors.category && (
                                <p style={{ color: "red" }}>
                                  {" "}
                                  {errors.category.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-3">
                              <label>Course Title</label>
                            </div>
                            <div className="col-lg-9 col-9">
                              <input
                                type="text"
                                className="subjectBox"
                                name="courseTitle"
                                id="courseTitle"
                                {...register("courseTitle", {
                                  required: "courseTitle is required",
                                })}
                              />
                              {errors.courseTitle && (
                                <small style={{ color: "red", float: "left" }}>
                                  {errors.courseTitle.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-3">
                              <label>Short Description</label>
                            </div>
                            <div className="col-lg-9 col-9">
                              <textarea
                                rows="4"
                                name="shortDescription"
                                id="shortDescription"
                                {...register("shortDescription", {
                                  required: "shortDescription is required",
                                })}
                              ></textarea>
                              {errors.shortDescription && (
                                <small style={{ color: "red", float: "left" }}>
                                  {errors.shortDescription.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-3">
                              <label>Duration</label>
                            </div>
                            <div className="col-lg-9 col-9">
                              <input
                                type="text"
                                placeholder="hh:mm:ss"
                                className="COrAdForm"
                                name="duration"
                                id="duration"
                                {...register("duration", {
                                  required: "duration is required",
                                  pattern: {
                                    value:
                                      /^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/,
                                    message:
                                      "Invalid duration format (HH:mm:ss)",
                                  },
                                })}
                                onChange={(e) =>
                                  handleDurationChange(e.target.value)
                                }
                              />
                              {errors.duration && (
                                <small style={{ color: "red", float: "left" }}>
                                  {errors.duration.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-3">
                              <label>Level</label>
                            </div>
                            <div className="col-lg-9 col-9">
                              <select
                                name="level"
                                {...register("level", {
                                  required: "level is required",
                                  pattern: {
                                    value: /^(?!.*test).*$/,
                                    message: "select course level",
                                  },
                                })}
                              >
                                <option value="test">Select Level</option>
                                <option value="Basic">Basic</option>
                                <option value="Intermediate">
                                  Intermediate
                                </option>
                                <option value="Advance">Advance</option>
                              </select>
                              {errors.level && (
                                <p style={{ color: "red" }}>
                                  {" "}
                                  {errors.level.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <hr></hr>
                          <div className="row">
                            <div className="col-lg-3 col-3">
                              <label>Course Description</label>
                            </div>
                            <div className="col-lg-9 col-9">
                              <Controller
                                name="description"
                                control={control}
                                rules={{
                                  validate: (value) =>
                                    (value && value.length > 0) ||
                                    "Course Description is required",
                                }}
                                render={({ field }) => (
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={field.value}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      field.onChange(data);
                                    }}
                                    onBlur={() => {
                                      if (!field.value) {
                                        field.onChange("");
                                      }
                                    }}
                                  />
                                )}
                              />
                              {errors.description && (
                                <small style={{ color: "red", float: "left" }}>
                                  {errors.description.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-3">
                              <label>What you'll learn</label>
                            </div>
                            <div className="col-lg-9 col-9">
                              <Controller
                                name="whatWillYouLearn"
                                control={control}
                                rules={{
                                  validate: (value) =>
                                    (value && value.length > 0) ||
                                    "What you'll learn is required",
                                }}
                                render={({ field }) => (
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={field.value}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      field.onChange(data);
                                    }}
                                    onBlur={() => {
                                      if (!field.value) {
                                        field.onChange("");
                                      }
                                    }}
                                  />
                                )}
                              />
                              {errors.whatWillYouLearn && (
                                <small style={{ color: "red", float: "left" }}>
                                  {errors.whatWillYouLearn.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3 col-md-3">
                              <label>Certifications</label>
                            </div>
                            <div className="col-lg-9 col-9">
                              <Controller
                                name="certifications"
                                control={control}
                                rules={{
                                  validate: (value) =>
                                    (value && value.length > 0) ||
                                    "certifications is required",
                                }}
                                render={({ field }) => (
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={field.value}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      field.onChange(data);
                                    }}
                                    onBlur={() => {
                                      if (!field.value) {
                                        field.onChange("");
                                      }
                                    }}
                                  />
                                )}
                              />
                              {errors.certifications && (
                                <small style={{ color: "red", float: "left" }}>
                                  {errors.certifications.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3 col-md-3">
                              <label>Who this course is for</label>
                            </div>
                            <div className="col-lg-9 col-9">
                              <Controller
                                name="whoThisCourseIsFor"
                                control={control}
                                rules={{
                                  validate: (value) =>
                                    (value && value.length > 0) ||
                                    "Who this course is for is required",
                                }}
                                render={({ field }) => (
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={field.value}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      field.onChange(data);
                                    }}
                                    onBlur={() => {
                                      if (!field.value) {
                                        field.onChange("");
                                      }
                                    }}
                                  />
                                )}
                              />
                              {errors.whoThisCourseIsFor && (
                                <small style={{ color: "red", float: "left" }}>
                                  {errors.whoThisCourseIsFor.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3 col-md-3">
                              <label className="thumbnailfile">Thumbnail</label>
                            </div>
                            <div className="col-lg-9">
                              <div className="ThumBnails newThumb">
                                <div className="thumbnail-container crsCntn">
                                  <div className="blogadd crsAd">
                                    {thumbnail ? null : (
                                      <label
                                        htmlFor="file-upload"
                                        className="file-upload-label"
                                      >
                                        Image Dimensions (322 x 508)
                                      </label>
                                    )}
                                    <input
                                      id="file-upload"
                                      type="file"
                                      className="fileUpload"
                                      {...register("thumbnail", {
                                        required: "thumbnail  is required",
                                      })}
                                      accept="image/*"
                                      onChange={handleFileChange}
                                    />
                                    {thumbnail && (
                                      <div className="thumbnail-preview">
                                        <img
                                          src={thumbnail}
                                          alt="Thumbnail Preview"
                                          className="imgthumbnail"
                                        />
                                      </div>
                                    )}
                                  </div>
                                  <div className="iconUpload">
                                    <label
                                      for="file-upload"
                                      className="custom-file-upload"
                                    >
                                      <span className="text-center">
                                        <TbFileUpload className="iconupload crsIcon" />
                                      </span>
                                      <p className="uploadthumb">
                                        Upload Thumbnail
                                      </p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {errors.thumbnail && (
                                <small style={{ color: "red", float: "left" }}>
                                  {errors.thumbnail.message}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-3 col-md-3">
                              <label>Course Includes</label>
                            </div>
                            <div className="col-lg-9 col-md-9">
                              {corserows.map((row, index) => (
                                <div className="assment-course d-flex addcourse">
                                  <input
                                    type="text"
                                    className="add-course"
                                    onChange={(event) =>
                                      cousrseInclude(event, index)
                                    }
                                    value={crcIncludes[index]}
                                  />

                                  <p className="Add-row" onClick={addRow1}>
                                    +
                                  </p>

                                  <p className="del-row" onClick={removeRow1}>
                                    -
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="row justify-content-center">
                            <div className="col-4">
                              <div class="Grn-Btnmanin">
                                <button type="submit" class="Grn-Btn">
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Tab>

                    <Tab eventKey="second" title="2. Course For">
                      <div className="CorsELiSTab">
                        <form>
                          <div className="d-flex">
                            <input
                              type="checkbox"
                              name="isCorporate"
                              value="isCorporate"
                              checked={isCorporate}
                              onChange={handleCorporateCheckboxChange}
                              style={{ marginRight: "10px" }}
                            />
                            <label className="TApadig">
                              This course is for Corporate
                            </label>
                            <br />
                          </div>

                          {isCorporate ? (
                            <div className="row">
                              <div className="col-lg-12 col-12">
                                <select
                                  value={selectedCorporate}
                                  onChange={handleCorporateChange}
                                >
                                  <option value="">Select Corporate</option>
                                  {corporates.map((corporate, index) => (
                                    <option key={index} value={corporate._id}>
                                      {corporate.corporateName}
                                    </option>
                                  ))}
                                </select>
                                {errors?.level && (
                                  <p style={{ color: "red" }}>
                                    {errors.level.message}
                                  </p>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="col-lg-12 d-flex">
                              <input
                                type="checkbox"
                                name="student"
                                value="student"
                                checked={selectedOptions.includes("student")}
                                onChange={() => handleOptionChange("student")}
                                style={{ marginRight: "10px" }}
                              />
                              <label
                                className="TApadig"
                                style={{ marginRight: "20px" }}
                              >
                                Student
                              </label>

                              <input
                                type="checkbox"
                                name="working"
                                value="workingProfessional"
                                checked={selectedOptions.includes("working")}
                                onChange={() => handleOptionChange("working")}
                                style={{ marginRight: "10px" }}
                              />
                              <label
                                className="TApadig"
                                style={{ marginRight: "20px" }}
                              >
                                Working
                              </label>

                              <input
                                type="checkbox"
                                name="doctor"
                                value="doctor"
                                checked={selectedOptions.includes("doctor")}
                                onChange={() => handleOptionChange("doctor")}
                                style={{ marginRight: "10px" }}
                              />
                              <label
                                className="TApadig"
                                style={{ marginRight: "20px" }}
                              >
                                Doctor
                              </label>
                              <br />
                            </div>
                          )}

                          <div className="d-flex justify-content-center">
                            <div className="">
                              <div className="SHortsForm1">
                                <span
                                  className="Grn-Btn adCrs"
                                  onClick={() => {
                                    handlePreviousButton("first");
                                  }}
                                >
                                  Previous
                                </span>
                              </div>
                            </div>
                            <div className="">
                              <div className="SHortsForm1">
                                <span
                                  className="Grn-Btn adCrs"
                                  onClick={priceFn}
                                >
                                  Next
                                </span>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Tab>

                    <Tab eventKey="third" title="3. Pricing" disabled>
                      <div className="CorsELiSTab">
                        <form>
                          <div className="row">
                            <div className="col-lg-3 col-md-12 col-3"></div>
                            <div className="col-lg-3 col-md-12 pt-4 d-flex">
                              <input
                                type="checkbox"
                                name="freeFor"
                                value="everyone"
                                checked={checkboxes.freeFor}
                                onChange={handleCheckBoxesChange}
                                disabled={
                                  checkboxenr.enrolled ||
                                  checkboxcol.colleges ||
                                  checkboxValues.discount
                                }
                              />
                              <label className="TApadig">
                                Free for everyone
                              </label>
                              <br />
                            </div>
                            <div className="col-lg-5 col-md-12 pt-4 d-flex">
                              <input
                                className="checkbox"
                                type="checkbox"
                                name="enrolled"
                                value="enrolled"
                                checked={checkboxenr.enrolled}
                                onChange={handleCheckBoxesenrolled}
                                disabled={
                                  checkboxes.freeFor ||
                                  checkboxcol.colleges ||
                                  checkboxValues.discount
                                }
                              />
                              <label className="TApadig">
                                Free for those who enrolled in 30 days
                              </label>
                              <br />
                            </div>
                          </div>
                          {checkboxes.freeFor ? (
                            <></>
                          ) : (
                            <>
                              <div className="row">
                                <div className="col-lg-3 col-md-12 col-3"></div>
                                <div className="col-lg-3 col-md-12 pt-4 d-flex">
                                  <input
                                    className="checkbox"
                                    type="checkbox"
                                    name="colleges"
                                    value="colleges"
                                    checked={checkboxcol.colleges}
                                    onChange={handleCheckBoxesColleges}
                                    disabled={
                                      checkboxes.freeFor ||
                                      checkboxenr.enrolled ||
                                      checkboxValues.discount
                                    }
                                  />
                                  <label className="TApadig">
                                    Free for based on Colleges
                                  </label>
                                  <br />
                                </div>
                                {checkboxcol.colleges ? (
                                  <div className="col-lg-5 col-md-12 pt-4 d-flex">
                                    <Select
                                      isMulti
                                      onChange={handleCollegeChange}
                                      options={optionscolleges}
                                      isSearchable={true}
                                      placeholder="Choose colleges..."
                                      classNamePrefix="react-select"
                                      className="collegeDrpDwn"
                                    />
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>

                              <div className="row">
                                <div className="col-lg-3 col-md-12 col-3">
                                  <label>MRP</label>
                                </div>
                                <div className="col-lg-9 col-9">
                                  <input
                                    type="number"
                                    className="COrAdForm"
                                    name="price"
                                    id="price"
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) =>
                                      setCoursePrice(
                                        parseInt(e.target.value) || ""
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-3 col-md-12 col-3">
                                  <label>Course Price</label>
                                </div>
                                <div className="col-lg-9 col-9">
                                  <input
                                    type="number"
                                    className="COrAdForm"
                                    name="price"
                                    id="price"
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) =>
                                      setRegularPrice(
                                        parseInt(e.target.value) || ""
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-3 col-md-12"></div>
                                <div className="col-lg-6 col-md-12 col-9 d-flex">
                                  <input
                                    type="checkbox"
                                    name="discount"
                                    checked={checkboxValues.discount}
                                    onChange={handleCheckBoxChange}
                                    disabled={
                                      checkboxes.discount ||
                                      checkboxenr.enrolled ||
                                      checkboxcol.colleges
                                    }
                                  />
                                  <label className="TApadig">
                                    Check if this course has discount
                                  </label>
                                  <br />
                                </div>
                              </div>
                              {checkboxValues.discount ? (
                                <>
                                  <div className="row">
                                    <div className="col-lg-3  col-md-12 col-3">
                                      <label>Discount Price</label>
                                    </div>
                                    <div className="col-lg-9 col-md-12 col-9">
                                      <input
                                        type="number"
                                        className="COrAdForm"
                                        name="discount"
                                        id="discount"
                                        value={disPrice}
                                        onWheel={(e) => e.target.blur()}
                                        onChange={(e) => {
                                          if (
                                            parseInt(e.target.value) !== NaN &&
                                            parseInt(e.target.value) <
                                              coursePrice
                                          ) {
                                            setDisPrice(
                                              parseInt(e.target.value) || ""
                                            );
                                          } else {
                                            toast.error(
                                              "Enter valid discount price"
                                            );
                                            setDisPrice("");
                                          }
                                        }}
                                      />
                                      <p className="NotCa">
                                        This course has{" "}
                                        <span>
                                          {calculateDiscountPercentage()}%
                                        </span>{" "}
                                        Discount
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-3 col-3">
                                      <label>Discount Expiry Date</label>
                                    </div>
                                    <div className="col-lg-9 col-9">
                                      <input
                                        type="date"
                                        className="COrAdForm"
                                        name="duration"
                                        id="duration"
                                        onChange={(e) => {
                                          setDiscountExpiry(
                                            new Date(e.target.value).getTime() /
                                              1000
                                          );
                                        }}
                                        min={
                                          new Date().toISOString().split("T")[0]
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </>
                          )}
                          <div className="d-flex justify-content-center">
                            <div className="">
                              <div className="SHortsForm1">
                                <span
                                  className="Grn-Btn adCrs"
                                  onClick={() => {
                                    handlePreviousButton("second");
                                  }}
                                >
                                  Previous
                                </span>
                              </div>
                            </div>
                            <div className="">
                              <div className="SHortsForm1">
                                <span
                                  className="Grn-Btn adCrs"
                                  onClick={priceFn1}
                                >
                                  Next
                                </span>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Tab>

                    <Tab eventKey="four" title="4. Educators" disabled>
                      <div className="CorsELiSTab">
                        <form>
                          <div className="row">
                            <div className="col-lg-3 col-3">
                              <label> Select Educator</label>
                            </div>

                            <div className="col-lg-9 col-9">
                              <select name="level" onChange={educatorSelect}>
                                <option value="test">Select Educator</option>
                                {userType !== "educator" ? (
                                  educatorData.map((item) => (
                                    <option key={item?._id} value={item?._id}>
                                      {item?.name}
                                    </option>
                                  ))
                                ) : (
                                  <option>{profileName}</option>
                                )}
                              </select>
                            </div>
                          </div>
                          {singleEduData && singleEduData.length != 0 ? (
                            <>
                              <div className="row">
                                <div className="col-lg-3 pt-4">
                                  <label className="eduPic">
                                    Educator Photo
                                  </label>
                                </div>
                                <div className="col-lg-3">
                                  <div className="ThumBnail adEduThumb">
                                    <div className="upLoad course add addCrs">
                                      <label
                                        for="file-upload"
                                        className="custom-file-upload "
                                      >
                                        <img
                                          src={singleEduData?.photoUrl}
                                          style={{
                                            padding: "10px",
                                            height: "196px",
                                            width: " 226px",
                                          }}
                                        />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-3 col-3">
                                  <label>Educator Name</label>
                                </div>
                                <div className="col-lg-9 col-9">
                                  <input
                                    type="text"
                                    className="COrAdForm"
                                    readOnly
                                    value={singleEduData?.name}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-3 col-3">
                                  <label>Email</label>
                                </div>
                                <div className="col-lg-9 col-9">
                                  <input
                                    type="text"
                                    className="COrAdForm"
                                    readOnly
                                    value={singleEduData?.email}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-3 col-3">
                                  <label>CV</label>
                                </div>
                                <div className="col-lg-9 col-9">
                                  <span
                                    onClick={() => showPdf(singleEduData.cvUrl)}
                                    className="view_button "
                                  >
                                    {" "}
                                    View CV Details
                                  </span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          <div className="d-flex justify-content-center mt-5">
                            <div className="">
                              <div className="SHortsForm1">
                                <span
                                  className="Grn-Btn adCrs"
                                  onClick={() => {
                                    handlePreviousButton("third");
                                  }}
                                >
                                  Previous
                                </span>
                              </div>
                            </div>
                            <div className="">
                              <div className="SHortsForm1">
                                <span
                                  className="Grn-Btn adCrs"
                                  onClick={addCourseFn}
                                >
                                  Submit
                                </span>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Tab>

                    <Tab eventKey="five" title="5. Course Content">
                      <div className="CorCountenT">
                        <div className="row justify-content-center">
                          <form>
                            <div className="row">
                              <div className="col-lg-3 col-3">
                                <label>Select Course</label>
                              </div>
                              <div className="col-lg-9 col-9">
                                <select name="course">
                                  <option key="" value="">
                                    Select course
                                  </option>
                                  {courseList.map((item) => (
                                    <option key={item._id} value={item._id}>
                                      {item.title}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-lg-3 col-md-3">
                            <Link
                              to=""
                              className="BluEBUTn"
                              onClick={handleShow}
                            >
                              + Add Session
                            </Link>
                          </div>

                          <div className="col-lg-3 col-md-3">
                            <Link
                              to=""
                              className="BluEBUTn"
                              onClick={handleShow1}
                            >
                              + Add Lesson
                            </Link>
                          </div>
                          <div className="col-lg-3 col-md-3">
                            <Link
                              to=""
                              className="BluEBUTn"
                              onClick={handleShow3}
                            >
                              + Add Assessment
                            </Link>
                          </div>
                        </div>
                        <div className="row justify-content-center mt-5">
                          <div className="col-12">
                            <div className="DulBG">
                              <div className="d-flex">
                                <div className="DuLLBg">
                                  <p>
                                    <span>Session 1 :</span> Grow Personal
                                    pharmacist Security Thinking & Principles
                                  </p>
                                </div>
                                <div className="AcBtN Wh">
                                  <button type="submit" onClick={handleShow2}>
                                    View Assessment
                                  </button>
                                </div>
                                <div className="AcBtN Wh">
                                  <button type="submit">Edit Session</button>
                                </div>
                                <div className="AcBtN Wh">
                                  <button type="submit">Delete Session</button>
                                </div>
                              </div>
                              <div className="row mt-5">
                                <div className="col-12">
                                  <div className="LeSSonSesion">
                                    <div className="d-flex">
                                      <div className="LesSNp">
                                        <p>
                                          <span>Lesson 1 :</span>Definition and
                                          scope of the pharmaceutical industry
                                          (3m)
                                        </p>
                                      </div>
                                      <div className="AcBtN">
                                        <button>
                                          <i>
                                            <MdEdit />
                                          </i>
                                        </button>
                                        <button>
                                          <i>
                                            <MdDelete />
                                          </i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="LeSSonSesion">
                                    <div className="d-flex">
                                      <div className="LesSNp">
                                        <p>
                                          <span>Lesson 2 :</span>Definition and
                                          scope of the pharmaceutical industry
                                          (3m)
                                        </p>
                                      </div>
                                      <div className="AcBtN">
                                        <button>
                                          <i>
                                            <MdEdit />
                                          </i>
                                        </button>
                                        <button>
                                          <i>
                                            <MdDelete />
                                          </i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="LeSSonSesion">
                                    <div className="d-flex">
                                      <div className="LesSNp">
                                        <p>
                                          <span>Lesson 3 :</span>Definition and
                                          scope of the pharmaceutical industry
                                          (3m)
                                        </p>
                                      </div>
                                      <div className="AcBtN">
                                        <button>
                                          <i>
                                            <MdEdit />
                                          </i>
                                        </button>
                                        <button>
                                          <i>
                                            <MdDelete />
                                          </i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row justify-content-center mt-5">
                          <div className="col-12">
                            <div className="DulBG">
                              <div className="d-flex">
                                <div className="DuLLBg">
                                  <p>
                                    <span>Session 1 :</span> Grow Personal
                                    pharmacist Security Thinking & Principles
                                  </p>
                                </div>
                                <div className="AcBtN">
                                  <button type="submit">View Assessment</button>
                                </div>
                                <div className="AcBtN Wh">
                                  <button type="submit">Edit Session</button>
                                </div>
                                <div className="AcBtN Wh">
                                  <button type="submit">Delete Session</button>
                                </div>
                              </div>
                              <div className="row mt-5">
                                <div className="col-12">
                                  <div className="LeSSonSesion">
                                    <div className="d-flex">
                                      <div className="LesSNp">
                                        <p>
                                          <span>Lesson 1 :</span>Definition and
                                          scope of the pharmaceutical industry
                                          (3m)
                                        </p>
                                      </div>
                                      <div className="AcBtN">
                                        <button>
                                          <i>
                                            <MdEdit />
                                          </i>
                                        </button>
                                        <button>
                                          <i>
                                            <MdDelete />
                                          </i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="LeSSonSesion">
                                    <div className="d-flex">
                                      <div className="LesSNp">
                                        <p>
                                          <span>Lesson 2 :</span>Definition and
                                          scope of the pharmaceutical industry
                                          (3m)
                                        </p>
                                      </div>
                                      <div className="AcBtN">
                                        <button>
                                          <i>
                                            <MdEdit />
                                          </i>
                                        </button>
                                        <button>
                                          <i>
                                            <MdDelete />
                                          </i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="LeSSonSesion">
                                    <div className="d-flex">
                                      <div className="LesSNp">
                                        <p>
                                          <span>Lesson 3 :</span>Definition and
                                          scope of the pharmaceutical industry
                                          (3m)
                                        </p>
                                      </div>
                                      <div className="AcBtN">
                                        <button>
                                          <i>
                                            <MdEdit />
                                          </i>
                                        </button>
                                        <button>
                                          <i>
                                            <MdDelete />
                                          </i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>

                    <Tab eventKey="five" title="6. Finish" disabled>
                      <div className="CorsELiSTab">
                        <div className="row">
                          <div className="col-12">
                            <div className="CoConTenT">
                              <h4>Thank You!</h4>
                              <h4>Your Course has been Created </h4>
                              <div className="row justify-content-center">
                                <div className="col-xl-6 col-lg-6">
                                  <div class="Grn-Btnmanin">
                                    <Link class="Grn-Btn">Finish</Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Session</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit3(SessionSubmit)}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div className="assment-course">
                <label>Select course</label>
                <select
                  name="courseId"
                  {...register3("courseId", {
                    required: "select course is required",
                    pattern: {
                      value: /^(?!.*test).*$/,
                      message: "select course",
                    },
                  })}
                >
                  <option key="test" value="test">
                    Select course
                  </option>
                  {courseList.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {errors3.courseId && (
                  <p style={{ color: "red" }}> {errors3.courseId.message}</p>
                )}
              </div>
              <Form.Label>Session Title</Form.Label>

              <Form.Control
                type="text"
                placeholder=""
                autoFocus
                name="title"
                id="title"
                {...register3("title", {
                  required: "Session Title is required",
                })}
              />
            </Form.Group>
            {errors3.title && (
              <small style={{ color: "red", float: "left" }}>
                {errors3.title.message}
              </small>
            )}
            <div className="d-flex justify-content-center">
              <button className="Grn-Btn Half" type="submit">
                add
              </button>
              <button
                className="WHt-Btn Half"
                type="button"
                onClick={cancelAddSession}
              >
                cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Add Lesson */}
      <Modal show={showview} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Add Lesson</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit4(lessonSubmit)}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Select Course</Form.Label>
              <div className="assment-course">
                <select
                  name="courseId"
                  {...register4("courseId", {
                    required: "select course  is required",
                    pattern: {
                      value: /^(?!.*test).*$/,
                      message: "please select course",
                    },
                  })}
                  onChange={(e) => {
                    sessionSelFn(e.target.value);
                  }}
                >
                  <option key="test" value="test">
                    Select course
                  </option>
                  {courseList.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {errors4.courseId && (
                  <p style={{ color: "red" }}> {errors4.courseId.message}</p>
                )}
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Select Session</Form.Label>
              <div className="assment-course">
                <select
                  name="sessionId"
                  {...register4("sessionId", {
                    required: "select session is required",
                    pattern: {
                      value: /^(?!.*test).*$/,
                      message: "select session",
                    },
                  })}
                >
                  <option key="test" value="test">
                    Select session
                  </option>
                  {sessList.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {errors4.sessionId && (
                  <p style={{ color: "red" }}> {errors4.sessionId.message}</p>
                )}
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Lesson Title</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="title"
                id="title"
                {...register4("title", {
                  required: "title is required",
                })}
              />
              {errors4.title && (
                <p style={{ color: "red" }}>{errors4.title.message}</p>
              )}
            </Form.Group>

            <div className="row">
              <div className="col-6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Duration</Form.Label>
                  <Form.Control
                    type="time"
                    placeholder=""
                    {...register4("duration", {
                      required: "duration is required",
                    })}
                  />
                  {errors4.duration && (
                    <small style={{ color: "red", float: "left" }}>
                      {errors4.duration.message}
                    </small>
                  )}
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Video Upload</Form.Label>
                  <Form.Control
                    type="file"
                    {...register4("videoUrl", {
                      required: "video  is required",
                    })}
                    onChange={(e) => validateFile(e.target.files[0])}
                  />

                  {errors4.videoUrl && (
                    <small style={{ color: "red", float: "left" }}>
                      {errors4.videoUrl.message}
                    </small>
                  )}
                </Form.Group>
              </div>
              {/* <div className="OnOfFbtn">
                <Form.Group className="mb-3">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="Free Video"
                    onChange={handleToggle1}
                    checked={isHidden}
                  />
                </Form.Group>
              </div> */}
            </div>

            <div className="d-flex justify-content-center">
              <button className="Grn-Btn Half" type="submit">
                add
              </button>
              <button
                className="WHt-Btn Half"
                type="button"
                onClick={cancelAddLesson}
              >
                cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* view assesment */}
      <Modal show={showview1} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>View Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="DuLLBg">
              <p>
                <span>Session 1 :</span> Grow Personal pharmacist Security
                Thinking & Principles
              </p>
            </div>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="AccWidth">
                  <p>
                    {" "}
                    What Importance and impact of the industry on global health
                    and economy
                  </p>
                  <div className="Edit-tool">
                    <i>
                      <BiSolidPencil />
                    </i>
                    <i>
                      <AiTwotoneDelete />
                    </i>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <form>
                    <input type="checkbox" />
                    <label className="AccCheck"> Security Thinking</label>
                    <br />
                    <input type="checkbox" />
                    <label className="AccCheck"> Grow Personal</label>
                    <br />
                    <input type="checkbox" />
                    <label className="AccCheck">
                      {" "}
                      Industry structure and players
                    </label>
                    <br />
                    <input type="checkbox" />
                    <label className="AccCheck"> Pharmacy Student</label>
                    <br />
                  </form>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  What Importance and impact of the industry on global health
                  and economy
                </Accordion.Header>
                <Accordion.Body>
                  <form>
                    <input type="checkbox" />
                    <label className="AccCheck"> Security Thinking</label>
                    <br />
                    <input type="checkbox" />
                    <label className="AccCheck"> Grow Personal</label>
                    <br />
                    <input type="checkbox" />
                    <label className="AccCheck">
                      {" "}
                      Industry structure and players
                    </label>
                    <br />
                    <input type="checkbox" />
                    <label className="AccCheck"> Pharmacy Student</label>
                    <br />
                  </form>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={show1} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>View CV Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <iframe
              src={`https://docs.google.com/gview?url=${getImg}&embedded=true`}
              width="100%"
              height="500px"
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>

      {/* add assesment */}
      <Modal show={showview3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>Add Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit5(assesmentFn)}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Select Course</Form.Label>
              <div className="assment-course">
                <select
                  name="courseId"
                  {...register5("courseId", {
                    required: "select course  is required",
                    pattern: {
                      value: /^(?!.*test).*$/,
                      message: "please select course",
                    },
                  })}
                  onChange={(e) => {
                    sessionSelFn(e.target.value);
                  }}
                >
                  <option key="test" value="test">
                    Select course
                  </option>
                  {courseList.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {errors5.courseId && (
                  <p style={{ color: "red" }}> {errors5.courseId.message}</p>
                )}
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Select Session</Form.Label>
              <div className="assment-course">
                <select
                  name="sessionId"
                  {...register5("sessionId", {
                    required: "select session is required",
                    pattern: {
                      value: /^(?!.*test).*$/,
                      message: "select session",
                    },
                  })}
                >
                  <option key="test" value="test">
                    Select session
                  </option>
                  {sessList.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {errors5.sessionId && (
                  <p style={{ color: "red" }}> {errors5.sessionId.message}</p>
                )}
              </div>
            </Form.Group>

            {rows.map((row, i) => (
              <div className="assment-course d-flex mt-4">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <input
                        type="text"
                        className="add-course"
                        placeholder="Question"
                        onChange={(event) => ansFn(event, i, "question")}
                      />
                    </Accordion.Header>
                    <Accordion.Body>
                      <form className="Addasses">
                        <input
                          type="radio"
                          name={`courseanswer${i}`}
                          value="option1"
                          onChange={(event) => {
                            ansFn(event, i, "correctAnswer");
                          }}
                        />
                        <input
                          type="text"
                          className="Checkboxans"
                          placeholder="Option1"
                          onChange={(event) => ansFn(event, i, "option1")}
                        />
                        <br />
                        <input
                          type="radio"
                          name={`courseanswer${i}`}
                          value="option2"
                          onChange={(event) => {
                            ansFn(event, i, "correctAnswer");
                          }}
                        />
                        <input
                          type="text"
                          className="Checkboxans"
                          placeholder="Option2"
                          onChange={(event) => ansFn(event, i, "option2")}
                        />
                        <br />
                        <input
                          type="radio"
                          name={`courseanswer${i}`}
                          value="option3"
                          onChange={(event) => {
                            ansFn(event, i, "correctAnswer");
                          }}
                        />
                        <input
                          type="text"
                          className="Checkboxans"
                          placeholder="Option3"
                          onChange={(event) => ansFn(event, i, "option3")}
                        />
                        <br />
                        <input
                          type="radio"
                          name={`courseanswer${i}`}
                          value="option4"
                          onChange={(event) => {
                            ansFn(event, i, "correctAnswer");
                          }}
                        />
                        <input
                          type="text"
                          className="Checkboxans"
                          placeholder="Option4"
                          onChange={(event) => ansFn(event, i, "option4")}
                        />
                        <br />
                      </form>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <p className="Add-row" onClick={addQandARow}>
                  +
                </p>
                <p className="del-row" onClick={removeQandARow}>
                  -
                </p>
              </div>
            ))}
            <button className="Grn-Btn assesm" type="submit">
              Save
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Addcourse;
