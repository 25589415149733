import { createSlice } from "@reduxjs/toolkit";
import Helper from "../../helper/axiosHelper";
const baseUrl = Helper.baseUrl();

const initialState = {
     firstSignUp: {},
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
   
  },
});

export const { isLogin } = loginSlice.actions;

export default loginSlice.reducer;

export const loginDetails =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "signIn", data).then(
      (response) => response.data
    );
    callback(result);
  };

export const add_admin_users =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "signUp", data).then(
      (response) => response.data
    );
    callback(result);
  };
export const change_admin_user_status =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(baseUrl + "change_admin_user_status", data).then(
      (response) => response.data
    );
    callback(result);
  };
export const delete_admin_user =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "delete_admin_user", data).then(
      (response) => response.data
    );
    callback(result);
  };

export const list_of_admin_roles =
  ( callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "list_of_admin_roles").then(
      (response) => response.data
    );
    callback(result);
  };

export const admin_assign_permissions =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(baseUrl + "assign_permissions",data).then(
      (response) => response.data
    );
    callback(result);
  };

export const list_Of_admin_users =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "list_Of_admin_users", data).then(
      (response) => response.data
    );
    callback(result);
  };

export const changePassword =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(baseUrl + "changePassword", data).then(
      (response) => response.data
    );
    callback(result);
  };

export const addCategory =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "category/new", data).then(
      (response) => response.data
    );
    callback(result);
  };

export const CategoryList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "categories").then(
      (response) => response.data
    );
    callback(result);
  };

export const viewCategory =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + `category/info/${data}`).then(
      (response) => response.data
    );
    callback(result);
  };

export const changeStatusCategoty =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(baseUrl + "category/edit", data).then(
      (response) => response.data
    );
    callback(result);
  };

  export const editCategory =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(baseUrl + "category/edit", data).then(
      (response) => response.data
    );
    callback(result);
  };

export const deleteCategory =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "category/delete", data).then(
      (response) => response.data
    );
    callback(result);
  };
  export const deleteCoupon =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "coupon/delete_coupon", data).then(
      (response) => response.data
    );
    callback(result);
  };
  export const addCoupon =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "coupon/add_coupon", data).then(
      (response) => response.data
    );
    callback(result);
  };
  export const CouponList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "coupon/coupon_list").then(
      (response) => response.data
    );
    callback(result);
  };
  export const CouponStatus =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(baseUrl + "coupon/edit_coupon", data).then(
      (response) => response.data
    );
    callback(result);
  };
  export const CouponInfo =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "coupon/coupon_info", data).then(
      (response) => response.data
    );
    callback(result);
  };
  export const add_admin_roles =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "add_admin_role", data).then(
      (response) => response.data
    );
    callback(result);
  };
  export const admin_roles_delete =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "delete_admin_role", data).then(
      (response) => response.data
    );
    callback(result);
  };
  export const  admin_roles_and_permissions =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "admin_roles_and_permissions", data).then(
      (response) => response.data
    );
    callback(result);
  };
  export const create_permissions =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "create_permissions", data).then(
      (response) => response.data
    );
    callback(result);
  };
  export const notifications =
  ( callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "notifications").then(
      (response) => response.data
    );
    callback(result);
  };
  export const dashboard_info =
  ( callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "dashboard_info").then(
      (response) => response.data
    );
    callback(result);
  };
  export const dashboard_graph =
  ( callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "order/sales_and_revenue").then(
      (response) => response.data
    );
    callback(result);
  };
 