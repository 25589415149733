import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { JobApplicantsList, viewApplicantDetail } from "../redux/jobs";

const JobApplicants = () => {
  let dispatch = useDispatch();
  const [showview, setShowview] = useState(false);
  const [jobData, setJobData] = useState([]);
  let [refresher, setRefresher] = useState(true);
  let [viewData, setViewData] = useState({});
  const [show1, setShow1] = useState(false);
  const [getImg, setImg] = useState();

  const handleClose4 = () => setShow1(false);
  const handleClose1 = () => setShowview(false);

  const handleShow1 = (id) => {
    let data = id;
    setShowview(true);
    dispatch(loader(true));
    dispatch(
      viewApplicantDetail(data, (resp) => {
        if (resp.status) {
          setViewData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };
  const showPdf = (data) => {
    setShow1(!show1);
    setImg(data);
  };

  const {
    register,
    formState: { errors: errors },
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      JobApplicantsList((resp) => {
        if (resp.status) {
          setJobData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, [refresher]);

  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-md-5">
              <h3>Jobs Applicants List</h3>
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/job_applicants">Jobs Applicants List</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <Table striped hover>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>emailId</th>
                    <th>position</th>
                    <th>Work Experience</th>
                    <th>Location</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {jobData.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.fullName}</td>
                        <td>{item.emailId}</td>
                        <td>{item.positionAppliedFor}</td>
                        <td
                          style={{ textAlign: "center", paddingRight: "60px" }}
                        >
                          {item.totalWorkExperience}
                        </td>
                        <td>{item.city}</td>
                        <td>
                          <div className="d-flex">
                            <div className="col-12">
                              <div className="Options jobCnd pt-1">
                                <i>
                                  <BsThreeDotsVertical />
                                </i>
                                <ul class="hidden-list ">
                                  <li onClick={() => handleShow1(item._id)}>
                                    View
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showview} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>View Jobs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="title"
                value={viewData.fullName}
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={viewData.emailId}
                name="description"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="department"
                value={viewData.phoneNumber}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div className="col-3">
                <Form.Label>Gender</Form.Label>
              </div>
              <div className="col-12">
                <Form.Control
                  type="text"
                  placeholder=""
                  name="department"
                  value={viewData.gender}
                />
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div className="col-3">
                <Form.Label>Address</Form.Label>
              </div>
              <div className="col-12">
                <Form.Control
                  type="text"
                  placeholder=""
                  name="department"
                  value={viewData.address}
                />
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div className="col-3">
                <Form.Label>city</Form.Label>
              </div>
              <div className="col-12">
                <Form.Control
                  type="text"
                  placeholder=""
                  name="department"
                  value={viewData.city}
                />
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>pincode</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="location"
                value={viewData.pincode}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Total Work Experience</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="location"
                value={viewData.totalWorkExperience}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Last Employer</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="location"
                value={viewData.lastEmployer}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>source</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="location"
                value={viewData.source}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Resume</Form.Label>
              <div
                onClick={() => showPdf(viewData.cv)}
                className="view_button job"
              >
                {" "}
                View CV Details
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={show1} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>View CV Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <iframe
              src={`https://docs.google.com/gview?url=${getImg}&embedded=true`}
              width="100%"
              height="500px"
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default JobApplicants;
