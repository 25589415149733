import { TbFileUpload } from "react-icons/tb";
import "./Experts.css";
import { Link, useNavigate } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import { viewShortsList, editShortsList } from "../redux/shorts";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
const Editexperts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shortSViewData, setShortSViewData] = useState();
  const [previewImage, setPreviewImage] = useState();
  const [imageFile, setImageFile] = useState("");

  useEffect(() => {
    let viewListID = sessionStorage.getItem("ShortListId");
 
    let data = {};
    data["shortsId"] = viewListID;
    dispatch(loader(true));
    dispatch(
      viewShortsList(data, (resp) => {
        if (resp.status) {
          setShortSViewData(resp.data);
          setPreviewImage(resp.data.thumbnail);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, []);

  function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    var arr = {};
    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        arr[key] = object1[key];
      }
    }
    return arr;
  }

  const submitShorts = (e) => {
    e.preventDefault();
    let viewListID = sessionStorage.getItem("ShortListId");

    var compareVal = {
      title: e.target.Title.value,
      videoUrl: e.target[2].value,
    };
    var result = shallowEqual(compareVal, shortSViewData);
    let formData = new FormData();
    formData.append("shortsId", viewListID);
    if (result != "") {
      formData.append("title", e.target.Title.value);
    }
    if (result != "") {
      formData.append("videoUrl", e.target[2].value);
    }
    if (imageFile != "") {
      formData.append("thumbnail", imageFile);
    }

    dispatch(loader(true));
    dispatch(
      editShortsList(formData, (resp) => {
        if (resp.status) {
          setShortSViewData(resp.data);
          toast.success(resp.message);
          navigate("/list_to_experts");
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };
  const handleProfilePic = (event) => {
    const selectedFile = event.target.files[0];
    setImageFile(event.target.files[0]);

    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };

      reader.readAsDataURL(selectedFile);
    } else {
      //   setPreviewImage(
      //     "https://cdn.landesa.org/wp-content/uploads/default-user-image.png"
      //   );
    }
  };
  const {
    register: register,
    control,
    formState: { errors: errors },
    reset: reset,
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur" });
  return (
  
      <div className="Categorypage">
        <div className="PageTit ">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h3>Edit Shorts</h3>
                <p>
                  <Link to="/dashboard">Dasboard</Link>{" "}
                  <i>
                    <AiOutlineRight />
                  </i>{" "}
                  <Link to="/list_to_experts">Listen to Experts</Link>{" "}
                  <i>
                    <AiOutlineRight />
                  </i>{" "}
                  <Link to="">Edit Shorts</Link>
                </p>
              </div>
              <div className="col-6"></div>
            </div>
          </div>
        </div>
        <div className="CateCont">
          <div className="container">
            <div className="row">
              <div className="CateGorTab">
                <div className="TittlE">
                  <h4>Edit Shorts</h4>
                </div>
                <div className="ExpertSBacKG">
                  <form onSubmit={submitShorts}>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="">
                          <div className="upLoadedit">
                            <label htmlFor="imgupload">
                           
                              <input
                                type="file"
                                id="imgupload"
                                onChange={handleProfilePic}
                                style={{ display: "none" }}
                                accept="image/*"
                              />
                              
                              <i style={{ display: "none" }}>
                                <TbFileUpload className="uploadIcon" />
                              </i>
                              <img
                                src={previewImage}
                                className="img-fluid"
                                alt="User Profile"
                                style={{ 
                                  padding: "20px",
                                  height: "180px",
                                  width: "223px"}}
                              />
                               Image Dimensions (200 x 300)<br/>
                               Click here to Upload  <TbFileUpload className="uploadIcon" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8 pt-3">
                        <div className="row">
                          <div className="col-12">
                            <div className="SHortsForm">
                              <label>Title</label>
                              <br></br>
                              <input
                                type="text"
                                className="FormShorts"
                                name="Title"
                                defaultValue={shortSViewData?.title}
                                {...register('Title', { required: 'Title is required' })}
                              />
                                 {errors.Title && <small style={{ color: 'red' }}>{errors.Title.message}</small>}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="SHortsForm">
                              <label>Video URL</label>
                              <br></br>
                              <input
                                type="text"
                                name="videourls"
                                className="FormShorts"
                                defaultValue={shortSViewData?.videoUrl}
                                {...register('videoUrl', {
                                  required: 'Video URL is required',
                                  pattern: {
                                    value: /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
                                    message: 'Invalid URL format'
                                  }
                                })}
                              />
                                  {errors.videoUrl && <small style={{ color: 'red' }}>{errors.videoUrl.message}</small>}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-3">
                            <div className="SHortsForm1">
                              <button className="Grn-Btn" type="submit">
                                Save
                              </button>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="SHortsForm1">
                              <button
                                className="WHt-Btn"
                                type="button"
                                onClick={() => navigate("/list_to_experts")}
                              >
                                cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 
  );
};
export default Editexperts;
