import { createSlice } from "@reduxjs/toolkit";
import Helper from "../../helper/axiosHelper";
const baseUrl = Helper.baseUrl();

const initialState = {
  //   firstSignUp: {},
};

// export const blogSlice = createSlice({
//     name: "blog",
//     initialState,
//     reducers: {});

export const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    addBlog: (state, action) => {
      state.blogs.push(action.payload); // Add the new blog to the list of blogs
    },
    blogList: (state, action) => {
      state.blogs = action.payload; // Set the list of blogs to the state
    },
    updateBlog: (state, action) => {
      const { id, data } = action.payload;
      const index = state.blogs.findIndex((blog) => blog.blogId === id);
      if (index !== -1) {
        state.blogs[index] = { ...state.blogs[index], ...data }; // Update the specified blog with the new data
      }
    },
  },
});
export const { addBlog, blogList, updateBlog } = blogSlice.actions;

export default blogSlice.reducer;

export const fetchBlogList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "blog/s").then(
      (response) => response.data
    );
    callback(result);
  };
export const addNewBlog =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.formData(baseUrl + "blog/create_new", data).then(
      (response) => response.data
    );
    callback(result);
  };
export const viewBlog =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "blog/detail", data).then(
      (response) => response.data
    );
    callback(result);
  };

export const editBlog =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.pathFormData(baseUrl + "blog/update", data).then(
      (response) => response.data
    );
    callback(result);
  };
export const deleteBlog =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "blog/del", data).then(
      (response) => response.data
    );
    callback(result);
  };

export const changeStatusCb =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(
      baseUrl + "blog/update_status",
      data
    ).then((response) => response.data);
    callback(result);
  };
