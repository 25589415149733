import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./Course.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { BsSearch } from "react-icons/bs";
import Select from "react-select";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  selectCourses,
  courseBundleAddNew,
  courseBundleList,
  courseBundleListInfo,
  courseBundleEdit,
  courseBundleStatusChange,
} from "../redux/courses";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { toast } from "react-toastify";

const Coursebundle = () => {
  let dispatch = useDispatch();
  const [refresher, setRefresher] = useState(true);
  const [courseList, setCourseList] = useState([]);
  const [bundleList, setBundleList] = useState([]);
  const [coursesOption, setCoursesOption] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [bundleTitle, setBundleTitle] = useState("");
  const [bundleViewInfo, setBundleViewInfo] = useState("");
  const [courseEditTitle, setCourseEditTitle] = useState("");
  const [courseEditBundle, setCourseEditBundle] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [checked, setChecked] = useState([]);
  const [dummydataComplete, setdummydataComplete] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [show, setShow] = useState(false);
  const [showedit, setShowedit] = useState(false);
  const [showview, setShowview] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShowedit(false);
  const handleClose2 = () => setShowview(false);
  const handleShow1 = (id) => {
    const bundleId = bundleList.find((course) => course._id === id);
    setShowedit(true);
    setSelectedId(bundleId._id);
    var temp = [];
    bundleId.selectedCourses.map((element) => {
      temp.push({ label: element.title, value: element.courseId });
    });
    const updatedTitleValue = bundleId.title;
    setCourseEditTitle({title: updatedTitleValue});
    setCourseEditBundle(temp, ...bundleList);
  };

  const viewBundle = (id) => {
    setShowview(true);

    let data = {};
    data["bundleId"] = id;
    dispatch(loader(true));
    dispatch(
      courseBundleListInfo(data, (resp) => {
        if (resp.status) {
          setBundleViewInfo(resp.data);
          const updatedCoursesOption = resp.data.selectedCourses.map(
            (element) => ({
              label: element.title,
              value: element._id,
            })
          );
          setCoursesOption(updatedCoursesOption);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const editBundle = (id) => {
    id.preventDefault();
    setShowedit(true);
    const bundleTitle = bundleList.find((course) => course._id === selectedId);
    const bundleVal = selectedCourses.map((course) => course.value);
    let data = {};

    // Check if title is edited and update data object accordingly
    if (courseEditTitle.title !== bundleTitle.title) {
      data.id = selectedId;
      data.title = courseEditTitle.title;
    }

    // Check if selectedCourses is edited and update data object accordingly

    if (
      bundleVal.length > 0 &&
      !arraysAreEqual(bundleVal, bundleTitle.selectedCourses)
    ) {
      data.id = selectedId;
      data.selectedCourses = bundleVal;
    }

    dispatch(loader(true));
    dispatch(
      courseBundleEdit(data, (resp) => {
        if (resp.status) {
          toast.success(resp.message);
          setRefresher(!refresher);
          setShowedit(false);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  // Function to compare two arrays for equality
  function arraysAreEqual(array1, array2) {
    if (!Array.isArray(array1) || !Array.isArray(array2)) {
      return false; // If either input is not an array, they are not equal
    }

    return (
      array1.length === array2.length &&
      array1.every((value, index) => value === array2[index])
    );
  }

  const bundleCourseSubmit = (e) => {
    e.preventDefault();
    let data = {};
    let selectedCourses = selectedValue.map((x) => x.value);

    data["title"] = bundleTitle;
    data["selectedCourses"] = selectedCourses;
    dispatch(loader(true));
    dispatch(
      courseBundleAddNew(data, (resp) => {
        if (resp.status) {
          setBundleTitle("");
          setRefresher(!refresher);
          setShow(false);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const changeStatus = (id, index) => {
    let data = {};
    let value = {
      isActive: !checked[index],
    };
    data["bundleId"] = id;
    data["isActive"] = value.isActive;

    dispatch(loader(true));
    dispatch(
      courseBundleStatusChange(data, (response) => {
        if (response.status) {
          setRefresher(!refresher);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );
  };

  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      selectCourses((resp) => {
        if (resp.status) {
          var temp = [];
          resp.data.map((element) => {
            temp.push({ label: element.title, value: element._id });
          });
          setCourseList([...courseList, ...temp]);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
    dispatch(
      courseBundleList((resp) => {
        if (resp.status) {
          setBundleList(resp.data);
          setdummydataComplete(resp.data);
          setSelectedData(resp.data);
          // status change
          var arr = [];
          for (var i = 0; i < resp.data.length; i++) {
            arr.push(resp.data[i].isActive);
          }
          setChecked(arr);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, [refresher]);

  const filterBySearch = (event) => {
    const query = event.target.value;
    var updatedlist = [...dummydataComplete];
    var updatedList = updatedlist.filter((item) => {
      return item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    setBundleList(updatedList);
  };

  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-5">
              <h3>Course Bundle List</h3>
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="">Manage Course Bundle</Link>
              </p>
            </div>
            <div className="col-7">
              <div className="Grn-Btnmanin">
                <button className="Grn-Btn" onClick={handleShow}>
                  Add course bundle
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <div className="col-12">
                <div className="searchBtNs">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="BtNSSearcH">
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder="Search"
                            aria-describedby="basic-addon2"
                            onChange={filterBySearch}
                          />
                          <Button
                            variant="outline-secondary"
                            id="button-addon2"
                          >
                            <BsSearch />
                          </Button>
                        </InputGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Course Bundle Name</th>
                    <th>Course</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {bundleList.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.title}</td>
                      <td>
                        <ul className="CourSEl">
                          {item.selectedCourses.map((course, index) => (
                            <li key={index}>{course.title}</li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          <div className="">
                            <div className="OnOfF">
                              <Form>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  onChange={() => changeStatus(item._id, index)}
                                  checked={checked[index]}
                                />
                              </Form>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="Options">
                              <i>
                                <BsThreeDotsVertical />
                              </i>
                              <ul class="hidden-list">
                                <li onClick={() => viewBundle(item._id)}>
                                  View
                                </li>
                                <li onClick={() => handleShow1(item._id)}>
                                  Edit
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Course Bundle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={bundleCourseSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Course Bundle Title</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                autoFocus
                onChange={(e) => setBundleTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Select Courses</Form.Label>
              <Select
                isMulti
                value={courseList.filter((obj) => selectedValue.includes(obj))}
                options={courseList}
                onChange={(selectedOptions) => {
                  const selectedCourses1 = selectedOptions.filter((option) =>
                    courseList.find((course) => course.value === option.value)
                  );
                  setSelectedValue(selectedCourses1);
                }}
              />
            </Form.Group>
            <div className="d-flex justify-content-center">
              <button className="Grn-Btn Half" type="submit">
                add
              </button>
              <button
                className="WHt-Btn Half"
                type="button"
                onClick={handleClose}
              >
                cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* edit modal */}
      <Modal show={showedit} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Course Bundle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={editBundle}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Course Bundle Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Course Bundle Title"
                value={
                  courseEditTitle.title
                }
                onChange={(e) => {
                  setCourseEditTitle(prev => ({
                    title: e.target.value
                  }));
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Select Courses</Form.Label>
              <Select
                isMulti
                onChange={(selectedOptions) => {
                  setSelectedCourses(selectedOptions);
                  setCourseEditBundle(selectedOptions);
                }}
                value={courseEditBundle}
                options={courseList}
              />
            </Form.Group>
            <div className="d-flex justify-content-center">
              <button className="Grn-Btn Half" type="submit">
                Update
              </button>
              <button
                className="WHt-Btn Half"
                type="button"
                onClick={handleClose1}
              >
                cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* view modal */}
      <Modal show={showview} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>View Course Bundle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={viewBundle}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Course Bundle Title</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                // autoFocus
                value={bundleViewInfo.title}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Select Courses</Form.Label>
              <Select isMulti value={coursesOption} options={courseList} />
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Coursebundle;
