import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./VideoTestimonials.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import { useForm } from "react-hook-form";
import { BsThreeDotsVertical } from "react-icons/bs";
import Topbar from "../../components/topbar/Topbar";
import SideBar from "../../components/Sidebar/SideBar";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { toast } from "react-toastify";
import {
  VideoTestimonialsList,
  addVideoTestimonial,
  deleteVideoTestimonial,
  editVideoTestimonial,
} from "../redux/videotestimonial";
import { TbFileUpload } from "react-icons/tb";

const VideoTestimonial = () => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [showview, setShowview] = useState(false);
  const [refresher, setRefresher] = useState(false);
  const [editTestData, setEditTestData] = useState([]);
  const [editTestDataImg, setEditTestDataImg] = useState([]);
  const [editTestDataVideo, setEditTestDataVideo] = useState([]);
  const [listTestimonials, setListTestimonials] = useState([]);
  const [checked, setChecked] = useState([]);
  const [previewImage, setPreviewImage] = useState();
  const [imageFile, setImageFile] = useState();
  const handleClose = () => {
    setShow(false);
    setPreviewImage("");
    reset();
  };
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShowview(false);
  const handleShow1 = (data, index) => {
    console.log(data, "videotestdata")
    setShowview(true);
    setEditTestData(data);
    setValue('videoUrl', data.videoUrl);
    setEditTestDataImg(data.thumbnail);
    setEditTestDataVideo(data.videoUrl)
  };


  const {
    register: register, 
    control,
    formState: { errors: errors },
    reset: reset,
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur" });

  const {
    register: register1,
    control1,
    formState: { errors: errors1 },
    reset: reset1,
    handleSubmit: handleSubmit1,
    setValue
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      VideoTestimonialsList((resp) => {
        dispatch(loader(false));
        if (resp.status) {
          console.log(resp.data, "VideoTestimonialsList");
          setListTestimonials(resp.data);
          var arr = [];
          for (var i = 0; i < resp.data.length; i++) {
            arr.push(resp.data[i].isActive);
          }
          setChecked(arr);
        }
      })
    );
  }, [refresher]);

  const changeStatus = (id, index) => {
    let data = {};
    let value = {
      isHidden: !checked[index],
    };
    data["_id"] = id;
    data["isActive"] = value.isHidden;
    console.log("value.isHidden", value.isHidden);
    dispatch(loader(true));
    dispatch(
      editVideoTestimonial(data, (response) => {
        if (response.status) {
          toast.success(response.message);
          dispatch(loader(false));
          setRefresher(!refresher);
        } else {
          dispatch(loader(false));
          toast.error(response.message);
        }
      })
    );
  };


  const testAddFn = (e) => {

    let formData = new FormData();

    formData.append("videoUrl", e.videoUrl);
    formData.append("thumbnail", imageFile);
    // let data = {};
    // data["videoUrl"] = e.videourl;
    // data["thumbnail"] = imageFile;
    dispatch(loader(true));
    dispatch(
      addVideoTestimonial(formData, (response) => {
        if (response.status) {
          toast.success(response.message);
          dispatch(loader(false));
          reset();
          handleClose();
          setPreviewImage("");
          setRefresher(!refresher);
        } else {
          dispatch(loader(false));
          toast.error(response.message);
        }
      })
    );
  };

  const editTestFn = (data) => {
    console.log(data)
    let formData = new FormData();
    if(data.videoUrl === editTestData.videoUrl){
      formData.append("videoUrl", editTestData.videoUrl);
    }else{
      formData.append("videoUrl", data.videoUrl);
    }

    formData.append("_id", editTestData._id);
    if (imageFile) {
      formData.append('thumbnail', imageFile);
    }
    dispatch(loader(true));
    dispatch(
      editVideoTestimonial(formData, (response) => {
        if (response.status) {
          toast.success(response.message);
          dispatch(loader(false));
          setRefresher(!refresher);
          setPreviewImage("");
          reset1();
          handleClose1();
          setImageFile(null)
          setEditTestData({})
        } else {
          dispatch(loader(false));
          toast.error(response.message);
        }
      })
    );
    // }
  };

  const deleteTestFn = (id) => {
    let data = {};
    data["_id"] = id;
    dispatch(loader(true));
    dispatch(
      deleteVideoTestimonial(data, (resp) => {
        if (resp.status) {
          dispatch(loader(false));
          setRefresher(!refresher);
          toast.success(resp.message);
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const handleProfilePic = (event) => {
    const selectedFile = event.target.files[0];
    setImageFile(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      //   setPreviewImage("");
    }
  };

  return (

    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-6 col-md-5">
              <h3>Video Testimonial List</h3>
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="">Video Testimonial</Link>
              </p>
            </div>
            <div className="col-6 col-md-7">
              <div className="Grn-Btnmanin">
                <button className="Grn-Btn" onClick={handleShow}>
                  add Video testimonial
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <Table striped hover>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Image</th>

                    <th>VideoUrl</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {listTestimonials.map((item, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        <img
                          src={item?.thumbnail}
                          alt=""
                          style={{ height: "40px" }}
                        />
                      </td>

                      <td className="DivWidth">{item?.videoUrl}</td>
                      <td >
                        <div className="d-flex">
                          <div className="">
                            <div className="OnOfF">
                              <Form>
                                <Form.Check // prettier-ignore
                                  type="switch"
                                  id="custom-switch"
                                  onChange={() => changeStatus(item._id, i)}
                                  checked={checked[i]}
                                />
                              </Form>
                            </div>
                          </div>
                          <div className="col-5">
                            <div className="Options pt-1">
                              <i>
                                <BsThreeDotsVertical />
                              </i>
                              <ul class="hidden-list tstLi">
                                <li
                                  onClick={() => {
                                    handleShow1(item, i);
                                  }}
                                >
                                  Edit
                                </li>
                                <li onClick={() => deleteTestFn(item._id, i)}>
                                  Delete
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Video Testimonial</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(testAddFn)}>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Add ThumbNail</Form.Label>

                  <div className="upLoadthunbnail">
                    {previewImage ? null : (
                      <label
                        htmlFor="file-upload"
                        className="file-upload-label"
                        style={{ paddingTop: "75px", paddingBottom: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}
                      >
                        Image Dimensions (270 x 170) <br /> Click here to upload
                      </label>
                    )}
                    <label htmlFor="imgupload">
                      <input
                        type="file"
                        id="imgupload"
                        onChange={handleProfilePic}
                        style={{ display: "none" }}
                        accept="image/*"
                      />
                      <i
                        style={{
                          display: previewImage ? "none" : "inline",
                        }}
                      >
                        <TbFileUpload className="uploadIconsvid" />
                      </i>
                      {previewImage && (
                        <img
                          src={previewImage ? previewImage : ""}
                          className="img-fluid"
                          alt="User Profile"
                          style={{
                            padding: "20px",
                            height: "100%",
                            width: "100%",
                          }}
                        />
                      )}
                    </label>
                  </div>

                </Form.Group>
              </div>
            </div>




            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Add Video Url</Form.Label>
              <Form.Control
                type="text"
                placeholder="www.youtube.com"
                {...register('videoUrl', {
                  required: 'Video URL is required',
                  pattern: {
                    value: /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/,
                    message: 'Invalid URL format'
                  }
                })} />
              {errors.videoUrl && <small style={{ color: 'red' }}>{errors.videoUrl.message}</small>}
            </Form.Group>
            <div className="d-flex justify-content-center">
              <button className="Grn-Btn Half" type="submit">
                add
              </button>
              <button
                className="WHt-Btn Half"
                type="button"
                onClick={handleClose}
              >
                cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* view modal */}
      <Modal show={showview} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Video Testimonial</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit1(editTestFn)}>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Edit ThumbNail</Form.Label>
                  <div className="upLoadthunbnail">
                    <label htmlFor="imgupload">
                      <input
                        type="file"
                        id="imgupload"
                        onChange={handleProfilePic}
                        style={{ display: "none" }}
                        accept="image/*"
                      />
                      {editTestDataImg && !previewImage && (
                        <img
                          src={editTestDataImg}
                          className="img-fluid"
                          alt="User Profile"
                          style={{
                            // display: editTestDataImg ? "block" : "none",
                            padding: "20px",
                            height: "100%",
                            width: "100%",
                            marginBottom: " -20px",
                          }}
                        />
                      )}

                      {previewImage && (
                        <img
                          src={previewImage ? previewImage : ""}
                          className="img-fluid"
                          alt="User Profile"
                          style={{
                            padding: "20px",
                            height: "100%",
                            width: "100%",
                            marginBottom: " -20px",
                          }}
                        />
                      )}
                      <TbFileUpload className="uploadIconsvid2" />
                    </label>

                  </div>
                  <label className="vidLabel">Image Dimensions (270 x 170)  <span className="spanVid">Click here to upload  </span>  </label>

                </Form.Group>
              </div>
            </div>

            <Form.Group
              className="mb-3 mt-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Edit Video Url</Form.Label>
              <Form.Control
                type="text"
                defaultValue={editTestDataVideo}
                onChange={(e) => setEditTestDataVideo(e.target.value)}
                {...register1('videoUrl', {
                  required: 'Video URL is required',
                  pattern: {
                    value: /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/,
                    message: 'Invalid URL format'
                  }
                })}
              />
              {errors1.videoUrl && <small style={{ color: 'red' }}>{errors1.videoUrl.message}</small>}
            </Form.Group>
            <div className="d-flex" style={{ justifyContent: "center" }}>
              <button className="Grn-Btn Half" type="submit">
                Update
              </button>
              <button
                className="WHt-Btn Half"
                type="button"
                onClick={() => setShowview(!showview)}
              >
                Cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>

  );
};
export default VideoTestimonial;
