import React, { useEffect, useRef, useState } from "react";
import "../../css/Topbar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { IoMdNotificationsOutline } from "react-icons/io";
import { FaUserAlt } from "react-icons/fa";
import { isLogin, loader } from "../../pages/redux/common";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginDetails, notifications } from "../../pages/redux/login";
import { toast } from "react-toastify";

const Topbar = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [notification, setNotification] = useState([]);
  const [selectedNotificationIndex, setSelectedNotificationIndex] =
    useState(null);
  const [open, setOpen] = useState(false);
  const menuRef = useRef();
  const notifyRef = useRef();

  const storedAdminName = localStorage.getItem("adminName");
  const adminName = storedAdminName
    ? storedAdminName.replace(/^"(.*)"$/, "$1")
    : "";

  const logOutFn = () => {
    dispatch(isLogin(false));
    navigate("/");
    localStorage.clear();
  };
  const profileFn = () => {
    dispatch(isLogin(false));
    navigate("/profile");
    // localStorage.clear()
  };
  const formatDate = (timestamp) => {
    const currentDate = new Date();
    const createdAtDate = new Date(timestamp);
    const timeDifference = currentDate - createdAtDate;

    // Convert time difference to seconds
    const seconds = Math.floor(timeDifference / 1000);

    if (seconds < 60) {
      return `${seconds}s ago`;
    }

    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
      return `${minutes}m ago`;
    }

    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
      return `${hours}h ago`;
    }

    const days = Math.floor(hours / 24);
    return `${days}d ago`;
  };


  const [notifiWatched, setNotifiWatched] = useState({
    count: 0,
    show : false
  })
  const listNotifications = () => {
    setOpen(!open);
    setNotifiWatched(prev => ({...prev, count : notification.length}))
  };
  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      notifications((resp) => {
        if (resp.status) {
          setNotification(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, []);
  useEffect(() => {
    const handleClick = (e) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(e.target) &&
        notifyRef.current &&
        !notifyRef.current.contains(e.target)
      ) {
        setOpen(false);
      }
    };

    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div className="topBar">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-9 col-md-8 col-sm-6">
                <div className="wrapperleft">{/* <h1>Admin Portal</h1> */}</div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 align-self-center d-sm-flex justify-content-sm-end">
                <div className="wrapperleft d-flex">
                  <div className="button-header pull-left notifyouter">
                    <div ref={notifyRef} className="Icons">
                      <a
                        class="notification"
                        onClick={() => listNotifications()}
                      >
                        <span>
                          <IoMdNotificationsOutline />
                        </span>
                        <span class="badge">{notifiWatched.count !== notification.length ? notification.length : ""}</span>
                      </a>
                    </div>

                    {open && (
                      <div ref={menuRef} className="modalnot">
                        <div className="nothead">
                          Notifications
                          <span className="notification-badge">
                            {notification.length}
                          </span>
                        </div>
                        {notification.map((item, index) => (
                          <div
                            key={item._id}
                            className={`notification-item ${
                              selectedNotificationIndex === index
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              setSelectedNotificationIndex(index);
                            }}
                          >
                            <span
                              className={`notmsg ${
                                selectedNotificationIndex === index
                                  ? "selected-message"
                                  : ""
                              }`}
                            >
                              {item.message.trim()}
                            </span>
                            <span className="nottime">
                              {formatDate(item.createdAt)}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="userDetails d-flex">
                    <span className="admin-name">
                      {" "}
                      <FaUserAlt />
                      {adminName}
                    </span>
                    <div className="Logout-btn">
                      <span onClick={logOutFn}>Logout</span>
                      <hr />
                      <div onClick={profileFn}>Profile</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
