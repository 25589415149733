import "./Changepassword.css";
import LImg from "../../assets/images/login-image.png";
import Logo from "../../assets/images/akareersity-logo.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { changePassword } from "../redux/login";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { toast } from "react-toastify";
import { AiOutlineLock, AiOutlineUnlock } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import React, { useState } from "react";

const Changepassword = () => {
  let navigate = useNavigate();

  const {
    register: register,
    formState: { errors: errors },
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur" });

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  const togglePasswordVisibility1 = () => {
    setPasswordVisible1((prevState) => !prevState);
  };

  let dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisible1, setPasswordVisible1] = useState(false);

  const signInFn = (data) => {
    dispatch(loader(true));
    dispatch(
      changePassword(data, (resp) => {
        if (resp.status) {
          toast.success(resp.message);
          navigate("/");
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };
  return (
    <>
      <div className="LoGinPaGe">
        <div className="container">
          <div className="MainDIV">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12 Logn">
                <div className="LoGInImage">
                  <img src={LImg} className="img-fluid" alt="" />
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12 Logn">
                <div className="LoginForm">
                  <div className="LogO">
                    <img src={Logo} className="img-fluid" alt="" />
                  </div>
                  <div className="FoRmLog">
                    <form onSubmit={handleSubmit(signInFn)}>
                      <h4>Change Password</h4>
                      <div className="col-12">
                        <InputGroup className="mb-3">
                          <Form.Control
                            type={passwordVisible ? "text" : "password"}
                            placeholder="New Password"
                            aria-describedby="basic-addon2"
                            name="newPassword"
                            id="newPassword"
                            {...register("newPassword", {
                              required: "password is required",
                            })}
                          />
                          <Button
                            variant="outline-secondary"
                            id="button-addon2"
                          >
                            <i onClick={togglePasswordVisibility}>
                              {passwordVisible ? (
                                <AiOutlineUnlock />
                              ) : (
                                <AiOutlineLock />
                              )}
                            </i>
                          </Button>
                        </InputGroup>
                        {errors.password && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.password.message}
                          </small>
                        )}
                      </div>
                      <div className="col-12">
                        <InputGroup className="mb-3">
                          <Form.Control
                            type={passwordVisible1 ? "text" : "password"}
                            placeholder="Confirm Password"
                            aria-describedby="basic-addon2"
                            name="confirmPassword"
                            id="confirmPassword"
                            {...register("confirmPassword", {
                              required: "password is required",
                            })}
                          />
                          <Button
                            variant="outline-secondary"
                            id="button-addon2"
                          >
                            <i onClick={togglePasswordVisibility1}>
                              {passwordVisible1 ? (
                                <AiOutlineUnlock />
                              ) : (
                                <AiOutlineLock />
                              )}
                            </i>
                          </Button>
                        </InputGroup>
                        {errors.password && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.password.message}
                          </small>
                        )}
                      </div>
                      <div className="col-12">
                        <button type="submit" className="Grn-Btn">
                          submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Changepassword;
