import { TbDeviceTabletPause, TbFileUpload } from "react-icons/tb";
import "./Banners.css";
import { Link, useNavigate, useParams } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Topbar from "../../components/topbar/Topbar";
import SideBar from "../../components/Sidebar/SideBar";
import { editLiveBanner, viewLiveBanner } from "../redux/liveprogram";
import { useDispatch } from "react-redux";
import { loader, isLogin } from "../redux/common";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";

const EditLiveProgramBanner = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let params = useParams();
  const [viewData, setViewData] = useState({});
  const [previewImage, setPreviewImage] = useState(null);
  const [imageFile, setImageFile] = useState("");
  const [selectedOption, setSelectedOption] = useState(""); 
  const [videoUrlValue, setVideoUrlValue] = useState(""); 

  useEffect(() => {
    let Id = params.id;
    let data = {};
    data["bannerId"] = Id;
    dispatch(loader(true));
    dispatch(
      viewLiveBanner(data, (resp) => {
        if (resp.status) {
          setViewData(resp.data);
          setSelectedOption(resp?.data?.bannerFor);
          setPreviewImage(resp?.data?.thumbnail);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, [params.id]);

  const {
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur" });

  function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    var arr = {};
    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        arr[key] = object1[key];
      }
    }
    return arr;
  }

  const bannerAdd = (data) => {
    var compareVal = {
      videoUrl: videoUrlValue,
      bannerFor: selectedOption,
    };
    var result = shallowEqual(compareVal, viewData);
    let formData = new FormData();
    formData.append("bannerId", params.id);
    if (result.videoUrl != "") {
      formData.append("videoUrl", videoUrlValue);
    }
    if (result != "") {
      formData.append("bannerFor", selectedOption);
    }
    if (imageFile != "") {
      formData.append("thumbnail", imageFile);
    }

    dispatch(loader(true));
    dispatch(
      editLiveBanner(formData, (resp) => {
        if (resp.status) {
          toast.success(resp.message);
          navigate("/live_program");
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const cancelBanner = () => {
    navigate("/live_program");
  };


  const handleProfilePic = (event) => {
    const selectedFile = event.target.files[0];
    setImageFile(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const videourlChange = (event) => {
    setVideoUrlValue(event.target.value);
  };

  return (


      <div className="Categorypage">
        <div className="PageTit ">
          <div className="container">
            <div className="row">
              <div className="col-10">
                <h3>Edit Live Program Banner</h3>
                <p>
                  <Link to="/dashboard">Dashboard</Link>{" "}
                  <i>
                    <AiOutlineRight />
                  </i>{" "}
                  <Link to="#">Content Update</Link>{" "}
                  <i>
                    <AiOutlineRight />
                  </i>{" "}
                  <Link to="/live_program">Live Program Banners</Link>
                </p>
              </div>
              <div className="col-2"></div>
            </div>
          </div>
        </div>
        <div className="CateCont">
          <div className="container">
            <div className="row">
              <div className="CateGorTab">
                <div className="TittlE">
                  <h4>Edit Live Program Banner</h4>
                </div>
                <div className="ExpertSBacKG">
                  <form onSubmit={handleSubmit(bannerAdd)}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="ThumBnail banner">
                          <div className="upLoad upLoaded">
                            <label
                              htmlFor="imgupload"
                              style={{
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: previewImage ? "none" : "block",
                                  width: "100%",
                                }}
                              >
                                <span className="text-center">
                                  <TbFileUpload className="uploadIcons" />
                                </span>
                              </div>
                              <input
                                type="file"
                                id="imgupload"
                                onChange={handleProfilePic}
                                style={{ display: "none" }}
                              />
                              <div
                                style={{
                                  display: previewImage ? "none" : "block",
                                }}
                              >
                                <i style={{ display: "none" }}>
                                  <TbFileUpload className="uploadIcon" />
                                </i>
                                <p>Upload Thumbnail</p>
                              </div>
                              {previewImage && (
                                <img
                                  src={previewImage}
                                  className="img-fluid upLoad"
                                  alt="User Profile"
                                />
                              )}
                            </label>
                          </div>
                        </div>
                      
                      </div>
                      <div className="col-lg-12 pt-3">
                        <div className="SHortsForm banner">
                          <label>Video URL</label>
                          <br></br>
                          <input
                            type="text"
                            className="FormShorts"
                            name="videoUrl"
                            defaultValue={viewData.videoUrl}
                            onChange={videourlChange}
                          />
                        </div>
                        
                        <div className="row">
                          <div className="col-lg-3 col-md-3">
                            <label className="professionbanner">
                              <input
                                type="radio"
                                name="options"
                                value="Student"
                                checked={selectedOption === "Student"}
                                onChange={handleOptionChange}
                              />
                              Student
                            </label>
                          </div>
                          <div className="col-lg-3 col-md-3">
                            <label className="professionbanner">
                              <input
                                type="radio"
                                name="options"
                                value="Working"
                                checked={selectedOption === "Working"}
                                onChange={handleOptionChange}
                              />
                              Working
                            </label>
                          </div>
                        </div>

                        <div className="row justify-content-center">
                          <div className="col-lg-2 col-md-4">
                            <div className="SHortsForm1">
                              <button className="Grn-Btn" type="submit">
                                Update
                              </button>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-4">
                            <div className="SHortsForm1">
                              <button
                                className="WHt-Btn"
                                type="button"
                                onClick={cancelBanner}
                              >
                                cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
  );
};
export default EditLiveProgramBanner;
