import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./Subscription.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import Select from "react-select";
import { BsThreeDotsVertical } from "react-icons/bs";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { loader } from "../redux/common";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  activeCourseBundleList,
  addSubscriptionPlan,
  deleteSubscriptionPlan,
  editSubscriptionPlan,
  subscriptionPlanList,
} from "../redux/Subscription";

const SubscriptionPlan = () => {
  let dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showview1, setShowview1] = useState(false);
  const [checked, setChecked] = useState([]);
  const [ftrrows, setFtrrows] = useState([1]);
  const [ftrIncludes, setFtrIncludes] = useState([]);
  const [bundleIdList, setBundleIdList] = useState([]);
  const [options, setOptions] = useState([]);
  const [planData, setPlanData] = useState([]);
  const [planftrData, setPlanftrData] = useState([]);
  const [planbundleData, setPlanbundleData] = useState([]);
  const [bundleTitle, setBundleTitle] = useState([]);
  const [ftrs, setFtrs] = useState([]);
  const [crsbndl, setCrsbndls] = useState([]);
  let [refresher, setRefresher] = useState(true);
  let [planID, setPlanID] = useState();

  const handleClose = () => {
    setShow(false);
    reset();
  };
  const handleClose2 = () => setShowview1(false);
  const handleShow = () => {
    reset();
    setFtrIncludes([]);
    setOptions([]);
    setShow(true);
  };
  const schema = yup.object().shape({
    features: yup.array().of(yup.string().required("Feature is required")),
    courseBundles: yup
      .array()
      .of(yup.string().required("Course Bundles is required")),
    oneMonthPrice: yup.number().required("Price for 1 Month is required"),
    threeMonthPrice: yup.number().required("Price for 3 Months is required"),
    sixMonthPrice: yup.number().required("Price for 6 Months is required"),
    twelveMonthPrice: yup.number().required("Price for 12 Months is required"),
  });

  const handleShow2 = (id) => {
    const selectedPlan = planData.find((plan) => plan._id === id);

    if (selectedPlan) {
      const selectedBundleTitles = selectedPlan.courseBundles.map(
        (courseBundleId) => {
          const bundle = bundleTitle.find(
            (title) => title._id === courseBundleId
          );
          return bundle ? bundle.title : "Title not found";
        }
      );
      const uniqueCourseBundles = Array.from(
        new Set([...selectedPlan.courseBundles])
      );
      setPlanbundleData(uniqueCourseBundles);
      setPlanftrData([selectedPlan]);

      setShowview1(true);
      setPlanID(id);
      setFtrs([...selectedPlan.features]);
      setPlanbundleData(uniqueCourseBundles);
      setCrsbndls([...selectedPlan.courseBundles]);
    } else {
      console.error("Plan not found");
    }
  };
  const crsbndlOptions = crsbndl.map((bundleId) => {
    const bundle = bundleIdList.find((bundle) => bundle.value === bundleId);
    return { value: bundleId, label: bundle ? bundle.label : bundleId };
  });

  const uniqueCrsbndlOptions = [...new Set(crsbndlOptions)];
  const filteredOptions = bundleIdList.filter((option) => {
    return !crsbndlOptions.find(
      (selectedOption) => selectedOption.value === option.value
    );
  });

  const deletePlan = (id, index) => {
    let data = {};
    data["subscriptionPlanId"] = id;

    dispatch(loader(true));
    dispatch(
      deleteSubscriptionPlan(data, (resp) => {
        if (resp.status) {
          dispatch(loader(false));
          setRefresher(!refresher);
          toast.success(resp.message);
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const editPlan = () => {
    const oneMonthPrice = parseFloat(
      planftrData && planftrData[0].oneMonthPrice
        ? planftrData[0].oneMonthPrice
        : 0
    );
    const threeMonthPrice = parseFloat(
      planftrData && planftrData[0].threeMonthPrice
        ? planftrData[0].threeMonthPrice
        : 0
    );
    const sixMonthPrice = parseFloat(
      planftrData && planftrData[0].sixMonthPrice
        ? planftrData[0].sixMonthPrice
        : 0
    );
    const twelveMonthPrice = parseFloat(
      planftrData && planftrData[0].twelveMonthPrice
        ? planftrData[0].twelveMonthPrice
        : 0
    );
    const validCourseBundles = planftrData[0].courseBundles.filter(
      (bundle) =>
        bundle.value !== "Title not found" && bundle.label !== "Title not found"
    );
    const courseBundleValues = validCourseBundles.map((bundle) => bundle);

    let data = {};

    data["courseBundles"] = planbundleData;
    data["subscriptionPlanId"] = planID;
    data["planName"] = planftrData[0].planName;
    data["features"] = planftrData[0].features;
    data["oneMonthPrice"] = oneMonthPrice;
    data["threeMonthPrice"] = threeMonthPrice;
    data["sixMonthPrice"] = sixMonthPrice;
    data["twelveMonthPrice"] = twelveMonthPrice;

    dispatch(loader(true));
    dispatch(
      editSubscriptionPlan(data, (resp) => {
        if (resp.status) {
          dispatch(loader(false));
          toast.success(resp.message);
          setRefresher(!refresher);
          setShowview1(false);
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const {
    register: register,
    control,
    formState: { errors: errors },
    reset: reset,
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur", resolver: yupResolver(schema) });

  const {
    register: register1,
    control1,
    formState: { errors: errors1 },
    reset: reset1,
    handleSubmit: handleSubmit1,
  } = useForm({ mode: "onBlur" });

  const addSubscription = (data) => {
    var speciality = options.map((x) => x.value);

    const oneMonthPrice = parseFloat(data.oneMonthPrice);
    const threeMonthPrice = parseFloat(data.threeMonthPrice);
    const sixMonthPrice = parseFloat(data.sixMonthPrice);
    const twelveMonthPrice = parseFloat(data.twelveMonthPrice);

    let subsData = {
      planName: data.planName,
      oneMonthPrice: oneMonthPrice,
      threeMonthPrice: threeMonthPrice,
      sixMonthPrice: sixMonthPrice,
      twelveMonthPrice: twelveMonthPrice,
      features: ftrIncludes,
      courseBundles: speciality,
    };
    dispatch(loader(true));
    dispatch(
      addSubscriptionPlan(subsData, (resp) => {
        if (resp.status) {
          dispatch(loader(false));
          setShow(false);
          setRefresher(!refresher);
          reset();
          toast.success(resp.message);
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const mapDataToOptions = (data) => {
    return data.map((item) => ({
      value: item._id,
      label: item.title, // Assuming 'title' field holds the display value
    }));
  };

  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      activeCourseBundleList((resp) => {
        if (resp.status) {
          const options = mapDataToOptions(resp.data);
          setBundleIdList(options);
          setBundleTitle(resp.data);

          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );

    dispatch(
      subscriptionPlanList((resp) => {
        if (resp.status) {
          setPlanData(resp.data);
          setPlanftrData([resp.data[0]]);

          var arr = [];
          for (var i = 0; i < resp.data.length; i++) {
            arr.push(resp.data[i].isActive);
          }
          setChecked(arr);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, [refresher]);

  const changeStatus = (id, index) => {
    let data = {};
    let value = {
      isActive: !checked[index],
    };

    data["subscriptionPlanId"] = id;
    data["isActive"] = value.isActive;

    dispatch(loader(true));
    dispatch(
      editSubscriptionPlan(data, (response) => {
        if (response.status) {
          toast.success(response.message);
          dispatch(loader(false));
          setRefresher(!refresher);
        } else {
          dispatch(loader(false));
          toast.error(response.message);
        }
      })
    );
  };
  const addRow1 = () => {
    const newRow = ftrrows.length + 1;
    setFtrrows([...ftrrows, newRow]);
    setFtrIncludes([...ftrIncludes, ""]);
  };

  const removeRow1 = () => {
    if (ftrrows.length > 1) {
      const updatedRows = ftrrows.slice(0, -1);
      setFtrrows(updatedRows);
      setFtrIncludes(ftrIncludes.slice(0, -1));
    }
  };
  const handleChange = (e) => {
    setOptions(Array.isArray(e) ? e.map((x) => x) : []);
  };

  const featureInclude = (event, index) => {
    const { value } = event.target;
    setFtrIncludes((prevftrIncludes) => {
      const updatedValues = [...prevftrIncludes];
      updatedValues[index] = value;
      return updatedValues;
    });
  };

  const handlePlanNameChange = (e, index) => {
    const updatedBundleData = [...planftrData];
    updatedBundleData[index].planName = e.target.value;
    setPlanftrData(updatedBundleData);
  };

  const handleCourseBundleChange = (newvalue, index) => {
    const bundleValue = newvalue.map((item) => item.value);
    setPlanbundleData(bundleValue);
  };

  const handleCourseFeatureChange = (event, index, featureIndex) => {
    const updatedFeatureData = [...planftrData];
    updatedFeatureData[index].features[featureIndex] = event.target.value;
    setPlanftrData(updatedFeatureData);
  };

  const addFeatureRow = (index) => {
    const updatedFeatureData = [...planftrData];
    updatedFeatureData[index].features.push("");
    setPlanftrData(updatedFeatureData);
  };

  const removeFeatureRow = (index, featureIndex) => {
    const updatedFeatureData = [...planftrData];
    if (updatedFeatureData[index].features.length === 1) {
      return;
    }
    updatedFeatureData[index].features.splice(featureIndex, 1);
    setPlanftrData(updatedFeatureData);
  };

  const handleOneMonthChange = (e, index) => {
    const updatedBundleData = [...planftrData];
    updatedBundleData[index].oneMonthPrice = e.target.value;
    setPlanftrData(updatedBundleData);
  };

  const handleThreeMonthChange = (e, index) => {
    const updatedBundleData = [...planftrData];
    updatedBundleData[index].threeMonthPrice = e.target.value;
    setPlanftrData(updatedBundleData);
  };

  const handleSixMonthChange = (e, index) => {
    const updatedBundleData = [...planftrData];
    updatedBundleData[index].sixMonthPrice = e.target.value;
    setPlanftrData(updatedBundleData);
  };

  const handleTwelveMonthChange = (e, index) => {
    const updatedBundleData = [...planftrData];
    updatedBundleData[index].twelveMonthPrice = e.target.value;
    setPlanftrData(updatedBundleData);
  };

  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-md-5">
              <h3>Subscription Plan</h3>
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/subscription_plan">Subscription Plan</Link>
              </p>
            </div>
            <div className="col-xl-5 col-md-7">
              <div className="Grn-Btnmanin plan">
                <button className="Grn-Btn plan" onClick={handleShow}>
                  Add Subscription Plan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <Table striped hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Plan Name</th>
                    <th style={{ paddingLeft: "20px" }}>Price</th>
                    <th>Features</th>
                    <th>Course Bundle</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {planData.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.planName}</td>

                      <td style={{ padding: "0px" }}>
                        <div className="price-groupmain">
                          <div className="price-group">
                            <div className="price-group1">
                              1M - {item.oneMonthPrice}
                            </div>
                            <div className="price-group2">
                              3M - {item.threeMonthPrice}
                            </div>
                          </div>
                          <div className="price-group">
                            <div className="price-group3">
                              6M - {item.sixMonthPrice}
                            </div>
                            <div className="price-group4">
                              12M - {item.twelveMonthPrice}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <ul style={{ padding: "0px" }}>
                          {item.features.map((feature, featureIndex) => (
                            <li key={featureIndex}>{feature}</li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        <ul style={{ padding: "0px" }}>
                          {item.courseBundles.map((bundleId, bundleIndex) => {
                            const bundle = bundleTitle.find(
                              (title) => title._id === bundleId
                            );
                            return bundle ? (
                              <li key={bundleIndex}>{bundle.title}</li>
                            ) : null;
                          })}
                        </ul>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          <div className="">
                            <div className="OnOfF">
                              <Form>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  onChange={() => changeStatus(item._id, index)}
                                  checked={checked[index]}
                                />
                              </Form>
                            </div>
                          </div>
                          <div className="col-5">
                            <div className="Options pt-1">
                              <i>
                                <BsThreeDotsVertical />
                              </i>
                              <ul class="hidden-list subLi">
                                <li onClick={() => handleShow2(item._id)}>
                                  Edit
                                </li>
                                <li onClick={() => deletePlan(item._id, index)}>
                                  Delete
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Subscription Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body className="subBod">
          <Form onSubmit={handleSubmit(addSubscription)}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Plan Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                // autoFocus
                // value={categoryName}
                // onChange={(e) => setCategoryName(e.target.value)}
                name="name"
                // id="name"
                {...register("planName", {
                  required: " PlanName is required",
                })}
              />
              {errors.planName && (
                <small style={{ color: "red" }}>
                  {errors.planName.message}
                </small>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Feature Include</Form.Label>

              <div className="">
                {ftrrows.map((row, index) => (
                  <div className="feature-include d-flex addcourse">
                    <input
                      type="text"
                      className="add-course"
                      onChange={(event) => featureInclude(event, index)}
                      value={ftrIncludes[index]}
                      // {...register("features", {
                      //     required: "Feature is required",
                      // })}
                    />

                    <p className="Add-row" onClick={addRow1}>
                      +
                    </p>

                    <p className="del-row" onClick={removeRow1}>
                      -
                    </p>
                  </div>
                ))}
              </div>
              {errors.features && (
                <small style={{ color: "red" }}>
                  {errors.features.message}
                </small>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div className="col-3">
                <Form.Label>Select Course Bundle</Form.Label>
              </div>
              <div className="col-12">
                <Select
                  isMulti
                  value={options}
                  onChange={handleChange}
                  options={bundleIdList}
                />
              </div>
              {errors.courseBundles && (
                <small style={{ color: "red" }}>
                  {errors.courseBundles.message}
                </small>
              )}
            </Form.Group>
            <Form.Label className="mt-2 ">Price</Form.Label>
            <div className="row ">
              <div className="col-xl-12 d-flex gap-3 ">
                <Form.Group
                  className="mb-3 gap-2 d-flex text-center align-items-center"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="w-100 mb-0">1 Month</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder=""
                    name="name"
                    className="w-75"
                    {...register("oneMonthPrice", {
                      // required: "Price is required",
                    })}
                  />
                  {/* {errors.oneMonthPrice && (
                                    <small style={{ color: "red" }}>{errors.oneMonthPrice.message}</small>
                                )} */}
                </Form.Group>
                <Form.Group
                  className="mb-3  d-flex gap-2 text-center align-items-center"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="w-100 mb-0">3 Months</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder=""
                    name="name"
                    className="w-75"
                    {...register("threeMonthPrice", {
                      // required: "Price is required",
                    })}
                  />
                  {/* {errors.threeMonthPrice && (
                                    <small style={{ color: "red" }}>{errors.threeMonthPrice.message}</small>
                                )} */}
                </Form.Group>
                <Form.Group
                  className="mb-3  d-flex gap-2 text-center align-items-center"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="w-100 mb-0">6 Months</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder=""
                    name="name"
                    className="w-75"
                    {...register("sixMonthPrice", {
                      // required: "Price is required",
                    })}
                  />
                  {/* {errors.sixMonthPrice && (
                                    <small style={{ color: "red" }}>{errors.sixMonthPrice.message}</small>
                                )} */}
                </Form.Group>
                <Form.Group
                  className="mb-3 d-flex  gap-2 text-center align-items-center"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="w-100 mb-0">12 Months</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder=""
                    name="name"
                    className="w-75"
                    {...register("twelveMonthPrice", {
                      //     required: "Price is required",
                    })}
                  />
                  {/* {errors.twelveMonthPrice && (
                                    <small style={{ color: "red" }}>{errors.twelveMonthPrice.message}</small>
                                )} */}
                </Form.Group>
              </div>
            </div>
            {(errors.oneMonthPrice ||
              errors.threeMonthPrice ||
              errors.sixMonthPrice ||
              errors.twelveMonthPrice) && (
              <small style={{ color: "red" }}>Price is required</small>
            )}

            <div className="d-flex justify-content-center mt-5 mb-3">
              <button className="Grn-Btn Half" type="submit">
                add
              </button>
              <button
                className="WHt-Btn Half"
                type="button"
                onClick={handleClose}
              >
                cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showview1} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Subscription Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit1(editPlan)}>
            {planftrData.map((featureData, index) => (
              <div key={index}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Plan Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    defaultValue={featureData.planName}
                    onChange={(newValue) => {
                      handlePlanNameChange(newValue, index);
                    }}
                  />

                  {errors1.planName && (
                    <small style={{ color: "red" }}>
                      {errors1.planName.message}
                    </small>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Feature Include</Form.Label>
                  <div className="">
                    {featureData.features.map((feature, featureIndex) => (
                      <div
                        className="feature-include d-flex addcourse"
                        key={featureIndex}
                      >
                        <input
                          type="text"
                          className="add-course"
                          onChange={(e) => {
                            handleCourseFeatureChange(e, index, featureIndex);
                          }}
                          value={feature}
                        />
                        <p
                          className="Add-row"
                          onClick={() => addFeatureRow(index)}
                        >
                          +
                        </p>
                        <p
                          className="del-row"
                          onClick={() => removeFeatureRow(index, featureIndex)}
                        >
                          -
                        </p>
                      </div>
                    ))}
                  </div>
                  {errors1.features && (
                    <small style={{ color: "red" }}>
                      {errors1.features.message}
                    </small>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <div className="col-3">
                    <Form.Label>Select Course Bundle</Form.Label>
                  </div>

                  <div className="col-12">
                    <Select
                      isMulti
                      isSearchable
                      defaultValue={uniqueCrsbndlOptions}
                      options={filteredOptions}
                      onChange={(newValue) => {
                        handleCourseBundleChange(newValue, index);
                      }}
                    />
                  </div>

                  {errors1.courseBundles && (
                    <small style={{ color: "red" }}>
                      {errors1.courseBundles.message}
                    </small>
                  )}
                </Form.Group>

                <Form.Label className="mt-2 ">Price</Form.Label>
                <div className="row ">
                  <div className="col-xl-12 d-flex gap-3 ">
                    <Form.Group
                      className="mb-3 gap-2 d-flex text-center align-items-center"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="w-100 mb-0">1 Month</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder=""
                        name="name"
                        className="w-75"
                        defaultValue={featureData.oneMonthPrice}
                        onChange={(newValue) => {
                          handleOneMonthChange(newValue, index);
                        }}
                      />
                      {/* {errors.oneMonthPrice && (
                                                    <small style={{ color: "red" }}>{errors.oneMonthPrice.message}</small>
                                                )} */}
                    </Form.Group>
                    <Form.Group
                      className="mb-3  d-flex gap-2 text-center align-items-center"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="w-100 mb-0">3 Months</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder=""
                        name="name"
                        className="w-75"
                        defaultValue={featureData.threeMonthPrice}
                        onChange={(newValue) => {
                          handleThreeMonthChange(newValue, index);
                        }}
                      />
                      {/* {errors.threeMonthPrice && (
                                                    <small style={{ color: "red" }}>{errors.threeMonthPrice.message}</small>
                                                )} */}
                    </Form.Group>
                    <Form.Group
                      className="mb-3  d-flex gap-2 text-center align-items-center"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="w-100 mb-0">6 Months</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder=""
                        name="name"
                        className="w-75"
                        defaultValue={featureData.sixMonthPrice}
                        onChange={(newValue) => {
                          handleSixMonthChange(newValue, index);
                        }}
                      />
                      {/* {errors.sixMonthPrice && (
                                                    <small style={{ color: "red" }}>{errors.sixMonthPrice.message}</small>
                                                )} */}
                    </Form.Group>
                    <Form.Group
                      className="mb-3 d-flex  gap-2 text-center align-items-center"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="w-100 mb-0">12 Months</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder=""
                        name="name"
                        className="w-75"
                        defaultValue={featureData.twelveMonthPrice}
                        onChange={(newValue) => {
                          handleTwelveMonthChange(newValue, index);
                        }}
                      />
                      {/* {errors.twelveMonthPrice && (
                                                    <small style={{ color: "red" }}>{errors.twelveMonthPrice.message}</small>
                                                )} */}
                    </Form.Group>
                    {Object.keys(errors1).length > 0 && (
                      <small style={{ color: "red" }}>Price is required</small>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <button className="Grn-Btn Half" type="submit">
                    Update
                  </button>
                  <button
                    className="WHt-Btn Half"
                    type="button"
                    onClick={() => setShowview1(false)}
                  >
                    cancel
                  </button>
                </div>
              </div>
            ))}
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default SubscriptionPlan;
