import React, { useState } from "react";
import "./Login.css";
import LImg from "../../assets/images/login-image.png";
import Logo from "../../assets/images/akareersity-logo.png";
import Logo2 from "../../assets/images/logo.png";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { AiOutlineUser } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { loginDetails } from "../redux/login";
import { useDispatch, useSelector } from "react-redux";
import { loader, isLogin, setAuthToggler } from "../redux/common";
import { toast } from "react-toastify";
import { AiOutlineLock, AiOutlineUnlock } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { educatorInfo, profileInfo } from "../redux/Educators";

const Login = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  let authToggler = useSelector((state) => state.apiReducer.authToggler);
  const {
    register: register,
    formState: { errors: errors },
    reset5,
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur" });

  const signInFn = (data) => {
    dispatch(loader(true));
    dispatch(
      loginDetails(data, (resp) => {
        if (resp.status) {
          dispatch(setAuthToggler(!authToggler));
          toast.success("login successfully");
          localStorage.setItem("adminOAuth", "Bearer" + " " + resp.origin);
          localStorage.setItem("userType", resp.type);
          localStorage.setItem("adminName", resp.name);
          localStorage.setItem("isLogin", true);
          dispatch(isLogin(true));
          const validSections = resp.permissions.filter(
            (permission) => permission.permissions.length > 0
          );
          const userPermissions = validSections.map((section) => ({
            section: section.section,
            permissions: section.permissions,
          }));
          dispatch(
            profileInfo((resp) => {
              dispatch(loader(false));
              if (resp.status) {
                localStorage.setItem("profileId", JSON.stringify(resp.data));
              } else {
                dispatch(loader(false));
                //toast.error(resp.message);
              }
            })
          );
          if (validSections.length > 0) {
            localStorage.setItem(
              "sidebarSections",
              JSON.stringify(validSections)
            );
            localStorage.setItem(
              "userPermissions",
              JSON.stringify(userPermissions)
            );

            if (
              resp.type === "educator" &&
              validSections.some((section) => section.section === "courses")
            ) {
              dispatch(
                educatorInfo({ email: data.email }, (profileResp) => {
                  if (profileResp.status) {
                    localStorage.setItem(
                      "profileDetails",
                      JSON.stringify(profileResp.data._id)
                    );
                    localStorage.setItem(
                      "profileDet",
                      JSON.stringify(profileResp.data)
                    );
                    localStorage.setItem(
                      "profileName",
                      JSON.stringify(profileResp.data.name)
                    );
                    dispatch(loader(false));
                    dispatch(isLogin(true));
                    navigate("/Courses");
                  } else {
                    dispatch(loader(false));
                    toast.error(profileResp.message);
                  }
                })
              );
            } else {
              dispatch(loader(false));
              dispatch(isLogin(true));
              navigate("/dashboard");
            }
          } else {
            dispatch(loader(false));
            toast.error("You do not have access to any sections.");
          }
        } else {
          alert(resp.message);
          dispatch(loader(false));
        }
      })
    );
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  return (
    <>
      <div className="LoGinPaGe">
        <div className="container">
          <div className="MainDIV">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12 Logn">
                <div className="LoGInImage">
                  <img src={LImg} className="img-fluid" alt="" />
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12 Logn">
                <div className="LoginForm">
                  <div className="LogO">
                    <img src={Logo2} className="img-fluid logo-size" alt="" />
                  </div>
                  <div className="FoRmLog">
                    <form onSubmit={handleSubmit(signInFn)}>
                      <h4>Admin Login</h4>
                      <div className="col-12">
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="email"
                            placeholder="Email Address"
                            aria-describedby="basic-addon2"
                            name="email"
                            id="email"
                            {...register("email", {
                              required: "Email is required",
                            })}
                          />

                          <InputGroup.Text id="basic-addon1">
                            <AiOutlineUser />
                          </InputGroup.Text>
                        </InputGroup>
                        {errors.email && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.email.message}
                          </small>
                        )}
                      </div>
                      <div className="col-12">
                        <InputGroup className="mb-3">
                          <Form.Control
                            type={passwordVisible ? "text" : "password"}
                            placeholder="Password"
                            aria-describedby="basic-addon2"
                            name="password"
                            id="password"
                            {...register("password", {
                              required: "password is required",
                            })}
                          />
                          <InputGroup.Text
                            id="basic-addon1"
                            onClick={togglePasswordVisibility}
                            style={{ cursor: "pointer" }}
                          >
                            {passwordVisible ? (
                              <AiOutlineUnlock />
                            ) : (
                              <AiOutlineLock />
                            )}
                          </InputGroup.Text>
                        </InputGroup>
                        {errors.password && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.password.message}
                          </small>
                        )}
                      </div>
                      <div className="col-12">
                        <button type="submit" className="Grn-Btn">
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
