import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./Corporate.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import InputGroup from "react-bootstrap/InputGroup";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  addCategory,
  CategoryList,
  viewCategory,
  editCategory,
  changeStatusCategoty,
  deleteCategory,
} from "../redux/login";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import helper from "../../helper/axiosHelper";

const Corporate = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show3, setShow3] = useState(false);
  const [showview, setShowview] = useState(false);
  const [showview1, setShowview1] = useState(false);
  const [checked, setChecked] = useState([]);
  const [name, setName] = useState("");
  const [editName, setEditName] = useState("");
  const [editDesc, setEditDesc] = useState("");
  let [refresher, setRefresher] = useState(true);
  let [viewData, setViewData] = useState({});
  let [categoryID, setCategoryID] = useState();
  const [corporates, setcorporates] = useState([]);

  const handleClose = () => {
    setShow(false);
    reset();
  };
  const handleClose2 = () => setShowview1(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleShow3 = () => {
    setShow3(true);
  };
  const handleClose3 = () => {
    setShow3(false);
    reset();
  };

  const handleClose1 = () => setShowview(false);
  const handleShow1 = (id) => {
    let data = id;
    setShowview(true);
    dispatch(loader(true));
    dispatch(
      viewCategory(data, (resp) => {
        if (resp.status) {
          setViewData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const update_corporate = (id) => {
    const selectedCorporate = corporates.find(
      (corporate) => corporate._id === id
    );

    setCategoryID(id);

    setEditName(selectedCorporate.corporateName);
    setEditDesc(selectedCorporate.corporateCode);
    setShowview1(true);
  };

  const deleteCorporate = async (id) => {
    let data = { corporateId: id };

    try {
      dispatch(loader(true));
      const response = await helper.postData(
        helper.baseUrl() + "deleteCorporate",
        data
      );

      if (response && response.data) {
        console.log("Corporate deleted successfully:", response.data);
        toast.success("Corporate deleted successfully");
        list_corporates();
      } else {
        toast.error("Failed to delete corporate");
      }
    } catch (error) {
      console.error("Error deleting corporate:", error);
      toast.error("Failed to delete corporate");
    } finally {
      dispatch(loader(false));
    }
  };

  const submitEditCategory = async (e) => {
    e.preventDefault();
    const data = {
      corporateId: categoryID,
      corporateName: editName,
      corporateCode: editDesc,
    };

    try {
      const response = await helper.postData(
        helper.baseUrl() + "editCorporate",
        data
      );

      if (response && response.data) {
        console.log("Corporate updated successfully:", response.data);
        toast.success("Corporate updated successfully");
        setShowview1(false);
        list_corporates();
      }
    } catch (error) {
      console.error("Error updating corporate:", error);
      toast.error("Failed to update corporate");
    } finally {
      dispatch(loader(false));
    }
  };

  const [categoryData, setCategoryData] = useState([]);

  const {
    register: register,
    formState: { errors: errors },
    reset: reset,
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur" });

  const {
    formState: { errors: errors1 },
  } = useForm({ mode: "onBlur" });

  const categoryAdd = (data) => {
    reset();
    dispatch(loader(true));
    dispatch(
      addCategory(data, (resp) => {
        if (resp.status) {
          setShow(false);
          setRefresher(!refresher);
          toast.success(resp.message);
          setName(" ");
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      CategoryList((resp) => {
        if (resp.status) {
          setCategoryData(resp.data);
          var arr = [];
          for (var i = 0; i < resp.data.length; i++) {
            arr.push(resp.data[i].isHidden);
          }
          setChecked(arr);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, [refresher]);

  const changeStatus = (id, index) => {
    let data = {};
    let value = {
      isHidden: !checked[index],
    };

    data["_id"] = id;
    data["isHidden"] = value.isHidden;

    dispatch(loader(true));
    dispatch(
      changeStatusCategoty(data, (response) => {
        if (response.status) {
          toast.success(response.message);
          setRefresher(!refresher);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(response.message);
        }
      })
    );
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const alphanumericRegex = /^[a-zA-Z][a-zA-Z0-9 ]*$/;
    if (alphanumericRegex.test(inputValue) || inputValue === "") {
      setName(inputValue);
    }
  };
  const handleInputChange1 = (value) => {
    const inputValue = value;
    const alphanumericRegex = /^[a-zA-Z][a-zA-Z0-9 ]*$/;
    if (alphanumericRegex.test(inputValue) || inputValue === "") {
      setEditName(inputValue);
    }
  };

  //api call

  const list_corporates = async () => {
    try {
      const response = await helper.postData(
        helper.baseUrl() + "getCorporates"
      );
      console.log("Corporates Data:", response.data.corporates);
      setcorporates(response.data.corporates);
    } catch (error) {
      console.error("Error fetching corporates:", error);
    }
  };

  const addCorporate = async (data) => {
    try {
      const corporateData = {
        corporateName: data.corporateName,
        corporateCode: data.corporateCode,
      };
      const response = await helper.postData(
        helper.baseUrl() + "createCorporate",
        corporateData
      );

      if (!response?.response?.data?.error) {
        toast.success("Corporate Added successfully");
        handleClose();
        list_corporates();
      } else {
        toast.error(response?.response?.data?.error);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }
  };

  useEffect(() => {
    list_corporates();
  }, []);

  const submitBulkUsers = async (data) => {
    const corporateId = data.corporateName;
    const formData = new FormData();

    // Ensure the file is selected and append it to FormData
    if (data.corporateCode && data.corporateCode.length > 0) {
      formData.append("file", data.corporateCode[0]);
    } else {
      console.error("No file selected");
      toast.error("Please select a file to upload");
      return;
    }

    console.log([...formData]); // Verify FormData content

    try {
      const response = await helper.postFormData(
        helper.baseUrl() + `uploadCorporateUsers?corporateId=${corporateId}`,
        formData
      );

      if (response && response?.data?.status === true) {
        console.log("Users uploaded successfully:", response.data);
        toast.success("Users uploaded successfully");
        setShow3(false);
        list_corporates();
      } else {
        toast.error(
          response?.response?.data?.message || "Failed to upload users"
        );
      }
    } catch (error) {
      console.error("Error uploading users:", error);
      toast.error("Failed to upload users");
    } finally {
      dispatch(loader(false));
    }
  };

  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-md-5">
              <h3>Corporate List</h3>
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/category">Corporate</Link>
              </p>
            </div>
            <div className="col-xl-5 col-md-7">
              <div
                className="Grn-Btnmanin"
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "end",
                }}
              >
                <button className="Grn-Btn" onClick={handleShow}>
                  add Corporate
                </button>
                <button className="Grn-Btn" onClick={handleShow3}>
                  Upload CSV
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <Table striped hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Corporate Name</th>
                    <th>Corporate Code</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {corporates.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.corporateName}</td>
                      <td>{item.corporateCode}</td>
                      <td>
                        <div className=" justify-content-center">
                          {/* <div className="">
                            <div className="OnOfF">
                              <Form>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  onChange={() => changeStatus(item._id, index)}
                                  checked={checked[index]}
                                />
                              </Form>
                            </div>
                          </div> */}
                          <div className="col-3">
                            <div className="Options pt-1">
                              <i>
                                <BsThreeDotsVertical />
                              </i>
                              <ul class="hidden-list catLi">
                                {/* <li onClick={() => handleShow1(item._id)}>
                                  View
                                </li> */}
                                <li onClick={() => update_corporate(item._id)}>
                                  Edit
                                </li>
                                <li onClick={() => deleteCorporate(item._id)}>
                                  Delete
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>Add Bulk Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(submitBulkUsers)}>
            <Form.Group className="mb-3" controlId="formCorporateName">
              <Form.Label>Select Corporate</Form.Label>
              <Form.Select
                name="corporateName"
                {...register("corporateName", {
                  required: "Corporate is required",
                })}
                onChange={handleInputChange}
              >
                <option value="">Select Corporate</option>
                {corporates.map((corporate, index) => (
                  <option key={index} value={corporate._id}>
                    {corporate.corporateName}
                  </option>
                ))}
              </Form.Select>
              {errors.corporateName && (
                <small style={{ color: "red" }}>
                  {errors.corporateName.message}
                </small>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formCorporateCode">
              <Form.Label>Upload CSV</Form.Label>
              <Form.Control
                type="file"
                name="corporateCode"
                accept=".csv"
                {...register("corporateCode", {
                  required: "CSV file is required",
                })}
                style={{
                  height: "38px",
                }}
              />
              {errors.corporateCode && (
                <small style={{ color: "red" }}>
                  {errors.corporateCode.message}
                </small>
              )}
            </Form.Group>
            <div className="d-flex justify-content-center">
              <button className="Grn-Btn Half" type="submit">
                Add
              </button>
              <button
                className="WHt-Btn Half"
                type="button"
                onClick={handleClose3}
              >
                Cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Corporate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(addCorporate)}>
            <Form.Group className="mb-3" controlId="formCorporateName">
              <Form.Label>Corporate Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="corporateName"
                {...register("corporateName", {
                  required: "Corporate Name is required",
                })}
                onChange={handleInputChange}
              />
              {errors.corporateName && (
                <small style={{ color: "red" }}>
                  {errors.corporateName.message}
                </small>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCorporateCode">
              <Form.Label>Corporate Code</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="corporateCode"
                {...register("corporateCode", {
                  required: "Corporate Code is required",
                })}
              />
              {errors.corporateCode && (
                <small style={{ color: "red" }}>
                  {errors.corporateCode.message}
                </small>
              )}
            </Form.Group>
            <div className="d-flex justify-content-center">
              <button className="Grn-Btn Half" type="submit">
                Add
              </button>
              <button
                className="WHt-Btn Half"
                type="button"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* edit modal */}
      <Modal show={showview1} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body className="catBod">
          <form onSubmit={submitEditCategory}>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">
                Corporate Name
              </InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                defaultValue={editName}
                name="categoryName"
                onChange={(e) => handleInputChange1(e.target.value)}
              />
              {errors1.name && (
                <small style={{ color: "red" }}>{errors1.name.message}</small>
              )}
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">
                Corporate Code
              </InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                defaultValue={editDesc}
                name="categoryDesc"
                onChange={(e) => setEditDesc(e.target.value)}
              />
              {errors1.description && (
                <small style={{ color: "red" }}>
                  {errors1.description.message}
                </small>
              )}
            </InputGroup>
            <div className="d-flex justify-content-center">
              <button className="Grn-Btn Half cat" type="submit">
                Update
              </button>
              <button
                className="WHt-Btn Half"
                type="button"
                onClick={() => setShowview1(false)}
              >
                cancel
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* view modal */}
      {/* <Modal
        show={showCorporateViewModal}
        onHide={handleCloseCorporateViewModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>View Corporate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">
                Corporate Name
              </InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={selectedCorporateView.name || ""}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">
                Corporate Code
              </InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={selectedCorporateView.code || ""}
                readOnly
              />
            </InputGroup>
          </Form>
        </Modal.Body>
      </Modal> */}
    </div>
  );
};
export default Corporate;
