import { TbFileUpload } from "react-icons/tb";
import "./Banners.css";
import { Link, useNavigate } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import { addLiveBanner } from "../redux/liveprogram";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { toast } from "react-toastify";
import { useState } from "react";
import { useForm } from "react-hook-form";

const Addliveprogram = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const [previewImage, setPreviewImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const handleProfilePic = (event) => {
    const selectedFile = event.target.files[0];
    setImageFile(event.target.files[0]);

    if (selectedFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };

      // Add error handling for reading the file
      reader.onerror = (error) => {
        console.error("Error reading the file:", error);
      };

      try {
        reader.readAsDataURL(selectedFile);
      } catch (error) {
        console.error("Error reading the file:", error);
      }
    }
  };

  const {
    register: register,
    formState: { errors: errors },
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur" });

  const bannerAdd = (data) => {
    let datas = {
      thumbnail: imageFile,
      videoUrl: data.videoUrl,
      bannerFor: data.bannerFor,
    };

    dispatch(loader(true));
    dispatch(
      addLiveBanner(datas, (resp) => {
        if (resp.status) {
          toast.success(resp.message);
          navigate("/live_program");
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const cancelBanner = () => {
    navigate("/live_program");
  };
  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-10">
              <h3>Add Live Program Banner</h3>
              <p>
                <Link to="/dashboard">Dasboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="#">Content Update</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="#">Add Live Program Banner</Link>
              </p>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <div className="TittlE">
                <h4> Live Program banners</h4>
              </div>
              <div className="ExpertSBacKG">
                <form onSubmit={handleSubmit(bannerAdd)}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="ThumBnail banner">
                        <div className="upLoad upLoaded">
                          <label htmlFor="imgupload" style={{ width: "100%" }}>
                            <div
                              style={{
                                display: previewImage ? "none" : "block",
                              }}
                            >
                              <span className="text-center">
                                <TbFileUpload className="uploadIcons" />
                              </span>
                            </div>
                            <input
                              type="file"
                              id="imgupload"
                              onChange={handleProfilePic}
                              style={{ display: "none" }}
                            />
                            <div
                              style={{
                                display: previewImage ? "none" : "block",
                              }}
                            >
                              <i style={{ display: "none" }}>
                                <TbFileUpload className="uploadIcon" />
                              </i>
                              <p>Upload Thumbnail</p>
                            </div>
                            {previewImage && (
                              <img
                                src={previewImage}
                                className="img-fluid upLoad"
                                alt="User Profile"
                              />
                            )}
                          </label>
                        </div>
                        {errors.thumbnail && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.thumbnail.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12 pt-3">
                      <div className="SHortsForm banner">
                        <label>Video URL</label>
                        <br></br>
                        <input
                          type="text"
                          className="FormShorts"
                          name="videoUrl"
                          {...register("videoUrl", {
                            required: "VideoUrl is required",
                          })}
                        />
                        {errors.videoUrl && (
                          <small style={{ color: "red" }}>
                            {errors.videoUrl.message}
                          </small>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-3">
                          <input
                            className="bannerinput"
                            type="radio"
                            name="profession"
                            value="Student"
                            {...register("bannerFor", {
                              required: "bannerFor is required",
                            })}
                          />
                          <label className="professionbanner">Student</label>
                        </div>
                        <div className="col-lg-3 col-md-3">
                          <input
                            className="bannerinput"
                            type="radio"
                            name="profession"
                            value="Working"
                            {...register("bannerFor", {
                              required: "bannerFor is required",
                            })}
                          />
                          <label className="professionbanner">Working</label>
                        </div>
                        {errors.bannerFor && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.bannerFor.message}
                          </small>
                        )}
                      </div>

                      <div className="row">
                        <div className="col-lg-2 col-md-4">
                          <div className="SHortsForm1">
                            <button className="Grn-Btn" type="submit">
                              add
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-4">
                          <div className="SHortsForm1">
                            <button
                              className="WHt-Btn"
                              type="button"
                              onClick={cancelBanner}
                            >
                              cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Addliveprogram;
