import { createSlice } from "@reduxjs/toolkit";
import Helper from "../../helper/axiosHelper";
const baseUrl = Helper.baseUrl();

const initialState = {
  //   firstSignUp: {},
};

export const coursesSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
});

export const { firstSignUp } = coursesSlice.actions;

export default coursesSlice.reducer;

export const categoryList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "categories").then(
      (response) => response.data
    );
    callback(result);
  };

export const courseAddNew =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "courses/addNew", data).then(
      (response) => response.data
    );
    callback(result);
  };

export const courseSessionAddNew =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "course/session/addNew",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const selectCourses =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + "course/bundle/selectCourses"
    ).then((response) => response.data);
    callback(result);
  };

export const courseBundleAddNew =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "course/bundle/addNew",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const courseBundleList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "course/bundle/list").then(
      (response) => response.data
    );
    callback(result);
  };

export const courseBundleListInfo =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "course/bundle/info",
      data
    ).then((response) => response.data);
    callback(result);
  };

  
  export const courseSessioninfo =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "course/session/info", data)
    .then((response) => response.data);
    callback(result);
  };
// export const courseBundleDelete =
//   (data, callback = () => {}) =>
//   async (dispatch) => {
//     var result = await Helper.deleteData(
//       baseUrl + "course/assessment/delete",
//       data
//     ).then((response) => response.data);
//     callback(result);
//   };

export const courseBundleEdit =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(
      baseUrl + "course/bundle/edit",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const courseSessionEdit =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(
      baseUrl + "course/session/edit",
      data
    ).then((response) => response.data);
    callback(result);
  };

  export const courseLessonEdit =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.pathFormData(
      baseUrl + "course/lesson/edit",
      data
    ).then((response) => response.data);
    callback(result);
  };
  export const courseAssesmentEdit =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(
      baseUrl + "course/assessment/edit",
      data
    ).then((response) => response.data);
    callback(result);
  };

  export const courseBundleStatusChange =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(
      baseUrl + "course/bundle/changeStatus",
      data
    ).then((response) => response.data);
    callback(result);
  };
  

  export const courseListCb =
  ( data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "courses/all", data
      
    ).then((response) => response.data);
    callback(result);
  };
  export const collegeList =
  ( data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "courses/institutions", data
      
    ).then((response) => response.data);
    callback(result);
  };
  export const courseListview =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData( baseUrl + `course/${data}`)
    .then((response) => response.data);
    callback(result);
  };
  export const courseListinfo =
  ( data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "course/detail", data)
    .then((response) => response.data);
    callback(result);
  };
  export const editCourse =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(baseUrl + "courses/edit", data).then(
      (response) => response.data
    );
    callback(result);
  };
  export const CoursesStatus =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(baseUrl + "courses/approve_course", data).then(
      (response) => response.data
    );
    callback(result);
  };
  export const listOfActiveCourses =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "courses/list_of_active_courses",
      data
    ).then((response) => response.data);
    callback(result);
  };
  export const listOfCourses =
  ( callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData( baseUrl +"courses/active_courses")
    .then((response) => response.data);
    callback(result);
  };
  export const courseListDelete=
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "courses/delete", data).then(
      (response) => response.data
    );
    callback(result);
  };
  export const courseSessionDelete =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "course/session/delete", data).then(
      (response) => response.data
    );
    callback(result);
  };
  export const courseLessonDelete =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "course/lesson/delete", data).then(
      (response) => response.data
    );
    callback(result);
  };
  export const courseAssesmentDelete =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "course/assessment/delete", data).then(
      (response) => response.data
    );
    callback(result);
  };
  export const sessionListCb =
  ( data,callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "course/session/list",data)
    .then((response) => response.data);
    callback(result);
  };


  export const addLessonCb =
  ( data,callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.formData(baseUrl + "course/lesson/addNew",data)
    .then((response) => response.data);
    callback(result);
  };

  export const addAssesmentCb =
  ( data,callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "course/assessment/addNew",data)
    .then((response) => response.data);
    callback(result);
  };
  export const fileUploading =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.formData(
      baseUrl + "upload_single_file",
      data
    ).then((response) => response.data);
    callback(result);
  };
  export const searchBarCourseListAd =
  (data, callback = () => {}) =>
  async (dispatch) => {
    console.log(data, "fata")
    var result = await Helper.postData(
      baseUrl + `courses/search_courses`, data
    ).then((response) => response.data);
    callback(result);
  };
  export const ratingsFetchCb =
  ( data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "ratings_and_reviews/fetch_ratings_and_reviews", data
      
    ).then((response) => response.data);
    callback(result);
  };
  export const ratingsListCb =
  ( callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData( baseUrl +"ratings_and_reviews/list_of_ratings_and_reviews")
    .then((response) => response.data);
    callback(result);
  };
  export const ratingsListDelete =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "ratings_and_reviews/delete_ratings_and_reviews", data).then(
      (response) => response.data
    );
    callback(result);
  };
  export const ratingsStatus =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(baseUrl + "ratings_and_reviews/approve_ratings_and_reviews", data).then(
      (response) => response.data
    );
    callback(result);
  };
  export const initialUpload =
  ( data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "course/initiateUpload", data
      
    ).then((response) => response.data);
    callback(result);
  };
  export const completeUpload =
  ( data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "course/completeUpload", data
      
    ).then((response) => response.data);
    callback(result);
  };
  export const UploadIdapi =
  ( data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + `/course/upload?uploadId=${data}`, data
      
    ).then((response) => response.data);
    callback(result);
  };