import { useState, useEffect } from "react";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import { useLocation } from "react-router-dom";

const ViewAdminUser = () => {
  const location = useLocation();
  const [viewData, setViewData] = useState({});

  const { data } = location.state || {};
  useEffect(() => {
    if (data) {
      setViewData(data);
    }
  }, [data]);

  if (!data) {
    return <div>User not found</div>;
  }

  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/admin_user">Admin Users</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="">{viewData.name}</Link>
              </p>
              <h3>{viewData.name}</h3>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <div className="CorsELiSTab">
                <div className="row justify-content-center">
                  <div className="col-9">
                    <div className="CorsELiSTab pt-4 pb-4">
                      <Table striped bordered hover>
                        <tbody>
                          <tr>
                            <td className="CatCOlr">Name</td>
                            <td>{viewData.name}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Email Address</td>
                            <td>{viewData.email}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Password</td>
                            <td>{viewData.password}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Role</td>
                            <td>{viewData.type}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Status</td>
                            <td>
                              {viewData.status === 0 ? "Active" : "inActive"}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewAdminUser;
