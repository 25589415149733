import { createSlice } from "@reduxjs/toolkit";
import Helper from "../../helper/axiosHelper";
const baseUrl = Helper.baseUrl();

const initialState = {
  //   firstSignUp: {},
};

export const bannerSlice = createSlice({
  name: "liveprogram",
  initialState,
  reducers: {},
});
export const { addBlog, blogList, updateBlog } = bannerSlice.actions;

export default bannerSlice.reducer;

export const addLiveBanner =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.formData(
      baseUrl + "live_program_banner/add_live_program_banner",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const liveBannersList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + "live_program_banner/live_program_banner_list"
    ).then((response) => response.data);
    callback(result);
  };

export const viewLiveBanner =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "live_program_banner/live_program_banner_info",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const editLiveBanner =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.pathFormData(
      baseUrl + "live_program_banner/edit_live_program_banner",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const deleteLiveBanner =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "live_program_banner/delete_live_program_banner",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const liveChangeStatus =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(
      baseUrl + "live_program_banner/change_status_of_live_program_banner",
      data
    ).then((response) => response.data);
    callback(result);
  };
