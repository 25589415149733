import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./Category.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import InputGroup from "react-bootstrap/InputGroup";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  addCategory,
  CategoryList,
  viewCategory,
  editCategory,
  changeStatusCategoty,
  deleteCategory,
} from "../redux/login";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const Category = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showview, setShowview] = useState(false);
  const [showview1, setShowview1] = useState(false);
  const [checked, setChecked] = useState([]);
  const [name, setName] = useState("");
  const [editName, setEditName] = useState("");
  const [editDesc, setEditDesc] = useState("");
  let [refresher, setRefresher] = useState(true);
  let [viewData, setViewData] = useState({});
  let [categoryID, setCategoryID] = useState();

  const handleClose = () => {
    setShow(false);
    reset();
  };
  const handleClose2 = () => setShowview1(false);
  const handleShow = () => {
    setShow(true);
  };
  const handleClose1 = () => setShowview(false);
  const handleShow1 = (id) => {
    let data = id;
    setShowview(true);
    dispatch(loader(true));
    dispatch(
      viewCategory(data, (resp) => {
        if (resp.status) {
          setViewData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const handleShow2 = (id) => {
    setCategoryID(id);
    setShowview1(true);
    const selectedCategory = categoryData.find((coupon) => coupon._id === id);
    setEditName(selectedCategory.name);
    setEditDesc(selectedCategory.description);
  };

  const deletecategory = (id, index) => {
    let data = {};
    data["_id"] = id;

    dispatch(loader(true));
    dispatch(
      deleteCategory(data, (resp) => {
        if (resp.status) {
          setRefresher(!refresher);
          toast.success(resp.message);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const submitEditCategory = (e, id) => {
    e.preventDefault();
    const alphanumericRegex = /^[a-zA-Z][a-zA-Z0-9 ]*$/;
    if (!alphanumericRegex.test(editName)) {
      toast.error("Title can only contain alphanumeric characters.");
      return;
    }
    let data = {};
    data["_id"] = categoryID;
    data["name"] = editName;
    data["description"] = editDesc;

    dispatch(loader(true));
    dispatch(
      editCategory(data, (resp) => {
        if (resp.status) {
          setViewData(resp.data);
          toast.success(resp.message);
          setRefresher(!refresher);
          setShowview1(false);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const [categoryData, setCategoryData] = useState([]);

  const {
    register: register,
    formState: { errors: errors },
    reset: reset,
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur" });

  const {
    formState: { errors: errors1 },
  } = useForm({ mode: "onBlur" });

  const categoryAdd = (data) => {
    reset();
    dispatch(loader(true));
    dispatch(
      addCategory(data, (resp) => {
        if (resp.status) {
          setShow(false);
          setRefresher(!refresher);
          toast.success(resp.message);
          setName(" ");
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      CategoryList((resp) => {
        if (resp.status) {
          setCategoryData(resp.data);
          var arr = [];
          for (var i = 0; i < resp.data.length; i++) {
            arr.push(resp.data[i].isHidden);
          }
          setChecked(arr);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, [refresher]);

  const changeStatus = (id, index) => {
    let data = {};
    let value = {
      isHidden: !checked[index],
    };

    data["_id"] = id;
    data["isHidden"] = value.isHidden;

    dispatch(loader(true));
    dispatch(
      changeStatusCategoty(data, (response) => {
        if (response.status) {
          toast.success(response.message);
          setRefresher(!refresher);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(response.message);
        }
      })
    );
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const alphanumericRegex = /^[a-zA-Z][a-zA-Z0-9 ]*$/;
    if (alphanumericRegex.test(inputValue) || inputValue === "") {
      setName(inputValue);
    }
  };
  const handleInputChange1 = (value) => {
    const inputValue = value;
    const alphanumericRegex = /^[a-zA-Z][a-zA-Z0-9 ]*$/;
    if (alphanumericRegex.test(inputValue) || inputValue === "") {
      setEditName(inputValue);
    }
  };
  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-md-5">
              <h3>Category List</h3>
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/category">Category</Link>
              </p>
            </div>
            <div className="col-xl-5 col-md-7">
              <div className="Grn-Btnmanin">
                <button className="Grn-Btn" onClick={handleShow}>
                  add category
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <Table striped hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Category</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {categoryData.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.description}</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          <div className="">
                            <div className="OnOfF">
                              <Form>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  onChange={() => changeStatus(item._id, index)}
                                  checked={checked[index]}
                                />
                              </Form>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="Options pt-1">
                              <i>
                                <BsThreeDotsVertical />
                              </i>
                              <ul class="hidden-list catLi">
                                <li onClick={() => handleShow1(item._id)}>
                                  View
                                </li>
                                <li onClick={() => handleShow2(item._id)}>
                                  Edit
                                </li>
                                <li
                                  onClick={() =>
                                    deletecategory(item._id, index)
                                  }
                                >
                                  Delete
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body className="catBod">
          <Form onSubmit={handleSubmit(categoryAdd)}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="name"
                //value={name}
                // id="name"
                {...register("name", {
                  required: "Name is required",
                })}
                onChange={handleInputChange}
              />

              {errors.name && (
                <small style={{ color: "red" }}>{errors.name.message}</small>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Category Short description</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="description"
                {...register("description", {
                  required: "Description is required",
                })}
              />
              {errors.description && (
                <small style={{ color: "red", float: "left" }}>
                  {errors.description.message}
                </small>
              )}
            </Form.Group>
            <div className="d-flex justify-content-center">
              <button className="Grn-Btn Half cat" type="submit">
                add
              </button>
              <button
                className="WHt-Btn Half"
                type="button"
                onClick={handleClose}
              >
                cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* edit modal */}
      <Modal show={showview1} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body className="catBod">
          <form onSubmit={submitEditCategory}>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Category Name</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                defaultValue={editName}
                name="categoryName"
                onChange={(e) => handleInputChange1(e.target.value)}
              />
              {errors1.name && (
                <small style={{ color: "red" }}>{errors1.name.message}</small>
              )}
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">
                Category Short description
              </InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                defaultValue={editDesc}
                name="categoryDesc"
                onChange={(e) => setEditDesc(e.target.value)}
              />
              {errors1.description && (
                <small style={{ color: "red" }}>
                  {errors1.description.message}
                </small>
              )}
            </InputGroup>
            <div className="d-flex justify-content-center">
              <button className="Grn-Btn Half cat" type="submit">
                Update
              </button>
              <button
                className="WHt-Btn Half"
                type="button"
                onClick={() => setShowview1(false)}
              >
                cancel
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* view modal */}
      <Modal show={showview} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>View Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Category Name</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData.name}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">
                Category Short description
              </InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData.description}
                readOnly
              />
            </InputGroup>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Category;
