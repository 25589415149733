import { useState, useEffect } from "react";
import "./Course.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import { MdEdit, MdDelete } from "react-icons/md";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch, useSelector } from "react-redux";
import Topbar from "../../components/topbar/Topbar";
import SideBar from "../../components/Sidebar/SideBar";
import {
  courseListinfo,
  sessionListCb,
  courseListview,
  collegeList,
} from "../redux/courses";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loader, isLogin } from "../redux/common";
import { LuEye } from "react-icons/lu";
const Viewcourse = () => {
  const navigate = useNavigate();
  const courses = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const courseId = useParams();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [showview, setShowview] = useState(false);
  const [showview1, setShowview1] = useState(false);
  const [refresher, setRefresher] = useState(true);
  const [viewData, setViewData] = useState({});
  const [sessionData, setSessionData] = useState([]);
  const [lessonData, setLessonData] = useState([]);
  const [assesmentData, setAssesmentData] = useState([]);
  const [educatorsData, setEducatorsData] = useState({});
  const [categoryData, setCategoryData] = useState({});
  const [activeTab, setActiveTab] = useState("first");
  const [viewID, setViewID] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [optionscolleges, setOptionscolleges] = useState([]);
  const handleShowModal = () => {
    setSelectedLesson(lessonData);
    setShowModal(true);
  };
  const handleClose2 = () => {
    setShow1(false);
  };
  const showPdf = (data) => {
    setShow1(!show1);
  };
  const handleCloseModal = () => {
    setSelectedLesson(null);
    setShowModal(false);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShowview(false);
  const handleShow1 = () => setShowview(true);

  useEffect(() => {
    let data = {};
    data["courseId"] = courseId.id;
    dispatch(loader(true));
    dispatch(
      courseListinfo(data, (resp) => {
        if (resp.status) {
          setViewData(resp.data.courseInfo);
          setEducatorsData(resp.data.educatorInfo);
          setCategoryData(resp.data.categoryInfo);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, []);

  useEffect(() => {
    let data = {};
    data["search"] = searchTerm;
    dispatch(loader(true));
    dispatch(
      collegeList(data, (resp) => {
        dispatch(loader(false));
        if (resp) {
          var temp2 = [];
          var optData = resp.data.map((ele) => {
            temp2.push({ value: ele._id, label: ele.name });
          });
          setOptionscolleges(temp2);
        } else {
        }
      })
    );
  }, [searchTerm]);
  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
    if (tabKey == "third") {
      thirdTabfn();
    }
    if (tabKey == "four") {
      fourthTabfn();
    }
  };
  const thirdTabfn = () => {
    let data = {};
    data["courseId"] = courseId.id;

    dispatch(loader(true));
    dispatch(
      courseListview(data.courseId, (resp) => {
        if (resp.status) {
          setSessionData(resp.data[0].session);
          setLessonData(resp.data[0]?.session[0]?.lessons);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const fourthTabfn = () => {
    let data = {};
    data["courseId"] = courseId.id;
    dispatch(loader(true));
    dispatch(
      courseListview(data.courseId, (resp) => {
        if (resp.status) {
          setAssesmentData(resp.data[0].session);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  return (

      <div className="Categorypage">
        <div className="PageTit ">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h3>View Course</h3>
                <p>
                  <Link to="/dashboard">Dashboard</Link>{" "}
                  <i>
                    <AiOutlineRight />
                  </i>{" "}
                  <Link to="/">View Course</Link>
                </p>
              </div>
              <div className="col-6">
              </div>
            </div>
          </div>
        </div>
        <div className="CateCont">
          <div className="container">
            <div className="row">
              <div className="CateGorTab">
                <div className="row">
                  <div className="col-12">
                    <Tabs defaultActiveKey="first" onSelect={handleTabSelect}>
                      <Tab eventKey="first" title="Course Detail">
                        <div className="CorsELiSTab pt-4 pb-4">
                          <Table striped bordered hover>
                            <tbody>
                              <tr>
                                <td className="CatCOlr">Category</td>
                                <td>{categoryData.name}</td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">Course title</td>
                                <td>{viewData.title}</td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">Short description</td>
                                <td>{viewData.shortDescription}</td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">Price</td>
                                <td>{viewData.price}</td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">Duration</td>
                                <td>{viewData.duration}</td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">Level</td>
                                <td>{viewData.level}</td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">Educators Name</td>
                                <td>{educatorsData.name}</td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">Thumbnail</td>
                                <td>
                                  <button
                                    onClick={showPdf}
                                    className="view_button "
                                  >
                                    Click here to view
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Tab>

                      <Tab eventKey="second" title="Course Overview">
                        <div className="CorsELiSTab pt-4 pb-4">
                          <Table striped bordered hover>
                            <tbody>
                              <tr>
                                <td className="CatCOlr">Course description</td>
                                <td>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: viewData.description,
                                    }}
                                  ></p>
                                </td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">What you'll learn</td>
                                <td>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: viewData.whatWillYouLearn,
                                    }}
                                  ></p>
                                </td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">Certifications</td>
                                <td>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: viewData.certifications,
                                    }}
                                  ></p>
                                </td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">
                                  Who this course is for
                                </td>
                                <td>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: viewData.whoThisCourseIsFor,
                                    }}
                                  ></p>
                                </td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">Course Includes</td>
                                <td>
                                  <ul className="PAdinGUl">
                                    {viewData?.courseIncludes?.map(
                                      (item, index) => {
                                        return (
                                          <>
                                            <li key={index}>{item}</li>
                                          </>
                                        );
                                      }
                                    )}
                                  </ul>
                                </td>
                                </tr>
                                <tr>
                                <td className="CatCOlr">College Includes</td>
                                <td>
                                  <ol className="PAdinGUl">
                                  {viewData?.freeColleges?.map((collegeId, index) => {
        const college = optionscolleges.find(option => option.value === collegeId);
        return (
          <li key={index}>{index + 1}. {college ? college.label : 'Unknown College'}</li>
        );
      })}
                                  </ol>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Tab>

                      <Tab eventKey="third" title="Course Content">
                        <div className="CorCountenT">
                          <div className="row justify-content-center">
                            <div className="col-12">
                              {sessionData.map((session, sessionIndex) => (
                                <div key={sessionIndex} className="DulBG">
                                  <div className="d-flex">
                                    <div className="DuLLBg">
                                      <p>
                                        <span>
                                          Session {session.sessionNo}:
                                        </span>{" "}
                                        {session.title}
                                      </p>
                                    </div>
                                  </div>
                                  <div>
                                    {session.lessons.map(
                                      (lesson, lessonIndex) => (
                                        <div key={lessonIndex} className="row ">
                                          <div className="col-12">
                                            <div className="LeSSonSesion">
                                              <div className="">
                                                <div className="LesSNp d-flex justify-content-between">
                                                  <p>
                                                    <span>
                                                      Lesson {lesson.lessonNo} :
                                                    </span>{" "}
                                                    {lesson.title}{" "}
                                                    {lesson.duration}
                                                  </p>
                                                  <span
                                                    className="viewVideo"
                                                    onClick={handleShowModal}
                                                  >
                                                    <LuEye />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </Tab>

                      <Tab eventKey="four" title="Assessment">
                        <div className="CorCountenT">
                          <div className="row justify-content-center">
                            <div className="col-12">
                              {sessionData.map((session, sessionIndex) => (
                                <div className="DulBG" key={sessionIndex}>
                                  <div className="d-flex">
                                    <div className="DuLLBg">
                                      <p>
                                        <span>
                                          Session {session.sessionNo} :
                                        </span>{" "}
                                        {session.title}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row mt-2">
                                    <div className="col-12">
                                      <Accordion defaultActiveKey="0">
                                        {session.assessments.map(
                                          (assessment, assessmentIndex) => (
                                            <Accordion.Item
                                              eventKey={assessmentIndex.toString()}
                                              key={assessmentIndex}
                                            >
                                              <Accordion.Header className="ViewCrsefun1">
                                                {assessment.question}
                                              </Accordion.Header>
                                              <Accordion.Body>
                                                <form>
                                                  {assessment.options.map(
                                                    (option, optionIndex) => (
                                                      <div key={optionIndex}>
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            assessment.correctAnswer ===
                                                            option
                                                          }
                                                          disabled={true}
                                                        />
                                                        <label className="AccCheckBox">
                                                          {" "}
                                                          {option}
                                                        </label>
                                                        <br />
                                                      </div>
                                                    )
                                                  )}
                                                </form>
                                              </Accordion.Body>
                                            </Accordion.Item>
                                          )
                                        )}
                                      </Accordion>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {View Thumbnail} */}
        <Modal show={show1} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>View Thumbnail</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center">
              <img src={viewData.thumbnail} width="100%" height="500px" />
            </div>
          </Modal.Body>
        </Modal>

        {/* Modal */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Session</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Session Title</Form.Label>
                <Form.Control type="text" placeholder="" autoFocus />
              </Form.Group>

              <div className="d-flex">
                <button className="Grn-Btn Half">add</button>
                <button className="WHt-Btn Half">cancel</button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header
            style={{
              background: "#107B38",
              color: "#fff",
              fontFamily: "Roboto, sans-serif",
              borderRadius: 0,
            }}
            closeButton
          >
            <Modal.Title style={{ textAlign: "center" }}>
              {selectedLesson ? selectedLesson.title : ""}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedLesson && (
              <video
                controls
                controlsList="nodownload"
                style={{ width: "100%" }}
              >
                <source src={selectedLesson[0].videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </Modal.Body>
        </Modal>

        {/* view modal */}
        <Modal show={showview} onHide={handleShow1}>
          <Modal.Header closeButton>
            <Modal.Title>View Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Select Session</Form.Label>
                <Form.Control type="text" placeholder="" autoFocus />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Lesson Title</Form.Label>
                <Form.Control type="text" placeholder="" autoFocus />
              </Form.Group>
              <div className="row">
                <div className="col-6">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Duration</Form.Label>
                    <Form.Control type="text" placeholder="" autoFocus />
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Video Upload</Form.Label>
                    <Form.Control type="file" />
                  </Form.Group>
                </div>
              </div>

              <div className="d-flex">
                <button className="Grn-Btn Half">add</button>
                <button className="WHt-Btn Half">cancel</button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
   
  );
};
export default Viewcourse;
