import React, { useState } from "react";
import { TbFileUpload } from "react-icons/tb";
import "./Blogs.css";
import "./Addblog.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { addNewBlog } from "../redux/blogs";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { loader } from "../redux/common";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
const Addblog = () => {
  let dispatch = useDispatch();
  const [thumbnail, setThumbnail] = useState(null);
  const [dataThumbnail, setDataThumbnail] = useState("");
  let [refresher, setRefresher] = useState(true);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setDataThumbnail(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setThumbnail(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const navigate = useNavigate();

  const blogAdd = (data) => {
    const blogData = {
      title: data.title,
      sDesc: data.shortDescription,
      dDesc: data.description,
      thumbnail: dataThumbnail,
    };
    dispatch(loader(true));
    dispatch(
      addNewBlog(blogData, (resp) => {
        if (resp && resp.status) {
          setRefresher(!refresher);
          toast.success(resp.message);
          navigate("/blogs", { state: { addedBlog: resp.data } });
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };
  const validateWordLimit = (value) => {
    if (value && value.split(" ").length > 30) {
      return "Short description should not exceed 30 words";
    }
    return true;
  };
  return (
    <form onSubmit={handleSubmit(blogAdd)}>
      <div className="Categorypage">
        <div className="PageTit ">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h3>Add Blog</h3>
                <p>
                  <Link to="/dashboard">Dashboard</Link>{" "}
                  <i>
                    <AiOutlineRight />
                  </i>{" "}
                  <Link to="/blogs">Blogs</Link>{" "}
                  <i>
                    <AiOutlineRight />
                  </i>{" "}
                  <Link to="/add_blog">Add Blog</Link>
                </p>
              </div>
              <div className="col-6"></div>
            </div>
          </div>
        </div>
        <div className="CateCont">
          <div className="container">
            <div className="row">
              <div className="CateGorTab">
                <div className="TittlE">
                  <h4>Add Blog</h4>
                </div>
                <div className="ExpertSBacKG">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="ThumBnail blogcnt">
                        <div className="thumbnail-container adBlog">
                          <div className="blogadd">
                            {thumbnail ? null : (
                              <label
                                htmlFor="file-upload"
                                className="file-upload-label"
                                style={{ paddingTop: "77px" }}
                              >
                                Image Dimensions (1700 x 1133)
                              </label>
                            )}
                            <input
                              id="file-upload"
                              type="file"
                              className="fileUpload"
                              {...register("blogImg", {
                                required: "blogImage  is required",
                              })}
                              onChange={handleFileChange}
                              accept="image/*"
                            />
                            {thumbnail && (
                              <div className="thumbnail-preview">
                                <img
                                  src={thumbnail}
                                  alt="Thumbnail Preview"
                                  className="imgthumbnails"
                                />
                              </div>
                            )}
                          </div>
                          <div className="iconUpload">
                            <label
                              for="file-upload"
                              className="custom-file-upload"
                            >
                              <span className="text-center">
                                <TbFileUpload className="iconuploads blogicon uplIcon" />
                              </span>
                              <p className="uploadthumbs blogpad clkBlog">
                                Click here to upload
                              </p>
                            </label>
                          </div>
                        </div>
                      </div>
                      {errors.blogImg && (
                        <small style={{ color: "red", float: "left" }}>
                          {errors.blogImg.message}
                        </small>
                      )}
                    </div>
                    <div className="col-lg-9 pt-3">
                      <div className="row">
                        <div className="col-12">
                          <div className="SHortsForm">
                            <form>
                              <label>Title</label>
                              <br></br>
                              <input
                                type="text"
                                className="FormShorts"
                                {...register("title", {
                                  required: "Title is required",
                                })}
                              />
                              {errors.title && (
                                <small style={{ color: "red", float: "left" }}>
                                  {errors.title.message}
                                </small>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="SHortsForm">
                            <label>Short Description</label>
                            <br></br>
                            <textarea
                              rows="5"
                              cols="72"
                              class="FormShorts"
                              {...register("shortDescription", {
                                required: "Short description is required",
                                validate: validateWordLimit,
                              })}
                            ></textarea>
                            {errors.shortDescription && (
                              <small style={{ color: "red", float: "left" }}>
                                {errors.shortDescription.message}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="CKEditTors">
                        <label>Detail Description</label>
                        <br></br>
                        <Controller
                          name="description"
                          control={control}
                          rules={{
                            validate: (value) =>
                              (value && value.length > 0) ||
                              "Description is required",
                          }}
                          render={({ field }) => (
                            <CKEditor
                              editor={ClassicEditor}
                              data={field.value}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                field.onChange(data);
                              }}
                              onBlur={() => {
                                if (!field.value) {
                                  field.onChange("");
                                }
                              }}
                            />
                          )}
                        />
                        {errors.description && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.description.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-2 col-md-3">
                      <div className="SHortsForm1">
                        <button className="Grn-Btn blogBtnGn" type="submit">
                          add
                        </button>
                      </div>
                    </div>
                    <div className="col-2 col-md-3">
                      <div className="SHortsForm1">
                        <Link to="/blogs">
                          {" "}
                          <button className="WHt-Btn blogBtnWh">cancel</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
export default Addblog;
