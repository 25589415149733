import { TbFileUpload } from "react-icons/tb";
import "./Banners.css";
import { Link, useNavigate } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import { addBannerSubScription } from "../redux/Subscription";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { toast } from "react-toastify";
import { useState } from "react";
import { useForm } from "react-hook-form";

const Addsubscription = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const [previewImage, setPreviewImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const handleProfilePic = (event) => {
    const selectedFile = event.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];

    if (selectedFile && allowedTypes.includes(selectedFile.type)) {
      setImageFile(selectedFile);

      const reader = new FileReader();

      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };

      // Add error handling for reading the file
      reader.onerror = (error) => {
        console.error("Error reading the file:", error);
      };

      try {
        reader.readAsDataURL(selectedFile);
      } catch (error) {
        console.error("Error reading the file:", error);
      }
    } else {
      // Notify the user about selecting the wrong file type
      toast.error("Please select a valid image file (JPEG, PNG, GIF)");
      // Optionally, you can reset the input field to clear the selection
      event.target.value = null;
    }
  };

  const {
    register: register,
    formState: { errors: errors },
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur" });

  const bannerAdd = (data) => {
    let datas = {
      thumbnail: imageFile,
      videoUrl: data.videoUrl,
      bannerFor: data.bannerFor,
    };

    dispatch(loader(true));
    dispatch(
      addBannerSubScription(datas, (resp) => {
        if (resp.status) {
          toast.success(resp.message);
          navigate("/subscription_banner");
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const cancelBanner = () => {
    navigate("/subscription_banner");
  };
  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-10">
              <h3>Add Subscription Banner</h3>
              <p>
                <Link to="/dashboard">Dasboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="">Content Update</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/subscription_banner">Subscription Banner</Link>
              </p>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <div className="TittlE">
                <h4>Add Subscription Banner</h4>
              </div>
              <div className="ExpertSBacKG">
                <form onSubmit={handleSubmit(bannerAdd)}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="ThumBnail banner subbnr">
                        <div className="upLoad upLoaded">
                          <label htmlFor="imgupload" style={{ width: "100%" }}>
                            <div
                              style={{
                                display: previewImage ? "none" : "block",
                              }}
                            >
                              Image Dimensions (1920 x 550)
                              <span className="text-center">
                                <TbFileUpload className="uploadIcons subBnr" />
                              </span>
                            </div>
                            <input
                              type="file"
                              id="imgupload"
                              onChange={handleProfilePic}
                              style={{ display: "none" }}
                            />
                            <div
                              style={{
                                display: previewImage ? "none" : "block",
                              }}
                            >
                              <i style={{ display: "none" }}>
                                <TbFileUpload className="uploadIcon" />
                              </i>
                              <p>Click here to upload</p>
                            </div>
                            {previewImage && (
                              <img
                                src={previewImage}
                                className="img-fluid upLoad"
                                alt="User Profile"
                              />
                            )}
                          </label>
                        </div>
                        {errors.thumbnail && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.thumbnail.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <br />
                    <div className="col-lg-12 pt-3">
                      <div className="SHortsForm banner">
                        <label>Video URL</label>
                        <br></br>
                        <input
                          type="text"
                          className="FormShorts"
                          name="videoUrl"
                          {...register("videoUrl", {
                            required: "Please enter a valid URL",
                            pattern: {
                              value: /^(ftp|http|https):\/\/[^ "]+$/,
                              message: "Please enter a valid URL",
                            },
                          })}
                        />
                        {errors.videoUrl && (
                          <small style={{ color: "red" }}>
                            {errors.videoUrl.message}
                          </small>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-3">
                          <input
                            className="bannerinput"
                            type="radio"
                            name="profession"
                            value="Student"
                            {...register("bannerFor", {
                              required: "Please select any one of the option",
                            })}
                          />
                          <label className="professionbanner">Student</label>
                        </div>
                        <div className="col-lg-3 col-md-3">
                          <input
                            className="bannerinput"
                            type="radio"
                            name="profession"
                            value="Working"
                            {...register("bannerFor", {
                              required: "Please select any one of the option",
                            })}
                          />
                          <label className="professionbanner">Working</label>
                        </div>
                        {errors.bannerFor && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.bannerFor.message}
                          </small>
                        )}
                      </div>

                      <div className="row justify-content-center">
                        <div className="col-lg-2 col-md-4">
                          <div className="SHortsForm1">
                            <button className="Grn-Btn" type="submit">
                              add
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-4">
                          <div className="SHortsForm1">
                            <button
                              className="WHt-Btn"
                              type="button"
                              onClick={cancelBanner}
                            >
                              cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Addsubscription;
