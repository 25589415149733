import { createSlice } from "@reduxjs/toolkit";
import Helper from "../../helper/axiosHelper";
const baseUrl = Helper.baseUrl();

const initialState = {
  //   firstSignUp: {},
};

export const videotestmonialSlice = createSlice({
  name: "videotest",
  initialState,
  reducers: {},
});

export const { firstSignUp } = videotestmonialSlice.actions;

export default videotestmonialSlice.reducer;

export const VideoTestimonialsList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "video_testimonials").then(
      (response) => response.data
    );
    callback(result);
  };

export const addVideoTestimonial =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.formData(
      baseUrl + "video_testimonial/create_new",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const deleteVideoTestimonial =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "video_testimonial/del",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const editVideoTestimonial =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.pathFormData(
      baseUrl + "video_testimonial/update",
      data
    ).then((response) => response.data);
    callback(result);
  };
