import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "../Category/Category.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import InputGroup from "react-bootstrap/InputGroup";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  addCoupon,
  CouponList,
  CouponStatus,
  deleteCoupon,
} from "../redux/login";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import { listOfCourses } from "../redux/courses";
import Select from "react-select";
import { FormCheck } from "react-bootstrap";
import moment from "moment";
import { activeSubscriptionPlanList } from "../redux/Subscription";

const Coupon = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showview, setShowview] = useState(false);
  const [showview1, setShowview1] = useState(false);
  const [checked, setChecked] = useState([]);
  let [refresher, setRefresher] = useState(true);
  let [viewData, setViewData] = useState({});
  let [couponID, setCouponID] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showCourseDropdown, setShowCourseDropdown] = useState(false);
  const [showSubscriptionDropdown, setShowSubscriptionDropdown] =
    useState(false);
  const [courseList, setCourseList] = useState([]);
  const [subList, setSubList] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [courseEdit, setCourseEdit] = useState([]);
  const [selectedSubscriptions, setSelectedSubscriptions] = useState([]);
  const [subscriptionEdit, setSubscriptionEdit] = useState([]);
  const [couponName, setCouponName] = useState("");
  const [validFrom, setValidFrom] = useState("");
  const [validTill, setValidTill] = useState("");
  const [couponType, setCouponType] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [couponValue, setCouponValue] = useState(0);

  const courseOptions = courseList.map((course) => ({
    label: course.title,
    value: course._id,
  }));

  const subscriptionOptions = subList.map((subscription) => ({
    label: subscription.planName,
    value: subscription._id,
  }));
  const handleClose = () => {
    setShow(false);
  };

  const handleClose2 = () => setShowview1(false);
  const handleShow = () => {
    reset();
    setValidFrom("");
    setValidTill("");
    setSelectedCourses([]);
    setSelectedSubscriptions([]);
    setDiscountType("");
    setCouponType("");
    setShow(true);
  };
  const handleClose1 = () => setShowview(false);
  const handleShow1 = (couponId) => {
    const selectedCoupon = couponData.find((coupon) => coupon._id === couponId);
    if (selectedCoupon) {
      setViewData(selectedCoupon);
      setShowview(true);
    } else {
      console.error("Coupon not found");
    }
  };

  const handleShow2 = (id) => {
    const selectedCoupon = couponData.find((coupon) => coupon._id === id);
    if (selectedCoupon) {
      setShowview1(true);
      setCouponID(id);
      setCouponName(selectedCoupon.couponName);
      setValidFrom(selectedCoupon.validFrom);
      setValidTill(selectedCoupon.validTill);
      setDiscountType(selectedCoupon.discountType);
      setCouponValue(selectedCoupon.couponValue.toString());
      setCouponType(selectedCoupon.couponType);
      var temp = [];
      selectedCoupon.selectedCourses.map((element) => {
        temp.push({ label: element.title, value: element.courseId });
      });
      var temp2 = [];
      selectedCoupon.selectedSubscriptions.map((element) => {
        temp2.push({ label: element.planName, value: element.planId });
      });
      setCourseEdit(temp, ...couponData);
      setSubscriptionEdit(temp2, ...couponData);
    } else {
      console.error("Coupon not found");
    }
  };

  
  const handleCouponTypeChange = (newType) => {
    // Reset coupon value if discount type is changing
    setCouponValue("");
    setDiscountType(newType);
  };
  const deletecoupon = (id, index) => {
    let data = {};
    data["couponId"] = id;

    dispatch(loader(true));
    dispatch(
      deleteCoupon(data, (resp) => {
        if (resp.status) {
          setRefresher(!refresher);
          toast.success(resp.message);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const submitEditCoupon = (e) => {
    e.preventDefault();
    const validFromDate = new Date(validFrom);
    const validTillDate = new Date(validTill);
    const updatedCouponType = couponType;
    let data = {};
    data["couponId"] = couponID;
    data["couponName"] = couponName;
    data["validFrom"] = validFromDate;
    data["validTill"] = validTillDate;
    data["couponType"] = updatedCouponType;
    data["selectedCourses"] = selectedCourses.map((course) => course.value);
    data["selectedSubscriptions"] = selectedSubscriptions.map(
      (subscription) => subscription.value
    );
    data["discountType"] = discountType;
    data["couponValue"] = parseInt(couponValue, 10);

    dispatch(loader(true));
    dispatch(
      CouponStatus(data, (resp) => {
        if (resp.status) {
          toast.success(resp.message);
          setRefresher(!refresher);
          setShowview1(false);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const [couponData, setCouponData] = useState([]);
 
  const validationSchema = Yup.object().shape({
    validFrom: Yup.date()
      .required('Start Date is required')
      .min(new Date(), 'Start Date must greater than Current date-time'),
    validTill: Yup.date()
      .required('End Date date is required')

      .test('greater-than-validFrom', 'End Date must be greater than Start date-time', function (value) {
        return value > this.resolve(Yup.ref('validFrom'));
      })
      .min(new Date(), 'End Date date must be greater than Current date-time'),
    couponName: Yup.string().required('Coupon Name Required')
      .matches(/^[A-Z]{4}\d{4}$/, 'Invalid coupon name format. Use ABCD1234 pattern.'),
  });

  const formatDateTime1 = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    const hours = String(date.getHours()).padStart(2, '0'); // Add leading zero if needed
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Add leading zero if needed

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const {
    register: register,
    control,
    formState: { errors: errors },
    reset: reset,
    handleSubmit: handleSubmit,
  } = useForm({ resolver: yupResolver(validationSchema), mode: "onBlur", });

  const {
    register: register1,
    control1,
    formState: { errors: errors1 },
    reset: reset1,
    handleSubmit: handleSubmit1,
  } = useForm({ mode: "onBlur" });

  const couponAdd = (data) => {
    const validFromDate = new Date(data.validFrom);
    const validTillDate = new Date(data.validTill);



    console.log(data)
    data["couponType"] = couponType;
    data["discountType"] = discountType;
    data["couponValue"] = parseInt(data.couponValue, 10);
    data["selectedCourses"] = selectedCourses.map((course) => course.value);
    data["selectedSubscriptions"] = selectedSubscriptions.map(
      (subscription) => subscription.value
    );
    data["validFrom"] = validFromDate.toISOString();
    data["validTill"] = validTillDate.toISOString();

    if (selectedCourses.length === 0 && selectedSubscriptions.length === 0) {
      toast.error("Course or Subscription should not be empty");
    } else {
      dispatch(loader(true));
      dispatch(
        addCoupon(data, (resp) => {
          if (resp.status) {
            setShow(false);
            setRefresher(!refresher);
            toast.success(resp.message);
            dispatch(loader(false));
          } else {
            dispatch(loader(false));
            toast.error(resp.message);
          }
        })
      );
    }

  };

  useEffect(() => {
    setShowCourseDropdown(couponType === "course");
    setShowSubscriptionDropdown(couponType === "subscription");

    let data = {};
    dispatch(loader(true));
    dispatch(
      CouponList((resp) => {
        if (resp.status) {
          setCouponData(resp.data);
          setViewData(resp.data);
          var arr = [];
          for (var i = 0; i < resp.data.length; i++) {
            arr.push(resp.data[i].isActive);
          }
          setChecked(arr);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );

    dispatch(
      listOfCourses((resp) => {
        if (resp.status) {
          setCourseList(resp.data);
          dispatch(loader(false));
        } else {
          toast.error(resp.message);
          dispatch(loader(false));
        }
      })
    );
    dispatch(
      activeSubscriptionPlanList((resp) => {
        dispatch(loader(true));
        if (resp.status) {
          setSubList(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, [refresher, couponType]);

  const changeStatus = (id, index) => {
    let data = {};
    let value = {
      isActive: !checked[index],
    };

    data["couponId"] = id;
    data["isActive"] = value.isActive;

    dispatch(loader(true));
    dispatch(
      CouponStatus(data, (response) => {
        if (response.status) {
          toast.success(response.message);
          setRefresher(!refresher);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(response.message);
        }
      })
    );
  };
  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return "";

    const dateTime = new Date(dateTimeString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };

    const formattedDate = dateTime
      .toLocaleDateString("en-IN", options)
      .replace(",", "");
    return formattedDate;
  };
  const convertToDatetimeLocal = (dateTimeString) => {
    if (!dateTimeString) return "";

    const dateTime = new Date(dateTimeString);
    const year = dateTime.getFullYear();
    const month = `0${dateTime.getMonth() + 1}`.slice(-2);
    const day = `0${dateTime.getDate()}`.slice(-2);
    const hours = `0${dateTime.getHours()}`.slice(-2);
    const minutes = `0${dateTime.getMinutes()}`.slice(-2);
    const seconds = `0${dateTime.getSeconds()}`.slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };



  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-md-5">
              <h3>Coupons</h3>
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/coupons">Coupons</Link>
              </p>
            </div>
            <div className="col-xl-5 col-md-7">
              <div className="Grn-Btnmanin">
                <button className="Grn-Btn" onClick={handleShow}>
                  Add Coupons
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <Table striped hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Coupon Name</th>
                    <th>Coupon Validity</th>
                    <th>Coupon Type</th>
                    <th>Discount</th>
                    <th style={{ paddingLeft: "25px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {couponData.map((item, index) => {
                    const formattedValidTill = moment(item.validTill).format(
                      "DD MMM YYYY"
                    );
                    const formattedCouponValue =
                      item.discountType === "percentage"
                        ? `${item.couponValue}%`
                        : `₹${item.couponValue}`;
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.couponName}</td>
                        <td>{formattedValidTill}</td>
                        <td>{item.couponType}</td>
                        <td>{formattedCouponValue}</td>
                        <td>
                          <div
                            className="d-flex justify-content-center"
                            style={{ border: "none" }}
                          >
                            <div className="">
                              <div className="OnOfF">
                                <Form>
                                  <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    onChange={() =>
                                      changeStatus(item._id, index)
                                    }
                                    checked={checked[index]}
                                  />
                                </Form>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="Options pt-1">
                                <i>
                                  <BsThreeDotsVertical />
                                </i>
                                <ul class="hidden-list tstLi">
                                  <li onClick={() => handleShow1(item._id)}>
                                    View
                                  </li>
                                  <li onClick={() => handleShow2(item._id)}>
                                    Edit
                                  </li>
                                  <li
                                    onClick={() =>
                                      deletecoupon(item._id, index)
                                    }
                                  >
                                    Delete
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(couponAdd)}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Coupon Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="couponName"
                {...register("couponName")}
              />
              {errors.couponName && (
                <small style={{ color: "red" }}>
                  {errors.couponName.message}
                </small>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Coupon Validity</Form.Label>
              <div className="row">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      placeholder="Start Date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      className="form-control"
                      min={moment().format("YYYY-MM-DDTHH:mm")}
                      {...register("validFrom")}
                      defaultValue={moment().format('YYYY-MM-DDTHH:mm')}
                    />
                    {errors.validFrom && (
                      <small style={{ color: "red" }}>
                        {errors.validFrom.message}
                      </small>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div>
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      className="form-control"
                      min={moment().format("YYYY-MM-DDTHH:mm")}
                      {...register("validTill", {
                        required: "validTill is required",
                      })}
                      defaultValue={moment().format('YYYY-MM-DDTHH:mm')}
                    />

                    {errors.validTill && (
                      <small style={{ color: "red" }}>
                        {errors.validTill.message}
                      </small>
                    )}
                  </div>
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Coupon Type</Form.Label>
              <div className="d-flex">
                <FormCheck
                  type="radio"
                  label="Courses"
                  checked={couponType === "course"}
                  onChange={() => {
                    setShowCourseDropdown(true);
                    setShowSubscriptionDropdown(false);
                    setCouponType("course");
                  }}
                />
                <FormCheck
                  type="radio"
                  label="Subscription"
                  checked={couponType === "subscription"}
                  onChange={() => {
                    setShowSubscriptionDropdown(true);
                    setShowCourseDropdown(false);
                    setCouponType("subscription");
                  }}
                />
              </div>
              {showCourseDropdown && (
                <div>
                  <Select
                    isMulti
                    options={courseOptions}
                    value={selectedCourses}
                    onChange={(selectedOptions) => {
                      const selectedCourses1 = selectedOptions.filter(
                        (option) =>
                          courseOptions.find(
                            (course) => course.value === option.value
                          )
                      );
                      setSelectedCourses(selectedCourses1);
                    }}
                  />
                  {selectedCourses.length === 0 && (
                    <small style={{ color: "red", float: "left" }}>
                      Select at least one course
                    </small>
                  )}
                </div>
              )}
              {showSubscriptionDropdown && (
                <div>
                  <Select
                    isMulti
                    options={subscriptionOptions}
                    value={selectedSubscriptions}
                    onChange={(selectedOptions) => {
                      const selectedSubscriptions1 = selectedOptions.filter(
                        (option) =>
                          subscriptionOptions.find(
                            (subscription) =>
                              subscription.value === option.value
                          )
                      );
                      setSelectedSubscriptions(selectedSubscriptions1);
                    }}
                  />
                  {selectedSubscriptions.length === 0 && (
                    <small style={{ color: "red", float: "left" }}>
                      Select at least one subscription plan
                    </small>
                  )}
                </div>
              )}
              <input
                type="hidden"
                {...register("couponType")}
              />
            </Form.Group>

            <Form.Group className="mb-3 pt-3" controlId="exampleForm.ControlInput1" >
              <Form.Label>Coupon Discount</Form.Label>
              <div className="d-flex">
                <FormCheck
                  type="radio"
                  label="Percentage"
                  checked={discountType === "percentage"}
                  onChange={() => setDiscountType("percentage")}
                />
                <FormCheck
                  type="radio"
                  label="Price"
                  checked={discountType === "price"}
                  onChange={() => setDiscountType("price")}
                />
              </div>
              {discountType && (
                <div>
                  <Form.Control
                    type="number"
                    placeholder={
                      discountType === "percentage"
                        ? "Enter percentage"
                        : "Enter price"
                    }
                    name="couponValue"
                    {...register("couponValue", {
                      required: "Discount value is required",
                      min: {
                        value: 1,
                        message: "Discount must be at least 1",
                      },
                    })}
                  />
                  {errors.couponValue && (
                    <small style={{ color: "red", float: "left" }}>
                      {errors.couponValue.message}
                    </small>
                  )}
                </div>
              )}
            </Form.Group>
            <div className="d-flex justify-content-center">
              <button className="Grn-Btn Half" type="submit">
                add
              </button>
              <button
                className="WHt-Btn Half"
                type="button"
                onClick={handleClose}
              >
                cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Edit Coupon */}
      <Modal show={showview1} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitEditCoupon}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Coupon Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="couponName"
                defaultValue={couponName}
                
                onChange={(e) => setCouponName(e.target.value)}
              />
              {errors.couponName && (
                <small style={{ color: "red" }}>
                  {errors.couponName.message}
                </small>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Coupon Validity</Form.Label>
              <div className="row">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      placeholder="Start Date"
                      value={moment(validFrom).format("YYYY-MM-DDTHH:mm")}
                      onChange={(e) => {setValidFrom(e.target.value); 
                      console.log(e.target.value)}}
                      min={moment().format("YYYY-MM-DDTHH:mm")}
                      className="form-control"
                    />
                    {errors.validFrom && (
                      <small style={{ color: "red" }}>
                        {errors.validFrom.message}
                      </small>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div>
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      value={moment(validTill).format("YYYY-MM-DDTHH:mm")}
                      min={moment().format("YYYY-MM-DDTHH:mm")}
                      onChange={(e) => setValidTill(e.target.value)}
                      className="form-control"
                    />
                    {errors.validTill && (
                      <small style={{ color: "red" }}>
                        {errors.validTill.message}
                      </small>
                    )}
                  </div>
                </div>
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Coupon Type</Form.Label>
              <div className="d-flex">
                <FormCheck
                  type="radio"
                  label="Courses"
                  checked={couponType === "course"}
                  onChange={() => {
                    setShowCourseDropdown(true);
                    setShowSubscriptionDropdown(false);
                    setCouponType("course");
                  }}
                />
                <FormCheck
                  type="radio"
                  label="Subscription"
                  checked={couponType === "subscription"}
                  onChange={() => {
                    setShowSubscriptionDropdown(true);
                    setShowCourseDropdown(false);
                    setCouponType("subscription");
                  }}
                />
              </div>
              {showCourseDropdown && (
                <Select
                  isMulti
                  options={courseOptions}
                  value={courseEdit}
                  onChange={(selectedOptions) => {
                    const selectedCourses1 = selectedOptions.filter((option) =>
                      courseOptions.find(
                        (course) => course.value === option.value
                      )
                    );
                    setSelectedCourses(selectedCourses1);
                    setCourseEdit(selectedCourses1);
                  }}
                />
              )}

              {showSubscriptionDropdown && (
                <Select
                  isMulti
                  options={subscriptionOptions}
                  value={subscriptionEdit}
                  onChange={(selectedOptions) => {
                    const selectedSubscriptions1 = selectedOptions.filter(
                      (option) =>
                        subscriptionOptions.find(
                          (subscription) => subscription.value === option.value
                        )
                    );
                    setSelectedSubscriptions(selectedSubscriptions1);
                    setSubscriptionEdit(selectedSubscriptions1);
                  }}
                />
              )}

              {(showCourseDropdown || showSubscriptionDropdown) && (
                <small style={{ color: "red", float: "left" }}>
                  {errors.couponType && errors.couponType.message}
                </small>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Coupon Discount</Form.Label>
              <div className="d-flex">
                <Form.Check
                  type="radio"
                  label="Percentage"
                  checked={discountType === "percentage"}
                  onChange={() => handleCouponTypeChange("percentage")}
                />
                <Form.Check
                  type="radio"
                  label="Price"
                  checked={discountType === "price"}
                  onChange={() => handleCouponTypeChange("price")}
                />
              </div>
              {discountType && (
                <div>
                  <Form.Control
                    type="number"
                    placeholder={
                      discountType === "percentage"
                        ? "Enter percentage"
                        : "Enter price"
                    }
                    value={couponValue}
                    name="couponValue"
                    onChange={(e) => setCouponValue(e.target.value)}
                  />
                  {errors.couponValue && (
                    <small style={{ color: "red", float: "left" }}>
                      {errors.couponValue.message}
                    </small>
                  )}
                </div>
              )}
            </Form.Group>
            <div className="d-flex justify-content-center">
              <button className="Grn-Btn Half" type="submit">
                update
              </button>
              <button
                className="WHt-Btn Half"
                type="button"
                onClick={() => setShowview1(false)}
              >
                cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* view modal */}

      <Modal show={showview} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>View Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Coupon Name</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData.couponName}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Start Date</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={formatDateTime(viewData.validFrom)}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">End Date</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={formatDateTime(viewData.validTill)}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Coupon Type</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={viewData.couponType}
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">Coupon Value</InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                value={
                  viewData.couponValue
                    ? viewData.discountType === "percentage"
                      ? `${viewData.couponValue}%`
                      : `₹${viewData.couponValue}`
                    : ""
                }
                readOnly
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">
                {viewData.couponType === "course"
                  ? "Selected Courses"
                  : "Selected Subscription Plans"}
              </InputGroup.Text>
              <Form.Control
                id="basicborder"
                aria-describedby="basic-addon3"
                as="ul"
                readOnly
              >
                {viewData.couponType === "course" &&
                  viewData.selectedCourses.map((courseId) => {
                    const course = courseOptions.find(
                      (option) => option.value === courseId.courseId
                    );
                    return (
                      <ol className="unOrdLi">
                        <li key={courseId}>
                          {course ? course.label : courseId.courseId}
                        </li>
                      </ol>
                    );
                  })}
                {viewData.couponType === "subscription" &&
                  viewData.selectedSubscriptions.map((subscriptionId) => {
                    const subscription = subscriptionOptions.find(
                      (option) => option.value === subscriptionId.planId
                    );
                    return (
                      <ol className="unOrdLi">
                        <li key={subscriptionId}>
                          {subscription
                            ? subscription.label
                            : subscriptionId.planId}
                        </li>
                      </ol>
                    );
                  })}
              </Form.Control>
            </InputGroup>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Coupon;
