import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import Select from "react-select";
import moment from "moment";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { loader, isLogin } from "../redux/common";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  activeJobList,
  addJobs,
  deleteJob,
  editJobList,
  jobListData,
  statesList,
} from "../redux/jobs";

const JobList = () => {
  let dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [showview, setShowview] = useState(false);
  const [showview1, setShowview1] = useState(false);
  const [title, setTitle] = useState(" ");
  const [description, setdescription] = useState(" ");
  const [checked, setChecked] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [optionsJt, setOptionsJt] = useState([]);
  const [optionsRem, setOptionsRem] = useState([]);
  const [optionsLoc, setOptionsLoc] = useState([]);
  const [optionsDept, setOptionsDept] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [editTitle, setEditTitle] = useState("");
  const [editDesc, setEditDesc] = useState("");
  const [editDept, setEditDept] = useState([]);
  const [editLocation, setEditLocation] = useState([]);
  const [editLastDate, setEditLastDate] = useState("");
  const [editExpLvl, setEditExpLvl] = useState([]);
  const [editJobType, setEditJobType] = useState([]);
  const [editremote, setEditremote] = useState([]);
  let [refresher, setRefresher] = useState(true);
  let [jobID, setJobID] = useState();

  const fetchLocationOptions = () => {
    dispatch(loader(true));
    dispatch(
      statesList((resp) => {
        if (resp.status) {
          const options = resp.data.map((state, index) => ({
            label: state,
            value: state,
          }));
          setLocationOptions(options);
          dispatch(loader(false));
        } else {
          toast.error(resp.message);
        }
      })
    );
  };
  useEffect(() => {
    fetchLocationOptions();
  }, [refresher]);

  const handleClose = () => {
    setShow(false);
    reset();
  };
  const handleClose2 = () => setShowview1(false);
  const handleClose1 = () => setShowview(false);
  const handleShow = () => {
    setShow(true);
    reset();
    setTitle("");
    setdescription(" ");
    setOptionsDept([]);
    setOptionsLoc([]);
    setOptionsRem([]);
    setOptionsJt([]);
    setOptions([]);
  };
  const handleShow1 = (id) => {
    const selectedJob = jobData.find((plan) => plan._id === id);
    setShowview(true);
    setEditData([selectedJob]);
    setEditTitle(selectedJob.title);
    setEditDesc(selectedJob.description);
    setEditLastDate(selectedJob.lastDateForApply);

    var temp1 = [];
    selectedJob.experienceLevel.map((element) => {
      temp1.push({ label: element, value: element });
    });
    setEditExpLvl(temp1);

    var temp2 = [];
    selectedJob.jobType.map((element) => {
      temp2.push({ label: element, value: element });
    });
    setEditJobType(temp2);

    var temp3 = [];
    selectedJob.remote.map((element) => {
      temp3.push({ label: element, value: element });
    });
    setEditremote(temp3);

    var temp4 = [];
    selectedJob.location.map((element) => {
      temp4.push({ label: element, value: element });
    });
    setEditLocation(temp4);

    var temp5 = [];
    selectedJob.department.map((element) => {
      temp5.push({ label: element, value: element });
    });
    setEditDept(temp5);
    setJobID(id);
  };
  const handleShow2 = (id) => {
    const selectedJob = jobData.find((plan) => plan._id === id);
    setShowview1(true);
    setEditData([selectedJob]);
    setEditTitle(selectedJob.title);
    setEditDesc(selectedJob.description);
    setEditLastDate(selectedJob.lastDateForApply);

    var temp1 = [];
    selectedJob.experienceLevel.map((element) => {
      temp1.push({ label: element, value: element });
    });
    setEditExpLvl(temp1);
    var temp2 = [];
    selectedJob.jobType.map((element) => {
      temp2.push({ label: element, value: element });
    });
    setEditJobType(temp2);
    var temp3 = [];
    selectedJob.remote.map((element) => {
      temp3.push({ label: element, value: element });
    });
    setEditremote(temp3);
    var temp4 = [];
    selectedJob.location.map((element) => {
      temp4.push({ label: element, value: element });
    });
    setEditLocation(temp4);
    var temp5 = [];
    selectedJob.department.map((element) => {
      temp5.push({ label: element, value: element });
    });
    setEditDept(temp5);
    setJobID(id);
  };

  const deleteJobData = (id, index) => {
    if (
      localStorage.getItem("isLogin") == null ||
      localStorage.getItem("isLogin") == false
    ) {
      dispatch(isLogin(false));
    } else {
      dispatch(isLogin(true));
    }
    let data = id;

    dispatch(loader(true));
    dispatch(
      deleteJob(data, (resp) => {
        if (resp.status) {
          setRefresher(!refresher);
          toast.success(resp.message);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const editSubmit = (e) => {
    e.preventDefault();

    let data = {};

    data["jobId"] = jobID;
    data["title"] = editTitle;
    data["description"] = editDesc;
    data["experienceLevel"] = editExpLvl.map((course) => course.value);
    data["jobType"] = editJobType.map((course) => course.value);
    data["remote"] = editremote.map((course) => course.value);
    data["location"] = editLocation.map((course) => course.value);
    data["lastDateForApply"] = new Date(editLastDate).toISOString();
    data["department"] = editDept.map((course) => course.value);

    dispatch(loader(true));
    dispatch(
      editJobList(data, (resp) => {
        if (resp.status) {
          toast.success(resp.message);
          setRefresher(!refresher);
          setShowview1(false);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const {
    register: register,
    formState: { errors: errors },
    reset: reset,
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur" });

  const {
    register: register1,
    formState: { errors: errors1 },
    reset: reset1,
    handleSubmit: handleSubmit1,
  } = useForm({ mode: "onBlur" });

  const addJobsSubmit = (datas) => {
    reset();
    let expLvl = options.map((item) => item.value);
    let jobType = optionsJt.map((item) => item.value);
    let remote = optionsRem.map((item) => item.value);
    let locationData = optionsLoc.map((item) => item.value);
    let dept = optionsDept.map((item) => item.value);

    const validTillDate = new Date(datas.lastDateForApply);
    let data = {};

    data["title"] = title;
    // data["description"] = description;
    data["department"] = dept;
    data["experienceLevel"] = expLvl;
    data["jobType"] = jobType;
    data["remote"] = remote;
    data["location"] = locationData;
    data["lastDateForApply"] = validTillDate.toISOString();

    dispatch(loader(true));
    dispatch(
      addJobs(data, (resp) => {
        if (resp.status) {
          setShow(false);
          setRefresher(!refresher);
          toast.success(resp.message);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };
  const convertToDatetimeLocal = (dateTimeString) => {
    if (!dateTimeString) return "";

    const dateTime = new Date(dateTimeString);
    const year = dateTime.getFullYear();
    const month = `0${dateTime.getMonth() + 1}`.slice(-2);
    const day = `0${dateTime.getDate()}`.slice(-2);
    const hours = `0${dateTime.getHours()}`.slice(-2);
    const minutes = `0${dateTime.getMinutes()}`.slice(-2);
    const seconds = `0${dateTime.getSeconds()}`.slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  const optionsExp = [
    { value: "entry_level", label: "Entry Level" },
    { value: "mid_level", label: "Mid Level" },
    { value: "senior_level", label: "Senior Level" },
  ];

  const optionsJobType = [
    { value: "full_time", label: "Full Time" },
    { value: "part_time", label: "Part Time" },
    { value: "internship", label: "Internship" },
  ];
  const optionsRemote = [
    { value: "remote", label: "Remote" },
    { value: "on_site", label: "On-Site" },
    { value: "hybrid", label: "Hybrid" },
  ];
  const optionsLocation = [
    { value: "chennai", label: "Chennai" },
    { value: "bangalore", label: "Bangalore" },
    { value: "hyderabad", label: "Hyderabad" },
    { value: "mumbai", label: "Mumbai" },
  ];
  const optionsDepartment = [
    { value: "sales", label: "Sales" },
    { value: "marketing", label: "Marketing" },
    { value: "r&d", label: "R&D" },
    { value: "manufacturing", label: "Manufacturing" },
    { value: "medical", label: "Medical" },
    { value: "admin", label: "Admin" },
    { value: "hr", label: "HR" },
    { value: "finance", label: "Finance" },
    { value: "senior_management", label: "Senior management" },
    { value: "others", label: "Others" },
  ];

  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      activeJobList((resp) => {
        if (resp.status) {
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );

    dispatch(
      jobListData((resp) => {
        if (resp.status) {
          setJobData(resp.data);

          var arr = [];
          for (var i = 0; i < resp.data.length; i++) {
            arr.push(resp.data[i].isActive);
          }
          setChecked(arr);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, [refresher]);

  const changeStatus = (id, index) => {
    let data = {};
    let value = {
      isActive: !checked[index],
    };

    data["jobId"] = id;
    data["isActive"] = value.isActive;

    dispatch(loader(true));
    dispatch(
      editJobList(data, (response) => {
        if (response.status) {
          toast.success(response.message);
          dispatch(loader(false));
          setRefresher(!refresher);
        } else {
          dispatch(loader(false));
          toast.error(response.message);
        }
      })
    );
  };

  const handleChangeExp = (e) => {
    setOptions(Array.isArray(e) ? e.map((x) => x) : []);
  };

  const handleChangeJt = (e) => {
    setOptionsJt(Array.isArray(e) ? e.map((x) => x) : []);
  };
  const handleChangeRem = (e) => {
    setOptionsRem(Array.isArray(e) ? e.map((x) => x) : []);
  };
  const handleChangeLoc = (e) => {
    setOptionsLoc(Array.isArray(e) ? e.map((x) => x) : []);
  };
  const handleChangeDept = (e) => {
    setOptionsDept(Array.isArray(e) ? e.map((x) => x) : []);
  };

  const capitalizeFirstLetter = (str) => {
    return str
      .replace(/_/g, " ")
      .replace(/\b\w/g, (match) => match.toUpperCase());
  };

  const processedEditDept = editDept.map((option) => ({
    value: option.value,
    label: capitalizeFirstLetter(option.label),
  }));
  const processedEditExpLvl = editExpLvl.map((option) => ({
    value: option.value,
    label: capitalizeFirstLetter(option.label),
  }));
  const processedEditJobType = editJobType.map((option) => ({
    value: option.value,
    label: capitalizeFirstLetter(option.label),
  }));
  const processedEditRemote = editremote.map((option) => ({
    value: option.value,
    label: capitalizeFirstLetter(option.label),
  }));
  const processedEditLocation = editLocation.map((option) => ({
    value: option.value,
    label: capitalizeFirstLetter(option.label),
  }));

  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-md-5">
              <h3>Job List</h3>
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/job_list">Jobs List</Link>
              </p>
            </div>
            <div className="col-xl-5 col-md-7">
              <div className="Grn-Btnmanin plan">
                <button className="Grn-Btn plan" onClick={handleShow}>
                  Add Jobs
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <Table striped hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Experience Level</th>
                    <th>Job Type</th>
                    <th>Location</th>
                    <th>Remote</th>
                    <th>Last Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {jobData.map((item, index) => {
                    const formattedValidTill = item.lastDateForApply
                      ? moment(item.lastDateForApply).format("DD MMM YYYY")
                      : "-";
                    const formatText = (text) => {
                      return text
                        .toLowerCase()
                        .split("_")
                        .map((word, index) => {
                          if (index === 0) {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          } else {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          }
                        })
                        .join(" ");
                    };
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.title}</td>

                        <td>
                          <ul style={{ padding: "0px" }}>
                            <li>
                              <ul style={{ padding: "0px" }}>
                                {item.experienceLevel.map((exp, expIndex) => (
                                  <li key={expIndex}>{formatText(exp)}</li>
                                ))}
                              </ul>
                            </li>
                          </ul>
                        </td>
                        <td>
                          <ul style={{ padding: "0px" }}>
                            <li>
                              <ul style={{ padding: "0px" }}>
                                {item.jobType.map((jt, jtIndex) => (
                                  <li key={jtIndex}>{formatText(jt)}</li>
                                ))}
                              </ul>
                            </li>
                          </ul>
                        </td>
                        <td>
                          <ul style={{ padding: "0px" }}>
                            <li>
                              <ul style={{ padding: "0px" }}>
                                {item.location.map((loc, locIndex) => (
                                  <li key={locIndex}>
                                    {capitalizeFirstLetter(loc)}
                                  </li>
                                ))}
                              </ul>
                            </li>
                          </ul>
                        </td>
                        <td>
                          <ul style={{ padding: "0px" }}>
                            <li>
                              <ul style={{ padding: "0px" }}>
                                {item.remote.map((rem, remIndex) => (
                                  <li key={remIndex}>{formatText(rem)}</li>
                                ))}
                              </ul>
                            </li>
                          </ul>
                        </td>

                        <td>{formattedValidTill}</td>
                        <td>
                          <div className="d-flex justify-content-center">
                            <div className="">
                              <div className="OnOfF">
                                <Form>
                                  <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    onChange={() =>
                                      changeStatus(item._id, index)
                                    }
                                    checked={checked[index]}
                                  />
                                </Form>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="Options pt-1">
                                <i>
                                  <BsThreeDotsVertical />
                                </i>
                                <ul class="hidden-list tstLi">
                                  <li onClick={() => handleShow1(item._id)}>
                                    View
                                  </li>
                                  <li onClick={() => handleShow2(item._id)}>
                                    Edit
                                  </li>
                                  <li
                                    onClick={() =>
                                      deleteJobData(item._id, index)
                                    }
                                  >
                                    Delete
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showview} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>View Jobs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {editData.map((item, index) => (
              <div key={index}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="title"
                    readOnly
                    defaultValue={
                       editTitle && editTitle.charAt(0).toUpperCase() + editTitle.slice(1)
                    }
                  />
                </Form.Group>
                {/* <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Job Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    readOnly
                    defaultValue={
                      editDesc && editDesc.charAt(0).toUpperCase() + editDesc.slice(1)
                    }
                    name="description"
                  />
                </Form.Group> */}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Select Department</Form.Label>
                  <Select isMulti value={processedEditDept} />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <div className="col-3">
                    <Form.Label className="expLvlLabel">
                      Select Experience Level
                    </Form.Label>
                  </div>
                  <div className="col-12">
                    <Select isMulti value={processedEditExpLvl} />
                  </div>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <div className="col-3">
                    <Form.Label>Select Job Type</Form.Label>
                  </div>
                  <div className="col-12">
                    <Select isMulti value={processedEditJobType} />
                  </div>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <div className="col-3">
                    <Form.Label>Select Remote</Form.Label>
                  </div>
                  <div className="col-12">
                    <Select isMulti value={processedEditRemote} />
                  </div>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Location</Form.Label>
                  <Select isMulti value={processedEditLocation} />
                </Form.Group>
                <div>
                  <Form.Label>Last Date For Apply</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    readOnly
                    defaultValue={convertToDatetimeLocal(editLastDate)}
                    className="form-control"
                    min={new Date().toISOString().slice(0, 16)}
                  />
                </div>
              </div>
            ))}
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Jobs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(addJobsSubmit)}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
              {errors.title && (
                <small style={{ color: "red" }}>{errors.title.message}</small>
              )}
            </Form.Group>
           {/*  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Job Description</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={description}
                onChange={(e) => setdescription(e.target.value)}
                name="description"
              />
              {errors.description && (
                <small style={{ color: "red" }}>
                  {errors.description.message}
                </small>
              )}
            </Form.Group>*/}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Select Department</Form.Label>

              <Select
                isMulti
                value={optionsDept}
                onChange={handleChangeDept}
                options={optionsDepartment}
              />
            </Form.Group>
            {errors.department && (
              <small style={{ color: "red" }}>
                {errors.department.message}
              </small>
            )}

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div className="col-3">
                <Form.Label className="expLvlLabel">
                  Select Experience Level
                </Form.Label>
              </div>
              <div className="col-12">
                <Select
                  isMulti
                  value={options}
                  onChange={handleChangeExp}
                  options={optionsExp}
                />
              </div>
              {errors.experienceLevel && (
                <small style={{ color: "red", float: "left" }}>
                  {errors.experienceLevel.message}
                </small>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div className="col-3">
                <Form.Label>Select Job Type</Form.Label>
              </div>
              <div className="col-12">
                <Select
                  isMulti
                  value={optionsJt}
                  onChange={handleChangeJt}
                  options={optionsJobType}
                />
                {errors.jobType && (
                  <small style={{ color: "red", float: "left" }}>
                    {errors.jobType.message}
                  </small>
                )}
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div className="col-3">
                <Form.Label>Select Remote</Form.Label>
              </div>
              <div className="col-12">
                <Select
                  isMulti
                  value={optionsRem}
                  onChange={handleChangeRem}
                  options={optionsRemote}
                />
                {errors.remote && (
                  <small style={{ color: "red", float: "left" }}>
                    {errors.remote.message}
                  </small>
                )}
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Location</Form.Label>
              <Select
                isMulti
                value={optionsLoc}
                onChange={handleChangeLoc}
                options={locationOptions}
              />
            </Form.Group>
            {errors.location && (
              <small style={{ color: "red" }}>{errors.location.message}</small>
            )}

            <div>
              <Form.Label>Last Date For Apply</Form.Label>
              <Form.Control
                type="datetime-local"
                className="form-control"
                min={new Date().toISOString().slice(0, 16)}
                {...register("lastDateForApply", {
                  required: "lastDateForApply is required",
                })}
              />

              {errors.lastDateForApply && (
                <small style={{ color: "red" }}>
                  {errors.lastDateForApply.message}
                </small>
              )}
            </div>

            <div className="d-flex justify-content-center mt-5 mb-3">
              <button className="Grn-Btn Half" type="submit">
                add
              </button>
              <button
                className="WHt-Btn Half"
                type="button"
                onClick={handleClose}
              >
                cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showview1} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Jobs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={editSubmit}>
            {editData.map((item, index) => (
              <div key={index}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="title"
                    defaultValue={
                      editTitle.charAt(0).toUpperCase() + editTitle.slice(1)
                    }
                    onChange={(e) => setEditTitle(e.target.value)}
                  />
                  {errors1.title && (
                    <small style={{ color: "red" }}>
                      {errors1.title.message}
                    </small>
                  )}
                </Form.Group>
                {/* <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Job Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    defaultValue={editDesc}
                    onChange={(e) => setEditDesc(e.target.value)}
                    name="description"
                  />
                  {errors1.description && (
                    <small style={{ color: "red" }}>
                      {errors1.description.message}
                    </small>
                  )}
                </Form.Group> */}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Select Department</Form.Label>

                  <Select
                    isMulti
                    value={processedEditDept}
                    options={optionsDepartment}
                    onChange={(selectedOptions) => {
                      const selectedDept = selectedOptions.filter((option) =>
                        optionsDepartment.find(
                          (item) => item.value === option.value
                        )
                      );
                      setEditDept(selectedDept);
                    }}
                  />
                  {errors1.department && (
                    <small style={{ color: "red" }}>
                      {errors1.department.message}
                    </small>
                  )}
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <div className="col-3">
                    <Form.Label className="expLvlLabel">
                      Select Experience Level
                    </Form.Label>
                  </div>
                  <div className="col-12">
                    <Select
                      isMulti
                      value={processedEditExpLvl}
                      options={optionsExp}
                      onChange={(selectedOptions) => {
                        const selectedExpeLvl = selectedOptions.filter(
                          (option) =>
                            optionsExp.find(
                              (item) => item.value === option.value
                            )
                        );
                        setEditExpLvl(selectedExpeLvl);
                      }}
                    />
                  </div>
                  {errors1.experienceLevel && (
                    <small style={{ color: "red", float: "left" }}>
                      {errors1.experienceLevel.message}
                    </small>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <div className="col-3">
                    <Form.Label>Select Job Type</Form.Label>
                  </div>
                  <div className="col-12">
                    <Select
                      isMulti
                      value={processedEditJobType}
                      options={optionsJobType}
                      onChange={(selectedOptions) => {
                        const selectedjobtype = selectedOptions.filter(
                          (option) =>
                            optionsJobType.find(
                              (item) => item.value === option.value
                            )
                        );

                        setEditJobType(selectedjobtype);
                      }}
                    />
                    {errors1.jobType && (
                      <small style={{ color: "red", float: "left" }}>
                        {errors1.jobType.message}
                      </small>
                    )}
                  </div>
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <div className="col-3">
                    <Form.Label>Select Remote</Form.Label>
                  </div>
                  <div className="col-12">
                    <Select
                      isMulti
                      value={processedEditRemote}
                      options={optionsRemote}
                      onChange={(selectedOptions) => {
                        const selectedremote = selectedOptions.filter(
                          (option) =>
                            optionsRemote.find(
                              (item) => item.value === option.value
                            )
                        );

                        setEditremote(selectedremote);
                      }}
                    />
                    {errors1.remote && (
                      <small style={{ color: "red", float: "left" }}>
                        {errors1.remote.message}
                      </small>
                    )}
                  </div>
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Location</Form.Label>
                  <Select
                    isMulti
                    value={processedEditLocation}
                    options={locationOptions}
                    onChange={(selectedOptions) => {
                      const selectedLocation = selectedOptions.filter(
                        (option) =>
                          locationOptions.find(
                            (item) => item.value === option.value
                          )
                      );
                      setEditLocation(selectedLocation);
                    }}
                  />
                  {errors1.location && (
                    <small style={{ color: "red" }}>
                      {errors1.location.message}
                    </small>
                  )}
                </Form.Group>
                <div>
                  <Form.Label>Last Date For Apply</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    defaultValue={convertToDatetimeLocal(editLastDate)}
                    onChange={(e) => setEditLastDate(e.target.value)}
                    className="form-control"
                    min={new Date().toISOString().slice(0, 16)}
                  />

                  {errors1.lastDateForApply && (
                    <small style={{ color: "red" }}>
                      {errors1.lastDateForApply.message}
                    </small>
                  )}
                </div>

                <div className="d-flex justify-content-center mt-5 mb-3">
                  <button className="Grn-Btn Half" type="submit">
                    Update
                  </button>
                  <button
                    className="WHt-Btn Half"
                    type="button"
                    onClick={handleClose2}
                  >
                    cancel
                  </button>
                </div>
              </div>
            ))}
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default JobList;
