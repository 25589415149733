import Form from "react-bootstrap/Form";
import "./Adminlist.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  add_admin_roles,
  admin_roles_delete,
  list_of_admin_roles,
} from "../redux/login";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

const Adminrole = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [adminRoles, setAdminRoles] = useState([]);
  const [role, setRole] = useState("");
  const [isActive, setIsActive] = useState(false);
  let [refresher, setRefresher] = useState(true);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      list_of_admin_roles((resp) => {
        if (resp.status) {
          setAdminRoles(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );
  }, [refresher]);
  const deleteRoles = (id) => {
    let data = {};
    data["roleId"] = id;
    dispatch(loader(true));
    dispatch(
      admin_roles_delete(data, (resp) => {
        if (resp.status) {
          toast.success(resp.message);
          dispatch(loader(false));
          setRefresher(!refresher);
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    let data = {};
    data["role"] = role;
    data["isActive"] = isActive;
    dispatch(loader(true));
    dispatch(
      add_admin_roles(data, (result) => {
        if (result.status) {
          toast.success(result.message);
          setRole("");
          setIsActive(0);
          setRefresher(!refresher);
          handleClose();
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(result.message);
        }
      })
    );
  };
  const capitalizeAndRemoveUnderscores = (str) => {
    // Check if str is undefined or null, and handle it gracefully
    if (!str) {
      return ""; // or any default value you prefer
    }

    // Split the string by underscores and capitalize each word
    const words = str
      .split("_")
      .map((word) => {
        // Capitalize the first letter of each word
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" "); // Join the words back together with a space

    return words;
  };

  return (
   
    
      <div className="Categorypage">
        <div className="PageTit ">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h3>Admin Roles</h3>
                <p>
                  <Link to="/dashboard">Dashboard</Link>{" "}
                  <i>
                    <AiOutlineRight />
                  </i>{" "}
                  <Link to="/">Admin Roles</Link>
                </p>
              </div>
              <div className="col-6">
                <div className="Grn-Btnmanin">
                  <button className="Grn-Btn" onClick={handleShow}>
                    add Roles
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="CateCont">
          <div className="container">
            <div className="row">
              <div className="CateGorTab">
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>Role</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {adminRoles.map((item) => {
                      return (
                        <tr key={item._id}>
                          <td>{capitalizeAndRemoveUnderscores(item.role)}</td>
                          <td className="ddFlAR ">
                            <div className="Options">
                              <i>
                                <BsThreeDotsVertical />
                              </i>
                              <ul class="hidden-list Ar">
                                <li>
                                  <Link to={`/permission_list/${item._id}`}>
                                    Assign Permission{" "}
                                  </Link>
                                </li>
                                <li onClick={() => deleteRoles(item._id)}>
                                  Delete
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>

        {/*Add Modal */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Roles</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Roles</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Roles"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  autoFocus
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Status</Form.Label>
                <br></br>
                <select
                  name="status"
                  value={isActive ? "1" : "0"} // Convert true to '1', false to '0'
                  onChange={(e) => setIsActive(e.target.value === "1")}
                >
                  <option value="0">Inactive</option>
                  <option value="1">Active</option>
                </select>
              </Form.Group>

              <div className="d-flex justify-content-center">
                <button type="submit" className="Grn-Btn Half">
                  add
                </button>
                <button
                  type="button"
                  className="WHt-Btn Half"
                  onClick={handleClose}
                >
                  cancel
                </button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
  
  );
};
export default Adminrole;
