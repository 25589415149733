import React, { Fragment, useEffect, useState } from "react";
import "./Dashboard.css";
import { Link } from "react-router-dom/dist";
import { Line } from "react-chartjs-2";
import {
  Chart as chartjs,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
} from "chart.js";
import Topbar from "../../components/topbar/Topbar";
import SideBar from "../../components/Sidebar/SideBar";
import { dashboard_graph, dashboard_info } from "../redux/login";
import { loader } from "../redux/common";
import { useDispatch } from "react-redux";

chartjs.register(LineElement, CategoryScale, LinearScale, PointElement, Legend);
const Dashboard = () => {
  let dispatch = useDispatch();
  const [ttlCount, setTtlCount] = useState({});
  const [graphData, setGraphData] = useState({});
  const [selectedYear, setSelectedYear] = useState(2024);
  const handleYearChange = (year) => {
    setSelectedYear(year);
  };
  const currentYear = new Date().getFullYear();
  const yearOptions = [
    <option key={currentYear} value={currentYear}>
      {currentYear}
    </option>,
  ];
  for (let year = currentYear - 1; year >= 2020; year--) {
    yearOptions.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }
  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      dashboard_info((resp) => {
        if (resp.status) {
          setTtlCount(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );
    dispatch(
      dashboard_graph((resp) => {
        if (resp.status) {
          const modifiedData = {};
          resp.data.forEach((item) => {
            if (item._id.year === selectedYear) {
              switch (item._id.month) {
                case 1:
                  modifiedData["Jan"] = item.totalDiscountedPrice;
                  break;
                case 2:
                  modifiedData["Feb"] = item.totalDiscountedPrice;
                  break;
                case 3:
                  modifiedData["Mar"] = item.totalDiscountedPrice;
                  break;
                case 4:
                  modifiedData["Apr"] = item.totalDiscountedPrice;
                  break;
                case 5:
                  modifiedData["May"] = item.totalDiscountedPrice;
                  break;
                case 6:
                  modifiedData["Jun"] = item.totalDiscountedPrice;
                  break;
                case 7:
                  modifiedData["July"] = item.totalDiscountedPrice;
                  break;
                case 8:
                  modifiedData["Aug"] = item.totalDiscountedPrice;
                  break;
                case 9:
                  modifiedData["Sep"] = item.totalDiscountedPrice;
                  break;
                case 10:
                  modifiedData["Oct"] = item.totalDiscountedPrice;
                  break;
                case 11:
                  modifiedData["Nov"] = item.totalDiscountedPrice;
                  break;
                case 12:
                  modifiedData["Dec"] = item.totalDiscountedPrice;
                  break;

                default:
                  break;
              }
            }
          });

          setGraphData(modifiedData);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );
  }, [selectedYear]);

  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Sales",
        data: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "July",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ].map((month) => graphData[month] || 0), // Map the totalDiscountedPrice based on label (month)
        backgroundColor: "#107B38",
        borderColor: "#107B38",
        pointBorderColor: "pink",
        fill: true,
      },
    ],
  };

  const options1 = {
    plugins: {
      // legend: true
      legend: {
        onClick: (e) => e.stopPropagation(), // Prevent interaction with legend onClick
      },
    },
    scales: {
      y: {
        min: 0, // Set the minimum value for the y-axis
        max: 100000, // Set the maximum value for the y-axis
        // Add other configurations as needed
      },
    },
  };

  return (
    
      <div className="Categorypage">
        <div className="PageTit ">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h3>Dashboard</h3>
                <p>
                  <Link to="/dashboard">Dashboard</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="CateCont">
          <div className="container">
            <div className="row">
              <div className="CateGorTab">
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div className="Count OranGE">
                      <h3>{ttlCount.totalCourse}</h3>
                      <h6>Total Course</h6>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="Count BluE">
                      <h3>{ttlCount.totalSubscribers}</h3>
                      <h6>Total Subscribers</h6>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="Count PuRp">
                      <h3>{ttlCount.totalLearners}</h3>
                      <h6>Learners</h6>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="Count YlW">
                      <h3>{ttlCount.totalEducators}</h3>
                      <h6>Educators</h6>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div
                      style={{
                        width: "200px",
                        overflowY: "scroll",
                        maxHeight: "150px",
                      }}
                    >
                      <select
                        value={selectedYear}
                        onChange={(e) =>
                          handleYearChange(parseInt(e.target.value))
                        }
                        style={{ width: "100%" }}
                      >
                        {yearOptions}
                      </select>
                    </div>
                    <div
                      className="GrAphS"
                      style={{
                        height: "520px",
                        width: "100%",
                      }}
                    >
                      <Line data={data} options1={options1}></Line>
                    </div>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="col-lg-3 col-md-6">
                    <div className="Count GRn">
                      <h3>{ttlCount.activeCourse}</h3>
                      <h6>Active Course</h6>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="Count ReD">
                      <h3>{ttlCount.pendingCourse}</h3>
                      <h6>Pending Course</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   
  );
};

export default Dashboard;
