import { createSlice } from "@reduxjs/toolkit";
import Helper from "../../helper/axiosHelper";
const baseUrl = Helper.baseUrl();

const initialState = {
  //   firstSignUp: {},
};

export const bannerSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {},
});
export const { addBlog, blogList, updateBlog } = bannerSlice.actions;

export default bannerSlice.reducer;

// export const subscriptionList =
//   (callback = () => {}) =>
//   async (dispatch) => {
//     var result = await Helper.getData(baseUrl + "banner/banner_list").then(
//       (response) => response.data
//     );
//     callback(result);
//   };

export const addBannerSubScription =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.formData(
      baseUrl + "subscription_banner/add_subscription_banner",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const SubBannersList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + "subscription_banner/subscription_banner_list"
    ).then((response) => response.data);
    callback(result);
  };

// export const viewBanner =
//   (data, callback = () => {}) =>
//   async (dispatch) => {
//     var result = await Helper.postData(
//       baseUrl + "banner/banner_info",
//       data
//     ).then((response) => response.data);
//     callback(result);
//   };
  

  export const viewSubBanner =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "subscription_banner/subscription_banner_info",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const editSubBanner =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.pathFormData(
      baseUrl + "subscription_banner/edit_subscription_banner",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const deleteSubBanner =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "subscription_banner/delete_subscription_banner",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const subChangeStatus =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(
      baseUrl + "subscription_banner/change_status_of_subscription_banner",
      data
    ).then((response) => response.data);
    callback(result);
  };
  export const addSubscriptionPlan =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "subscription_plan/add_subscription_plan",
      data
    ).then((response) => response.data);
    callback(result);
  };
  export const activeCourseBundleList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + "course/bundle/activeList"
    ).then((response) => response.data);
    callback(result);
  };
  export const subscriptionPlanList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + "subscription_plan/subscription_plan_list"
    ).then((response) => response.data);
    callback(result);
  };
  export const editSubscriptionPlan =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(
      baseUrl + "subscription_plan/edit_subscription_plan",
      data
    ).then((response) => response.data);
    callback(result);
  };
  export const deleteSubscriptionPlan =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "subscription_plan/delete_subscription_plan",
      data
    ).then((response) => response.data);
    callback(result);
  };
  export const SubscriptionPlanInfo =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "subscription_plan/subscription_plan_info",
      data
    ).then((response) => response.data);
    callback(result);
  };
  export const activeSubscriptionPlanList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + "subscription_plan/active_subscription_plan_list"
    ).then((response) => response.data);
    callback(result);
  };