import React, { useEffect, useState } from "react";
import { TbFileUpload } from "react-icons/tb";
import "./Banners.css";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import { addBanner } from "../redux/banners";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import helper from "../../helper/axiosHelper";

const Addbanner = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const [previewImage, setPreviewImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [isCorporate, setIsCorporate] = useState(false);
  const [corporates, setCorporates] = useState([]);
  const [selectedCorporate, setSelectedCorporate] = useState("");

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onBlur" });

  const handleProfilePic = (event) => {
    const selectedFile = event.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];

    if (selectedFile && allowedTypes.includes(selectedFile.type)) {
      setImageFile(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };

      try {
        reader.readAsDataURL(selectedFile);
      } catch (error) {
        console.error("Error reading the file:", error);
      }
    } else {
      toast.error("Please select a valid image file (JPEG, PNG, GIF)");
      event.target.value = null;
    }
  };

  const bannerAdd = (data) => {
    console.log("data", data);

    let datas = {
      thumbnail: imageFile,
      videoUrl: data.videoUrl,
      bannerFor: data.bannerFor,
    };

    if (data.bannerFor === "Corporate") {
      datas.corporate = selectedCorporate;
    }

    dispatch(loader(true));
    dispatch(
      addBanner(datas, (resp) => {
        if (resp.status) {
          toast.success(resp.message);
          navigate("/banner");
        } else {
          toast.error(resp.message);
        }
        dispatch(loader(false));
      })
    );
  };

  const cancelBanner = () => {
    navigate("/banner");
  };

  const list_corporates = async () => {
    try {
      const response = await helper.postData(
        helper.baseUrl() + "getCorporates"
      );
      console.log("Corporates Data:", response.data.corporates);
      setCorporates(response.data.corporates);
    } catch (error) {
      console.error("Error fetching corporates:", error);
    }
  };

  const handleCorporateChange = (event) => {
    setSelectedCorporate(event.target.value);
  };

  useEffect(() => {
    list_corporates();
  }, []);

  return (
    <div className="Categorypage">
      <div className="PageTit">
        <div className="container">
          <div className="row">
            <div className="col-10">
              <h3>Add Banner</h3>
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="">Content Update</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/banner">Banners</Link>
              </p>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <div className="TittlE">
                <h4>Add Banner</h4>
              </div>
              <div className="ExpertSBacKG">
                <form onSubmit={handleSubmit(bannerAdd)}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="ThumBnail banner">
                        <div className="upLoad upLoaded">
                          <label htmlFor="imgupload">
                            <div
                              style={{
                                display: previewImage ? "none" : "block",
                              }}
                            >
                              Image Dimensions (1920 x 550)
                              <span className="text-center">
                                <TbFileUpload className="uploadIcons bannerIcon" />
                              </span>
                            </div>
                            <input
                              type="file"
                              id="imgupload"
                              onChange={handleProfilePic}
                              style={{ display: "none" }}
                            />
                            <div
                              style={{
                                display: previewImage ? "none" : "block",
                              }}
                            >
                              <i style={{ display: "none" }}>
                                <TbFileUpload className="uploadIcon " />
                              </i>
                              <p>Click here to upload</p>
                            </div>
                            {previewImage && (
                              <img
                                src={previewImage}
                                className="img-fluid upLoad"
                                alt="User Profile"
                              />
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 pt-3">
                      <div className="SHortsForm banner">
                        <label>Video URL</label>
                        <br />
                        <input
                          type="text"
                          className="FormShorts"
                          name="videoUrl"
                          {...register("videoUrl", {
                            required: "Please Enter a valid URL",
                            pattern: {
                              value:
                                /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/,
                              message: "Invalid URL format",
                            },
                          })}
                        />
                        {errors.videoUrl && (
                          <small style={{ color: "red" }}>
                            {errors.videoUrl.message}
                          </small>
                        )}
                      </div>
                      <div className="row bannerRadios">
                        <div className="col-lg-3 col-md-4">
                          <input
                            className="bannerinput"
                            type="radio"
                            name="profession"
                            value="Student"
                            {...register("bannerFor", {
                              required: "Please select any one of the option",
                            })}
                            onChange={(e) =>
                              setIsCorporate(e.target.value === "Corporate")
                            }
                          />
                          <label className="professionbanner">Student</label>
                        </div>
                        <div className="col-lg-3 col-md-4">
                          <input
                            className="bannerinput"
                            type="radio"
                            name="profession"
                            value="workingProfessional"
                            {...register("bannerFor", {
                              required: "Please select any one of the option",
                            })}
                            onChange={(e) =>
                              setIsCorporate(e.target.value === "Corporate")
                            }
                          />
                          <label className="professionbanner">Working</label>
                        </div>
                        <div className="col-lg-3 col-md-4">
                          <input
                            className="bannerinput"
                            type="radio"
                            name="profession"
                            value="Doctor"
                            {...register("bannerFor", {
                              required: "Please select any one of the option",
                            })}
                            onChange={(e) =>
                              setIsCorporate(e.target.value === "Corporate")
                            }
                          />
                          <label className="professionbanner">Doctor</label>
                        </div>
                        <div className="col-lg-3 col-md-4">
                          <input
                            className="bannerinput"
                            type="radio"
                            name="profession"
                            value="Corporate"
                            {...register("bannerFor", {
                              required: "Please select any one of the option",
                            })}
                            onChange={(e) =>
                              setIsCorporate(e.target.value === "Corporate")
                            }
                          />
                          <label className="professionbanner">Corporate</label>
                        </div>
                        {errors.bannerFor && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.bannerFor.message}
                          </small>
                        )}
                      </div>

                      {isCorporate && (
                        <div className="row">
                          <div className="col-lg-12 col-12">
                            <select
                              value={selectedCorporate}
                              onChange={handleCorporateChange}
                              style={{
                                marginTop: "20px",
                                height: "50px",
                                width: "100%",
                                padding: "10px",
                                borderRadius: "4px",
                                border: "1px solid #ccc",
                                fontSize: "18px",
                                color: "#333",
                                outline: "none",
                              }}
                            >
                              <option value="">Select Corporate Name</option>
                              {corporates.map((corporate, index) => (
                                <option key={index} value={corporate._id}>
                                  {corporate.corporateName}
                                </option>
                              ))}
                            </select>
                            {errors?.level && (
                              <p style={{ color: "red" }}>
                                {errors.level.message}
                              </p>
                            )}
                          </div>
                        </div>
                      )}

                      <div className="row justify-content-center bannerBtn">
                        <div className="col-lg-2 col-md-4">
                          <div className="SHortsForm1">
                            <button className="Grn-Btn" type="submit">
                              Add
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-4">
                          <div className="SHortsForm1">
                            <button
                              className="WHt-Btn"
                              type="button"
                              onClick={cancelBanner}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addbanner;
