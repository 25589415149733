import { NavLink } from "react-router-dom";
import { FaBars, FaQuora, FaUserEdit } from "react-icons/fa";
import { BiCategory, BiBookOpen, BiUser, BiCommentAdd } from "react-icons/bi";
import {
  AiOutlineDashboard,
  AiOutlineShopping,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { BsCardList, BsFiles } from "react-icons/bs";
import { MdOutlineContentCopy } from "react-icons/md";
import { TfiLayoutSliderAlt } from "react-icons/tfi";
import { RiAdminLine, RiChatFollowUpLine, RiCouponFill } from "react-icons/ri";
import { PiStudentBold, PiNotepadBold } from "react-icons/pi";
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { BiPurchaseTagAlt } from "react-icons/bi";
import { MdOutlineModelTraining } from "react-icons/md";
import { GrUserWorker } from "react-icons/gr";
import { MdOutlineSubscriptions } from "react-icons/md";
import {
  MdRateReview,
  MdOutlineSettings,
  MdOutlineLiveTv,
  MdReviews,
  MdOutlinePreview,
} from "react-icons/md";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import logo from "../../assets/images/wkareersity-logo.png";
import { RiSuitcaseLine } from "react-icons/ri";
import { useEffect } from "react";

const routes = [
  {
    path: "/dashboard",
    section: "dashboard",
    name: "Dashboard",
    icon: <AiOutlineDashboard />,
  },
  {
    path: "/category",
    section: "category",
    name: "Category",
    icon: <BiCategory />,
  },

  {
    path: "/corporate",
    section: "corporate",
    name: "Corporate",
    icon: <BiCategory />,
  },

  {
    path: "/Courses",
    section: "courses",
    name: "Courses",
    icon: <BiBookOpen />,
    subRoutes: [
      {
        path: "/courses",
        name: "Manage Courses",
        icon: <AiOutlineUnorderedList />,
      },
      {
        path: "/Add_course",
        name: "Add New Courses",
        icon: <BiCommentAdd />,
      },
      {
        path: "/course_bundle",
        name: "Manage Courses Bundle",
        icon: <BsFiles />,
      },
    ],
  },
  {
    path: "/subscription_plan",
    section: "subscription_plan",
    name: "Subscription Plan",
    icon: <MdOutlineSubscriptions />,
  },
  {
    path: "/list_to_experts",
    section: "listen_to_experts",
    name: "Listen to experts",
    icon: <BsCardList />,
  },
  {
    path: "/learners",
    section: "learners",
    name: "Learners",
    icon: <PiStudentBold />,
  },
  {
    path: "/educators",
    section: "educators",
    name: "Educators",
    icon: <LiaChalkboardTeacherSolid />,
  },
  {
    path: "/blogs",
    section: "blog",
    name: "Blogs",
    icon: <PiNotepadBold />,
  },
  {
    path: "/testimonials",
    section: "testimonial",
    name: "Testimonials",
    icon: <MdRateReview />,
  },
  {
    path: "/videotestimonials",
    section: "video_testimonial",
    name: "VideoTestimonials",
    icon: <MdOutlinePreview />,
  },
  {
    path: "/faq",
    section: "faq",
    name: "FAQ",
    icon: <FaQuora />,
  },
  {
    path: "/reviews",
    section: "ratings_and_reviews",
    name: "Reviews and Ratings",
    icon: <MdReviews />,
  },
  {
    path: "/coupons",
    section: "coupons",
    name: "Coupons",
    icon: <RiCouponFill />,
  },
  {
    path: "/job_list",
    name: "Jobs List",
    icon: <RiSuitcaseLine />,
  },
  {
    path: "/job_applicants",
    name: "Jobs Applicants",
    icon: <GrUserWorker />,
  },

  {
    path: "/purchase_history",
    section: "purchase_history",
    name: "Purchase History",
    icon: <BiPurchaseTagAlt />,
    subRoutes: [
      {
        path: "/purchase_list",
        name: "Courses",
        icon: <BiBookOpen />,
      },
      {
        path: "/sub_purchase_list",
        name: "Subscriptions",
        icon: <AiOutlineShopping />,
      },
      {
        path: "/training_purchase_list",
        name: "Direct Training",
        icon: <MdOutlineModelTraining />,
      },
    ],
  },
  {
    path: "/user_managements",
    section: "user_management",
    name: "User Managements",
    icon: <BiUser />,
    subRoutes: [
      {
        path: "/admin_user",
        name: "Admin User",
        icon: <RiAdminLine />,
      },
      {
        path: "/admin_role",
        name: "Admin Role",
        icon: <FaUserEdit />,
      },
    ],
  },

  {
    path: "/configure_settings",
    section: "configure_settings",
    name: "Configuration Settings",
    icon: <MdOutlineSettings />,
    subRoutes: [
      {
        path: "/change_password",
        name: "Change Password",
        icon: <RiAdminLine />,
      },
    ],
  },

  {
    path: "",
    name: "Content Update",
    section: "content_update",
    icon: <MdOutlineContentCopy />,
    subRoutes: [
      {
        path: "/banner",
        name: "Banner",
        section: "subscription_banner",
        icon: <TfiLayoutSliderAlt />,
      },
      {
        path: "/subscription_banner",
        name: "Subscription Banner",
        section: "subscription_banner",
        icon: <RiChatFollowUpLine />,
      },
      {
        path: "/live_program",
        name: "Live Program banner",
        section: "live_program_banner",

        icon: <MdOutlineLiveTv />,
      },
    ],
  },
];

const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [userType, setUserType] = useState();
  const [sidebarSections, setSidebarSections] = useState([]);
  const [refresher, setRefresher] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  const shouldDisplayCourses = sidebarSections.some(
    (section) => section.section === "courses" && section.permissions.length > 0
  );

  useEffect(() => {
    let storageType = localStorage.getItem("userType");
    setUserType(storageType);

    const storedSections = JSON.parse(localStorage.getItem("sidebarSections"));
    setSidebarSections(storedSections || []);
  }, [refresher, userType]);

  const filteredRoutes = routes.filter((route) => {
    if (userType === "super_admin") {
      return true;
    } else if (userType === "educator") {
      // Exclude specific subRoute for educator user type
      const storedSections = JSON.parse(
        localStorage.getItem("sidebarSections")
      );
      if (storedSections) {
        const sections = storedSections
          .filter((item) => item.section !== "something")
          .map((item) => item.section)
          .filter(Boolean);

        if (sections.includes(route.section)) {
          if (route.section === "courses") {
            // Exclude specific subRoute "Manage Courses Bundle" for educators
            route.subRoutes = route.subRoutes
              .filter((subRoute) => subRoute.name !== "Manage Courses Bundle")
              .filter(Boolean);
          }
          return true;
        } else {
          return false;
        }
      }
    } else {
      const storedSections = JSON.parse(
        localStorage.getItem("sidebarSections")
      );
      console.log(storedSections);
      if (storedSections) {
        const sections = storedSections
          .filter((item) => item.section !== "something")
          .map((item) => item.section)
          .filter(Boolean);

        console.log(sections);
        if (sections.includes(route.section)) {
          return true;
        } else {
          return false;
        }
      }
    }
  });
  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.8,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "300px" : "45px",
          }}
          className={`sidebar `}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="show"
                  animate="show"
                  exit="hidden"
                  className="logo"
                >
                  <img src={logo} alt="logo" className="img-fluid" />
                </motion.h1>
              )}
            </AnimatePresence>

            <div className="bars">
              <FaBars onClick={toggle} className="toggleBtn" />
            </div>
          </div>

          <section className={`routes ${!isOpen ? "" : "add-gap"}`}>
            {filteredRoutes.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    isOpen={isOpen}
                  />
                );
              }
              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link"
                  activeClassName="active"
                >
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className={`link_text ${!isOpen ? "" : "add-gap"}`}
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}
          </section>
        </motion.div>
        <main>{children}</main>
      </div>
    </>
  );
};

export default SideBar;
