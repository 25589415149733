import { createSlice } from "@reduxjs/toolkit";
import Helper from "../../helper/axiosHelper";
const baseUrl = Helper.baseUrl();

const initialState = {
  //   firstSignUp: {},
};

export const learnerSlice = createSlice({
  name: "shorts",
  initialState,
  reducers: {},
});

export const { firstSignUp } = learnerSlice.actions;

export default learnerSlice.reducer;

export const shortsList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + "listen_to_experts/shorts"
    ).then((response) => response.data);
    callback(result);
  };

export const viewShortsList =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "listen_to_experts/shorts/info",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const editShortsList =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.pathFormData(
      baseUrl + "listen_to_experts/shorts/edit",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const deleteShortsList =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "listen_to_experts/shorts/delete",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const addShortsList =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.formData(
      baseUrl + "listen_to_experts/shorts/add",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const changeStatusShorts =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(
      baseUrl + "listen_to_experts/shorts/changeStatus",
      data
    ).then((response) => response.data);
    callback(result);
  };

  export const viewTestimonialsList =
  ( callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData( baseUrl + "testimonials")
    .then((response) => response.data);
    callback(result);
  };

  export const addTestimonialCb =
  ( data,callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData( baseUrl + "testimonial/create_new",data)
    .then((response) => response.data);
    callback(result);
  };

  export const deleteTestCb =
  ( data,callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData( baseUrl + "testimonial/del",data)
    .then((response) => response.data);
    callback(result);
  };

  
  export const editTestCb =
  ( data,callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.pathFormData( baseUrl + "testimonial/update",data)
    .then((response) => response.data);
    callback(result);
  };


  
  export const addFAQCb =
  ( data,callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData( baseUrl + "faq/create_new",data)
    .then((response) => response.data);
    callback(result);
  };

  export const deleteFAQCb =
  ( data,callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData( baseUrl + "faq/del",data)
    .then((response) => response.data);
    callback(result);
  };

  
  export const editFAQCb =
  ( data,callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData( baseUrl + "faq/update",data)
    .then((response) => response.data);
    callback(result);
  };



  export const viewFAQList =
  ( callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData( baseUrl + "faq/list")
    .then((response) => response.data);
    callback(result);
  };
